import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >

	 
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M611.000000,1081.000000 
	C407.356781,1081.000000 204.213562,1081.000000 1.035167,1081.000000 
	C1.035167,721.065002 1.035167,361.129974 1.035167,1.097480 
	C360.893463,1.097480 720.786926,1.097480 1080.840210,1.097480 
	C1080.840210,360.999939 1080.840210,720.999939 1080.840210,1081.000000 
	C924.462830,1081.000000 767.981384,1081.000000 611.000000,1081.000000 
M786.006226,330.994995 
	C786.006226,330.994995 786.080811,330.944550 785.967468,330.409119 
	C785.676392,330.218140 785.385376,330.027191 785.117371,329.932129 
	C785.117371,329.932129 785.176575,329.853210 785.024353,329.197723 
	C782.385193,326.431213 779.746033,323.664703 776.997559,320.992126 
	C776.997559,320.992126 777.103943,320.892059 776.906738,320.252777 
	C770.971191,314.473511 765.035706,308.694275 758.954651,302.417786 
	C758.658203,302.267944 758.361755,302.118134 758.029297,301.969360 
	C758.029297,301.969360 758.027161,301.933411 757.906616,301.455292 
	C757.709656,301.147980 757.429077,301.006348 756.872925,300.549408 
	C756.577087,300.398041 756.281189,300.246674 755.768799,299.727356 
	C755.768799,299.727356 755.347595,299.657654 755.260864,299.671661 
	C755.260864,299.671661 755.297913,299.751343 755.177551,299.196533 
	C754.850525,299.036713 754.523438,298.876923 754.240784,298.832916 
	C754.240784,298.832916 754.282104,298.716003 754.096069,298.143616 
	C753.441650,297.712067 752.787170,297.280518 752.031799,296.893677 
	C752.031799,296.893677 752.141235,296.908295 751.997192,296.385742 
	C751.685181,296.228271 751.373108,296.070801 751.009766,295.996826 
	C751.009766,295.996826 751.085388,295.934357 750.961243,295.425446 
	C750.660156,295.275391 750.359131,295.125336 750.026855,294.971680 
	C750.026855,294.971680 750.018066,294.941559 749.878540,294.478973 
	C749.688354,294.173615 749.413940,294.035400 748.790527,293.542542 
	C748.450439,293.369843 748.110352,293.197174 747.518860,292.425385 
	C736.323303,284.363647 725.127747,276.301880 713.572266,267.937195 
	C713.572266,267.937195 713.105652,267.877228 713.004028,267.899200 
	C713.004028,267.899200 713.106995,267.907013 712.969238,267.408966 
	C712.658142,267.337646 712.347107,267.266327 711.559570,267.031372 
	C711.393555,267.006348 711.227539,266.981323 710.766663,266.315979 
	C703.875793,262.547028 696.984863,258.778076 689.980103,254.319717 
	C688.131897,253.385788 686.283691,252.451859 684.034119,251.177704 
	C683.688965,251.121964 683.343750,251.066238 682.608093,250.436035 
	C678.225525,248.555161 673.908875,246.498520 669.447876,244.826965 
	C656.994080,240.160385 644.480530,235.653320 631.384827,230.788986 
	C628.907715,230.240891 626.430664,229.692780 623.188843,229.026382 
	C619.456055,227.953690 615.779236,226.582596 611.978882,225.870377 
	C604.041504,224.382812 596.038940,223.243011 587.614990,221.266815 
	C575.084473,220.247055 562.553955,219.227280 549.095337,218.036087 
	C543.054138,218.100067 537.013000,218.164062 530.210449,218.074799 
	C528.782593,218.380478 527.354736,218.686157 525.448181,219.013199 
	C525.448181,219.013199 524.996887,219.174484 524.225830,219.030502 
	C507.906647,218.802933 491.872528,220.942383 475.247772,224.786255 
	C473.772156,225.147064 472.296570,225.507874 470.935181,225.882553 
	C470.935181,225.882553 470.909271,225.770447 470.102173,225.870056 
	C467.356812,226.552597 464.611450,227.235138 461.960968,227.928711 
	C461.960968,227.928711 461.947510,227.834152 461.180237,227.965286 
	C459.048615,228.585541 456.916992,229.205795 454.911835,229.842743 
	C454.911835,229.842743 454.875488,229.720474 454.110199,229.830322 
	C452.182770,230.503845 450.255371,231.177353 447.877258,231.712494 
	C447.558533,231.762573 447.274567,231.890060 446.245209,232.001617 
	C441.068024,233.842896 435.890839,235.684189 430.578369,237.451385 
	C430.578369,237.451385 430.424957,237.467453 429.737701,237.701614 
	C424.730988,239.768402 419.724304,241.835190 414.330078,243.869400 
	C414.330078,243.869400 414.016846,244.099899 413.322662,244.082306 
	C411.135712,245.135162 408.948730,246.188019 406.051605,247.384415 
	C404.607178,248.166489 403.162781,248.948563 401.905243,249.749893 
	C401.905243,249.749893 401.737610,249.665054 401.031128,249.827637 
	C398.977142,250.853073 396.923126,251.878510 394.974091,252.953598 
	C394.974091,252.953598 394.907135,252.858734 394.195038,252.965805 
	C390.790894,254.946884 387.386749,256.927979 383.316864,259.008972 
	C383.002869,259.312897 382.688843,259.616821 382.185822,259.834106 
	C382.185822,259.834106 382.041565,259.983795 381.409332,260.016602 
	C381.126221,260.313049 380.843109,260.609467 380.241119,260.840912 
	C380.241119,260.840912 380.033051,261.091125 379.479706,261.089996 
	C379.252838,261.361786 379.026001,261.633606 378.940002,261.928589 
	C378.940002,261.928589 378.933899,261.785950 378.326691,261.904907 
	C377.860229,262.259399 377.393738,262.613892 376.988800,262.995911 
	C376.988800,262.995911 376.986023,262.928558 376.429138,263.013519 
	C376.161743,263.251556 375.894348,263.489563 375.816589,263.748230 
	C375.816589,263.748230 375.744354,263.571686 375.142792,263.745209 
	C374.292755,264.313171 373.442719,264.881134 372.534607,265.520599 
	C372.534607,265.520599 372.449432,265.485413 371.860413,265.747894 
	C370.245148,266.837524 368.629883,267.927155 367.000000,269.000000 
	C367.000000,269.000000 367.016388,269.015686 366.404083,269.133026 
	C365.588867,269.750824 364.773651,270.368622 363.995209,271.008514 
	C363.995209,271.008514 363.997833,270.965759 363.436188,271.029633 
	C363.212036,271.315338 362.987915,271.601074 362.899628,271.898895 
	C362.899628,271.898895 362.867157,271.766388 362.383820,271.874603 
	C362.383820,271.874603 362.007599,272.196777 361.526947,272.195312 
	C361.231201,272.370667 361.054047,272.627777 360.472931,273.046997 
	C360.263214,273.310944 360.053467,273.574921 359.159302,273.966248 
	C353.403503,278.273193 347.647736,282.580139 341.225922,287.066254 
	C336.153137,291.377350 331.080353,295.688477 325.360260,300.137756 
	C323.854980,301.722198 322.349731,303.306641 320.946686,304.952667 
	C320.946686,304.952667 320.893158,304.846008 320.263519,305.083466 
	C317.753204,307.650360 315.242889,310.217224 312.735931,312.832214 
	C312.735931,312.832214 312.723969,312.784302 312.114014,313.035950 
	C309.345551,315.995941 306.577118,318.955933 303.191650,322.162903 
	C295.169464,332.116638 287.147247,342.070374 278.621582,352.483276 
	C273.698486,359.966370 268.775421,367.449493 263.239288,375.203735 
	C262.744965,376.411316 262.250610,377.618896 261.875946,378.893402 
	C261.875946,378.893402 261.814667,378.770752 261.203918,379.124969 
	C258.090393,385.065399 254.976883,391.005829 251.235992,397.281006 
	C249.122238,402.166809 247.008469,407.052643 244.978439,411.981506 
	C244.978439,411.981506 244.934540,411.898193 244.280563,412.223907 
	C243.876022,413.300903 243.507965,414.393311 243.061676,415.452698 
	C230.244659,445.878357 222.128494,477.537170 219.148911,510.389618 
	C215.748230,547.884949 218.248459,585.006104 227.891037,621.526123 
	C233.518204,642.838379 240.241714,663.734192 250.924210,683.077881 
	C250.924210,683.077881 250.816040,683.120850 250.907257,683.768494 
	C251.533340,684.879150 252.159424,685.989746 252.834839,687.689941 
	C253.154022,688.150269 253.473190,688.610596 253.892822,689.743652 
	C254.578522,690.856506 255.264221,691.969421 255.986206,693.014038 
	C255.986206,693.014038 255.911087,693.032227 255.999817,693.637878 
	C256.322998,694.109741 256.646149,694.581665 257.005157,695.009094 
	C257.005157,695.009094 256.950745,695.025940 257.047424,695.709290 
	C257.996246,697.235107 258.945038,698.760986 259.916565,700.976807 
	C260.901398,702.499695 261.886230,704.022644 262.992340,706.194214 
	C268.585754,714.522583 274.179138,722.850891 279.796936,731.123779 
	C279.796936,731.123779 279.764404,731.166321 279.882965,731.722961 
	C280.360321,732.299744 280.837677,732.876587 281.421295,733.562805 
	C281.421295,733.562805 281.483307,733.702148 281.754822,734.263733 
	C282.818542,735.534790 283.882263,736.805847 284.989868,738.008240 
	C284.989868,738.008240 284.914917,738.040161 285.053833,738.569824 
	C285.323303,738.793457 285.592743,739.017090 285.884430,739.127075 
	C285.884430,739.127075 285.773010,739.158875 285.867920,739.815125 
	C286.880096,740.920105 287.892242,742.025024 288.951172,743.045410 
	C288.951172,743.045410 288.857452,743.069092 288.992584,743.609741 
	C289.282440,743.821533 289.572266,744.033386 289.884064,744.130066 
	C289.884064,744.130066 289.771576,744.162903 289.876404,744.848389 
	C291.903503,747.277039 293.930603,749.705627 296.173187,752.719543 
	C297.376465,753.835815 298.579742,754.952087 299.996307,756.592407 
	C300.302734,756.795227 300.609161,756.998047 300.943695,757.107239 
	C300.943695,757.107239 300.846100,757.113770 300.998901,757.645630 
	C301.302124,757.844910 301.605347,758.044189 302.086060,758.856079 
	C302.944458,759.697876 303.802856,760.539612 304.732910,761.253235 
	C304.732910,761.253235 304.592560,761.296326 304.782867,761.821655 
	C305.202148,762.169556 305.621399,762.517395 306.196747,763.445618 
	C306.777344,763.978516 307.357941,764.511414 308.031097,765.541748 
	C308.308228,765.709778 308.585358,765.877747 309.089569,766.615479 
	C309.709595,767.125793 310.329651,767.636108 311.066132,768.610352 
	C311.250824,768.915955 311.521332,769.057068 312.080383,769.530823 
	C312.378418,769.710083 312.676453,769.889404 312.995239,770.005859 
	C312.995239,770.005859 312.932434,770.026855 313.092072,770.540161 
	C313.383728,770.736023 313.675354,770.931946 314.159729,771.726562 
	C315.397552,772.830261 316.635376,773.934021 318.088013,775.604858 
	C318.703369,776.104431 319.318726,776.603943 319.982849,777.015808 
	C319.982849,777.015808 319.898285,777.069763 320.138977,777.663025 
	C321.012207,778.216309 321.885406,778.769531 322.820038,779.190613 
	C322.820038,779.190613 322.675537,779.209412 322.890961,779.735840 
	C323.221283,779.908447 323.551605,780.081055 323.898895,780.108459 
	C323.898895,780.108459 323.755737,780.138184 323.954102,780.660461 
	C324.278442,780.830383 324.602783,781.000305 324.940308,781.064087 
	C324.940308,781.064087 324.834290,781.078491 325.010468,781.602478 
	C325.317230,781.786987 325.623993,781.971558 325.943939,782.067322 
	C325.943939,782.067322 325.859467,782.097778 326.070831,782.652771 
	C326.684296,783.116943 327.297760,783.581055 328.218567,784.612244 
	C329.413239,785.494507 330.607941,786.376770 331.892670,787.124329 
	C331.892670,787.124329 331.752136,787.205017 332.051788,787.812012 
	C335.629333,790.634521 339.206848,793.457031 342.836151,796.170837 
	C342.836151,796.170837 342.717621,796.192017 342.951904,796.693298 
	C343.288330,796.831543 343.624756,796.969788 343.968445,797.030151 
	C343.968445,797.030151 343.891541,797.044373 344.068878,797.547058 
	C344.368774,797.724487 344.668701,797.901917 344.996002,798.013489 
	C344.996002,798.013489 344.928009,798.034851 345.067444,798.553467 
	C345.375946,798.672791 345.684479,798.792053 346.258301,799.160583 
	C346.258301,799.160583 346.619843,799.118347 346.871246,799.665405 
	C347.225586,799.836792 347.579956,800.008179 347.937286,800.060669 
	C347.937286,800.060669 347.820770,800.084900 348.030579,800.584778 
	C348.342224,800.755432 348.653870,800.926086 348.991028,801.022644 
	C348.991028,801.022644 348.917999,801.051270 349.180237,801.688171 
	C352.084930,803.588684 354.989624,805.489197 358.282471,807.912354 
	C365.473297,812.326782 372.664093,816.741272 380.183228,821.760437 
	C383.086517,823.230713 385.989807,824.700989 388.932037,826.073303 
	C388.932037,826.073303 388.834686,826.113525 389.136902,826.664429 
	C389.729248,826.857361 390.321625,827.050232 390.918518,827.117554 
	C390.918518,827.117554 390.799377,827.157471 391.099854,827.714050 
	C391.698883,827.901611 392.297943,828.089172 392.910309,828.138062 
	C392.910309,828.138062 392.776215,828.175903 393.142334,828.767944 
	C395.487427,829.825256 397.832550,830.882568 400.614502,832.471863 
	C410.031281,836.365051 419.448090,840.258240 429.273621,844.752869 
	C437.185211,847.167725 445.096802,849.582581 453.001129,851.997559 
	C453.001129,851.997559 452.995361,851.994385 453.566010,852.414124 
	C456.035614,853.006897 458.505188,853.599609 461.645111,854.719604 
	C470.773682,856.482971 479.902283,858.246277 489.687042,860.437866 
	C513.683411,864.334351 537.810547,865.336365 562.889282,863.833069 
	C575.582214,862.144226 588.275085,860.455444 601.616455,858.856323 
	C602.423035,858.570312 603.229675,858.284302 604.838745,858.112610 
	C607.344177,857.418884 609.843079,856.700684 612.356140,856.035767 
	C621.239319,853.685364 630.127258,851.353271 639.797974,849.024597 
	C645.227722,847.052124 650.657471,845.079590 656.834045,843.047974 
	C668.929810,837.405396 681.025635,831.762817 693.013794,826.023743 
	C693.013794,826.023743 693.070679,826.156677 693.678101,826.114258 
	C694.143555,825.771912 694.608948,825.429504 695.001526,825.001160 
	C695.001526,825.001160 695.056335,825.099670 695.663879,825.064880 
	C696.140503,824.727173 696.617126,824.389465 697.615051,823.996948 
	C697.747925,823.883484 697.880737,823.769958 698.568115,823.563110 
	C699.317444,823.034668 700.066772,822.506226 700.961365,822.035645 
	C700.961365,822.035645 701.048096,821.905640 701.561462,821.901672 
	C701.740967,821.632324 701.920410,821.363037 702.130066,821.082092 
	C702.130066,821.082092 702.142151,821.115845 702.775452,821.061523 
	C703.809387,820.384033 704.843323,819.706482 706.006104,819.057190 
	C706.006104,819.057190 706.050171,818.932922 706.658081,818.877563 
	C707.454773,818.276978 708.251526,817.676392 709.002869,817.000000 
	C709.002869,817.000000 709.057251,817.070374 709.617737,817.042480 
	C710.036926,816.705505 710.456116,816.368591 711.015015,816.066895 
	C711.015015,816.066895 711.070007,815.933777 711.576782,815.890320 
	C711.755310,815.620850 711.933838,815.351440 712.026123,815.010681 
	C712.026123,815.010681 712.076843,815.110474 712.596375,814.982849 
	C712.761902,814.674133 712.927368,814.365356 713.813171,814.016663 
	C721.903259,808.387939 729.993408,802.759155 738.749023,796.966858 
	C747.500305,789.309570 756.251587,781.652344 765.596863,773.761230 
	C766.744690,772.520020 767.892517,771.278748 769.542725,769.911926 
	C769.709595,769.623169 769.876526,769.334412 770.627625,768.824158 
	C771.412659,767.878418 772.197693,766.932739 773.578735,765.755249 
	C774.549927,764.652161 775.521118,763.549072 777.047180,762.098572 
	C778.992493,759.726257 780.937805,757.354004 783.093445,754.636414 
	C783.093445,754.636414 783.095642,754.232178 783.771729,754.099976 
	C785.534790,752.094482 787.297852,750.088928 789.000244,748.050598 
	C789.000244,748.050598 789.063416,748.064514 789.555725,747.934692 
	C789.694641,747.639282 789.833557,747.343811 790.038269,747.031128 
	C790.038269,747.031128 790.036438,746.963013 790.612244,746.701721 
	C791.430115,745.493774 792.247925,744.285889 793.024658,743.048279 
	C793.024658,743.048279 793.059692,743.064209 793.560059,742.936401 
	C793.712646,742.642578 793.865173,742.348755 794.520020,741.814270 
	C794.672607,741.485046 794.825256,741.155884 795.493286,740.594238 
	C795.724548,740.095398 795.955750,739.596619 796.098389,739.071899 
	C796.098389,739.071899 796.109863,739.163513 796.631287,739.020691 
	C796.770020,738.699341 796.908752,738.377991 797.019165,738.007019 
	C797.019165,738.007019 797.069336,738.034607 797.678650,737.768372 
	C799.457642,735.199768 801.236694,732.631104 803.045227,730.039429 
	C803.045227,730.039429 803.061401,730.005676 803.642639,729.779968 
	C804.126587,728.883728 804.610474,727.987549 805.014038,727.064087 
	C805.014038,727.064087 805.092407,727.074158 805.709900,726.826599 
	C806.868469,724.941833 808.027039,723.057068 809.154663,721.124939 
	C809.154663,721.124939 809.169861,721.159241 809.752441,720.903564 
	C810.220459,719.982849 810.688538,719.062073 811.144165,718.053284 
	C811.144165,718.053284 811.100159,718.130615 811.609802,717.992920 
	C811.736023,717.680847 811.862305,717.368774 812.052063,717.029541 
	C812.052063,717.029541 812.043274,716.960999 812.543579,716.664734 
	C812.732605,716.130676 812.921570,715.596558 813.035828,714.998962 
	C813.035828,714.998962 813.072327,715.089966 813.562744,714.950378 
	C813.685913,714.645325 813.809082,714.340332 814.023621,714.015137 
	C814.023621,714.015137 814.012268,713.922302 814.583740,713.620056 
	C815.397034,712.096008 816.210388,710.571960 817.018555,709.020020 
	C817.018555,709.020020 817.044617,709.009277 817.560425,708.708801 
	C817.757935,708.162231 817.955444,707.615662 818.041443,707.025696 
	C818.041443,707.025696 818.120056,707.115967 818.711304,706.788025 
	C820.125366,704.190674 821.539429,701.593323 823.192261,698.771118 
	C823.192261,698.771118 823.120667,698.451050 823.626038,698.138672 
	C823.746033,697.774719 823.866028,697.410767 824.042664,697.022400 
	C824.042664,697.022400 824.032654,696.961609 824.533203,696.676941 
	C824.693787,696.137146 824.854370,695.597412 825.067078,695.036255 
	C825.067078,695.036255 825.070923,694.980103 825.581909,694.703918 
	C825.745850,694.158875 825.909851,693.613770 826.101990,693.059204 
	C826.101990,693.059204 826.134399,693.063354 826.718323,692.694092 
	C830.740845,684.318726 835.127319,676.096497 838.727112,667.543213 
	C863.165283,609.475891 870.156494,549.236206 859.685852,487.190704 
	C857.051270,471.578857 851.948975,456.383453 848.125977,440.372314 
	C847.789795,439.876221 847.453613,439.380127 847.099609,439.002869 
	C847.099609,439.002869 847.162170,438.900238 847.184387,438.427490 
	C847.184387,438.427490 846.982483,437.999451 847.100220,437.281647 
	C846.393372,435.521454 845.686462,433.761261 845.041199,431.366425 
	C844.718750,430.872803 844.396362,430.379181 844.030212,429.419464 
	C844.030212,429.419464 843.829285,428.996643 843.963867,428.410065 
	C843.698120,427.908081 843.432373,427.406067 843.134460,426.213745 
	C842.496765,424.775482 841.859070,423.337250 841.183716,421.201569 
	C840.515991,419.766327 839.848206,418.331116 839.131226,416.201691 
	C838.446411,414.786682 837.761658,413.371674 837.045349,411.220398 
	C834.045227,405.131042 831.045044,399.041656 828.024719,392.369232 
	C827.693298,391.929077 827.361877,391.488892 826.936462,390.358612 
	C825.685730,388.212708 824.435059,386.066772 823.125977,383.320618 
	C822.771240,382.872223 822.416504,382.423798 822.022156,381.970917 
	C822.022156,381.970917 822.040894,381.935730 822.010803,381.346832 
	C821.657471,380.899933 821.304138,380.453033 820.922546,379.447388 
	C820.593811,379.022095 820.265076,378.596802 819.871643,377.629517 
	C819.607056,377.402283 819.342529,377.175049 819.024414,376.994476 
	C819.024414,376.994476 819.068542,376.949432 819.004333,376.307587 
	C818.703491,376.017303 818.402649,375.726990 818.201782,375.187500 
	C818.201782,375.187500 817.996582,375.014435 817.939392,374.331116 
	C816.667908,372.514740 815.396423,370.698364 814.045166,368.971771 
	C814.045166,368.971771 814.158142,368.931030 814.134521,368.333923 
	C813.766296,367.895569 813.398071,367.457214 813.026917,366.983398 
	C813.026917,366.983398 812.996094,366.965607 812.936096,366.348145 
	C812.310669,365.554474 811.685242,364.760803 811.004883,363.994446 
	C811.004883,363.994446 811.047913,363.950714 810.984009,363.401581 
	C810.701721,363.196747 810.419373,362.991913 810.126648,362.913940 
	C810.126648,362.913940 810.248840,362.878510 810.194702,362.281067 
	C809.811707,361.858704 809.428711,361.436340 809.040344,360.969421 
	C809.040344,360.969421 808.999390,360.950958 808.943726,360.442780 
	C808.711060,360.221527 808.478394,360.000275 808.140564,359.853333 
	C808.140564,359.853333 808.269104,359.861298 808.211792,359.267151 
	C807.820374,358.846100 807.428955,358.425049 807.023376,357.981293 
	C807.023376,357.981293 807.007446,357.959869 806.914368,357.322021 
	C805.978882,356.184174 805.043457,355.046326 804.008850,353.936035 
	C804.008850,353.936035 804.108765,353.893097 804.007812,353.280792 
	C803.360901,352.510254 802.713989,351.739685 802.022888,350.973236 
	C802.022888,350.973236 802.041626,350.932922 801.947021,350.336426 
	C801.394531,349.795227 800.841980,349.253998 800.206604,348.803528 
	C800.206604,348.803528 800.329346,348.809113 800.287415,348.202576 
	C799.882629,347.789551 799.477844,347.376526 799.022766,346.990631 
	C799.022766,346.990631 799.048340,346.939697 798.954773,346.406860 
	C798.668884,346.217499 798.382996,346.028137 798.115112,345.937683 
	C798.115112,345.937683 798.176575,345.858093 798.069275,345.248444 
	C797.396118,344.504547 796.722961,343.760620 796.044861,342.961273 
	C796.044861,342.961273 795.991394,342.945648 795.899658,342.437592 
	C795.621094,342.263824 795.342468,342.090057 795.102905,341.940491 
	C795.102905,341.940491 795.099243,341.894653 794.986816,341.249084 
	C793.689209,339.801758 792.391602,338.354401 791.061462,336.992432 
	C791.061462,336.992432 791.108215,336.905548 790.987732,336.261505 
	C789.679199,334.835510 788.370667,333.409515 787.032471,331.974548 
	C787.032471,331.974548 787.025146,331.944336 786.915100,331.433868 
	C786.628906,331.262695 786.342712,331.091522 786.006226,330.994995 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M783.095581,754.232178 
	C783.095642,754.232178 783.093445,754.636414 782.652527,754.815552 
	C781.141235,755.664795 780.070923,756.334839 778.622314,756.926270 
	C777.839844,756.191528 777.054077,755.512817 777.091309,754.882751 
	C777.413025,749.433899 777.964050,743.998291 778.260559,738.548584 
	C778.352905,736.850342 777.853027,735.123657 777.665771,733.405457 
	C777.467834,731.589050 777.103882,729.756226 777.195374,727.950012 
	C777.374512,724.409241 777.888611,720.886597 778.132385,717.347412 
	C778.325012,714.550598 778.287292,711.738647 778.418274,708.936462 
	C778.478882,707.639038 779.312378,705.867493 778.740356,705.137024 
	C775.916870,701.531128 777.733154,698.247803 778.893188,694.830139 
	C779.313232,693.592468 780.181519,691.575684 779.696594,691.109741 
	C775.876221,687.438538 778.138733,682.994934 778.112732,679.041504 
	C777.909180,648.136597 778.012390,617.229797 777.983948,586.323547 
	C777.980469,582.584290 778.812195,578.681213 775.526306,575.539490 
	C774.839111,574.882507 775.187683,573.021423 775.248535,571.724060 
	C775.499268,566.380493 775.891846,561.042908 776.085999,555.697815 
	C776.163330,553.568481 775.500793,551.577576 778.615173,550.627563 
	C779.731567,550.286987 780.720276,547.425964 780.652588,545.760620 
	C780.527100,542.672241 779.643372,539.602722 778.937378,536.557312 
	C778.739563,535.704102 778.037537,534.974426 777.605408,534.167480 
	C776.723083,532.519958 775.061096,530.817993 775.141785,529.209717 
	C775.464722,522.764160 776.628418,516.359863 776.932800,509.914368 
	C777.492859,498.057434 777.892456,486.183380 777.954407,474.314331 
	C778.097900,446.821259 777.956055,419.326691 778.068481,391.833313 
	C778.079651,389.106201 779.045288,386.382996 779.636169,383.302094 
	C778.707214,383.120880 777.021484,382.792084 775.293457,382.455017 
	C777.284912,377.790802 779.159668,373.400024 781.311768,368.739319 
	C781.993469,365.252686 783.179871,361.802094 779.005371,359.652527 
	C778.651611,358.895630 778.331787,358.431122 777.905640,357.753357 
	C777.609253,357.271393 777.359497,357.080750 777.033081,356.618469 
	C776.358948,355.495331 775.701843,354.721954 774.932373,353.731537 
	C774.624817,353.240906 774.371521,353.041016 773.943848,352.710114 
	C773.631775,352.249817 773.385620,352.059265 772.966675,351.725403 
	C772.638855,351.257874 772.381836,351.068939 772.015564,350.632141 
	C770.978027,349.527405 769.998047,348.740692 769.151672,347.654022 
	C771.109436,346.337341 772.933655,345.320587 774.823792,344.267090 
	C767.839417,337.482605 767.836731,329.178497 768.513672,321.729828 
	C771.507874,321.437805 774.305908,321.164917 777.103943,320.892059 
	C777.103943,320.892059 776.997559,320.992126 777.019043,321.317322 
	C779.752563,324.379395 782.464600,327.116302 785.176575,329.853210 
	C785.176575,329.853210 785.117371,329.932129 785.179810,330.138794 
	C785.521790,330.545197 785.801331,330.744873 786.080811,330.944550 
	C786.080811,330.944550 786.006226,330.994995 786.117493,331.191101 
	C786.415955,331.684814 786.681396,331.870514 787.025146,331.944336 
	C787.025146,331.944336 787.032471,331.974548 787.028809,332.346893 
	C788.386230,334.114685 789.747192,335.510132 791.108215,336.905548 
	C791.108215,336.905548 791.061462,336.992432 791.049316,337.323730 
	C792.391235,339.068237 793.745239,340.481445 795.099243,341.894653 
	C795.099243,341.894653 795.102905,341.940491 795.162842,342.159973 
	C795.397827,342.678467 795.653992,342.867188 795.991394,342.945648 
	C795.991394,342.945648 796.044861,342.961273 796.035767,343.350067 
	C796.743286,344.445251 797.459900,345.151672 798.176575,345.858093 
	C798.176575,345.858093 798.115112,345.937683 798.175049,346.139191 
	C798.506104,346.540375 798.777222,346.740021 799.048340,346.939697 
	C799.048340,346.939697 799.022766,346.990631 799.051880,347.296539 
	C799.497070,348.004669 799.913208,348.406891 800.329346,348.809143 
	C800.329346,348.809113 800.206604,348.803528 800.185974,349.106018 
	C800.790771,349.916626 801.416199,350.424774 802.041626,350.932922 
	C802.041626,350.932922 802.022888,350.973236 802.027954,351.332367 
	C802.724915,352.425385 803.416809,353.159241 804.108765,353.893097 
	C804.108765,353.893097 804.008850,353.936035 804.026733,354.292603 
	C805.032227,355.752716 806.019836,356.856293 807.007446,357.959869 
	C807.007446,357.959869 807.023376,357.981293 807.046143,358.304169 
	C807.468933,359.038452 807.869019,359.449890 808.269104,359.861298 
	C808.269104,359.861298 808.140564,359.853333 808.109924,360.110901 
	C808.051941,360.569885 808.024536,360.771301 807.618103,360.986206 
	C802.777100,361.787079 802.024353,365.089478 802.009644,368.819916 
	C801.978638,376.643188 802.000000,384.466675 802.000000,392.290070 
	C802.000000,435.611267 801.972961,478.932495 802.037537,522.253601 
	C802.044800,527.110962 804.083801,532.542053 802.508179,536.692505 
	C800.023926,543.236023 801.116394,547.783020 805.698669,552.344971 
	C806.236877,552.880798 805.927002,554.268555 806.010254,555.261353 
	C805.154602,555.086121 804.248779,555.031311 803.457703,554.701294 
	C802.691406,554.381531 802.043213,553.778870 800.519775,552.737793 
	C801.090332,557.519348 801.932861,561.330688 801.940796,565.143738 
	C802.033203,609.631104 802.041504,654.118713 801.949280,698.606079 
	C801.937317,704.401123 804.939880,710.377869 801.096985,716.039673 
	C800.749634,716.551453 800.939270,717.917175 801.394897,718.401611 
	C803.637573,720.786072 802.516052,722.778137 801.190491,725.081604 
	C800.154480,726.882019 799.709839,729.022705 798.960510,731.378540 
	C798.302185,733.841431 797.685730,735.938049 797.069336,738.034607 
	C797.069336,738.034607 797.019165,738.007019 796.797852,738.137817 
	C796.420898,738.566895 796.265381,738.865173 796.109863,739.163513 
	C796.109863,739.163513 796.098389,739.071899 795.868896,739.182007 
	C795.418945,739.803650 795.198425,740.315186 794.977905,740.826721 
	C794.825256,741.155884 794.672607,741.485046 794.043213,742.029419 
	C793.288147,742.450134 793.119202,742.723328 793.059692,743.064209 
	C793.059692,743.064209 793.024658,743.048279 792.684692,743.073181 
	C791.575317,744.386353 790.805847,745.674683 790.036377,746.963013 
	C790.036438,746.963013 790.038269,747.031128 789.788818,747.141357 
	C789.275024,747.460571 789.116333,747.731567 789.063416,748.064514 
	C789.063416,748.064514 789.000244,748.050598 788.663574,748.045288 
	C786.583130,750.104065 784.839355,752.168091 783.095581,754.232178 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M299.783020,756.068420 
	C298.579742,754.952087 297.376465,753.835815 296.087616,752.147034 
	C296.014954,751.387817 296.027832,751.201172 296.335388,750.969849 
	C296.937653,750.512939 297.245239,750.100647 297.552826,749.688293 
	C297.035217,749.458862 296.517609,749.229431 295.856323,748.702332 
	C294.806396,746.938049 293.900146,745.471497 293.039185,743.631226 
	C293.715851,741.590027 295.026581,739.849426 294.856567,738.268127 
	C294.243683,732.568787 293.380829,726.866394 292.109070,721.279724 
	C291.415619,718.233521 290.278168,715.631409 293.762268,713.536316 
	C294.437073,713.130493 294.978699,710.981750 294.587341,710.560303 
	C290.783966,706.464172 293.069458,701.748535 293.017914,697.310425 
	C292.650970,665.707886 292.163788,634.105347 292.068329,602.501770 
	C292.017120,585.557068 292.831207,568.611145 292.932251,551.663879 
	C292.984589,542.881653 292.067230,534.094971 292.074249,525.310669 
	C292.081635,516.026123 292.931366,506.741943 292.926971,497.457825 
	C292.922455,487.840302 292.027344,478.221619 292.071838,468.606018 
	C292.143311,453.157928 292.795258,437.713165 292.940002,422.264557 
	C293.001984,415.650421 292.308441,409.032623 292.139008,402.411865 
	C291.996246,396.835022 292.078461,391.251129 292.136932,385.671143 
	C292.159302,383.538818 291.549438,380.474670 292.684937,379.449951 
	C296.316162,376.172974 294.111206,373.484650 293.226471,370.113251 
	C291.917938,365.126678 291.679932,359.859253 290.986084,354.711395 
	C291.478729,354.665466 291.971375,354.619568 292.464020,354.573639 
	C292.644409,355.458252 292.780060,356.355164 293.018127,357.223969 
	C293.245575,358.054016 293.579681,358.854828 293.966614,359.950928 
	C296.974182,355.817383 296.859467,352.847351 293.446869,350.335205 
	C291.181366,348.667450 289.614716,346.357605 292.182953,344.880005 
	C295.306580,343.082916 294.860626,340.833466 295.048920,338.265350 
	C295.164307,336.691620 295.333954,334.839691 296.212646,333.658142 
	C298.636383,330.399170 301.500793,327.470978 304.040100,324.291504 
	C304.396210,323.845673 303.906738,322.724487 303.808685,321.915924 
	C306.577118,318.955933 309.345551,315.995941 312.470245,313.270813 
	C313.674744,315.580414 314.523010,317.655182 315.371277,319.729919 
	C313.937317,322.994507 313.128845,326.564911 313.064545,330.148682 
	C312.880951,340.377014 313.000214,340.378235 322.615692,342.500427 
	C322.761169,342.532532 322.877502,342.696625 322.611725,342.884766 
	C313.285522,343.921967 307.728851,349.247589 303.738922,357.212128 
	C302.844452,359.781342 302.368286,362.168671 301.628510,364.471313 
	C300.071503,369.317688 301.462738,372.918549 306.151215,375.889404 
	C306.095337,375.917847 306.151581,375.805756 306.111328,376.180420 
	C306.745209,377.371613 307.419373,378.188141 308.287201,379.275696 
	C310.369598,380.959869 312.258331,382.373016 313.747070,383.854431 
	C307.762695,385.370819 309.805420,389.155304 311.225922,392.140198 
	C312.283936,394.363434 313.599976,396.026886 311.539642,398.340149 
	C311.079071,398.857330 311.460693,400.192200 311.569763,401.137329 
	C312.022522,405.061279 312.889648,408.976013 312.933960,412.901672 
	C313.099243,427.550415 313.089447,442.203247 312.914124,456.851746 
	C312.883972,459.370178 311.577789,461.850128 311.150330,464.396423 
	C310.921478,465.759613 310.769135,468.008728 311.507294,468.518433 
	C316.034760,471.644806 315.430145,475.887817 314.378601,480.150696 
	C313.614807,483.247070 313.916382,485.614532 317.053955,487.464325 
	C316.311249,491.252533 315.552032,494.640961 314.565857,498.272827 
	C314.276825,499.170380 314.214722,499.824493 314.166016,500.873535 
	C313.726593,504.187622 313.273865,507.106842 312.571075,510.299377 
	C311.564178,519.419922 310.807312,528.267090 310.029175,537.545410 
	C310.018188,540.280640 310.028473,542.584839 310.026245,545.364624 
	C310.675873,556.891052 311.337921,567.941895 312.000031,579.243286 
	C312.000427,579.660950 312.000793,579.828064 312.106110,580.255493 
	C312.472229,581.011414 312.733368,581.506958 312.619720,582.078796 
	C311.167358,585.682800 310.034515,589.195251 309.057861,592.750610 
	C308.857574,593.479736 309.244934,594.368896 309.355835,595.184998 
	C309.783325,598.330933 310.218445,601.475952 310.630371,604.623901 
	C310.834808,606.186340 310.875641,607.780029 311.203186,609.314209 
	C311.705750,611.667786 312.903961,613.978699 312.919769,616.313782 
	C313.058411,636.784363 313.412537,657.268738 312.794312,677.721558 
	C312.551483,685.756165 310.024506,693.725403 308.452972,701.707703 
	C308.352600,702.217285 307.518433,702.582336 306.848816,702.783325 
	C306.119873,703.044739 305.567719,703.537537 305.010742,704.012817 
	C305.005890,703.995239 305.034241,704.019287 304.653198,704.020630 
	C302.880920,704.401489 301.489685,704.780945 300.098450,705.160400 
	C300.483124,705.804138 300.867798,706.447937 301.228271,707.398743 
	C301.186157,708.114807 301.168213,708.523865 300.851929,709.164246 
	C299.115967,713.259705 301.123596,715.780396 304.048798,718.283936 
	C304.489014,719.294006 304.788361,720.018250 305.163940,721.008667 
	C305.518829,721.503845 305.797485,721.732910 306.042053,721.977051 
	C306.007935,721.992188 306.040466,721.925049 306.151520,722.164795 
	C306.561798,722.576294 306.861023,722.748108 307.112396,722.927246 
	C307.064545,722.934509 307.078979,722.838745 307.166565,723.092896 
	C307.541473,723.547424 307.828796,723.747742 308.075378,723.955933 
	C308.034607,723.963867 308.060333,723.884888 308.123840,724.111694 
	C308.328766,724.635193 308.549164,724.851257 308.970276,725.244141 
	C309.398590,725.715027 309.705200,725.928528 310.172455,726.346863 
	C310.655121,726.666931 310.977081,726.782166 311.559875,726.941162 
	C312.213074,727.326599 312.605408,727.668274 313.166626,728.245850 
	C313.693176,728.611206 314.050873,728.740662 314.581512,728.844543 
	C314.754456,728.818970 315.002899,729.065063 315.128784,729.277222 
	C315.483368,729.743652 315.765717,729.902893 316.523315,729.934692 
	C320.250488,729.242859 323.555969,728.583496 327.118469,728.119141 
	C323.824524,732.333862 319.230835,733.122864 313.477997,732.485168 
	C312.854065,734.157410 311.532959,736.031311 311.590973,737.861389 
	C311.751923,742.937622 313.298798,748.086487 312.744507,753.032654 
	C312.281525,757.164124 309.624146,761.049744 307.938538,765.044250 
	C307.357941,764.511414 306.777344,763.978516 306.064362,762.853149 
	C305.485504,761.939209 305.039032,761.617737 304.592560,761.296326 
	C304.592560,761.296326 304.732910,761.253235 304.790314,760.933716 
	C303.899353,759.409790 302.950958,758.205383 301.711853,757.012573 
	C301.229431,757.054016 301.037781,757.083923 300.846100,757.113770 
	C300.846100,757.113770 300.943695,757.107239 300.831116,756.886719 
	C300.406708,756.466980 300.094849,756.267700 299.783020,756.068420 
M310.330414,479.176727 
	C311.116516,479.149323 311.902588,479.121918 312.688690,479.094513 
	C312.539490,478.527435 312.390289,477.960358 312.241058,477.393250 
	C311.552185,477.761627 310.863312,478.130005 310.330414,479.176727 
z"/>
<path fill="#0D9BFA" opacity="1.000000" stroke="none" 
	d="
M841.221313,421.899017 
	C841.859070,423.337250 842.496765,424.775482 842.904663,426.713715 
	C843.059631,427.808014 843.444458,428.402313 843.829285,428.996643 
	C843.829285,428.996643 844.030212,429.419464 844.046082,429.961121 
	C844.367859,431.002197 844.673767,431.501617 844.979614,432.001038 
	C845.686462,433.761261 846.393372,435.521454 846.877319,437.741272 
	C846.823669,438.434021 846.992920,438.667145 847.162170,438.900269 
	C847.162170,438.900238 847.099609,439.002869 847.088623,439.252441 
	C847.378662,440.000275 847.679688,440.498474 847.980713,440.996674 
	C851.948975,456.383453 857.051270,471.578857 859.685852,487.190704 
	C870.156494,549.236206 863.165283,609.475891 838.727112,667.543213 
	C835.127319,676.096497 830.740845,684.318726 826.382996,692.477539 
	C825.699219,687.027283 825.293152,681.796387 825.043152,676.558044 
	C824.981750,675.271423 825.445374,673.962952 825.639954,672.660339 
	C826.102051,669.567444 826.931885,666.473145 826.940857,663.377502 
	C827.036255,630.581482 826.969849,597.784973 827.042114,564.988831 
	C827.050171,561.315430 826.908569,558.148010 823.005432,556.189270 
	C821.910278,555.639709 821.642456,553.441833 821.387573,551.994873 
	C824.440857,551.891602 826.992004,551.644592 827.291748,547.947144 
	C827.735779,547.620056 827.930542,547.377869 828.384766,547.023010 
	C829.134460,546.300903 829.571960,545.649231 830.389465,544.988281 
	C831.486328,544.447205 832.882141,543.805420 832.816528,543.400208 
	C832.058411,538.718384 835.233093,533.438049 830.563293,529.255188 
	C829.783508,528.556824 830.128357,526.427551 830.215393,524.975098 
	C830.372864,522.347839 830.950684,519.733521 830.963318,517.111877 
	C831.037842,501.651215 831.009155,486.190033 830.996521,470.729004 
	C830.989136,461.672638 830.935913,452.616333 830.923340,443.559937 
	C830.909973,433.934875 830.920410,424.309814 830.920410,415.354004 
	C833.481934,413.940460 835.279419,412.948578 837.076843,411.956665 
	C837.761658,413.371674 838.446411,414.786682 838.872925,416.744781 
	C839.483459,418.824921 840.352417,420.361969 841.221313,421.899017 
z"/>
<path fill="#0F8FFB" opacity="1.000000" stroke="none" 
	d="
M820.995117,552.000000 
	C821.642456,553.441833 821.910278,555.639709 823.005432,556.189270 
	C826.908569,558.148010 827.050171,561.315430 827.042114,564.988831 
	C826.969849,597.784973 827.036255,630.581482 826.940857,663.377502 
	C826.931885,666.473145 826.102051,669.567444 825.639954,672.660339 
	C825.445374,673.962952 824.981750,675.271423 825.043152,676.558044 
	C825.293152,681.796387 825.699219,687.027283 826.091064,692.662109 
	C826.134399,693.063354 826.101990,693.059204 825.829163,693.203735 
	C825.394531,693.892212 825.232727,694.436157 825.070923,694.980103 
	C825.070923,694.980103 825.067078,695.036255 824.791626,695.191772 
	C824.355042,695.885376 824.193848,696.423523 824.032654,696.961609 
	C824.032654,696.961609 824.042664,697.022400 823.769897,697.192993 
	C823.371582,697.726013 823.246155,698.088562 823.120667,698.451050 
	C823.120667,698.451050 823.192261,698.771118 822.701782,698.892456 
	C818.194763,700.374939 818.107300,703.720154 818.120056,707.115967 
	C818.120056,707.115967 818.041443,707.025696 817.819031,707.176697 
	C817.412659,707.888245 817.228638,708.448730 817.044617,709.009277 
	C817.044617,709.009277 817.018555,709.020020 816.654846,709.059204 
	C815.531494,710.706299 814.771912,712.314331 814.012268,713.922302 
	C814.012268,713.922302 814.023621,714.015137 813.768188,714.137817 
	C813.260498,714.480835 813.113647,714.757324 813.072327,715.089966 
	C813.072327,715.089966 813.035828,714.998962 812.806213,715.162476 
	C812.398804,715.870972 812.221069,716.415955 812.043274,716.960999 
	C812.043274,716.960999 812.052063,717.029541 811.796509,717.148071 
	C811.269348,717.491028 811.122437,717.779053 811.100159,718.130615 
	C811.100159,718.130615 811.144165,718.053284 810.780762,718.099731 
	C810.001526,719.150513 809.585693,720.154846 809.169861,721.159241 
	C809.169861,721.159241 809.154663,721.124939 808.801147,721.096436 
	C807.630005,721.712646 806.812378,722.357300 805.702271,723.031921 
	C805.273132,723.708679 805.136536,724.355530 805.002258,725.348145 
	C805.033813,726.154053 805.063110,726.614136 805.092407,727.074158 
	C805.092407,727.074158 805.014038,727.064087 804.699463,727.094971 
	C803.943726,728.085754 803.502563,729.045715 803.061401,730.005676 
	C803.061401,730.005676 803.045227,730.039429 802.679688,730.030029 
	C801.210266,730.351135 800.106323,730.681702 799.002441,731.012207 
	C799.709839,729.022705 800.154480,726.882019 801.190491,725.081604 
	C802.516052,722.778137 803.637573,720.786072 801.394897,718.401611 
	C800.939270,717.917175 800.749634,716.551453 801.096985,716.039673 
	C804.939880,710.377869 801.937317,704.401123 801.949280,698.606079 
	C802.041504,654.118713 802.033203,609.631104 801.940796,565.143738 
	C801.932861,561.330688 801.090332,557.519348 800.519775,552.737793 
	C802.043213,553.778870 802.691406,554.381531 803.457703,554.701294 
	C804.248779,555.031311 805.154602,555.086121 806.010254,555.261353 
	C805.927002,554.268555 806.236877,552.880798 805.698669,552.344971 
	C801.116394,547.783020 800.023926,543.236023 802.508179,536.692505 
	C804.083801,532.542053 802.044800,527.110962 802.037537,522.253601 
	C801.972961,478.932495 802.000000,435.611267 802.000000,392.290070 
	C802.000000,384.466675 801.978638,376.643188 802.009644,368.819916 
	C802.024353,365.089478 802.777100,361.787079 807.868896,360.991119 
	C808.665588,360.972046 808.832458,360.961517 808.999390,360.950958 
	C808.999390,360.950958 809.040344,360.969421 809.054443,361.301300 
	C809.462036,362.048279 809.855408,362.463409 810.248840,362.878510 
	C810.248840,362.878510 810.126648,362.913940 810.184448,363.111694 
	C810.510803,363.523193 810.779358,363.736938 811.047913,363.950714 
	C811.047913,363.950714 811.004883,363.994446 811.015869,364.336121 
	C811.683350,365.440399 812.339722,366.203003 812.996094,366.965576 
	C812.996094,366.965607 813.026917,366.983398 813.042786,367.305481 
	C813.425171,368.062073 813.791626,368.496552 814.158142,368.931030 
	C814.158142,368.931030 814.045166,368.971771 814.045288,369.310608 
	C814.697083,371.431335 815.348816,373.213226 815.674927,375.043030 
	C815.321350,376.162659 815.167358,377.250214 815.299438,378.301819 
	C815.442566,379.440613 816.013794,380.531647 816.114197,381.668610 
	C816.363647,384.493011 816.354187,387.341736 816.644836,390.160461 
	C816.792114,391.588623 817.759521,392.996338 817.678223,394.373291 
	C817.235229,401.877991 816.165833,409.364960 816.069946,416.867706 
	C815.583923,454.910980 815.331726,492.957245 814.752197,531.008850 
	C814.506836,531.015259 814.015930,531.021118 813.860474,530.796997 
	C813.301453,530.566040 812.897888,530.559143 812.494263,530.552246 
	C812.669250,531.039612 812.844177,531.526978 812.883118,532.323608 
	C812.583679,533.492493 812.420288,534.352173 812.219238,535.216736 
	C812.181519,535.221680 812.216858,535.154480 811.908569,535.269409 
	C811.439819,535.994202 811.279297,536.604004 811.104614,537.422852 
	C811.090393,537.631836 810.942688,538.023865 810.662964,538.303467 
	C810.677124,541.874817 807.484985,546.172302 813.077271,547.946228 
	C813.073547,547.957886 813.089478,547.923889 813.099976,548.292603 
	C815.738708,549.774170 818.366882,550.887085 820.995117,552.000000 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M279.772552,731.179260 
	C274.179138,722.850891 268.585754,714.522583 262.977112,705.485107 
	C264.157013,703.083740 265.352173,701.391418 266.270447,700.091187 
	C265.430023,698.129578 264.285339,696.463440 264.035797,694.672668 
	C263.459747,690.539368 263.049683,686.346130 263.041840,682.176331 
	C262.970520,644.216797 262.950226,606.256897 263.074890,568.297668 
	C263.087769,564.382385 264.140991,560.470520 264.749969,556.293091 
	C263.490936,554.781433 261.693756,552.623657 260.186951,550.408447 
	C266.361176,550.629211 269.946930,548.147034 271.085938,543.007385 
	C272.066254,538.584045 269.973022,533.637573 265.534698,532.751831 
	C262.317627,532.109741 258.671753,533.616089 255.154755,533.741516 
	C256.095551,532.215027 257.186554,530.099548 258.087036,530.177795 
	C262.713074,530.579346 263.071716,528.181519 263.062866,524.463745 
	C262.963379,482.741089 263.053192,441.018036 262.946869,399.295410 
	C262.929443,392.452942 262.209778,385.612274 261.814667,378.770752 
	C261.814667,378.770752 261.875946,378.893402 262.205872,378.915802 
	C264.082764,379.580566 265.629669,380.222931 267.454865,380.980835 
	C268.239960,377.399780 267.810059,375.083618 263.852325,374.932587 
	C268.775421,367.449493 273.698486,359.966370 279.127960,352.515076 
	C278.974335,355.360382 278.135193,358.148102 277.724457,360.997559 
	C277.493561,362.599182 277.433411,365.378296 278.222198,365.772034 
	C281.426666,367.371429 280.998901,369.980743 280.998932,372.605804 
	C281.000183,486.930481 281.010437,601.255127 280.928741,715.579773 
	C280.927032,717.985962 279.862091,720.391357 279.233063,723.051208 
	C279.392456,725.452759 279.582489,728.315979 279.772552,731.179260 
z"/>
<path fill="#091258" opacity="1.000000" stroke="none" 
	d="
M261.509277,378.947876 
	C262.209778,385.612274 262.929443,392.452942 262.946869,399.295410 
	C263.053192,441.018036 262.963379,482.741089 263.062866,524.463745 
	C263.071716,528.181519 262.713074,530.579346 258.087036,530.177795 
	C257.186554,530.099548 256.095551,532.215027 255.146408,533.722961 
	C255.200409,534.127258 255.174957,534.128967 254.826965,534.112122 
	C254.061844,535.096252 253.644730,536.097168 253.192474,537.424011 
	C253.022964,538.164490 252.888596,538.579041 252.494263,539.036621 
	C252.319839,539.722168 252.405396,540.364746 252.615417,541.389893 
	C252.845764,542.839844 252.951660,543.907288 252.780060,545.186279 
	C251.561996,548.208191 253.066635,548.914368 255.663422,549.065063 
	C257.203064,549.677551 258.549805,550.071716 259.896545,550.465820 
	C261.693756,552.623657 263.490936,554.781433 264.749969,556.293091 
	C264.140991,560.470520 263.087769,564.382385 263.074890,568.297668 
	C262.950226,606.256897 262.970520,644.216797 263.041840,682.176331 
	C263.049683,686.346130 263.459747,690.539368 264.035797,694.672668 
	C264.285339,696.463440 265.430023,698.129578 266.270447,700.091187 
	C265.352173,701.391418 264.157013,703.083740 262.916443,705.160767 
	C261.886230,704.022644 260.901398,702.499695 259.930206,700.244995 
	C258.946136,698.017456 257.948456,696.521667 256.950745,695.025940 
	C256.950745,695.025940 257.005157,695.009094 256.970703,694.722961 
	C256.594513,693.968628 256.252808,693.500427 255.911087,693.032227 
	C255.911087,693.032227 255.986206,693.014038 255.981049,692.654785 
	C255.248062,691.220642 254.520203,690.145813 253.792358,689.070923 
	C253.473190,688.610596 253.154022,688.150269 253.115311,687.173279 
	C254.641724,683.941589 253.693420,682.827454 250.816040,683.120850 
	C250.816040,683.120850 250.924210,683.077881 250.935272,682.710632 
	C250.843170,681.027893 251.229141,679.344177 250.559738,678.454956 
	C245.042908,671.127014 247.317535,660.991150 241.450104,653.796387 
	C236.177933,647.331543 242.724930,639.411682 239.165192,632.598450 
	C238.883606,632.059631 240.256622,630.718933 240.746857,629.696350 
	C241.232361,628.683594 241.910202,627.606262 241.924545,626.549377 
	C242.030884,618.713867 241.996582,610.876343 241.999069,603.039490 
	C242.002670,591.723694 241.815445,580.403137 242.111816,569.095093 
	C242.201431,565.675415 243.663498,562.299316 244.408585,558.884033 
	C244.606796,557.975464 244.842178,556.786560 244.438614,556.083191 
	C242.251633,552.271118 242.713501,548.316833 242.850311,544.141357 
	C243.071869,537.379700 242.118790,530.588806 242.044983,523.806152 
	C241.894699,509.995911 242.161316,496.179840 241.905716,482.372681 
	C241.824738,477.998779 240.527435,473.658081 239.995804,469.276825 
	C239.771912,467.431671 239.613983,465.342285 240.241959,463.670349 
	C243.161560,455.897217 243.010391,454.746918 236.610947,449.269592 
	C242.847900,445.107483 237.849167,437.005859 242.869324,432.054443 
	C243.296341,431.633240 242.663132,430.172119 242.580551,429.189209 
	C242.500595,428.237671 242.336792,427.254456 242.484390,426.328949 
	C243.252853,421.511017 244.108948,416.707031 244.934540,411.898193 
	C244.934540,411.898193 244.978439,411.981506 245.306213,411.941162 
	C251.284714,408.752716 250.649338,402.548462 252.551697,397.582581 
	C252.593201,397.474274 252.104660,397.162903 251.863358,396.946259 
	C254.976883,391.005829 258.090393,385.065399 261.509277,378.947876 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M279.784729,731.151489 
	C279.582489,728.315979 279.392456,725.452759 279.233063,723.051208 
	C279.862091,720.391357 280.927032,717.985962 280.928741,715.579773 
	C281.010437,601.255127 281.000183,486.930481 280.998932,372.605804 
	C280.998901,369.980743 281.426666,367.371429 278.222198,365.772034 
	C277.433411,365.378296 277.493561,362.599182 277.724457,360.997559 
	C278.135193,358.148102 278.974335,355.360382 279.379700,352.285461 
	C287.147247,342.070374 295.169464,332.116638 303.500183,322.039429 
	C303.906738,322.724487 304.396210,323.845673 304.040100,324.291504 
	C301.500793,327.470978 298.636383,330.399170 296.212646,333.658142 
	C295.333954,334.839691 295.164307,336.691620 295.048920,338.265350 
	C294.860626,340.833466 295.306580,343.082916 292.182953,344.880005 
	C289.614716,346.357605 291.181366,348.667450 293.446869,350.335205 
	C296.859467,352.847351 296.974182,355.817383 293.966614,359.950928 
	C293.579681,358.854828 293.245575,358.054016 293.018127,357.223969 
	C292.780060,356.355164 292.644409,355.458252 292.464020,354.573639 
	C291.971375,354.619568 291.478729,354.665466 290.986084,354.711395 
	C291.679932,359.859253 291.917938,365.126678 293.226471,370.113251 
	C294.111206,373.484650 296.316162,376.172974 292.684937,379.449951 
	C291.549438,380.474670 292.159302,383.538818 292.136932,385.671143 
	C292.078461,391.251129 291.996246,396.835022 292.139008,402.411865 
	C292.308441,409.032623 293.001984,415.650421 292.940002,422.264557 
	C292.795258,437.713165 292.143311,453.157928 292.071838,468.606018 
	C292.027344,478.221619 292.922455,487.840302 292.926971,497.457825 
	C292.931366,506.741943 292.081635,516.026123 292.074249,525.310669 
	C292.067230,534.094971 292.984589,542.881653 292.932251,551.663879 
	C292.831207,568.611145 292.017120,585.557068 292.068329,602.501770 
	C292.163788,634.105347 292.650970,665.707886 293.017914,697.310425 
	C293.069458,701.748535 290.783966,706.464172 294.587341,710.560303 
	C294.978699,710.981750 294.437073,713.130493 293.762268,713.536316 
	C290.278168,715.631409 291.415619,718.233521 292.109070,721.279724 
	C293.380829,726.866394 294.243683,732.568787 294.856567,738.268127 
	C295.026581,739.849426 293.715851,741.590027 292.635681,743.631592 
	C291.381775,744.058167 290.576691,744.110535 289.771576,744.162903 
	C289.771576,744.162903 289.884064,744.130066 289.805573,743.918213 
	C289.437195,743.493958 289.147339,743.281494 288.857452,743.069092 
	C288.857452,743.069092 288.951172,743.045410 288.952942,742.704590 
	C287.894135,741.295471 286.833557,740.227173 285.773010,739.158875 
	C285.773010,739.158875 285.884430,739.127075 285.811829,738.918091 
	C285.464447,738.486145 285.189667,738.263184 284.914917,738.040161 
	C284.914917,738.040161 284.989868,738.008240 284.959320,737.698486 
	C283.808868,736.133118 286.319519,731.484375 281.483307,733.702148 
	C281.483307,733.702148 281.421295,733.562805 281.530823,733.166016 
	C281.015045,732.234985 280.389740,731.700623 279.764404,731.166321 
	C279.764404,731.166321 279.796936,731.123779 279.784729,731.151489 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M244.607559,412.061035 
	C244.108948,416.707031 243.252853,421.511017 242.484390,426.328949 
	C242.336792,427.254456 242.500595,428.237671 242.580551,429.189209 
	C242.663132,430.172119 243.296341,431.633240 242.869324,432.054443 
	C237.849167,437.005859 242.847900,445.107483 236.610947,449.269592 
	C243.010391,454.746918 243.161560,455.897217 240.241959,463.670349 
	C239.613983,465.342285 239.771912,467.431671 239.995804,469.276825 
	C240.527435,473.658081 241.824738,477.998779 241.905716,482.372681 
	C242.161316,496.179840 241.894699,509.995911 242.044983,523.806152 
	C242.118790,530.588806 243.071869,537.379700 242.850311,544.141357 
	C242.713501,548.316833 242.251633,552.271118 244.438614,556.083191 
	C244.842178,556.786560 244.606796,557.975464 244.408585,558.884033 
	C243.663498,562.299316 242.201431,565.675415 242.111816,569.095093 
	C241.815445,580.403137 242.002670,591.723694 241.999069,603.039490 
	C241.996582,610.876343 242.030884,618.713867 241.924545,626.549377 
	C241.910202,627.606262 241.232361,628.683594 240.746857,629.696350 
	C240.256622,630.718933 238.883606,632.059631 239.165192,632.598450 
	C242.724930,639.411682 236.177933,647.331543 241.450104,653.796387 
	C247.317535,660.991150 245.042908,671.127014 250.559738,678.454956 
	C251.229141,679.344177 250.843170,681.027893 250.907043,682.761536 
	C240.241714,663.734192 233.518204,642.838379 227.891037,621.526123 
	C218.248459,585.006104 215.748230,547.884949 219.148911,510.389618 
	C222.128494,477.537170 230.244659,445.878357 243.061676,415.452698 
	C243.507965,414.393311 243.876022,413.300903 244.607559,412.061035 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M713.932190,268.240143 
	C725.127747,276.301880 736.323303,284.363647 747.289429,292.711151 
	C745.194031,292.220001 743.328003,291.443085 740.898193,290.431427 
	C741.162659,293.741272 741.389038,296.574371 741.623169,299.504181 
	C740.041626,298.973724 738.819702,298.563904 737.597717,298.154083 
	C737.570862,299.906128 738.067139,300.950867 738.880981,301.353180 
	C741.282410,302.540375 742.440125,303.966370 741.648743,306.878876 
	C741.052673,309.072815 741.037964,311.465088 741.025085,313.770538 
	C740.952087,326.883087 741.011963,339.996582 740.894592,353.108582 
	C740.867981,356.084564 740.318604,359.055847 739.648071,362.023163 
	C738.569885,362.719299 737.853394,363.421570 737.123962,364.122498 
	C737.111023,364.121155 737.107178,364.095520 736.868286,364.171021 
	C736.444519,364.514648 736.259705,364.782806 736.045654,365.034088 
	C736.016479,365.017242 736.051270,365.074890 735.822083,365.166199 
	C735.302612,365.453949 735.133972,365.725891 734.997925,366.358459 
	C734.797913,366.809143 734.687073,366.974670 734.382690,367.453491 
	C734.824036,370.493744 735.458862,373.220703 736.056274,375.979004 
	C736.018860,376.010315 736.075500,375.931396 736.069214,376.237305 
	C736.376648,377.028931 736.690369,377.514618 737.113281,378.098145 
	C737.222473,378.196014 737.140808,378.477692 737.182617,378.786713 
	C737.506470,379.381439 737.788513,379.667114 738.341919,380.146942 
	C742.412964,383.649780 746.212708,386.958496 749.686157,390.493774 
	C747.085571,390.929443 744.811401,391.138580 742.567505,391.344879 
	C741.680298,394.226196 740.271057,396.818542 740.203247,399.445526 
	C740.071289,404.556976 741.101318,409.705444 740.882324,414.805603 
	C740.671997,419.704407 740.259705,424.284760 744.261719,428.109894 
	C744.875732,428.696808 744.059998,430.779541 743.600952,431.990326 
	C735.041199,415.938538 724.450439,401.684875 713.226074,387.839905 
	C712.741089,385.904114 712.475159,383.597565 711.185486,382.543549 
	C706.594238,378.791199 707.495239,375.058990 708.310425,369.621185 
	C709.227051,363.507477 710.484863,359.312439 716.413513,356.753906 
	C718.248352,355.582184 719.752136,354.450989 721.619629,353.046204 
	C715.313416,348.175323 709.628479,343.784332 703.739197,339.235504 
	C711.490234,329.127869 718.913635,319.447479 726.464233,309.601227 
	C724.606445,308.096619 723.224731,306.977600 721.295837,305.415375 
	C720.207092,306.788177 719.289062,307.945770 718.070679,309.189392 
	C717.514587,309.812622 717.258728,310.349792 716.929993,311.174072 
	C716.082336,312.342712 715.307617,313.224243 714.216858,314.174469 
	C713.663940,314.578857 713.427002,314.914551 713.275757,315.305725 
	C713.361572,315.361267 713.385559,315.564240 713.056030,315.405701 
	C710.985474,312.947174 709.244446,310.647186 707.927368,308.907288 
	C708.647217,305.804688 709.410583,302.905731 709.959656,299.966705 
	C710.199280,298.684174 710.076782,297.314423 709.987488,295.993164 
	C709.456238,288.136108 708.699280,280.287109 708.471680,272.422272 
	C708.419739,270.626740 710.150940,268.779633 711.061462,266.956268 
	C711.227539,266.981323 711.393555,267.006348 711.924683,267.298889 
	C712.519775,267.781860 712.792114,267.895386 713.106995,267.907013 
	C713.106995,267.907013 713.004028,267.899200 713.077759,268.064697 
	C713.411743,268.233490 713.671997,268.236816 713.932190,268.240143 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M716.082458,356.794403 
	C710.484863,359.312439 709.227051,363.507477 708.310425,369.621185 
	C707.495239,375.058990 706.594238,378.791199 711.185486,382.543549 
	C712.475159,383.597565 712.741089,385.904114 712.903320,387.726440 
	C708.179810,383.333740 704.029480,378.850708 699.808105,374.075104 
	C699.416870,373.562653 699.096802,373.342834 698.838257,373.124512 
	C698.899841,373.126007 698.850098,373.238770 698.788940,372.918701 
	C698.055359,372.142853 697.382874,371.687042 696.770752,371.213806 
	C696.831055,371.196381 696.785095,371.313171 696.732788,371.015533 
	C696.158936,370.484253 695.637329,370.250610 695.042114,370.018738 
	C694.968384,370.020477 694.953613,369.873840 694.863037,369.687317 
	C694.598999,369.269318 694.371094,369.122040 693.825195,368.975189 
	C693.351868,368.602386 693.142151,368.313385 692.842041,367.799744 
	C692.552979,367.297333 692.286072,367.126007 691.970215,367.030060 
	C691.989563,366.999023 691.937988,367.052612 691.843018,366.819763 
	C691.468872,366.414246 691.189758,366.241547 690.953125,366.039734 
	C690.995544,366.010651 690.933350,366.092743 690.829834,365.847046 
	C690.418884,365.442566 690.111389,365.283783 689.855408,365.134735 
	C689.906921,365.144440 689.825745,365.210815 689.840576,364.848572 
	C688.910034,363.994629 687.964783,363.502991 686.771301,362.963013 
	C686.328796,362.654175 686.134521,362.393677 685.840698,361.881470 
	C685.436096,361.453339 685.130920,361.276886 684.875244,361.112732 
	C684.924744,361.125000 684.840576,361.182495 684.888062,360.853790 
	C683.961914,360.034332 682.988342,359.543579 681.987732,359.049957 
	C681.960693,359.047089 681.944580,358.995117 681.843994,358.773010 
	C681.532898,358.280609 681.258728,358.116699 680.944153,358.071716 
	C680.967407,358.084229 680.911682,358.083313 680.908813,357.726074 
	C679.204956,354.091705 677.147400,351.368591 672.808594,352.178162 
	C672.793823,352.188873 672.762207,352.208038 672.834717,351.742554 
	C672.335449,346.468140 675.708862,340.343506 668.194885,338.063629 
	C672.584656,332.148346 672.584656,332.148346 671.643982,327.511902 
	C673.431885,327.698029 675.196899,327.881805 677.213867,328.324463 
	C678.971741,327.902405 681.073975,327.591583 681.797668,326.425293 
	C682.394043,325.464386 681.356812,323.489838 680.982727,321.631104 
	C678.981079,317.995392 673.389465,317.099731 674.605591,311.716248 
	C673.362366,311.528351 672.051636,311.001282 670.891602,311.231903 
	C669.139343,311.580292 667.492065,312.457062 665.800110,313.109314 
	C665.566467,312.540192 665.332825,311.971100 665.099121,311.401978 
	C668.437866,309.075714 671.776550,306.749481 675.496826,304.157349 
	C669.333374,299.212830 673.450684,295.656982 676.050537,291.978821 
	C678.839905,288.032562 681.210022,284.029266 679.030151,278.721130 
	C679.020386,278.480591 679.020081,277.998596 679.433594,277.999084 
	C681.813477,277.695801 683.779724,277.392029 685.746033,277.088226 
	C685.838867,276.562805 685.931641,276.037384 686.024475,275.511963 
	C680.988647,275.315247 680.770264,272.222961 680.951416,268.661407 
	C681.075439,266.225555 681.199646,263.626526 680.506592,261.349121 
	C679.353516,257.560242 680.089478,255.149490 684.383789,254.041565 
	C686.543030,254.393967 688.318481,254.701553 690.093994,255.009125 
	C696.984863,258.778076 703.875793,262.547028 710.914062,266.636108 
	C710.150940,268.779633 708.419739,270.626740 708.471680,272.422272 
	C708.699280,280.287109 709.456238,288.136108 709.987488,295.993164 
	C710.076782,297.314423 710.199280,298.684174 709.959656,299.966705 
	C709.410583,302.905731 708.647217,305.804688 707.927368,308.907288 
	C709.244446,310.647186 710.985474,312.947174 712.944092,315.709045 
	C707.154968,324.035828 701.148376,331.900726 694.980713,339.976440 
	C702.187988,345.720612 709.135193,351.257507 716.082458,356.794403 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M604.036316,857.998352 
	C603.229675,858.284302 602.423035,858.570312 600.983154,858.571655 
	C598.234314,858.356689 596.102295,858.282837 594.006165,858.522644 
	C587.327759,859.286499 580.662842,860.167053 573.958862,860.650696 
	C571.991272,857.521362 570.891479,853.096924 570.975525,848.849243 
	C570.998230,847.700623 570.817261,846.538452 570.638184,845.396912 
	C569.006287,834.993774 568.098450,824.627869 570.674988,814.189087 
	C571.241272,811.894775 570.744507,809.258728 570.380371,806.833679 
	C569.898499,803.624451 568.137756,800.320557 568.613647,797.325439 
	C569.233826,793.422791 569.105896,790.624573 565.338135,787.892639 
	C567.365051,787.799133 569.391968,787.705627 571.069397,787.628235 
	C571.451477,785.468140 571.961914,783.613159 572.047668,781.738647 
	C572.106018,780.462830 571.981506,778.417908 571.227173,777.999512 
	C568.223999,776.333496 569.524902,774.150024 570.367432,771.921631 
	C573.018738,771.235962 575.323669,770.711182 577.558228,769.973328 
	C579.718811,769.259888 581.806824,768.326843 584.252380,767.317139 
	C589.718567,766.433411 594.859192,765.722778 599.944519,765.360107 
	C597.799438,770.122009 598.165283,774.698853 600.442566,778.656738 
	C601.802734,781.020630 602.170959,782.359253 600.066040,784.049927 
	C600.048706,784.048584 600.035950,784.078552 599.662231,784.055664 
	C598.196960,784.667358 597.105347,785.301880 595.808960,786.211304 
	C589.616882,789.826904 585.822083,794.704346 584.720520,801.554810 
	C588.685730,803.022461 590.715759,801.930603 592.504028,798.128113 
	C596.121033,790.437134 606.190125,787.971191 613.539734,792.400574 
	C621.181458,797.005798 623.654785,808.229431 618.659973,815.634399 
	C613.910278,822.675964 603.737793,824.676270 597.182251,819.230896 
	C593.811707,816.431091 591.553955,816.537048 588.063904,820.395142 
	C592.160828,823.029724 596.103882,825.565308 600.317322,828.368225 
	C601.873962,828.709961 603.160156,828.784424 604.743591,828.743652 
	C605.671753,828.676392 606.302734,828.724243 606.953369,829.225891 
	C606.973022,837.217834 606.973022,844.756042 606.973022,852.643433 
	C604.019775,852.805481 602.366333,854.405090 604.036316,857.998352 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M623.953552,229.144684 
	C626.430664,229.692780 628.907715,230.240891 631.758789,231.287872 
	C635.981018,233.398987 639.829224,235.011215 642.388977,236.083603 
	C642.730286,242.409012 641.509338,248.604126 643.733948,253.090851 
	C646.067566,257.797546 647.075195,259.750153 641.465759,260.720978 
	C642.108459,262.822113 642.630920,264.530182 643.140381,266.449646 
	C643.127380,266.661041 642.906433,267.022491 642.677124,267.195068 
	C642.316162,267.910797 642.184570,268.453979 642.048157,269.014404 
	C642.043335,269.031708 642.019348,269.058350 641.646912,269.108185 
	C640.869934,270.771057 640.465454,272.384064 640.059326,274.017944 
	C640.057678,274.038757 640.029358,274.069519 639.640503,274.124390 
	C638.890015,275.627747 638.528259,277.076233 638.198608,278.669861 
	C638.230591,278.815033 637.995728,278.997345 637.655151,279.048950 
	C636.911438,279.820801 636.508301,280.541016 636.069214,281.643738 
	C633.550659,289.478882 631.068054,296.931519 628.308167,305.216461 
	C630.268616,305.684998 632.347534,306.181793 634.786133,306.668579 
	C636.146729,305.562805 637.147583,304.467102 638.364685,303.134552 
	C638.283752,306.861176 638.283752,306.861176 630.806213,311.641296 
	C632.112488,312.488831 633.618347,312.997986 634.320007,314.045715 
	C635.105713,315.218933 635.594238,316.932770 635.424683,318.314789 
	C635.227783,319.920105 634.352661,321.534943 633.446838,322.935364 
	C632.486389,324.420288 631.144348,325.658356 629.594604,326.962463 
	C626.487854,326.284973 623.755493,325.649292 621.009399,325.015381 
	C620.995789,325.017181 620.977905,324.996155 620.821411,324.677185 
	C619.670044,323.835449 618.727539,323.144623 617.672241,322.816193 
	C608.122803,319.844238 598.553650,316.935699 589.005493,313.615753 
	C590.839111,312.085266 592.657349,310.947601 594.914001,309.535645 
	C593.837219,308.635834 592.643677,307.843201 591.720886,306.808990 
	C590.821411,305.800842 590.198853,304.545593 589.454712,303.398865 
	C590.606812,302.933411 591.746643,302.434235 592.914185,302.011444 
	C594.196228,301.547241 595.503723,301.153351 596.800110,300.728821 
	C596.203247,299.174988 595.606445,297.621124 595.447266,296.022522 
	C599.166138,295.617920 602.447327,295.258087 606.149414,294.926544 
	C611.416687,295.147766 613.758362,292.852539 612.955383,288.024902 
	C612.113586,282.963989 610.937134,277.958740 609.914185,272.942505 
	C609.921570,272.955811 609.926880,272.938782 610.159119,272.845123 
	C610.573425,272.479706 610.755432,272.207947 610.967529,271.968689 
	C610.997559,272.001221 610.933472,271.938293 611.172241,271.827454 
	C611.559387,271.408508 611.707825,271.100403 611.830444,270.778748 
	C611.804688,270.765198 611.793823,270.707886 612.175171,270.740967 
	C613.355591,268.474976 614.452087,266.225555 614.846985,263.859009 
	C615.100525,262.339539 614.059082,260.648285 614.055359,259.032959 
	C614.045410,254.661560 612.281433,252.208008 607.671021,251.940201 
	C605.291992,251.802017 601.250244,245.868286 602.617371,244.274017 
	C606.401489,239.860916 604.363342,235.284805 604.048401,230.652130 
	C603.716492,225.769058 604.875061,225.489761 609.531433,226.852676 
	C614.165405,228.209045 619.132507,228.427353 623.953552,229.144684 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M698.013611,823.656494 
	C697.880737,823.769958 697.747925,823.883484 697.042847,824.032715 
	C695.999268,824.412292 695.527832,824.755981 695.056335,825.099670 
	C695.056335,825.099670 695.001526,825.001160 694.724609,825.042786 
	C693.988708,825.441833 693.529663,825.799255 693.070679,826.156677 
	C693.070679,826.156677 693.013794,826.023743 692.670166,826.039917 
	C688.317017,827.744324 684.371216,829.617004 680.268127,831.034363 
	C678.503357,831.643982 676.412415,831.309448 673.455261,831.453186 
	C679.016357,824.239929 679.016357,824.239929 671.730042,821.403137 
	C675.429443,819.521912 678.173828,818.126343 682.390259,815.982239 
	C678.441833,815.285400 675.948059,814.845337 672.676636,814.267944 
	C675.853027,811.997742 678.198914,810.004211 674.791382,806.590271 
	C679.416199,803.528320 683.649475,800.725586 688.462708,797.538879 
	C686.287170,794.294922 684.179993,791.152832 682.044067,787.657715 
	C681.358337,786.518799 680.701233,785.733093 680.030640,784.544189 
	C678.704102,778.371033 677.104126,772.751648 672.030029,768.530640 
	C671.690125,764.579834 671.360535,761.049683 671.011230,757.308960 
	C669.239197,757.058716 667.229675,756.774902 666.276489,756.640259 
	C665.021179,752.349121 664.053223,749.040222 663.298523,746.460388 
	C666.310913,744.635010 668.773560,743.142761 671.236145,741.650513 
	C671.096985,741.127441 670.957764,740.604370 670.818542,740.081360 
	C668.977966,739.475403 667.137329,738.869385 665.548584,738.346375 
	C668.777039,735.050354 671.817566,731.946167 674.907104,728.861694 
	C674.955994,728.881348 674.853699,728.906860 675.102051,728.831787 
	C675.539795,728.480408 675.729187,728.204163 676.018677,727.694214 
	C676.568970,727.271912 677.019165,727.083313 677.783997,726.834717 
	C678.380066,726.472717 678.661560,726.170715 678.947388,725.911865 
	C678.951660,725.955017 678.872681,725.919189 679.113525,725.846680 
	C679.556946,725.517212 679.759460,725.260315 679.969971,724.988037 
	C679.977905,724.972778 680.010986,724.963074 680.226624,724.863403 
	C680.710144,724.567139 680.880737,724.306885 680.970581,723.982361 
	C680.987244,723.981873 680.974182,723.951233 681.191284,723.876343 
	C681.685120,723.640015 681.878601,723.408875 682.171875,723.004395 
	C682.354919,722.900818 682.775696,722.897461 683.158630,722.926392 
	C684.982056,721.583435 686.422485,720.211487 687.908569,718.861572 
	C687.954224,718.883606 687.856018,718.908875 688.102417,718.834351 
	C688.544373,718.488586 688.739990,718.217285 688.964722,717.953857 
	C688.993835,717.961792 688.954590,717.915771 689.343750,717.934509 
	C690.225220,717.145325 690.717651,716.337341 691.285522,715.475830 
	C691.361023,715.422180 691.509094,715.310852 691.813721,715.432678 
	C692.385864,715.022949 692.653320,714.491333 692.940674,713.960693 
	C692.960632,713.961548 692.961914,713.921570 693.282349,713.915955 
	C694.034241,713.509460 694.465698,713.108582 695.032104,712.419250 
	C696.771362,710.732178 698.375610,709.333557 700.366272,707.957397 
	C701.835205,708.273865 702.917664,708.567932 703.989380,708.859009 
	C701.988647,713.209717 700.330139,716.816223 698.269104,721.298157 
	C698.525818,722.782715 699.012085,725.594482 699.458069,728.173462 
	C700.983887,728.487244 702.393188,728.776978 704.173523,729.143066 
	C702.342712,731.968811 700.973511,734.081970 699.275513,736.183167 
	C697.260254,737.254028 695.573853,738.336853 693.806763,739.471497 
	C698.505737,745.661377 702.785522,751.299072 706.844666,757.209595 
	C704.771851,758.522522 700.932861,755.566956 700.982910,760.510803 
	C701.090881,771.177429 701.028015,781.846008 700.985901,792.513672 
	C700.974121,795.498535 700.583191,798.493042 700.730103,801.465088 
	C701.019531,807.320801 701.563599,813.163879 702.009033,819.363770 
	C702.057800,820.182190 702.099976,820.648987 702.142151,821.115845 
	C702.142151,821.115845 702.130066,821.082092 701.888794,821.149292 
	C701.350586,821.361694 701.150757,821.591431 701.048096,821.905640 
	C701.048096,821.905640 700.961365,822.035645 700.577087,822.019958 
	C699.466431,822.555054 698.740051,823.105774 698.013611,823.656494 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M346.619843,799.118347 
	C346.619843,799.118347 346.258301,799.160583 346.003510,798.830200 
	C345.527100,798.253174 345.253510,798.098206 344.928009,798.034851 
	C344.928009,798.034851 344.996002,798.013489 344.874420,797.808228 
	C344.465729,797.416809 344.178650,797.230591 343.891541,797.044373 
	C343.891541,797.044373 343.968445,797.030151 343.840515,796.820435 
	C343.380920,796.471191 343.049286,796.331604 342.717621,796.192017 
	C342.717621,796.192017 342.836151,796.170837 342.850677,795.854614 
	C340.547272,790.911743 337.201050,787.656311 331.752136,787.205017 
	C331.752136,787.205017 331.892670,787.124329 331.885010,786.851562 
	C332.066864,784.678101 332.256378,782.777405 332.445892,780.876648 
	C332.513306,780.838318 332.580719,780.799988 332.648132,780.761597 
	C332.863068,777.582336 333.078003,774.403076 333.296417,771.172424 
	C331.204987,771.804504 329.455566,772.333252 326.754303,773.149719 
	C331.998779,764.757446 331.998749,764.757446 331.998749,757.058594 
	C331.998749,749.165649 331.998779,741.272644 331.998779,733.376160 
	C330.310333,733.451111 328.462158,733.533203 326.613983,733.615295 
	C332.875977,730.546326 331.468170,724.992554 332.296906,720.282349 
	C333.167480,715.334412 335.192749,710.446899 330.028625,705.747253 
	C334.142303,705.439819 337.022369,705.224548 339.978088,705.328125 
	C340.055603,706.083374 340.057465,706.519775 339.766785,707.101196 
	C336.941162,709.628601 338.523499,710.466370 341.005188,711.340942 
	C341.555695,712.696960 341.988373,713.722839 342.365509,714.617004 
	C347.794006,712.900024 347.892517,708.825562 346.511108,704.395508 
	C345.541931,701.287292 344.119232,698.320618 342.927979,694.882080 
	C343.286316,690.021484 340.451813,689.135864 336.903748,689.071350 
	C336.927948,689.060852 336.899750,689.106506 336.921143,688.713501 
	C336.284058,687.550049 335.775024,686.390991 334.943207,686.070862 
	C328.703705,683.670227 327.819153,681.682556 330.501831,675.727112 
	C330.822784,675.014709 331.028503,674.070251 330.849792,673.340637 
	C329.068909,666.069458 327.088074,658.845215 325.432373,651.546814 
	C325.063599,649.921204 325.747162,648.056824 325.946198,646.302368 
	C326.483246,646.376404 327.020294,646.450378 327.557312,646.524353 
	C327.705170,647.604980 327.728546,648.722107 328.031891,649.757141 
	C328.423920,651.094788 328.889252,652.470764 329.665009,653.593994 
	C329.858765,653.874573 332.144806,653.231201 332.140320,653.044373 
	C332.107361,651.673584 331.765808,650.304443 331.465363,648.947144 
	C330.997223,646.832336 330.484711,644.727295 329.724213,641.478210 
	C332.805695,643.989502 334.534821,645.398621 336.210358,646.830139 
	C336.156799,646.852478 336.179108,646.738586 336.209259,647.000732 
	C336.476257,647.510681 336.713104,647.758606 337.067291,648.137817 
	C337.184601,648.269226 337.121643,648.615784 337.169678,648.896973 
	C337.467194,649.454041 337.716705,649.729858 338.088867,650.093506 
	C338.211548,650.181274 338.139313,650.474182 338.107666,650.877747 
	C338.758362,652.174500 339.440704,653.067688 340.384399,654.097595 
	C340.836914,654.786255 341.028015,655.338135 340.939789,656.082397 
	C340.371643,660.322937 342.084778,662.957581 346.360291,664.100769 
	C346.854919,664.771423 347.083588,665.312134 347.026611,666.026001 
	C346.695068,669.318115 347.867004,671.455566 351.203918,671.827148 
	C351.149750,671.849548 351.170898,671.734192 351.216980,672.004272 
	C351.531006,672.507507 351.798981,672.740540 352.034058,672.982361 
	C352.001221,672.991089 352.039459,672.934814 352.032593,673.290771 
	C352.682983,675.098633 353.340271,676.550537 353.666504,678.184204 
	C352.982483,680.375549 352.584320,682.378723 352.292267,684.397156 
	C352.095032,685.760498 352.506653,687.434509 351.854218,688.471313 
	C348.854919,693.237732 349.710510,696.174744 354.915771,698.268372 
	C355.398743,698.462646 355.556488,699.465454 356.045868,700.459717 
	C354.149017,700.459717 351.602600,700.389832 351.597900,700.469666 
	C351.280334,705.845703 349.950256,711.326294 351.403229,715.919983 
	C352.784271,720.286133 351.656342,723.780273 351.355438,727.733276 
	C350.970215,732.793762 348.953186,738.464844 353.654938,741.256409 
	C352.476715,746.010437 351.553680,749.995483 350.495911,753.944519 
	C348.691833,760.679626 349.227814,761.601746 356.121887,763.595337 
	C354.782593,765.410767 353.565216,766.927185 352.203552,768.623352 
	C353.569183,769.876526 354.777740,770.985596 355.780884,772.351074 
	C351.204163,774.511658 351.384918,778.820374 351.381744,782.271118 
	C351.375977,788.517273 352.396149,794.764404 352.581238,801.007996 
	C351.088959,801.020325 350.003479,801.035767 348.917999,801.051270 
	C348.917999,801.051270 348.991028,801.022644 348.864227,800.815369 
	C348.431885,800.433716 348.126343,800.259277 347.820801,800.084839 
	C347.820770,800.084900 347.937286,800.060669 347.809174,799.845703 
	C347.327301,799.460022 346.973572,799.289185 346.619843,799.118347 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M406.761780,247.240875 
	C408.948730,246.188019 411.135712,245.135162 413.622681,244.448166 
	C413.480194,245.493118 413.193604,246.513397 412.570831,246.797989 
	C406.920288,249.380219 404.008484,253.887619 403.080627,259.857544 
	C402.917480,260.907196 402.865387,261.974121 402.762024,263.033051 
	C403.896362,262.861359 405.030029,262.685120 406.165253,262.519653 
	C406.899963,262.412598 407.636993,262.321350 408.373016,262.223022 
	C403.929932,266.203217 402.884033,270.684143 405.002777,276.186523 
	C404.997253,276.398987 404.996246,276.822052 404.774475,276.900177 
	C404.552673,276.978302 404.084320,277.020782 403.682800,277.014954 
	C401.200256,278.030579 399.119263,279.052063 397.022644,280.041168 
	C397.007050,280.008789 397.075317,280.031342 396.850739,280.109680 
	C396.333344,280.344971 396.126526,280.578186 395.752930,280.998840 
	C395.296234,281.408783 395.092224,281.707581 394.732300,282.111084 
	C394.722412,282.483002 394.868469,282.750183 395.106140,283.098450 
	C395.197754,283.179535 395.106903,283.406738 395.149994,283.721802 
	C395.446686,284.361847 395.700287,284.686829 396.071411,285.132568 
	C396.188934,285.253326 396.119202,285.583038 396.108185,285.942627 
	C396.478699,286.843628 396.860199,287.385040 397.180420,287.930542 
	C397.119110,287.934631 397.152802,287.816437 397.105255,288.236633 
	C397.157074,291.222626 396.339355,294.295410 400.352295,294.734497 
	C400.317688,294.790802 400.293823,294.660767 400.278503,294.934174 
	C400.489380,295.478546 400.715607,295.749512 401.057373,296.141479 
	C401.172913,296.262512 401.105316,296.590271 401.097870,296.949097 
	C401.467010,297.853088 401.843597,298.398285 402.491577,299.157288 
	C405.866821,306.522400 408.970642,313.673676 411.917297,321.150421 
	C410.781647,321.919769 409.703369,322.884857 408.841400,322.719849 
	C404.471222,321.883423 403.603607,323.401428 404.556946,327.546448 
	C405.157013,330.155518 404.429169,333.048462 404.499847,335.808899 
	C404.542053,337.456879 404.807556,339.146729 405.291351,340.719757 
	C405.636658,341.842438 406.511932,342.802155 407.165039,343.857788 
	C405.592316,344.781128 404.518707,345.411438 403.665924,345.912109 
	C406.400604,346.713196 408.948517,347.459564 411.055908,348.076904 
	C414.744110,346.699768 418.359283,345.349915 421.761414,344.259552 
	C409.349426,353.590424 397.150543,362.661835 384.688293,371.512634 
	C380.351410,371.428009 375.921844,374.763336 372.522186,369.921906 
	C374.548553,361.924835 376.082092,353.941895 369.009766,345.675079 
	C376.639221,349.422180 377.049591,349.493896 378.315582,348.118164 
	C377.258148,346.794647 376.236572,345.515930 375.214966,344.237244 
	C376.175964,343.792206 377.136932,343.347168 378.069214,342.920074 
	C378.040497,342.938049 378.043488,342.870422 378.168121,343.125977 
	C378.635559,343.513519 378.978333,343.645508 379.214294,343.771667 
	C379.107452,343.765839 379.185791,343.566681 379.270813,343.917236 
	C380.874298,345.553040 382.392761,346.838318 383.911224,348.123566 
	C384.191864,347.760925 384.472504,347.398315 384.753143,347.035675 
	C384.132660,345.309937 383.512177,343.584198 382.918945,341.901611 
	C382.946198,341.944763 382.846985,341.920837 383.097382,341.841248 
	C383.558929,341.491913 383.770050,341.222137 384.069153,340.627258 
	C384.188629,339.854797 384.220123,339.407410 384.370270,338.821045 
	C384.248169,338.507355 384.007416,338.332703 383.772461,338.139343 
	C383.778290,338.120636 383.758636,338.168365 383.830383,337.735901 
	C383.856567,335.483154 384.507965,333.158813 383.627930,331.942047 
	C381.439117,328.915588 380.860992,326.872314 383.378693,323.362518 
	C385.104248,320.957062 384.623535,316.908234 384.908569,313.575165 
	C384.975342,312.794189 384.017395,311.975250 383.766266,311.105774 
	C383.388855,309.799072 383.043884,308.435303 383.021240,307.090149 
	C383.005554,306.160126 383.972687,305.126831 383.786438,304.300690 
	C382.677338,299.381287 381.360229,294.508759 380.131531,289.693207 
	C387.573425,289.295624 388.254578,287.958282 384.461700,280.127625 
	C385.364410,278.756042 386.257263,277.399384 387.444122,275.596039 
	C385.246826,275.182709 383.728149,275.279419 382.767334,274.594055 
	C382.012421,274.055573 381.455353,271.711212 381.647003,271.599091 
	C385.817719,269.159149 382.863617,266.816376 380.801849,263.929718 
	C383.495117,263.768433 385.344666,263.657684 387.078583,263.553864 
	C389.672424,260.010193 392.289764,256.434448 394.907104,252.858734 
	C394.907135,252.858734 394.974091,252.953598 395.337280,252.959106 
	C398.254242,252.855377 401.279053,253.607941 401.737610,249.665054 
	C401.737610,249.665054 401.905243,249.749893 402.232422,249.824432 
	C403.960327,249.012939 405.361053,248.126907 406.761780,247.240875 
M387.139069,294.490417 
	C387.567474,294.794586 387.995880,295.098785 388.424286,295.402985 
	C388.538361,294.952789 388.652405,294.502625 388.766449,294.052429 
	C388.264954,293.998627 387.763428,293.944794 387.139069,294.490417 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M447.025330,232.094971 
	C447.274567,231.890060 447.558533,231.762573 448.549896,231.821350 
	C451.106873,231.193649 452.991180,230.457077 454.875488,229.720490 
	C454.875488,229.720474 454.911835,229.842743 455.269287,229.888000 
	C456.749939,229.953278 457.873138,229.973282 459.001831,230.366150 
	C459.533936,232.236740 460.060577,233.734451 460.812927,235.873993 
	C458.444244,235.766937 456.442169,235.676453 452.933075,235.517853 
	C453.760529,238.962204 454.465973,241.898743 455.275482,245.268265 
	C451.932312,249.089050 451.932312,249.089050 459.456421,253.940445 
	C456.948730,254.860672 454.441040,255.780914 451.933350,256.701141 
	C452.489899,257.446045 453.046417,258.190918 453.602936,258.935822 
	C455.430450,258.652710 457.257935,258.369568 459.027588,258.428589 
	C449.150146,272.403046 449.403961,280.815002 459.643250,293.017883 
	C457.867157,293.930725 456.450806,294.838348 454.776794,295.911041 
	C457.213013,298.560272 459.175354,300.694153 461.137665,302.828064 
	C460.888733,303.124908 460.639801,303.421783 460.390900,303.718658 
	C458.803955,303.249298 457.217041,302.779968 455.833099,302.370636 
	C456.592865,306.250153 458.005707,309.880981 457.829865,313.433136 
	C457.585388,318.371460 458.717712,321.452332 463.827209,322.264069 
	C462.774506,323.359467 461.895874,324.198639 461.013275,325.016296 
	C461.009308,324.994720 461.039246,325.027466 460.689331,325.022766 
	C459.575043,325.398468 458.810638,325.778839 458.048889,326.113098 
	C458.051514,326.066986 458.138428,326.098206 457.721497,326.069519 
	C451.054443,328.345703 444.846863,330.779541 438.524170,332.864197 
	C436.954193,333.381866 434.580627,333.357483 433.383911,332.480438 
	C432.510071,331.839966 432.842102,329.391571 432.859650,327.751465 
	C432.872498,326.549835 433.619080,325.276031 433.392029,324.166748 
	C432.676636,320.672058 431.656403,317.239746 430.762848,313.794128 
	C430.771576,313.805023 430.798431,313.803833 431.125427,313.846527 
	C431.950043,313.542633 432.447662,313.196014 432.950195,312.894653 
	C432.955170,312.939911 432.874451,312.897827 433.191528,312.910400 
	C433.995270,312.586578 434.481934,312.250153 434.977570,311.952484 
	C434.986572,311.991241 434.924194,311.941803 435.332153,311.960510 
	C437.141876,311.266602 438.543610,310.553986 440.010071,309.595490 
	C440.332245,308.930756 440.589691,308.511871 441.120911,307.893524 
	C440.473969,305.838837 439.553253,303.983673 438.632507,302.128510 
	C437.439850,302.776733 436.247223,303.424957 435.024231,304.042297 
	C434.993927,304.011444 435.064911,304.059052 434.669342,304.039307 
	C432.069580,304.522217 429.865417,305.024902 427.399994,305.587158 
	C426.406921,303.292877 425.406555,300.981750 424.406158,298.670654 
	C427.403564,298.433502 430.400970,298.196350 434.399841,297.879974 
	C432.632629,294.648926 430.815430,291.326508 428.999146,288.002075 
	C429.000000,288.000000 428.996277,288.000824 428.933594,287.652710 
	C427.727539,286.541138 426.584167,285.777710 426.158661,285.493591 
	C426.647797,281.093994 427.962921,276.965546 427.326965,273.163422 
	C426.395294,267.593323 424.584839,262.324768 430.121765,257.809265 
	C429.037018,257.336792 428.390900,257.174744 427.884094,256.815918 
	C423.564911,253.757828 423.673615,251.572861 427.794434,248.377365 
	C429.022858,247.424820 429.480255,245.477890 430.724518,243.188889 
	C426.836182,243.550049 424.413147,243.775116 422.156067,243.684708 
	C424.059143,242.559067 425.936920,241.951172 427.488068,240.873276 
	C428.680237,240.044815 429.461151,238.624542 430.424957,237.467468 
	C430.424957,237.467453 430.578369,237.451385 431.051514,237.646851 
	C436.691559,235.926544 441.858459,234.010757 447.025330,232.094971 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M428.864899,844.151428 
	C419.448090,840.258240 410.031281,836.365051 400.543518,831.969116 
	C397.907074,830.369629 395.341644,829.272766 392.776184,828.175903 
	C392.776215,828.175903 392.910309,828.138062 392.779236,827.895020 
	C392.031921,827.487122 391.415649,827.322266 390.799377,827.157471 
	C390.799377,827.157471 390.918518,827.117554 390.786377,826.876709 
	C390.047699,826.461792 389.441193,826.287659 388.834686,826.113525 
	C388.834686,826.113525 388.932037,826.073303 388.948303,825.761353 
	C388.976776,824.966248 388.989014,824.483093 389.248749,823.800415 
	C388.636078,822.174316 387.575775,820.827637 386.967102,819.300842 
	C386.231262,817.455200 384.818481,814.814636 385.535614,813.632080 
	C389.411194,807.241394 389.301880,799.954651 388.554810,793.438538 
	C387.892303,787.660156 387.999847,782.045837 387.994812,776.345215 
	C387.993927,775.348267 388.170654,774.262817 387.846832,773.369995 
	C386.586823,769.896118 386.172089,766.868896 392.215790,765.903503 
	C390.055847,764.203857 388.773193,763.358826 387.695679,762.304138 
	C386.415100,761.050598 385.310822,759.616943 384.130127,758.261414 
	C385.592285,757.380920 387.054474,756.500427 388.053711,755.898743 
	C388.599335,751.714844 389.236084,747.582458 389.611877,743.426575 
	C389.706604,742.378540 388.937897,741.263489 388.615448,740.164734 
	C387.104736,735.017334 381.527008,730.596130 385.409973,725.088196 
	C387.753876,723.875793 389.524750,722.959778 391.295624,722.043823 
	C390.680603,721.857788 390.065552,721.671753 389.450531,721.485718 
	C388.633698,719.322998 387.816864,717.160278 387.286469,714.984741 
	C388.041870,714.691040 388.510803,714.410156 389.288849,714.245728 
	C391.353333,715.865417 392.987335,717.546265 394.883728,718.843445 
	C407.646698,727.573853 420.465363,736.222839 432.847626,744.922607 
	C427.438568,745.143250 424.005219,741.822205 420.170837,739.455444 
	C417.787018,737.984131 415.831451,735.980591 412.691895,738.500916 
	C409.704865,740.898804 411.862854,742.803955 412.407745,745.063721 
	C412.655273,746.090454 411.942383,747.348816 411.665222,748.502075 
	C410.619965,748.184753 409.355286,748.132141 408.579865,747.489319 
	C407.354492,746.473633 406.457275,745.062134 405.421173,743.818115 
	C404.976593,744.034119 404.532013,744.250061 404.087402,744.466064 
	C404.299011,745.734009 404.109039,747.789490 404.790100,748.136780 
	C408.183929,749.867371 408.453979,755.162659 413.452118,755.284607 
	C414.566803,755.311768 415.603424,758.538879 416.335999,760.217468 
	C411.173279,757.422058 406.350586,754.701965 401.371857,751.893921 
	C400.338776,753.802307 399.569702,755.223083 398.606171,757.002991 
	C404.862640,760.694580 410.919128,764.268127 417.042236,767.880981 
	C413.717926,773.657776 410.658081,778.975403 407.597717,784.292725 
	C404.565277,789.561707 401.532379,794.830444 398.378632,800.309570 
	C400.483307,801.468750 402.028809,802.319946 403.765045,803.276245 
	C407.832825,796.598450 411.742981,790.179443 415.748474,784.150391 
	C414.434052,789.303894 413.024231,794.067383 411.335388,799.773743 
	C408.570374,801.627380 404.816284,804.144043 401.062195,806.660767 
	C403.604675,806.922180 406.117218,806.381348 408.643066,805.912048 
	C409.693878,805.716797 410.786926,805.538513 411.830719,805.645508 
	C412.141357,805.677307 412.649139,807.148132 412.559235,807.208923 
	C411.553680,807.888550 410.502808,808.599670 409.356476,808.946960 
	C407.984558,809.362549 406.508148,809.433350 404.466675,809.747314 
	C406.366302,811.772034 408.434631,812.944397 408.503448,814.224304 
	C408.844971,820.575806 408.659210,826.955688 408.659210,833.151184 
	C412.859375,835.287964 417.048492,837.372314 421.191956,839.543823 
	C423.851593,840.937805 426.443970,842.460144 429.016144,843.981567 
	C428.965790,844.038269 428.864899,844.151428 428.864899,844.151428 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M489.030884,860.009583 
	C479.902283,858.246277 470.773682,856.482971 461.200562,854.138672 
	C458.169098,853.036682 455.582245,852.515503 452.995361,851.994385 
	C452.995361,851.994385 453.001129,851.997559 452.940460,851.631775 
	C448.257202,849.506592 443.634583,847.747192 439.008911,845.574890 
	C439.014252,842.094482 439.028076,839.026978 439.029907,835.959473 
	C439.033661,829.567017 439.031036,823.174500 439.031036,816.835876 
	C441.459900,816.729126 442.960907,816.663208 444.461884,816.597229 
	C444.495972,816.321350 444.530029,816.045532 444.564117,815.769653 
	C443.053772,815.199280 441.543427,814.628845 439.318298,813.788452 
	C441.136139,811.767029 442.504730,810.245178 443.873291,808.723328 
	C443.184021,806.663330 443.342468,804.842407 443.869995,803.135498 
	C445.847687,796.736572 447.945984,790.374878 449.999664,783.750305 
	C450.000244,783.501343 450.002197,783.003357 450.370697,782.934082 
	C451.167297,781.255127 451.595367,779.645508 452.385345,778.033813 
	C453.961517,778.828003 455.175751,779.624329 456.660522,780.598022 
	C457.575012,776.393250 454.744873,775.735291 451.874573,774.704102 
	C450.486969,773.613525 449.243103,772.810425 448.212677,771.739136 
	C450.262390,770.753113 451.295258,770.232117 450.221680,767.591797 
	C446.236267,757.790466 446.622955,757.365601 457.083588,755.913086 
	C457.029236,755.936890 457.097778,755.840027 457.114929,756.208984 
	C460.799713,760.365845 465.584961,760.694580 470.564362,760.797058 
	C470.709320,760.722900 470.971283,760.916321 471.149963,761.181763 
	C472.220123,761.967285 473.111633,762.487305 473.994019,763.411743 
	C473.322845,768.361633 472.323456,772.889343 472.082153,777.457031 
	C471.763489,783.489014 472.444275,783.734863 478.468597,784.097717 
	C480.598480,784.226135 482.658905,785.507690 484.317932,786.215698 
	C477.905853,785.299866 472.445770,785.983337 469.222595,791.907349 
	C466.531616,796.853210 467.648590,801.236511 472.677338,806.704773 
	C466.866364,809.179321 464.066132,813.582825 465.116852,819.801758 
	C466.109039,825.674255 470.857758,827.871948 475.875366,829.505676 
	C478.596985,830.391846 481.360382,831.149658 483.669556,831.979065 
	C477.721466,830.954895 472.208740,829.917114 467.118683,828.958923 
	C467.988983,836.888306 469.221161,845.223450 467.368683,853.968140 
	C473.640228,855.311462 480.504944,856.743469 487.339691,858.306824 
	C487.998779,858.457581 488.471588,859.422546 489.030884,860.009583 
M463.517853,853.410156 
	C463.517853,853.410156 463.585175,853.521667 463.517853,853.410156 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M600.083374,784.051331 
	C602.170959,782.359253 601.802734,781.020630 600.442566,778.656738 
	C598.165283,774.698853 597.799438,770.122009 600.230774,765.141235 
	C606.401489,762.681213 612.317078,761.014587 618.036865,758.834290 
	C624.347534,756.428772 630.478577,753.552002 637.053589,750.939331 
	C638.499084,752.078491 639.578796,753.158875 640.658508,754.239258 
	C639.437744,754.547119 638.165039,754.727783 637.011047,755.199158 
	C635.877625,755.662048 634.864868,756.420105 633.799194,757.048645 
	C641.173096,757.771057 634.945679,765.120483 638.918457,766.837952 
	C640.445007,765.735474 641.706543,764.824402 643.803284,763.310120 
	C642.988953,766.447327 642.515625,768.270691 641.895752,770.391113 
	C640.636047,771.299377 639.522827,771.910522 638.317505,772.572266 
	C639.363770,774.413574 640.329529,776.113220 640.854126,777.864929 
	C638.791504,778.031982 637.170044,778.147034 634.097656,778.364990 
	C635.557983,780.961243 636.556824,782.737061 637.123474,783.744507 
	C636.733765,786.122620 635.817749,788.422791 636.118835,790.550842 
	C636.773376,795.177185 638.642517,799.569214 635.295044,804.151245 
	C634.188782,805.665588 634.690674,808.535339 634.844971,810.758423 
	C635.473145,819.804565 636.294983,828.837341 636.917480,837.883850 
	C636.990967,838.952209 636.410828,840.142761 635.901123,841.160217 
	C633.983276,844.988586 634.761963,847.669128 639.013428,849.014221 
	C630.127258,851.353271 621.239319,853.685364 612.356140,856.035767 
	C609.843079,856.700684 607.344177,857.418884 604.437500,858.055481 
	C602.366333,854.405090 604.019775,852.805481 606.973022,852.643433 
	C606.973022,844.756042 606.973022,837.217834 607.269287,829.023804 
	C610.971436,827.252136 614.720398,826.711670 617.724121,824.921204 
	C630.996765,817.009216 630.816162,796.193970 617.648010,787.751587 
	C611.995605,784.127625 606.175964,784.114685 600.083374,784.051331 
M633.854919,770.382690 
	C633.681274,771.081177 633.507629,771.779724 633.333984,772.478271 
	C633.681091,772.664062 634.028198,772.849854 634.375244,773.035706 
	C634.988770,771.992554 635.602234,770.949402 636.215759,769.906250 
	C635.527588,769.882690 634.839478,769.859070 633.854919,770.382690 
M632.490295,798.859497 
	C632.794067,798.431824 633.097778,798.004150 633.401550,797.576477 
	C632.952148,797.462585 632.502686,797.348694 632.053345,797.234863 
	C631.999573,797.735474 631.945801,798.236084 632.490295,798.859497 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M814.997559,531.002441 
	C815.331726,492.957245 815.583923,454.910980 816.069946,416.867706 
	C816.165833,409.364960 817.235229,401.877991 817.678223,394.373291 
	C817.759521,392.996338 816.792114,391.588623 816.644836,390.160461 
	C816.354187,387.341736 816.363647,384.493011 816.114197,381.668610 
	C816.013794,380.531647 815.442566,379.440613 815.299438,378.301819 
	C815.167358,377.250214 815.321350,376.162659 816.007568,375.046143 
	C817.109436,375.005707 817.552979,375.010071 817.996582,375.014435 
	C817.996582,375.014435 818.201782,375.187500 818.203979,375.628052 
	C818.493652,376.362183 818.781128,376.655792 819.068542,376.949432 
	C819.068542,376.949432 819.024414,376.994476 819.033691,377.197479 
	C819.340698,377.657471 819.638489,377.914490 819.936279,378.171478 
	C820.265076,378.596802 820.593811,379.022095 820.714600,379.906494 
	C821.018127,380.888977 821.529480,381.412354 822.040894,381.935730 
	C822.040894,381.935730 822.022156,381.970917 822.047913,382.284180 
	C822.443848,383.038574 822.814087,383.479736 823.184326,383.920898 
	C824.435059,386.066772 825.685730,388.212708 826.994812,390.995728 
	C827.383789,392.072693 827.714355,392.512482 828.044922,392.952271 
	C831.045044,399.041656 834.045227,405.131042 837.061096,411.588531 
	C835.279419,412.948578 833.481934,413.940460 830.920410,415.354004 
	C830.920410,424.309814 830.909973,433.934875 830.923340,443.559937 
	C830.935913,452.616333 830.989136,461.672638 830.996521,470.729004 
	C831.009155,486.190033 831.037842,501.651215 830.963318,517.111877 
	C830.950684,519.733521 830.372864,522.347839 830.215393,524.975098 
	C830.128357,526.427551 829.783508,528.556824 830.563293,529.255188 
	C835.233093,533.438049 832.058411,538.718384 832.816528,543.400208 
	C832.882141,543.805420 831.486328,544.447205 830.531555,544.648071 
	C829.814758,541.927490 829.335876,539.538025 828.871948,537.137207 
	C828.886902,537.125916 828.887756,537.096069 828.922302,536.717041 
	C827.877625,534.857422 827.037415,532.426147 825.672180,532.082825 
	C822.240356,531.220032 818.571411,531.300293 814.997559,531.002441 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M629.968994,327.004272 
	C631.144348,325.658356 632.486389,324.420288 633.446838,322.935364 
	C634.352661,321.534943 635.227783,319.920105 635.424683,318.314789 
	C635.594238,316.932770 635.105713,315.218933 634.320007,314.045715 
	C633.618347,312.997986 632.112488,312.488831 630.806213,311.641296 
	C638.283752,306.861176 638.283752,306.861176 638.364685,303.134552 
	C637.147583,304.467102 636.146729,305.562805 634.900940,306.315125 
	C639.831909,292.986450 645.007812,280.001160 650.759644,265.571106 
	C647.388611,265.866760 645.270996,266.052490 643.153320,266.238220 
	C642.630920,264.530182 642.108459,262.822113 641.465759,260.720978 
	C647.075195,259.750153 646.067566,257.797546 643.733948,253.090851 
	C641.509338,248.604126 642.730286,242.409012 642.388977,236.083603 
	C639.829224,235.011215 635.981018,233.398987 632.061646,231.435028 
	C644.480530,235.653320 656.994080,240.160385 669.447876,244.826965 
	C673.908875,246.498520 678.225525,248.555161 682.789124,250.742096 
	C682.970154,251.048141 682.943848,251.087204 682.547363,251.062531 
	C677.080750,249.144089 671.879822,247.534164 666.978027,245.275208 
	C662.256714,243.099411 657.921265,243.772842 655.577820,248.342438 
	C653.750916,251.904877 654.858521,254.906250 659.152893,256.159485 
	C656.912415,260.492920 654.870972,264.441345 652.766724,268.511353 
	C653.159119,268.699554 653.912109,268.989532 654.593262,269.398956 
	C659.344910,272.255341 659.488770,273.452789 655.876343,277.421783 
	C655.299805,278.055206 655.266663,279.191833 655.006165,280.102661 
	C653.660095,284.810059 656.941101,287.409180 660.268066,289.883575 
	C657.879333,292.436890 654.144165,293.934448 657.540894,298.604492 
	C658.846436,297.191589 660.047058,296.117462 660.976501,294.845154 
	C664.059082,290.625122 667.044006,286.333740 670.027832,282.433716 
	C664.709961,293.720642 659.430603,304.643555 654.158936,315.550537 
	C662.039368,319.823578 669.537048,323.889038 677.015320,327.981476 
	C676.995911,328.008453 676.961914,328.065552 676.961914,328.065552 
	C675.196899,327.881805 673.431885,327.698029 671.643982,327.511902 
	C672.584656,332.148346 672.584656,332.148346 668.194885,338.063629 
	C675.708862,340.343506 672.335449,346.468140 672.491089,351.694305 
	C659.711853,345.024750 647.348694,337.937958 634.808899,330.566711 
	C633.423157,329.863586 632.213928,329.444946 630.997681,329.019257 
	C630.990662,329.012238 630.971191,329.008362 630.872192,328.745789 
	C630.505188,327.990265 630.237061,327.497284 629.968994,327.004272 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M765.002869,773.995056 
	C756.251587,781.652344 747.500305,789.309570 738.209045,796.780518 
	C735.796204,796.498840 732.293823,796.467407 732.287170,796.298828 
	C732.088074,791.221680 728.501404,792.309814 724.878601,792.840088 
	C725.079529,788.545044 732.490173,788.397644 729.666809,783.535461 
	C727.925232,780.536377 725.971497,777.660522 724.112976,774.729370 
	C724.516296,774.442078 724.919617,774.154846 725.322876,773.867554 
	C726.211304,774.674927 727.064575,776.087402 727.996765,776.141602 
	C729.258179,776.214966 730.580200,775.245911 732.737915,774.377014 
	C730.276855,773.068604 729.108887,772.447632 727.070679,771.364014 
	C729.531738,769.883301 731.328186,769.096558 732.752747,767.875854 
	C734.855530,766.073975 737.925232,764.660889 736.673950,760.606445 
	C735.058533,759.140747 733.529358,757.985229 732.000366,756.621094 
	C732.000549,756.412415 731.997559,755.995117 732.221069,755.728455 
	C737.409973,756.355042 738.672363,753.903259 738.041748,749.468567 
	C737.788452,747.687195 737.991577,745.839661 738.001648,744.022339 
	C738.029419,738.998901 738.064941,733.975525 738.308960,728.978027 
	C738.821045,729.094543 739.013916,729.291016 739.189575,729.883362 
	C739.833252,729.765198 740.386536,729.356995 741.080322,728.644165 
	C745.030273,723.406738 748.839661,718.473877 752.850403,713.280334 
	C750.927124,712.075195 749.608032,711.248596 748.253235,710.399719 
	C745.248962,714.175964 742.496643,717.635559 739.674927,721.182312 
	C734.679260,714.934631 734.517761,708.029175 738.906006,702.527527 
	C743.370178,696.930908 750.903564,695.097778 757.366089,698.035828 
	C764.539612,701.296997 768.412842,708.466309 767.033936,715.930115 
	C765.786743,722.680969 759.937012,728.010986 752.990173,727.772766 
	C748.372009,727.614441 746.943848,729.512634 746.766602,733.537292 
	C754.974976,735.220215 761.894348,733.336548 767.888184,728.296875 
	C768.076050,734.008545 763.056580,733.484741 760.240479,734.853271 
	C755.901917,736.961548 753.842346,739.658997 753.957947,744.443054 
	C754.122620,751.253845 754.384583,758.100525 753.875977,764.877136 
	C753.482361,770.120911 754.719116,773.794312 759.970703,775.568298 
	C761.496399,775.033203 763.175781,774.444214 764.891602,773.890747 
	C764.928101,773.926147 765.002869,773.995056 765.002869,773.995056 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M639.405701,849.019409 
	C634.761963,847.669128 633.983276,844.988586 635.901123,841.160217 
	C636.410828,840.142761 636.990967,838.952209 636.917480,837.883850 
	C636.294983,828.837341 635.473145,819.804565 634.844971,810.758423 
	C634.690674,808.535339 634.188782,805.665588 635.295044,804.151245 
	C638.642517,799.569214 636.773376,795.177185 636.118835,790.550842 
	C635.817749,788.422791 636.733765,786.122620 637.123474,783.744507 
	C636.556824,782.737061 635.557983,780.961243 634.097656,778.364990 
	C637.170044,778.147034 638.791504,778.031982 641.144409,777.714233 
	C648.830078,774.124329 655.784302,770.737183 663.190063,767.130066 
	C665.187866,771.131226 666.982300,774.725037 668.847168,778.459961 
	C662.163757,781.876953 656.006287,785.025024 649.450562,788.376709 
	C650.508545,790.280273 651.333618,791.764709 652.218689,793.357117 
	C658.935364,790.032043 665.133606,786.963623 671.543640,783.790405 
	C673.524963,787.717896 675.355896,791.347290 677.284668,795.170593 
	C669.852600,798.957092 662.958923,802.469360 655.118652,806.463806 
	C656.951294,808.553223 658.407654,810.213623 659.689209,812.182129 
	C655.008423,813.515991 653.222656,816.284180 653.962891,820.854187 
	C654.199463,822.314758 654.024597,823.846313 653.995483,825.344604 
	C653.903381,830.083313 653.891235,834.768677 660.917053,836.108643 
	C659.410278,837.387756 658.580139,838.667847 657.870972,838.604004 
	C651.849548,838.062134 653.942871,840.536316 656.087219,843.107056 
	C650.657471,845.079590 645.227722,847.052124 639.405701,849.019409 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M740.009766,362.029297 
	C740.318604,359.055847 740.867981,356.084564 740.894592,353.108582 
	C741.011963,339.996582 740.952087,326.883087 741.025085,313.770538 
	C741.037964,311.465088 741.052673,309.072815 741.648743,306.878876 
	C742.440125,303.966370 741.282410,302.540375 738.880981,301.353180 
	C738.067139,300.950867 737.570862,299.906128 737.597717,298.154083 
	C738.819702,298.563904 740.041626,298.973724 741.623169,299.504181 
	C741.389038,296.574371 741.162659,293.741272 740.898193,290.431427 
	C743.328003,291.443085 745.194031,292.220001 747.415161,293.010712 
	C748.110352,293.197174 748.450439,293.369843 749.019165,294.009766 
	C749.446106,294.728699 749.702881,294.883545 750.018066,294.941559 
	C750.018066,294.941559 750.026855,294.971680 750.140747,295.200623 
	C750.463806,295.709290 750.740723,295.877563 751.085388,295.934357 
	C751.085388,295.934357 751.009766,295.996826 751.135498,296.196045 
	C751.554626,296.566284 751.847900,296.737305 752.141235,296.908295 
	C752.141235,296.908295 752.031799,296.893677 752.083618,297.226562 
	C752.850952,297.944946 753.566528,298.330475 754.282104,298.716003 
	C754.282104,298.716003 754.240784,298.832916 754.271240,299.061646 
	C754.633728,299.444031 754.965820,299.597687 755.297913,299.751343 
	C755.297913,299.751343 755.260864,299.671661 755.241455,299.859924 
	C755.476440,300.063873 755.730896,300.079559 755.985352,300.095276 
	C756.281189,300.246674 756.577087,300.398041 757.060913,301.002380 
	C757.445679,301.716400 757.705139,301.875763 758.027161,301.933411 
	C758.027161,301.933411 758.029297,301.969360 758.145630,302.197327 
	C758.470642,302.709503 758.750122,302.872711 759.100220,302.914978 
	C765.035706,308.694275 770.971191,314.473511 777.005371,320.572418 
	C774.305908,321.164917 771.507874,321.437805 768.513672,321.729828 
	C767.836731,329.178497 767.839417,337.482605 774.823792,344.267090 
	C772.933655,345.320587 771.109436,346.337341 768.804932,347.517548 
	C765.213867,347.417114 762.103149,347.153198 758.829773,347.004425 
	C758.667175,347.119568 758.268677,347.109680 757.938354,347.091919 
	C757.087708,347.403229 756.567505,347.732300 756.022461,348.034729 
	C755.997559,348.008118 756.044250,348.068329 755.664429,348.049744 
	C754.200439,349.358948 753.116394,350.686737 752.017578,352.010803 
	C752.002869,352.007080 752.020874,352.032379 751.635498,352.100708 
	C751.120361,355.102081 750.990601,358.035187 750.919556,361.209534 
	C750.978394,361.450775 751.045227,361.942871 751.105042,362.154266 
	C751.299988,362.641144 751.508179,362.840240 751.883484,363.198547 
	C752.006226,363.600769 752.034912,363.767426 752.061646,364.240479 
	C752.393555,365.013367 752.727356,365.479889 753.032593,365.967773 
	C753.003906,365.989136 753.070435,365.964539 753.065674,366.264313 
	C753.380127,367.025146 753.699463,367.486206 754.029907,367.960205 
	C754.041077,367.973175 754.051636,368.005737 754.053345,368.302032 
	C754.379272,369.050049 754.703430,369.501770 755.025879,369.967560 
	C755.024048,369.981598 755.048218,369.996307 755.030457,370.361755 
	C755.615845,371.820282 756.218994,372.913330 756.941650,374.198181 
	C757.061096,374.390015 757.080933,374.841553 757.047241,375.242737 
	C755.644958,383.682800 753.927368,384.648895 747.593506,379.839569 
	C747.102112,379.466461 746.398621,379.372711 745.842957,379.098999 
	C745.892212,379.048920 745.851196,379.183319 745.795898,378.925598 
	C745.463623,378.468750 745.186646,378.269562 744.941772,378.034363 
	C744.973755,377.998352 744.900208,378.069031 744.931396,377.710449 
	C744.254517,376.270966 743.546509,375.190094 742.845459,374.097290 
	C742.852417,374.085327 742.879089,374.078522 742.920044,373.672546 
	C742.974792,372.178925 742.988586,371.091309 743.230164,369.831726 
	C742.976868,369.103516 742.495728,368.547180 742.011230,367.593109 
	C741.316956,365.811066 740.626099,364.426758 739.971069,363.032288 
	C740.006836,363.022125 739.951294,363.071777 739.969604,362.811371 
	C739.995300,362.377075 740.002563,362.203186 740.009766,362.029297 
z"/>
<path fill="#2528E0" opacity="1.000000" stroke="none" 
	d="
M524.996887,219.174484 
	C524.996887,219.174484 525.448181,219.013199 525.957886,219.029236 
	C526.647644,219.062622 526.827759,219.079987 527.181335,219.319870 
	C527.890381,219.682510 528.425964,219.822617 529.262146,219.952789 
	C530.052979,219.645294 530.543335,219.347748 531.493042,219.046021 
	C535.650635,219.075119 539.348877,219.108398 543.039307,219.511276 
	C548.259705,224.156830 545.777100,229.928207 545.989197,235.174164 
	C546.035828,236.328873 545.781799,237.544205 546.058960,238.631500 
	C546.728027,241.256378 546.250549,245.241699 551.225586,243.590225 
	C551.481140,243.505402 552.333679,245.219070 552.475586,246.041611 
	C550.268250,245.987839 548.493530,245.987839 546.451477,245.987839 
	C546.451477,252.519516 546.451477,258.498535 546.451477,265.047852 
	C539.034790,265.047852 531.649902,265.047852 523.755310,265.047852 
	C523.471558,259.191956 523.177856,253.129837 522.885193,247.089264 
	C521.341370,246.955261 520.187683,246.855133 519.239807,246.558731 
	C521.910522,242.854416 518.466736,242.664429 516.539978,240.786057 
	C516.539978,242.848068 516.539978,244.199677 516.539978,245.551285 
	C516.102478,245.737930 515.664917,245.924591 515.227417,246.111237 
	C514.347961,244.324783 512.838928,242.584976 512.702454,240.743439 
	C512.216064,234.180588 512.196350,227.583130 512.408936,220.944519 
	C516.877563,220.318314 520.937256,219.746399 524.996887,219.174484 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M736.760132,760.916626 
	C737.925232,764.660889 734.855530,766.073975 732.752747,767.875854 
	C731.328186,769.096558 729.531738,769.883301 727.070679,771.364014 
	C729.108887,772.447632 730.276855,773.068604 732.737915,774.377014 
	C730.580200,775.245911 729.258179,776.214966 727.996765,776.141602 
	C727.064575,776.087402 726.211304,774.674927 725.322876,773.867554 
	C724.919617,774.154846 724.516296,774.442078 724.112976,774.729370 
	C725.971497,777.660522 727.925232,780.536377 729.666809,783.535461 
	C732.490173,788.397644 725.079529,788.545044 724.878601,792.840088 
	C728.501404,792.309814 732.088074,791.221680 732.287170,796.298828 
	C732.293823,796.467407 735.796204,796.498840 737.876343,796.862305 
	C729.993408,802.759155 721.903259,808.387939 713.215149,814.135559 
	C712.437012,814.539795 712.256897,814.825134 712.076782,815.110474 
	C712.076843,815.110474 712.026123,815.010681 711.841309,815.114624 
	C711.356445,815.371338 711.161011,815.609741 711.070007,815.933777 
	C711.070007,815.933777 711.015015,816.066895 710.663696,816.070801 
	C709.893982,816.406616 709.475586,816.738464 709.057251,817.070374 
	C709.057251,817.070374 709.002869,817.000000 708.673950,817.022217 
	C707.580017,817.673950 706.815125,818.303406 706.050171,818.932922 
	C706.050171,818.932922 706.006104,819.057190 705.556458,819.036194 
	C704.072021,819.014221 703.037231,819.013184 702.002441,819.012207 
	C701.563599,813.163879 701.019531,807.320801 700.730103,801.465088 
	C700.583191,798.493042 700.974121,795.498535 700.985901,792.513672 
	C701.028015,781.846008 701.090881,771.177429 700.982910,760.510803 
	C700.932861,755.566956 704.771851,758.522522 707.143066,757.372681 
	C708.948425,759.172424 710.157471,761.139771 711.535034,762.980957 
	C715.830811,768.722412 715.805176,769.037354 710.143250,773.533020 
	C709.366455,774.149780 708.777527,775.058838 707.931763,775.523071 
	C704.752991,777.268127 706.784729,779.340576 707.918457,780.751709 
	C708.429626,781.388000 710.971436,781.270691 711.882324,780.571228 
	C720.263550,774.135071 728.489197,767.496399 736.760132,760.916626 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M394.551086,252.912262 
	C392.289764,256.434448 389.672424,260.010193 387.078583,263.553864 
	C385.344666,263.657684 383.495117,263.768433 380.801849,263.929718 
	C382.863617,266.816376 385.817719,269.159149 381.647003,271.599091 
	C381.455353,271.711212 382.012421,274.055573 382.767334,274.594055 
	C383.728149,275.279419 385.246826,275.182709 387.444122,275.596039 
	C386.257263,277.399384 385.364410,278.756042 384.461700,280.127625 
	C388.254578,287.958282 387.573425,289.295624 380.131531,289.693207 
	C381.360229,294.508759 382.677338,299.381287 383.786438,304.300690 
	C383.972687,305.126831 383.005554,306.160126 383.021240,307.090149 
	C383.043884,308.435303 383.388855,309.799072 383.766266,311.105774 
	C384.017395,311.975250 384.975342,312.794189 384.908569,313.575165 
	C384.623535,316.908234 385.104248,320.957062 383.378693,323.362518 
	C380.860992,326.872314 381.439117,328.915588 383.627930,331.942047 
	C384.507965,333.158813 383.856567,335.483154 383.546753,337.554626 
	C376.429047,328.431519 369.666748,319.057220 362.900970,309.678101 
	C366.946960,306.690674 370.471497,304.088257 374.668915,300.989044 
	C372.586884,299.461639 370.985992,298.287170 368.979431,297.081055 
	C364.898956,294.956879 361.204407,292.895386 357.644928,290.622375 
	C357.538239,290.554230 358.548523,287.820435 359.575012,287.142303 
	C360.685211,286.408905 362.426208,286.630432 365.334930,286.243561 
	C362.395355,284.227478 360.790131,283.126556 358.865143,281.806305 
	C361.679962,279.497498 367.460266,278.026062 361.014130,273.739044 
	C361.002899,273.309937 360.999176,273.138245 360.995453,272.966583 
	C361.054047,272.627777 361.231201,272.370667 361.903381,272.328796 
	C362.475616,272.230316 362.671387,271.998352 362.867157,271.766388 
	C362.867157,271.766388 362.899628,271.898895 363.098846,271.832336 
	C363.531311,271.499115 363.764587,271.232422 363.997833,270.965759 
	C363.997833,270.965759 363.995209,271.008514 364.334686,270.993652 
	C365.454926,270.324402 366.235657,269.670044 367.016388,269.015686 
	C367.016388,269.015686 367.000000,269.000000 367.405151,268.999969 
	C369.996307,268.672699 373.016449,269.446533 372.449432,265.485413 
	C372.449432,265.485413 372.534607,265.520599 372.916809,265.636200 
	C374.114136,265.025085 374.929230,264.298370 375.744354,263.571686 
	C375.744354,263.571686 375.816589,263.748230 376.010620,263.730835 
	C376.465088,263.451813 376.725555,263.190186 376.986023,262.928558 
	C376.986023,262.928558 376.988800,262.995911 377.278259,262.962708 
	C378.023102,262.548309 378.478516,262.167114 378.933899,261.785950 
	C378.933899,261.785950 378.940002,261.928589 379.122375,261.861633 
	C379.547485,261.560150 379.790283,261.325653 380.033051,261.091125 
	C380.033051,261.091125 380.241119,260.840912 380.701416,260.814789 
	C381.454987,260.520386 381.748291,260.252106 382.041565,259.983826 
	C382.041565,259.983795 382.185822,259.834106 382.609070,259.810852 
	C383.349060,259.494720 383.665833,259.201904 383.982605,258.909058 
	C387.386749,256.927979 390.790894,254.946884 394.551086,252.912262 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M339.902405,705.009277 
	C337.022369,705.224548 334.142303,705.439819 330.028625,705.747253 
	C335.192749,710.446899 333.167480,715.334412 332.296906,720.282349 
	C331.468170,724.992554 332.875977,730.546326 326.613983,733.615295 
	C328.462158,733.533203 330.310333,733.451111 331.998779,733.376160 
	C331.998779,741.272644 331.998749,749.165649 331.998749,757.058594 
	C331.998749,764.757446 331.998779,764.757446 326.754303,773.149719 
	C329.455566,772.333252 331.204987,771.804504 333.296417,771.172424 
	C333.078003,774.403076 332.863068,777.582336 332.648132,780.761597 
	C332.580719,780.799988 332.513306,780.838318 332.445892,780.876648 
	C332.256378,782.777405 332.066864,784.678101 331.839996,786.918945 
	C330.607941,786.376770 329.413239,785.494507 328.314941,784.132568 
	C327.560699,783.134460 326.710083,782.616089 325.859497,782.097778 
	C325.859467,782.097778 325.943939,782.067322 325.839722,781.856873 
	C325.435089,781.457092 325.134705,781.267822 324.834290,781.078491 
	C324.834290,781.078491 324.940308,781.064087 324.828491,780.857361 
	C324.396393,780.479797 324.076050,780.309021 323.755737,780.138184 
	C323.755737,780.138184 323.898895,780.108459 323.795624,779.903381 
	C323.353424,779.535339 323.014465,779.372375 322.675537,779.209473 
	C322.675537,779.209412 322.820038,779.190613 322.821106,778.880127 
	C321.847534,778.069702 320.872925,777.569702 319.898315,777.069702 
	C319.898285,777.069763 319.982849,777.015808 319.939697,776.717957 
	C319.222076,775.959290 318.547638,775.498535 317.873199,775.037781 
	C316.635376,773.934021 315.397552,772.830261 313.961426,771.184631 
	C313.486206,770.437439 313.209320,770.232117 312.932434,770.026855 
	C312.932434,770.026855 312.995239,770.005859 312.869904,769.801147 
	C312.455597,769.408752 312.166626,769.221191 311.877655,769.033569 
	C311.521332,769.057068 311.250824,768.915955 311.031128,768.096375 
	C311.008698,767.394226 311.021210,767.206116 311.440063,767.012146 
	C313.491180,766.326904 315.139648,765.655273 316.748688,764.899841 
	C316.775116,764.887451 316.322266,763.854065 316.093079,763.297119 
	C314.395233,764.193665 312.697388,765.090210 310.643250,765.991028 
	C309.812164,766.012146 309.337311,766.028931 308.862488,766.045776 
	C308.585358,765.877747 308.308228,765.709778 307.984833,765.292969 
	C309.624146,761.049744 312.281525,757.164124 312.744507,753.032654 
	C313.298798,748.086487 311.751923,742.937622 311.590973,737.861389 
	C311.532959,736.031311 312.854065,734.157410 313.477997,732.485168 
	C319.230835,733.122864 323.824524,732.333862 327.273468,727.804199 
	C332.568665,722.177368 330.125671,716.497559 328.240509,710.939087 
	C327.043610,707.409912 324.968781,704.076355 324.392548,700.478149 
	C324.058563,698.392334 325.343262,695.268982 326.978149,693.911438 
	C328.127899,692.956604 331.981567,693.389771 333.075195,694.602905 
	C335.820679,697.648254 337.684814,701.488037 339.902405,705.009277 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M489.358948,860.223755 
	C488.471588,859.422546 487.998779,858.457581 487.339691,858.306824 
	C480.504944,856.743469 473.640228,855.311462 467.368683,853.968140 
	C469.221161,845.223450 467.988983,836.888306 467.118683,828.958923 
	C472.208740,829.917114 477.721466,830.954895 483.990234,831.976074 
	C485.166901,831.986755 485.587463,832.013977 486.182129,832.358887 
	C488.559967,832.853516 490.763733,833.030579 493.312988,833.044434 
	C494.010925,832.328186 494.548553,831.818176 494.688477,831.215698 
	C497.796692,817.833618 500.861023,804.441284 504.284546,791.086792 
	C504.960938,791.609497 505.636139,792.161316 505.559631,792.573547 
	C504.328278,799.205505 502.955536,805.811157 501.719269,812.442261 
	C501.252838,814.943970 501.872345,818.077393 500.571106,819.911133 
	C497.137665,824.749573 496.851227,829.960815 496.915894,835.469543 
	C496.929688,836.643250 496.284851,838.170776 495.416321,838.940002 
	C493.317780,840.798340 492.976624,842.500122 494.344635,845.016052 
	C494.944031,846.118408 493.914032,848.078430 494.476196,849.234497 
	C496.268219,852.919556 497.968231,859.028687 500.583252,859.519897 
	C511.563873,861.582642 522.864441,861.942383 534.513306,862.937866 
	C543.975647,862.943848 552.972534,862.937805 561.987305,863.126038 
	C562.005188,863.320312 562.056580,863.707153 562.056580,863.707153 
	C537.810547,865.336365 513.683411,864.334351 489.358948,860.223755 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M524.611328,219.102493 
	C520.937256,219.746399 516.877563,220.318314 511.981415,220.944397 
	C507.363281,221.333572 503.524933,221.365707 499.810913,222.061569 
	C489.516510,223.990280 484.275055,224.254044 485.138397,234.504547 
	C485.560364,239.514420 486.262238,244.161026 481.053986,248.275543 
	C488.223297,249.052460 487.312958,253.195160 486.305573,257.170074 
	C484.288544,256.207397 482.592407,255.307526 480.784180,254.006897 
	C476.705963,251.077988 464.915527,251.240250 461.555298,254.109390 
	C460.418610,255.079971 459.879211,256.750153 459.056641,258.080414 
	C459.046448,258.060242 459.085419,258.086456 459.085419,258.086456 
	C457.257935,258.369568 455.430450,258.652710 453.602936,258.935822 
	C453.046417,258.190918 452.489899,257.446045 451.933350,256.701141 
	C454.441040,255.780914 456.948730,254.860672 459.456421,253.940445 
	C451.932312,249.089050 451.932312,249.089050 455.275482,245.268265 
	C454.465973,241.898743 453.760529,238.962204 452.933075,235.517853 
	C456.442169,235.676453 458.444244,235.766937 460.812927,235.873993 
	C460.060577,233.734451 459.533936,232.236740 459.332123,230.336365 
	C460.420471,229.233856 461.183990,228.533997 461.947510,227.834152 
	C461.947510,227.834152 461.960968,227.928711 462.355652,227.952667 
	C465.469971,227.241226 468.189636,226.505829 470.909271,225.770447 
	C470.909271,225.770447 470.935181,225.882553 471.288269,225.915588 
	C473.110657,225.576477 474.579895,225.204300 476.049164,224.832138 
	C491.872528,220.942383 507.906647,218.802933 524.611328,219.102493 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M421.990143,244.000183 
	C424.413147,243.775116 426.836182,243.550049 430.724518,243.188889 
	C429.480255,245.477890 429.022858,247.424820 427.794434,248.377365 
	C423.673615,251.572861 423.564911,253.757828 427.884094,256.815918 
	C428.390900,257.174744 429.037018,257.336792 430.121765,257.809265 
	C424.584839,262.324768 426.395294,267.593323 427.326965,273.163422 
	C427.962921,276.965546 426.647797,281.093994 426.158661,285.493591 
	C426.584167,285.777710 427.727539,286.541138 428.554810,287.577484 
	C421.735291,290.594971 415.231903,293.339539 408.429230,296.210419 
	C406.663269,292.262451 404.999573,288.543121 403.269043,284.674438 
	C410.821503,281.209167 417.976227,277.926422 425.627228,274.415955 
	C424.264191,272.429016 423.181854,270.851227 421.934570,268.988770 
	C420.984406,269.317993 420.199158,269.931915 419.389771,270.520264 
	C419.365692,270.494659 419.306213,270.532104 418.994934,270.349518 
	C418.451691,270.109406 418.219727,270.051849 417.644470,269.843384 
	C413.518707,268.810608 409.944763,268.286896 409.754456,274.091919 
	C409.368164,274.527100 409.204620,274.827271 409.042389,275.086731 
	C409.043701,275.046082 409.121582,275.069672 408.817749,275.067108 
	C408.002472,275.331665 407.490997,275.598816 406.768921,275.931061 
	C406.558319,275.996155 406.118744,276.030151 405.841278,276.013428 
	C405.378662,275.989197 405.193481,275.981628 405.008301,275.974091 
	C402.884033,270.684143 403.929932,266.203217 408.373016,262.223022 
	C407.636993,262.321350 406.899963,262.412598 406.165253,262.519653 
	C405.030029,262.685120 403.896362,262.861359 402.762024,263.033051 
	C402.865387,261.974121 402.917480,260.907196 403.080627,259.857544 
	C404.008484,253.887619 406.920288,249.380219 412.570831,246.797989 
	C413.193604,246.513397 413.480194,245.493118 413.969788,244.456970 
	C414.016846,244.099899 414.330078,243.869400 414.915344,243.918503 
	C417.663757,243.978455 419.826965,243.989319 421.990143,244.000183 
M423.268280,280.097870 
	C423.767761,280.042847 424.267242,279.987823 424.766724,279.932800 
	C424.651917,279.485565 424.537109,279.038361 424.422333,278.591125 
	C423.995819,278.893860 423.569305,279.196594 423.268280,280.097870 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M656.460632,843.077515 
	C653.942871,840.536316 651.849548,838.062134 657.870972,838.604004 
	C658.580139,838.667847 659.410278,837.387756 660.917053,836.108643 
	C653.891235,834.768677 653.903381,830.083313 653.995483,825.344604 
	C654.024597,823.846313 654.199463,822.314758 653.962891,820.854187 
	C653.222656,816.284180 655.008423,813.515991 659.708374,812.206970 
	C659.902405,811.923767 659.841614,811.912964 660.024658,811.884094 
	C660.306519,811.784973 660.499817,811.684692 660.488647,811.649292 
	C660.420898,811.435364 660.306335,811.236206 660.304199,810.706299 
	C665.738342,807.706116 671.077515,805.035278 676.412659,802.356628 
	C685.230469,797.929382 685.229370,797.927185 681.950623,788.862549 
	C682.015198,788.388916 682.043945,788.199829 682.072754,788.010803 
	C684.179993,791.152832 686.287170,794.294922 688.462708,797.538879 
	C683.649475,800.725586 679.416199,803.528320 674.791382,806.590271 
	C678.198914,810.004211 675.853027,811.997742 672.676636,814.267944 
	C675.948059,814.845337 678.441833,815.285400 682.390259,815.982239 
	C678.173828,818.126343 675.429443,819.521912 671.730042,821.403137 
	C679.016357,824.239929 679.016357,824.239929 673.455261,831.453186 
	C676.412415,831.309448 678.503357,831.643982 680.268127,831.034363 
	C684.371216,829.617004 688.317017,827.744324 692.723999,826.088135 
	C681.025635,831.762817 668.929810,837.405396 656.460632,843.077515 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M352.988037,801.011108 
	C352.396149,794.764404 351.375977,788.517273 351.381744,782.271118 
	C351.384918,778.820374 351.204163,774.511658 356.137756,772.361572 
	C360.347198,768.344666 363.994324,764.573669 367.747498,761.194214 
	C367.573730,764.399780 367.168793,767.208252 367.043945,770.029236 
	C366.864990,774.073608 366.877991,778.126953 366.836975,782.176697 
	C366.826172,783.248169 366.561005,784.513855 367.019775,785.361816 
	C370.331635,791.483582 369.844116,797.582275 367.289581,803.759460 
	C367.118347,804.173462 367.267212,804.719910 367.267212,805.149231 
	C368.581146,805.853149 369.788574,806.499939 371.201935,807.257080 
	C369.048431,809.914612 368.181091,811.550171 372.027435,813.447388 
	C375.173920,814.999390 377.430389,818.355774 380.024841,820.973083 
	C379.968262,821.033997 379.854919,821.155701 379.854919,821.155762 
	C372.664093,816.741272 365.473297,812.326782 358.095612,807.282471 
	C356.268524,804.772034 354.628265,802.891541 352.988037,801.011108 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M323.007416,342.799133 
	C322.877502,342.696625 322.761169,342.532532 322.615692,342.500427 
	C313.000214,340.378235 312.880951,340.377014 313.064545,330.148682 
	C313.128845,326.564911 313.937317,322.994507 315.371277,319.729919 
	C314.523010,317.655182 313.674744,315.580414 312.775208,313.144989 
	C312.723969,312.784302 312.735931,312.832214 313.118530,312.868591 
	C317.610352,311.727722 320.990204,309.881439 320.893158,304.846008 
	C320.893158,304.846008 320.946686,304.952667 321.268616,304.950378 
	C324.280304,304.385254 326.863861,303.727631 326.007568,299.999603 
	C331.080353,295.688477 336.153137,291.377350 341.607605,287.026581 
	C341.989288,286.986908 342.087158,287.086151 342.060791,287.468323 
	C340.669098,290.168549 339.715881,294.029449 337.863617,294.524139 
	C331.532562,296.215027 330.257812,301.954102 329.325104,306.186310 
	C327.685547,313.625793 328.438293,321.573639 327.951660,329.293610 
	C327.692078,333.411469 328.374481,336.786652 332.802368,338.265350 
	C332.101379,339.719971 331.553741,340.856323 330.616943,341.994171 
	C328.151825,342.261810 326.075867,342.527924 323.752075,342.854370 
	C323.338623,342.876160 323.173004,342.837646 323.007416,342.799133 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M623.571167,229.085526 
	C619.132507,228.427353 614.165405,228.209045 609.531433,226.852676 
	C604.875061,225.489761 603.716492,225.769058 604.048401,230.652130 
	C604.363342,235.284805 606.401489,239.860916 602.617371,244.274017 
	C601.250244,245.868286 605.291992,251.802017 607.671021,251.940201 
	C612.281433,252.208008 614.045410,254.661560 614.055359,259.032959 
	C614.059082,260.648285 615.100525,262.339539 614.846985,263.859009 
	C614.452087,266.225555 613.355591,268.474976 612.243286,270.347504 
	C615.218567,261.245880 612.165161,254.992020 603.240845,252.976318 
	C596.618713,251.480591 589.869385,250.547546 583.316528,249.022110 
	C584.942627,247.607864 586.428894,246.535385 587.915161,245.462891 
	C586.764648,244.056641 585.614136,242.650406 584.530151,241.325470 
	C585.638672,234.937332 586.754089,228.509262 587.909119,222.036835 
	C587.948669,221.992477 588.063110,221.960373 588.063110,221.960373 
	C596.038940,223.243011 604.041504,224.382812 611.978882,225.870377 
	C615.779236,226.582596 619.456055,227.953690 623.571167,229.085526 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M562.472900,863.770142 
	C562.056580,863.707153 562.005188,863.320312 562.282959,862.854797 
	C566.371338,861.927002 570.182007,861.464722 573.992676,861.002502 
	C580.662842,860.167053 587.327759,859.286499 594.006165,858.522644 
	C596.102295,858.282837 598.234314,858.356689 600.658936,858.526794 
	C588.275085,860.455444 575.582214,862.144226 562.472900,863.770142 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M550.023438,218.207504 
	C562.553955,219.227280 575.084473,220.247055 587.839050,221.613586 
	C588.063110,221.960373 587.948669,221.992477 587.453613,222.011108 
	C575.583679,221.336823 564.207581,220.661652 552.835938,219.918777 
	C551.878967,219.856277 550.951599,219.339600 550.011475,218.827271 
	C550.012451,218.620636 550.023438,218.207504 550.023438,218.207504 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M439.011963,845.987793 
	C443.634583,847.747192 448.257202,849.506592 452.944092,851.631714 
	C445.096802,849.582581 437.185211,847.167725 429.069275,844.452148 
	C428.864899,844.151428 428.965790,844.038269 429.416321,844.004639 
	C432.915253,844.643311 435.963593,845.315552 439.011963,845.987793 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M341.940613,286.937012 
	C347.647736,282.580139 353.403503,278.273193 359.557190,273.960083 
	C359.955078,273.953888 360.063904,274.068268 359.992371,274.399506 
	C358.864929,275.729187 357.926880,276.906128 356.732483,277.694855 
	C351.893555,280.890198 346.975800,283.966125 342.087158,287.086151 
	C342.087158,287.086151 341.989288,286.986908 341.940613,286.937012 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M422.156067,243.684708 
	C419.826965,243.989319 417.663757,243.978455 415.109070,243.934799 
	C419.724304,241.835190 424.730988,239.768402 430.081329,237.584534 
	C429.461151,238.624542 428.680237,240.044815 427.488068,240.873276 
	C425.936920,241.951172 424.059143,242.559067 422.156067,243.684708 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M251.549683,397.113647 
	C252.104660,397.162903 252.593201,397.474274 252.551697,397.582581 
	C250.649338,402.548462 251.284714,408.752716 245.264343,411.919617 
	C247.008469,407.052643 249.122238,402.166809 251.549683,397.113647 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M331.901978,787.508545 
	C337.201050,787.656311 340.547272,790.911743 342.824768,795.908936 
	C339.206848,793.457031 335.629333,790.634521 331.901978,787.508545 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M446.635254,232.048294 
	C441.858459,234.010757 436.691559,235.926544 431.119171,237.683899 
	C435.890839,235.684189 441.068024,233.842896 446.635254,232.048294 
z"/>
<path fill="#091258" opacity="1.000000" stroke="none" 
	d="
M263.545807,375.068176 
	C267.810059,375.083618 268.239960,377.399780 267.454865,380.980835 
	C265.629669,380.222931 264.082764,379.580566 262.146057,378.882324 
	C262.250610,377.618896 262.744965,376.411316 263.545807,375.068176 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M320.578339,304.964722 
	C320.990204,309.881439 317.610352,311.727722 313.116821,312.844513 
	C315.242889,310.217224 317.753204,307.650360 320.578339,304.964722 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M785.100464,329.525452 
	C782.464600,327.116302 779.752563,324.379395 777.073730,321.270325 
	C779.746033,323.664703 782.385193,326.431213 785.100464,329.525452 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M798.960510,731.378540 
	C800.106323,730.681702 801.210266,730.351135 802.664917,730.041504 
	C801.236694,732.631104 799.457642,735.199768 797.374023,737.901489 
	C797.685730,735.938049 798.302185,733.841431 798.960510,731.378540 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M352.581238,801.007996 
	C354.628265,802.891541 356.268524,804.772034 357.901550,807.021118 
	C354.989624,805.489197 352.084930,803.588684 349.049133,801.369690 
	C350.003479,801.035767 351.088959,801.020325 352.581238,801.007996 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M389.001221,824.000000 
	C388.989014,824.483093 388.976776,824.966248 388.928833,825.810303 
	C385.989807,824.700989 383.086517,823.230713 380.019073,821.458069 
	C379.854919,821.155701 379.968262,821.033997 380.416138,820.998718 
	C383.576416,821.975586 386.288818,822.987793 389.001221,824.000000 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M289.823975,744.505615 
	C290.576691,744.110535 291.381775,744.058167 292.590393,744.005310 
	C293.900146,745.471497 294.806396,746.938049 295.857666,749.038086 
	C296.015350,750.119202 296.028046,750.566833 296.040741,751.014465 
	C296.027832,751.201172 296.014954,751.387817 295.979858,751.854370 
	C293.930603,749.705627 291.903503,747.277039 289.823975,744.505615 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M549.559387,218.121796 
	C550.023438,218.207504 550.012451,218.620636 549.574463,218.830627 
	C547.106689,219.074295 545.076904,219.107986 543.047119,219.141663 
	C539.348877,219.108398 535.650635,219.075119 531.477295,218.840271 
	C531.002258,218.638687 530.971802,218.228043 530.971802,218.228043 
	C537.013000,218.164062 543.054138,218.100067 549.559387,218.121796 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M779.000549,757.004883 
	C780.070923,756.334839 781.141235,755.664795 782.547363,754.988220 
	C780.937805,757.354004 778.992493,759.726257 776.633423,762.147217 
	C776.219727,762.195923 775.939575,761.954346 775.990601,761.627502 
	C777.027954,759.868713 778.014282,758.436829 779.000549,757.004883 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M818.415649,706.952026 
	C818.107300,703.720154 818.194763,700.374939 822.582397,699.004883 
	C821.539429,701.593323 820.125366,704.190674 818.415649,706.952026 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M783.433655,754.166016 
	C784.839355,752.168091 786.583130,750.104065 788.693848,748.061646 
	C787.297852,750.088928 785.534790,752.094482 783.433655,754.166016 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M392.959259,828.471924 
	C395.341644,829.272766 397.907074,830.369629 400.325073,831.703125 
	C397.832550,830.882568 395.487427,829.825256 392.959259,828.471924 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M325.683899,300.068665 
	C326.863861,303.727631 324.280304,304.385254 321.217499,304.919556 
	C322.349731,303.306641 323.854980,301.722198 325.683899,300.068665 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M470.505737,225.820251 
	C468.189636,226.505829 465.469971,227.241226 462.308228,227.947144 
	C464.611450,227.235138 467.356812,226.552597 470.505737,225.820251 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M401.384369,249.746338 
	C401.279053,253.607941 398.254242,252.855377 395.284790,252.934280 
	C396.923126,251.878510 398.977142,250.853073 401.384369,249.746338 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M817.968018,374.672791 
	C817.552979,375.010071 817.109436,375.005707 816.333191,374.998230 
	C815.348816,373.213226 814.697083,371.431335 814.085205,369.265686 
	C815.396423,370.698364 816.667908,372.514740 817.968018,374.672791 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M453.280701,852.204224 
	C455.582245,852.515503 458.169098,853.036682 460.865356,853.875061 
	C458.505188,853.599609 456.035614,853.006897 453.280701,852.204224 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M690.037048,254.664429 
	C688.318481,254.701553 686.543030,254.393967 684.381042,253.716156 
	C683.975586,252.912567 683.956665,252.479233 684.067078,251.918579 
	C684.196472,251.791260 684.435486,251.517944 684.435486,251.517944 
	C686.283691,252.451859 688.131897,253.385788 690.037048,254.664429 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M805.401123,726.950378 
	C805.063110,726.614136 805.033813,726.154053 805.248047,725.181396 
	C805.659241,724.113159 805.826965,723.557495 805.994751,723.001953 
	C806.812378,722.357300 807.630005,721.712646 808.816650,721.120117 
	C808.027039,723.057068 806.868469,724.941833 805.401123,726.950378 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M791.047974,336.583527 
	C789.747192,335.510132 788.386230,334.114685 787.043701,332.351379 
	C788.370667,333.409515 789.679199,334.835510 791.047974,336.583527 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M795.043030,341.571869 
	C793.745239,340.481445 792.391235,339.068237 791.065613,337.281006 
	C792.391602,338.354401 793.689209,339.801758 795.043030,341.571869 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M372.154907,265.616638 
	C373.016449,269.446533 369.996307,268.672699 367.412476,269.008362 
	C368.629883,267.927155 370.245148,266.837524 372.154907,265.616638 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M841.202515,421.550293 
	C840.352417,420.361969 839.483459,418.824921 838.897522,417.091858 
	C839.848206,418.331116 840.515991,419.766327 841.202515,421.550293 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M281.619080,733.982910 
	C286.319519,731.484375 283.808868,736.133118 284.937378,737.732788 
	C283.882263,736.805847 282.818542,735.534790 281.619080,733.982910 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M250.861649,683.444702 
	C253.693420,682.827454 254.641724,683.941589 253.090637,686.878540 
	C252.159424,685.989746 251.533340,684.879150 250.861649,683.444702 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M256.999084,695.367615 
	C257.948456,696.521667 258.946136,698.017456 259.918823,699.900024 
	C258.945038,698.760986 257.996246,697.235107 256.999084,695.367615 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M302.002594,757.000977 
	C302.950958,758.205383 303.899353,759.409790 304.754486,760.997803 
	C303.802856,760.539612 302.944458,759.697876 302.031067,758.239746 
	C301.984924,757.415955 301.993774,757.208435 302.002594,757.000977 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M814.297974,713.771179 
	C814.771912,712.314331 815.531494,710.706299 816.657410,709.073120 
	C816.210388,710.571960 815.397034,712.096008 814.297974,713.771179 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M769.040283,770.037476 
	C767.892517,771.278748 766.744690,772.520020 765.299866,773.878174 
	C765.002869,773.995056 764.928101,773.926147 764.944702,773.531982 
	C766.284729,772.058350 767.608215,770.978882 768.957275,769.935181 
	C768.982971,769.970886 769.040344,770.037537 769.040283,770.037476 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M461.563873,227.899719 
	C461.183990,228.533997 460.420471,229.233856 459.326660,229.963501 
	C457.873138,229.973282 456.749939,229.953278 455.206055,229.879669 
	C456.916992,229.205795 459.048615,228.585541 461.563873,227.899719 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M454.492859,229.775406 
	C452.991180,230.457077 451.106873,231.193649 448.775238,231.890533 
	C450.255371,231.177353 452.182770,230.503845 454.492859,229.775406 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M285.820435,739.487000 
	C286.833557,740.227173 287.894135,741.295471 288.929565,742.746826 
	C287.892242,742.025024 286.880096,740.920105 285.820435,739.487000 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M806.960938,357.640930 
	C806.019836,356.856293 805.032227,355.752716 804.076294,354.278809 
	C805.043457,355.046326 805.978882,356.184174 806.960938,357.640930 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M790.324341,746.832397 
	C790.805847,745.674683 791.575317,744.386353 792.705322,743.088013 
	C792.247925,744.285889 791.430115,745.493774 790.324341,746.832397 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M530.591125,218.151428 
	C530.971802,218.228043 531.002258,218.638687 531.017944,218.844452 
	C530.543335,219.347748 530.052979,219.645294 529.100403,219.715820 
	C528.094727,219.358307 527.551331,219.227829 527.007935,219.097351 
	C526.827759,219.079987 526.647644,219.062622 526.197266,219.018555 
	C527.354736,218.686157 528.782593,218.380478 530.591125,218.151428 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M406.406677,247.312653 
	C405.361053,248.126907 403.960327,249.012939 402.138977,249.814804 
	C403.162781,248.948563 404.607178,248.166489 406.406677,247.312653 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M776.356079,762.320984 
	C775.521118,763.549072 774.549927,764.652161 773.248840,765.837646 
	C772.918945,765.919983 772.853882,765.854004 772.905701,765.521545 
	C773.951538,764.110840 774.945496,763.032593 775.939575,761.954346 
	C775.939575,761.954346 776.219727,762.195923 776.356079,762.320984 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M253.842590,689.407288 
	C254.520203,690.145813 255.248062,691.220642 255.962921,692.688843 
	C255.264221,691.969421 254.578522,690.856506 253.842590,689.407288 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M702.009033,819.363770 
	C703.037231,819.013184 704.072021,819.014221 705.492065,819.022095 
	C704.843323,819.706482 703.809387,820.384033 702.458801,821.088684 
	C702.099976,820.648987 702.057800,820.182190 702.009033,819.363770 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M772.950806,765.953491 
	C772.197693,766.932739 771.412659,767.878418 770.300659,768.904541 
	C769.973694,768.984985 769.899048,768.930725 769.935059,768.571045 
	C770.932007,767.425598 771.892944,766.639771 772.853882,765.854004 
	C772.853882,765.854004 772.918945,765.919983 772.950806,765.953491 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M366.710236,269.074341 
	C366.235657,269.670044 365.454926,270.324402 364.316315,270.982605 
	C364.773651,270.368622 365.588867,269.750824 366.710236,269.074341 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M706.354126,818.905273 
	C706.815125,818.303406 707.580017,817.673950 708.696655,817.060181 
	C708.251526,817.676392 707.454773,818.276978 706.354126,818.905273 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M475.648468,224.809204 
	C474.579895,225.204300 473.110657,225.576477 471.231171,225.908661 
	C472.296570,225.507874 473.772156,225.147064 475.648468,224.809204 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M798.122925,345.553284 
	C797.459900,345.151672 796.743286,344.445251 796.038208,343.377777 
	C796.722961,343.760620 797.396118,344.504547 798.122925,345.553284 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M375.443573,263.658447 
	C374.929230,264.298370 374.114136,265.025085 372.945862,265.600464 
	C373.442719,264.881134 374.292755,264.313171 375.443573,263.658447 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M325.965149,782.375244 
	C326.710083,782.616089 327.560699,783.134460 328.161255,783.848999 
	C327.297760,783.581055 326.684296,783.116943 325.965149,782.375244 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M803.352051,729.892822 
	C803.502563,729.045715 803.943726,728.085754 804.739685,727.108582 
	C804.610474,727.987549 804.126587,728.883728 803.352051,729.892822 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M809.461182,721.031372 
	C809.585693,720.154846 810.001526,719.150513 810.786987,718.143677 
	C810.688538,719.062073 810.220459,719.982849 809.461182,721.031372 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M320.018646,777.366333 
	C320.872925,777.569702 321.847534,778.069702 322.790375,778.946228 
	C321.885406,778.769531 321.012207,778.216309 320.018646,777.366333 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M804.058289,353.586945 
	C803.416809,353.159241 802.724915,352.425385 802.050049,351.330322 
	C802.713989,351.739685 803.360901,352.510254 804.058289,353.586945 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M812.966064,366.656860 
	C812.339722,366.203003 811.683350,365.440399 811.043335,364.322449 
	C811.685242,364.760803 812.310669,365.554474 812.966064,366.656860 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M822.025879,381.641296 
	C821.529480,381.412354 821.018127,380.888977 820.728760,380.185852 
	C821.304138,380.453033 821.657471,380.899933 822.025879,381.641296 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M279.823669,731.444641 
	C280.389740,731.700623 281.015045,732.234985 281.477692,733.111328 
	C280.837677,732.876587 280.360321,732.299744 279.823669,731.444641 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M801.994324,350.634674 
	C801.416199,350.424774 800.790771,349.916626 800.227417,349.060638 
	C800.841980,349.253998 801.394531,349.795227 801.994324,350.634674 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M843.896606,428.703339 
	C843.444458,428.402313 843.059631,427.808014 842.920776,427.058868 
	C843.432373,427.406067 843.698120,427.908081 843.896606,428.703339 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M754.189087,298.429810 
	C753.566528,298.330475 752.850952,297.944946 752.134033,297.204193 
	C752.787170,297.280518 753.441650,297.712067 754.189087,298.429810 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M698.290894,823.609802 
	C698.740051,823.105774 699.466431,822.555054 700.504517,821.991028 
	C700.066772,822.506226 699.317444,823.034668 698.290894,823.609802 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M317.980591,775.321289 
	C318.547638,775.498535 319.222076,775.959290 319.915283,776.761780 
	C319.318726,776.603943 318.703369,776.104431 317.980591,775.321289 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M308.976013,766.330627 
	C309.337311,766.028931 309.812164,766.012146 310.648712,766.249023 
	C311.018219,766.674438 311.025970,766.846191 311.033752,767.017944 
	C311.021210,767.206116 311.008698,767.394226 310.972900,767.864441 
	C310.329651,767.636108 309.709595,767.125793 308.976013,766.330627 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M828.034790,392.660767 
	C827.714355,392.512482 827.383789,392.072693 827.041748,391.340790 
	C827.361877,391.488892 827.693298,391.929077 828.034790,392.660767 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M304.687714,761.558960 
	C305.039032,761.617737 305.485504,761.939209 305.986328,762.562927 
	C305.621399,762.517395 305.202148,762.169556 304.687714,761.558960 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M817.302490,708.859009 
	C817.228638,708.448730 817.412659,707.888245 817.874817,707.198364 
	C817.955444,707.615662 817.757935,708.162231 817.302490,708.859009 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M819.903931,377.900513 
	C819.638489,377.914490 819.340698,377.657471 819.060425,377.174133 
	C819.342529,377.175049 819.607056,377.402283 819.903931,377.900513 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M823.155151,383.620758 
	C822.814087,383.479736 822.443848,383.038574 822.067749,382.286438 
	C822.416504,382.423798 822.771240,382.872223 823.155151,383.620758 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M824.282959,696.819275 
	C824.193848,696.423523 824.355042,695.885376 824.765564,695.202454 
	C824.854370,695.597412 824.693787,696.137146 824.282959,696.819275 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M795.235596,740.710449 
	C795.198425,740.315186 795.418945,739.803650 795.913208,739.194946 
	C795.955750,739.596619 795.724548,740.095398 795.235596,740.710449 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M845.010437,431.683716 
	C844.673767,431.501617 844.367859,431.002197 844.067993,430.194153 
	C844.396362,430.379181 844.718750,430.872803 845.010437,431.683716 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M848.053345,440.684509 
	C847.679688,440.498474 847.378662,440.000275 847.097534,439.193024 
	C847.453613,439.380127 847.789795,439.876221 848.053345,440.684509 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M378.630310,261.845428 
	C378.478516,262.167114 378.023102,262.548309 377.247498,262.948914 
	C377.393738,262.613892 377.860229,262.259399 378.630310,261.845428 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M812.293457,716.812866 
	C812.221069,716.415955 812.398804,715.870972 812.843567,715.194214 
	C812.921570,715.596558 812.732605,716.130676 812.293457,716.812866 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M814.146362,368.632477 
	C813.791626,368.496552 813.425171,368.062073 813.044250,367.323242 
	C813.398071,367.457214 813.766296,367.895569 814.146362,368.632477 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M390.949615,827.435791 
	C391.415649,827.322266 392.031921,827.487122 392.772583,827.964355 
	C392.297943,828.089172 391.698883,827.901611 390.949615,827.435791 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M800.308350,348.505859 
	C799.913208,348.406891 799.497070,348.004669 799.076965,347.282959 
	C799.477844,347.376526 799.882629,347.789551 800.308350,348.505859 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M388.985779,826.389038 
	C389.441193,826.287659 390.047699,826.461792 390.784119,826.939514 
	C390.321625,827.050232 389.729248,826.857361 388.985779,826.389038 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M693.374390,826.135498 
	C693.529663,825.799255 693.988708,825.441833 694.761108,825.085815 
	C694.608948,825.429504 694.143555,825.771912 693.374390,826.135498 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M695.360107,825.082275 
	C695.527832,824.755981 695.999268,824.412292 696.782227,824.060181 
	C696.617126,824.389465 696.140503,824.727173 695.360107,825.082275 
z"/>
<path fill="#08124D" opacity="1.000000" stroke="none" 
	d="
M255.955444,693.335083 
	C256.252808,693.500427 256.594513,693.968628 256.952759,694.745178 
	C256.646149,694.581665 256.322998,694.109741 255.955444,693.335083 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M810.221802,362.579773 
	C809.855408,362.463409 809.462036,362.048279 809.057129,361.323547 
	C809.428711,361.436340 809.811707,361.858704 810.221802,362.579773 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M709.337524,817.056396 
	C709.475586,816.738464 709.893982,816.406616 710.593872,816.053223 
	C710.456116,816.368591 710.036926,816.705505 709.337524,817.056396 
z"/>
<path fill="#0D9BFA" opacity="1.000000" stroke="none" 
	d="
M825.326416,694.842041 
	C825.232727,694.436157 825.394531,693.892212 825.815063,693.208496 
	C825.909851,693.613770 825.745850,694.158875 825.326416,694.842041 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M808.240479,359.564209 
	C807.869019,359.449890 807.468933,359.038452 807.053223,358.315521 
	C807.428955,358.425049 807.820374,358.846100 808.240479,359.564209 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M376.707581,262.971039 
	C376.725555,263.190186 376.465088,263.451813 375.915771,263.720520 
	C375.894348,263.489563 376.161743,263.251556 376.707581,262.971039 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M712.336548,815.046631 
	C712.256897,814.825134 712.437012,814.539795 712.854980,814.155518 
	C712.927368,814.365356 712.761902,814.674133 712.336548,815.046631 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M701.304810,821.903687 
	C701.150757,821.591431 701.350586,821.361694 701.873779,821.155090 
	C701.920410,821.363037 701.740967,821.632324 701.304810,821.903687 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M711.323364,815.912048 
	C711.161011,815.609741 711.356445,815.371338 711.884399,815.150330 
	C711.933838,815.351440 711.755310,815.620850 711.323364,815.912048 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M795.945557,342.691620 
	C795.653992,342.867188 795.397827,342.678467 795.143372,342.147888 
	C795.342468,342.090057 795.621094,342.263824 795.945557,342.691620 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M713.038086,267.657990 
	C712.792114,267.895386 712.519775,267.781860 712.162964,267.380737 
	C712.347107,267.266327 712.658142,267.337646 713.038086,267.657990 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M301.711853,757.012573 
	C301.993774,757.208435 301.984924,757.415955 301.942352,757.933472 
	C301.605347,758.044189 301.302124,757.844910 300.922485,757.379700 
	C301.037781,757.083923 301.229431,757.054016 301.711853,757.012573 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M799.001587,346.673279 
	C798.777222,346.740021 798.506104,346.540375 798.166016,346.089722 
	C798.382996,346.028137 798.668884,346.217499 799.001587,346.673279 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M819.036438,376.628510 
	C818.781128,376.655792 818.493652,376.362183 818.154053,375.752625 
	C818.402649,375.726990 818.703491,376.017303 819.036438,376.628510 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M342.834778,796.442627 
	C343.049286,796.331604 343.380920,796.471191 343.836914,796.859375 
	C343.624756,796.969788 343.288330,796.831543 342.834778,796.442627 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M322.783234,779.472656 
	C323.014465,779.372375 323.353424,779.535339 323.787170,779.975952 
	C323.551605,780.081055 323.221283,779.908447 322.783234,779.472656 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M323.854919,780.399292 
	C324.076050,780.309021 324.396393,780.479797 324.821899,780.910461 
	C324.602783,781.000305 324.278442,780.830383 323.854919,780.399292 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M324.922363,781.340454 
	C325.134705,781.267822 325.435089,781.457092 325.833130,781.901245 
	C325.623993,781.971558 325.317230,781.786987 324.922363,781.340454 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M808.971558,360.696869 
	C808.832458,360.961517 808.665588,360.972046 808.247864,360.977631 
	C808.024536,360.771301 808.051941,360.569885 808.162476,360.073730 
	C808.478394,360.000275 808.711060,360.221527 808.971558,360.696869 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M811.015991,363.676147 
	C810.779358,363.736938 810.510803,363.523193 810.189697,363.048279 
	C810.419373,362.991913 810.701721,363.196747 811.015991,363.676147 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M682.984375,251.029327 
	C683.343750,251.066238 683.688965,251.121964 684.234802,251.347824 
	C684.435486,251.517944 684.196472,251.791260 683.862427,251.802734 
	C683.267151,251.625244 683.072327,251.382919 682.943848,251.087204 
	C682.943848,251.087204 682.970154,251.048141 682.984375,251.029327 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M347.925690,800.334839 
	C348.126343,800.259277 348.431885,800.433716 348.851440,800.852417 
	C348.653870,800.926086 348.342224,800.755432 347.925690,800.334839 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M311.979004,769.282227 
	C312.166626,769.221191 312.455597,769.408752 312.859528,769.832520 
	C312.676453,769.889404 312.378418,769.710083 311.979004,769.282227 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M770.008545,769.015320 
	C769.876526,769.334412 769.709595,769.623169 769.291504,769.974731 
	C769.040344,770.037537 768.982971,769.970886 769.075928,769.734680 
	C769.364563,769.247864 769.607910,769.058594 769.899048,768.930725 
	C769.899048,768.930725 769.973694,768.984985 770.008545,769.015320 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M313.012268,770.283508 
	C313.209320,770.232117 313.486206,770.437439 313.865051,770.885254 
	C313.675354,770.931946 313.383728,770.736023 313.012268,770.283508 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M379.756378,261.090576 
	C379.790283,261.325653 379.547485,261.560150 379.051941,261.850037 
	C379.026001,261.633606 379.252838,261.361786 379.756378,261.090576 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M381.725464,260.000214 
	C381.748291,260.252106 381.454987,260.520386 380.860840,260.847290 
	C380.843109,260.609467 381.126221,260.313049 381.725464,260.000214 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M383.649719,258.959015 
	C383.665833,259.201904 383.349060,259.494720 382.703552,259.854156 
	C382.688843,259.616821 383.002869,259.312897 383.649719,258.959015 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M346.745544,799.391846 
	C346.973572,799.289185 347.327301,799.460022 347.807678,799.905151 
	C347.579956,800.008179 347.225586,799.836792 346.745544,799.391846 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M344.997742,798.294189 
	C345.253510,798.098206 345.527100,798.253174 345.870850,798.705566 
	C345.684479,798.792053 345.375946,798.672791 344.997742,798.294189 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M343.980225,797.295715 
	C344.178650,797.230591 344.465729,797.416809 344.860718,797.841187 
	C344.668701,797.901917 344.368774,797.724487 343.980225,797.295715 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M759.027466,302.666382 
	C758.750122,302.872711 758.470642,302.709503 758.163574,302.196777 
	C758.361755,302.118134 758.658203,302.267944 759.027466,302.666382 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M360.734192,273.006775 
	C360.999176,273.138245 361.002899,273.309937 360.774231,273.752350 
	C360.541840,274.023102 360.063904,274.068268 360.063904,274.068268 
	C360.063904,274.068268 359.955078,273.953888 359.899414,273.896393 
	C360.053467,273.574921 360.263214,273.310944 360.734192,273.006775 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M823.373352,698.294861 
	C823.246155,698.088562 823.371582,697.726013 823.741577,697.205200 
	C823.866028,697.410767 823.746033,697.774719 823.373352,698.294861 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M362.625488,271.820496 
	C362.671387,271.998352 362.475616,272.230316 362.143738,272.329529 
	C362.007599,272.196777 362.383820,271.874603 362.625488,271.820496 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M751.023315,295.679901 
	C750.740723,295.877563 750.463806,295.709290 750.156372,295.202393 
	C750.359131,295.125336 750.660156,295.275391 751.023315,295.679901 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M749.948303,294.710266 
	C749.702881,294.883545 749.446106,294.728699 749.151611,294.270691 
	C749.413940,294.035400 749.688354,294.173615 749.948303,294.710266 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M752.069214,296.647034 
	C751.847900,296.737305 751.554626,296.566284 751.161194,296.154297 
	C751.373108,296.070801 751.685181,296.228271 752.069214,296.647034 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M755.237732,299.473938 
	C754.965820,299.597687 754.633728,299.444031 754.249023,299.003754 
	C754.523438,298.876923 754.850525,299.036713 755.237732,299.473938 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M755.877075,299.911316 
	C755.730896,300.079559 755.476440,300.063873 755.284790,299.852905 
	C755.347595,299.657654 755.768799,299.727356 755.877075,299.911316 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M757.966919,301.694336 
	C757.705139,301.875763 757.445679,301.716400 757.156860,301.242889 
	C757.429077,301.006348 757.709656,301.147980 757.966919,301.694336 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M284.984375,738.304993 
	C285.189667,738.263184 285.464447,738.486145 285.800720,738.974976 
	C285.592743,739.017090 285.323303,738.793457 284.984375,738.304993 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M796.370605,739.092102 
	C796.265381,738.865173 796.420898,738.566895 796.812012,738.162598 
	C796.908752,738.377991 796.770020,738.699341 796.370605,739.092102 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M793.309875,743.000305 
	C793.119202,742.723328 793.288147,742.450134 793.792114,742.149780 
	C793.865173,742.348755 793.712646,742.642578 793.309875,743.000305 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M288.925018,743.339417 
	C289.147339,743.281494 289.437195,743.493958 289.794586,743.975769 
	C289.572266,744.033386 289.282440,743.821533 288.925018,743.339417 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M789.309570,747.999634 
	C789.116333,747.731567 789.275024,747.460571 789.755920,747.150024 
	C789.833557,747.343811 789.694641,747.639282 789.309570,747.999634 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M713.752197,268.088684 
	C713.671997,268.236816 713.411743,268.233490 713.128601,268.053711 
	C713.105652,267.877228 713.572266,267.937195 713.752197,268.088684 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M813.317505,715.020142 
	C813.113647,714.757324 813.260498,714.480835 813.722534,714.147888 
	C813.809082,714.340332 813.685913,714.645325 813.317505,715.020142 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M811.354980,718.061768 
	C811.122437,717.779053 811.269348,717.491028 811.764771,717.161621 
	C811.862305,717.368774 811.736023,717.680847 811.354980,718.061768 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M786.024170,330.676819 
	C785.801331,330.744873 785.521790,330.545197 785.168335,330.090851 
	C785.385376,330.027191 785.676392,330.218140 786.024170,330.676819 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M363.717010,270.997681 
	C363.764587,271.232422 363.531311,271.499115 363.030914,271.826294 
	C362.987915,271.601074 363.212036,271.315338 363.717010,270.997681 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M299.889648,756.330444 
	C300.094849,756.267700 300.406708,756.466980 300.817078,756.933594 
	C300.609161,756.998047 300.302734,756.795227 299.889648,756.330444 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M786.970093,331.689087 
	C786.681396,331.870514 786.415955,331.684814 786.142639,331.153809 
	C786.342712,331.091522 786.628906,331.262695 786.970093,331.689087 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M847.173279,438.663879 
	C846.992920,438.667145 846.823669,438.434021 846.818481,438.100189 
	C846.982483,437.999451 847.184387,438.427490 847.173279,438.663879 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M769.935059,768.571045 
	C769.607910,769.058594 769.364563,769.247864 769.050293,769.698975 
	C767.608215,770.978882 766.284729,772.058350 764.908203,773.496521 
	C763.175781,774.444214 761.496399,775.033203 759.970703,775.568298 
	C754.719116,773.794312 753.482361,770.120911 753.875977,764.877136 
	C754.384583,758.100525 754.122620,751.253845 753.957947,744.443054 
	C753.842346,739.658997 755.901917,736.961548 760.240479,734.853271 
	C763.056580,733.484741 768.076050,734.008545 767.910400,728.297485 
	C767.884827,727.907043 767.916321,727.935852 768.156006,727.844360 
	C768.574829,727.470764 768.753906,727.188660 768.911499,726.930176 
	C768.890015,726.953796 768.889404,726.889893 769.279785,726.919312 
	C773.443115,725.823120 772.805359,722.703064 772.924072,719.938721 
	C772.988647,719.981689 772.844910,719.923523 773.181458,719.732849 
	C775.624207,714.930054 776.244263,710.376831 772.876770,705.852539 
	C772.772583,705.727783 772.872131,705.418335 772.908447,704.995850 
	C772.857361,700.982056 772.260132,697.741699 767.851135,697.103149 
	C767.874756,697.063660 767.910645,697.148438 767.824829,696.891602 
	C767.436584,696.457153 767.134033,696.279602 766.876465,696.091553 
	C766.921387,696.080994 766.899902,696.170776 766.814575,695.907410 
	C766.414124,695.476501 766.099121,695.308899 765.825317,695.166504 
	C765.866516,695.191650 765.785522,695.244263 765.832764,694.890747 
	C765.664978,690.771973 763.040466,691.929993 760.942871,692.110413 
	C760.986145,692.121216 760.898682,692.138916 760.888062,691.780151 
	C759.919983,690.938354 758.962402,690.455383 757.948853,689.558960 
	C757.595215,687.039246 757.054016,684.934448 757.040100,682.826294 
	C756.958313,670.409729 757.089233,657.991394 756.940918,645.576111 
	C756.899414,642.103882 755.187866,638.406982 759.869690,636.398376 
	C760.399231,636.171143 760.167419,634.169312 760.288635,632.990356 
	C759.286194,633.049194 758.270142,633.026001 757.284851,633.188538 
	C756.175476,633.371521 755.093750,633.721985 754.007141,633.610352 
	C754.639893,630.442749 755.265503,627.664246 755.872314,624.923523 
	C755.853577,624.961243 755.836487,624.878723 756.231445,624.920715 
	C757.417542,624.974304 758.208801,624.985901 759.034668,625.282471 
	C759.374573,625.890686 759.679932,626.213867 759.985229,626.537109 
	C760.167297,626.327087 760.349365,626.117065 760.531372,625.907043 
	C760.020081,625.604675 759.508850,625.302368 759.104065,624.741089 
	C759.472900,623.988953 759.735229,623.495728 759.998535,622.668701 
	C759.999695,621.889954 759.999817,621.445007 760.000000,620.585754 
	C761.999207,613.446045 763.998413,606.720581 766.165405,599.908936 
	C766.397217,599.715820 766.539917,599.548828 766.511841,599.512085 
	C766.367798,599.323486 766.179871,599.168396 766.006775,598.593689 
	C766.821777,593.506897 767.757507,588.845093 768.427063,584.145447 
	C769.699341,575.215942 770.953369,566.279724 771.952393,557.317017 
	C772.555481,551.906494 772.664246,546.440857 772.996460,540.565430 
	C772.007263,529.347961 771.233032,518.539490 769.959167,507.790192 
	C768.899841,498.850800 767.683716,489.885651 765.738770,481.108795 
	C764.000549,473.265076 761.368042,465.594086 758.702881,457.996765 
	C757.909485,455.735199 755.673828,453.979553 754.065308,451.663147 
	C754.025940,450.893524 754.020203,450.452850 754.302124,449.789246 
	C755.393188,446.974854 756.826294,444.398804 756.889954,441.789337 
	C757.152588,431.019592 757.467773,420.210052 756.786621,409.477142 
	C756.483032,404.693146 761.457520,399.813049 756.586365,395.084381 
	C756.349854,394.854828 757.733826,392.966156 758.351257,391.843536 
	C758.879578,390.883209 759.390747,389.913483 759.922729,388.956238 
	C759.936096,388.964539 759.952698,388.948090 760.175049,388.866577 
	C760.684387,388.613464 760.865967,388.364960 761.055115,387.791687 
	C761.465271,387.349121 761.762573,387.154388 762.265869,386.860626 
	C762.725159,386.566071 762.883423,386.311462 762.954651,385.982971 
	C762.962646,385.968201 762.987122,385.945221 763.217834,385.836609 
	C763.590149,385.427826 763.731689,385.127655 763.844604,384.859558 
	C763.815979,384.891663 763.777283,384.814758 764.173218,384.815796 
	C764.670166,382.522552 764.771179,380.228271 764.779053,377.822906 
	C764.685974,377.711792 764.827637,377.458832 764.943115,377.132141 
	C764.734070,375.864563 764.409668,374.923706 763.967773,373.876709 
	C763.850342,373.770599 763.892883,373.456909 763.863831,373.176819 
	C763.612305,372.607849 763.389832,372.318939 762.972534,371.729309 
	C761.218750,368.325195 759.572571,365.261719 758.125610,362.106873 
	C756.725342,359.053497 755.955505,355.363739 759.749817,354.080017 
	C761.861328,353.365601 765.921448,354.477448 767.271545,356.201050 
	C770.962646,360.913574 773.682251,366.387054 776.973206,371.870148 
	C777.803528,371.248230 779.354187,370.086761 780.936157,368.947937 
	C780.967468,368.970551 781.034424,369.009247 781.034424,369.009247 
	C779.159668,373.400024 777.284912,377.790802 775.293457,382.455017 
	C777.021484,382.792084 778.707214,383.120880 779.636169,383.302094 
	C779.045288,386.382996 778.079651,389.106201 778.068481,391.833313 
	C777.956055,419.326691 778.097900,446.821259 777.954407,474.314331 
	C777.892456,486.183380 777.492859,498.057434 776.932800,509.914368 
	C776.628418,516.359863 775.464722,522.764160 775.141785,529.209717 
	C775.061096,530.817993 776.723083,532.519958 777.605408,534.167480 
	C778.037537,534.974426 778.739563,535.704102 778.937378,536.557312 
	C779.643372,539.602722 780.527100,542.672241 780.652588,545.760620 
	C780.720276,547.425964 779.731567,550.286987 778.615173,550.627563 
	C775.500793,551.577576 776.163330,553.568481 776.085999,555.697815 
	C775.891846,561.042908 775.499268,566.380493 775.248535,571.724060 
	C775.187683,573.021423 774.839111,574.882507 775.526306,575.539490 
	C778.812195,578.681213 777.980469,582.584290 777.983948,586.323547 
	C778.012390,617.229797 777.909180,648.136597 778.112732,679.041504 
	C778.138733,682.994934 775.876221,687.438538 779.696594,691.109741 
	C780.181519,691.575684 779.313232,693.592468 778.893188,694.830139 
	C777.733154,698.247803 775.916870,701.531128 778.740356,705.137024 
	C779.312378,705.867493 778.478882,707.639038 778.418274,708.936462 
	C778.287292,711.738647 778.325012,714.550598 778.132385,717.347412 
	C777.888611,720.886597 777.374512,724.409241 777.195374,727.950012 
	C777.103882,729.756226 777.467834,731.589050 777.665771,733.405457 
	C777.853027,735.123657 778.352905,736.850342 778.260559,738.548584 
	C777.964050,743.998291 777.413025,749.433899 777.091309,754.882751 
	C777.054077,755.512817 777.839844,756.191528 778.622314,756.926270 
	C778.014282,758.436829 777.027954,759.868713 775.990601,761.627441 
	C774.945496,763.032593 773.951538,764.110840 772.905701,765.521545 
	C771.892944,766.639771 770.932007,767.425598 769.935059,768.571045 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M758.992371,346.889282 
	C762.103149,347.153198 765.213867,347.417114 768.671387,347.817505 
	C769.998047,348.740692 770.978027,349.527405 771.972412,350.666077 
	C771.986877,351.018066 771.904480,351.090698 771.572510,350.965302 
	C767.157776,349.523010 763.075073,348.206146 758.992371,346.889282 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M779.039246,359.944946 
	C783.179871,361.802094 781.993469,365.252686 781.311768,368.739319 
	C781.034424,369.009247 780.967468,368.970551 780.908386,368.574158 
	C780.106201,365.550201 779.363098,362.922638 778.726074,360.209076 
	C778.832092,360.123047 779.039246,359.944946 779.039246,359.944946 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M775.044800,353.948547 
	C775.701843,354.721954 776.358948,355.495331 776.993164,356.648315 
	C776.970276,357.027863 776.893555,357.091553 776.619080,356.934692 
	C775.854431,355.892334 775.364258,355.006836 774.918945,354.080261 
	C774.963806,354.039215 775.044800,353.948547 775.044800,353.948547 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M778.011902,357.966644 
	C778.331787,358.431122 778.651611,358.895630 779.005371,359.652527 
	C779.039246,359.944946 778.832092,360.123047 778.522949,360.017151 
	C778.006042,359.395050 777.798340,358.878815 777.697510,358.265289 
	C777.804443,358.167999 778.011902,357.966644 778.011902,357.966644 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M774.060059,352.914825 
	C774.371521,353.041016 774.624817,353.240906 774.932373,353.731537 
	C775.044800,353.948547 774.963806,354.039215 774.681519,353.989380 
	C774.243286,353.648346 774.087402,353.357117 773.964966,353.029297 
	C773.998413,352.992645 774.060059,352.914825 774.060059,352.914825 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M777.010254,356.998047 
	C777.359497,357.080750 777.609253,357.271393 777.905640,357.753357 
	C778.011902,357.966644 777.804443,358.167999 777.505920,358.101624 
	C777.102783,357.720703 776.998169,357.406128 776.893555,357.091553 
	C776.893555,357.091553 776.970276,357.027863 777.010254,356.998047 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M772.030029,350.984131 
	C772.381836,351.068939 772.638855,351.257874 772.929382,351.754822 
	C773.014526,351.992645 772.943848,352.056061 772.698975,351.983856 
	C772.270874,351.638000 772.087646,351.364380 771.904419,351.090698 
	C771.904480,351.090698 771.986877,351.018066 772.030029,350.984131 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M773.051758,351.963226 
	C773.385620,352.059265 773.631775,352.249817 773.943848,352.710114 
	C774.060059,352.914825 773.998413,352.992645 773.726013,352.945679 
	C773.283691,352.617798 773.113770,352.336914 772.943848,352.056061 
	C772.943848,352.056061 773.014526,351.992645 773.051758,351.963226 
z"/>
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M634.985474,330.851166 
	C647.348694,337.937958 659.711853,345.024750 672.418640,352.159790 
	C672.762207,352.208038 672.793823,352.188873 672.960999,352.476318 
	C675.722656,354.536987 678.317200,356.310150 680.911682,358.083313 
	C680.911682,358.083313 680.967407,358.084229 681.004639,358.323547 
	C681.342712,358.706970 681.643677,358.851044 681.944580,358.995117 
	C681.944580,358.995117 681.960693,359.047089 682.103638,359.333313 
	C683.111267,360.140533 683.975891,360.661530 684.840576,361.182495 
	C684.840576,361.182495 684.924744,361.125000 684.957703,361.376831 
	C685.307190,361.796844 685.623657,361.965027 685.940186,362.133179 
	C686.134521,362.393677 686.328796,362.654175 686.901611,363.243500 
	C688.128662,364.118500 688.977173,364.664673 689.825745,365.210815 
	C689.825745,365.210815 689.906921,365.144440 689.932739,365.400635 
	C690.283447,365.802094 690.608398,365.947418 690.933350,366.092743 
	C690.933350,366.092743 690.995544,366.010651 691.008545,366.301392 
	C691.327087,366.745636 691.632507,366.899139 691.937988,367.052612 
	C691.937988,367.052612 691.989563,366.999023 692.033386,367.275269 
	C692.362305,367.709167 692.647400,367.866760 692.932495,368.024384 
	C693.142151,368.313385 693.351868,368.602386 693.881531,369.187073 
	C694.377869,369.756012 694.628540,369.886353 694.953613,369.873840 
	C694.953613,369.873840 694.968384,370.020477 695.139771,370.285248 
	C695.802490,370.804413 696.293823,371.058807 696.785095,371.313171 
	C696.785095,371.313171 696.831055,371.196381 696.851379,371.513092 
	C697.531189,372.299469 698.190613,372.769104 698.850098,373.238770 
	C698.850098,373.238770 698.899841,373.126007 698.912048,373.412720 
	C699.242615,373.922211 699.560913,374.144958 699.879211,374.367676 
	C704.029480,378.850708 708.179810,383.333740 712.652832,387.930237 
	C724.450439,401.684875 735.041199,415.938538 743.677063,432.316833 
	C744.517029,433.582428 744.976379,434.341339 745.440674,435.279541 
	C745.549011,435.698700 745.728882,435.843506 745.972046,435.940002 
	C745.958740,435.986786 746.049133,436.022705 746.049683,436.315216 
	C746.391663,437.064880 746.733032,437.522034 747.081055,438.254883 
	C747.183899,438.687256 747.280090,438.843933 747.410645,439.292725 
	C747.973206,440.389679 748.501465,441.194489 749.036926,442.331055 
	C749.675476,443.797516 750.306824,444.932220 750.939026,446.375671 
	C751.266907,447.181213 751.593872,447.678040 751.913330,448.457581 
	C752.179199,449.202881 752.452637,449.665466 752.771973,450.454285 
	C753.199219,451.530182 753.580566,452.279816 753.980347,453.400299 
	C765.283569,481.753052 770.940430,510.841217 771.068481,541.462646 
	C771.187378,560.248413 768.897644,578.292053 764.344177,596.007507 
	C761.848022,605.718628 758.693909,615.260498 755.836487,624.878723 
	C755.836487,624.878723 755.853577,624.961243 755.587646,625.128662 
	C754.257629,627.734497 753.341492,630.250793 752.104492,632.598145 
	C745.714661,644.723877 739.242737,656.806335 732.523560,669.064819 
	C731.476196,670.473755 730.704712,671.721863 729.687805,673.032227 
	C729.332031,673.389954 729.221741,673.685303 728.989258,674.125122 
	C728.867126,674.269592 728.882690,674.647583 728.626709,674.779236 
	C728.226807,675.255676 728.083008,675.600464 727.957153,675.975952 
	C727.975159,676.006653 727.923706,675.954163 727.633789,676.085449 
	C726.844971,677.099854 726.346069,677.982971 725.859314,678.911011 
	C725.871521,678.955872 725.908997,678.870728 725.651672,678.937500 
	C725.246765,679.315674 725.099243,679.626953 724.718140,680.028198 
	C724.300964,680.389709 724.117371,680.661316 723.622925,681.061646 
	C721.812073,683.404236 720.312012,685.618225 718.800903,687.843262 
	C718.789795,687.854431 718.764832,687.828796 718.475891,687.993591 
	C712.117920,694.750549 706.048889,701.342773 699.979858,707.934937 
	C698.375610,709.333557 696.771362,710.732178 694.730469,712.484131 
	C693.849792,713.198853 693.405884,713.560242 692.961914,713.921570 
	C692.961914,713.921570 692.960632,713.961548 692.687073,714.031006 
	C692.112061,714.503906 691.810547,714.907410 691.509094,715.310852 
	C691.509094,715.310852 691.361023,715.422180 691.046509,715.595154 
	C690.139465,716.484009 689.546997,717.199890 688.954590,717.915771 
	C688.954590,717.915771 688.993835,717.961792 688.703125,718.009888 
	C688.226929,718.341553 688.041443,718.625183 687.855957,718.908875 
	C687.856018,718.908875 687.954224,718.883606 687.572998,718.891968 
	C685.719727,720.232727 684.247681,721.565125 682.775696,722.897461 
	C682.775696,722.897461 682.354919,722.900818 681.908691,722.989380 
	C681.299683,723.368958 681.136902,723.660095 680.974182,723.951233 
	C680.974182,723.951233 680.987244,723.981873 680.748474,724.074951 
	C680.221375,724.356445 680.055115,724.621460 680.010986,724.963074 
	C680.010986,724.963074 679.977905,724.972778 679.712769,725.037476 
	C679.255981,725.374451 679.064331,725.646851 678.872681,725.919189 
	C678.872681,725.919189 678.951660,725.955017 678.632446,725.964722 
	C678.032043,726.281189 677.750732,726.588013 677.469421,726.894775 
	C677.019165,727.083313 676.568970,727.271912 675.758850,727.752319 
	C675.217224,728.331726 675.035461,728.619263 674.853699,728.906860 
	C674.853699,728.906860 674.955994,728.881348 674.566895,728.922607 
	C661.681152,736.269348 649.184448,743.574890 636.687805,750.880493 
	C630.478577,753.552002 624.347534,756.428772 618.036865,758.834290 
	C612.317078,761.014587 606.401489,762.681213 600.286011,764.793213 
	C594.859192,765.722778 589.718567,766.433411 583.809937,767.352539 
	C572.738647,768.722412 562.457520,770.184875 552.124756,770.945679 
	C544.464722,771.509827 536.737549,771.162048 528.587708,771.132629 
	C522.754150,770.660522 517.373169,770.276184 511.861328,769.795898 
	C511.730438,769.700012 511.452545,769.867676 511.140778,769.570557 
	C506.604919,768.772095 502.380829,768.270691 498.118073,767.807983 
	C498.079407,767.846558 498.188660,767.850098 497.927551,767.551025 
	C494.454956,766.814819 491.243469,766.377625 488.016724,765.968506 
	C488.001434,765.996643 488.059021,765.968689 487.873901,765.646118 
	C482.116272,763.854431 476.543793,762.385376 470.971283,760.916321 
	C470.971283,760.916321 470.709320,760.722900 470.333374,760.494873 
	C465.670868,758.791199 461.384338,757.315552 457.097778,755.839966 
	C457.097778,755.840027 457.029236,755.936890 456.853668,755.642090 
	C451.780975,753.286255 446.883850,751.225220 441.793457,749.008545 
	C441.600220,748.852905 441.105652,748.811890 441.078003,748.845581 
	C441.050385,748.879333 441.134491,748.902527 440.988617,748.631470 
	C440.256683,748.196350 439.670654,748.032288 439.080688,747.912903 
	C439.076782,747.957581 439.157593,747.918457 439.052307,747.687378 
	C438.636749,747.372620 438.326447,747.289001 437.828247,747.083130 
	C437.640320,746.960815 437.192535,746.938354 437.035706,746.656799 
	C436.270813,746.187866 435.662750,746.000427 435.053650,745.873413 
	C435.052582,745.933777 435.170898,745.909424 435.070160,745.661133 
	C434.645905,745.316956 434.322357,745.221130 433.836670,745.000488 
	C433.674530,744.875732 433.265991,744.898193 433.265991,744.898193 
	C420.465363,736.222839 407.646698,727.573853 394.883728,718.843445 
	C392.987335,717.546265 391.353333,715.865417 389.150208,713.962646 
	C387.858429,712.967163 387.014404,712.371094 386.150604,711.827026 
	C386.130829,711.879089 386.240875,711.863037 386.148926,711.598145 
	C385.741791,711.149841 385.426666,710.966431 385.104858,710.832031 
	C385.098206,710.881104 385.190918,710.846375 385.059143,710.537476 
	C381.634552,707.133057 378.341736,704.037415 375.034424,700.958374 
	C375.019897,700.974915 375.056976,700.944702 374.938599,700.630737 
	C370.257080,695.215820 365.694000,690.114868 361.042297,684.690430 
	C359.665955,682.870361 358.378265,681.373718 357.057495,679.926758 
	C357.024414,679.976440 357.125275,679.912720 357.088806,679.677795 
	C356.932098,679.105957 356.694031,678.919495 356.233154,678.556274 
	C354.765289,676.464294 353.402374,674.699524 352.039459,672.934814 
	C352.039459,672.934814 352.001221,672.991089 351.986938,672.709656 
	C351.705383,672.196838 351.438141,671.965515 351.170898,671.734192 
	C351.170898,671.734192 351.149750,671.849548 351.125610,671.503418 
	C349.838440,669.389160 348.575348,667.620972 347.312256,665.852783 
	C347.083588,665.312134 346.854919,664.771423 346.291656,663.759888 
	C344.377777,660.822632 342.798462,658.356323 341.219147,655.890015 
	C341.028015,655.338135 340.836914,654.786255 340.327179,653.759155 
	C339.385498,652.347290 338.762390,651.410767 338.139313,650.474182 
	C338.139313,650.474182 338.211548,650.181274 338.082794,649.795715 
	C337.676575,649.145386 337.399109,648.880554 337.121643,648.615784 
	C337.121643,648.615784 337.184601,648.269226 337.084137,647.857361 
	C336.715485,647.209900 336.447296,646.974243 336.179108,646.738586 
	C336.179108,646.738586 336.156799,646.852478 336.135956,646.473633 
	C332.369629,637.726135 328.624115,629.357422 324.917114,620.683655 
	C324.653076,619.890991 324.350586,619.403442 324.028748,618.947876 
	C324.009430,618.979858 324.083862,618.972168 324.055176,618.595703 
	C323.392517,616.594299 322.758484,614.969421 322.164307,613.256958 
	C322.204132,613.169373 322.075958,613.025940 322.074524,612.664246 
	C321.523346,610.754517 320.973572,609.206482 320.437134,607.637695 
	C320.450470,607.616882 320.472748,607.572876 320.395844,607.215637 
	C319.919037,605.740723 319.519135,604.623047 319.161316,603.352722 
	C319.203461,603.200073 318.957703,603.000305 318.980286,602.596924 
	C318.064240,598.895752 317.125610,595.597961 316.349396,592.095764 
	C316.355255,591.593811 316.198700,591.296387 316.071472,590.645386 
	C314.702637,580.783936 313.234955,571.285583 311.932648,561.764526 
	C311.165710,556.157898 310.661255,550.515381 310.038727,544.888977 
	C310.028473,542.584839 310.018188,540.280640 310.396790,537.331421 
	C311.464142,527.799622 312.142639,518.912842 312.821106,510.026062 
	C313.273865,507.106842 313.726593,504.187622 314.422485,500.648163 
	C314.708069,499.361755 314.750458,498.695557 314.792847,498.029388 
	C315.552032,494.640961 316.311249,491.252533 317.289581,487.229675 
	C317.614258,485.729797 317.719818,484.864380 317.986938,483.650330 
	C318.857147,480.575073 319.565704,477.848450 320.211975,475.115143 
	C320.149719,475.108429 320.194092,475.225586 320.445618,475.035278 
	C320.782196,474.231476 320.867218,473.617950 321.120300,472.777405 
	C321.434540,472.019440 321.580658,471.488525 321.878021,470.661560 
	C322.086609,469.928467 322.143951,469.491425 322.406128,468.945465 
	C322.816711,468.561432 322.850891,468.266388 322.869202,467.615387 
	C323.668243,465.184662 324.311554,463.090027 325.081818,460.901917 
	C325.208801,460.808441 325.134674,460.501923 325.430634,460.268860 
	C326.026215,459.082581 326.325836,458.129395 326.818970,456.900452 
	C329.349731,451.094360 331.687012,445.563965 334.306000,439.831390 
	C342.165649,427.176422 349.743622,414.723602 357.237885,402.162720 
	C357.154205,402.054657 357.277496,402.298676 357.559540,402.125916 
	C359.272583,399.997253 360.703613,398.041321 362.092224,396.073059 
	C362.049805,396.060699 362.068115,396.147156 362.319641,396.050385 
	C362.726318,395.640930 362.881500,395.328247 363.320923,394.890747 
	C364.085114,393.886749 364.565063,393.007599 365.309814,392.045868 
	C365.796173,391.685211 366.017731,391.407135 366.173889,391.118286 
	C366.108551,391.107544 366.135071,391.237335 366.398224,391.139954 
	C366.835175,390.722260 367.008972,390.401978 367.124878,390.076691 
	C367.066986,390.071686 367.087769,390.186035 367.344666,390.083344 
	C367.778473,389.666656 367.955383,389.352661 368.367004,388.961853 
	C368.871918,388.657379 369.008026,388.366852 369.293213,387.901703 
	C370.194611,387.004669 370.812805,386.219421 371.234833,385.278015 
	C371.175842,385.217377 371.312988,385.312897 371.726807,385.242767 
	C373.763336,383.098877 375.523163,381.120605 377.203674,379.130249 
	C377.124390,379.118195 377.154205,379.275787 377.444824,379.164185 
	C378.251373,378.419800 378.767273,377.786957 379.206543,377.141235 
	C379.129913,377.128357 379.161591,377.280579 379.455505,377.172546 
	C380.200165,376.385864 380.650879,375.707153 381.401642,374.919128 
	C382.785004,373.784241 383.868317,372.758698 384.951660,371.733215 
	C397.150543,362.661835 409.349426,353.590424 421.773376,344.262512 
	C421.998444,344.005951 422.008789,343.986389 422.284882,343.973663 
	C422.779388,343.681702 422.997803,343.402466 423.148560,343.111511 
	C423.080872,343.099762 423.097748,343.236084 423.405182,343.185120 
	C424.174286,342.780670 424.635956,342.427216 425.061279,342.046051 
	C425.024933,342.018372 425.048462,342.106659 425.312134,342.037567 
	C425.749207,341.658722 425.922546,341.348969 426.056091,341.030151 
	C426.016266,341.021057 426.057648,341.091461 426.397461,341.046509 
	C427.888916,340.390015 429.040588,339.778473 430.144470,339.142700 
	C430.096680,339.118500 430.118927,339.223328 430.392670,339.141846 
	C430.830353,338.721069 430.994293,338.381805 431.371460,338.034790 
	C431.584656,338.027039 431.992340,337.901306 432.317383,337.990997 
	C433.419556,337.654358 434.196747,337.227997 435.320007,336.755737 
	C443.156860,333.172577 450.647644,329.635376 458.138428,326.098206 
	C458.138428,326.098206 458.051514,326.066986 458.385742,326.105957 
	C459.493073,325.772400 460.266174,325.399933 461.039246,325.027466 
	C461.039246,325.027466 461.009308,324.994720 461.400177,325.014221 
	C465.379059,323.713745 468.967102,322.393829 472.667175,321.137878 
	C472.779266,321.201874 472.965485,321.023163 473.289398,321.048035 
	C474.099823,320.726654 474.586304,320.380463 475.039062,320.021790 
	C475.005341,320.009338 475.036530,320.074158 475.427429,320.059875 
	C477.911682,319.371246 480.005005,318.696899 482.327759,318.009583 
	C482.557159,317.996643 483.006165,317.898865 483.363190,318.007385 
	C484.616669,317.778961 485.513092,317.442047 486.555481,317.183105 
	C486.701385,317.261017 486.968292,317.065430 487.347839,317.128784 
	C488.887451,316.817169 490.047485,316.442169 491.610596,316.109741 
	C510.377228,312.560211 528.899231,310.727448 547.624207,311.109161 
	C550.101196,311.159668 552.580383,311.097656 555.494263,311.056396 
	C557.966736,310.964081 560.003540,310.903137 562.375916,311.030701 
	C568.683533,312.312134 574.727539,313.112488 580.613281,314.557190 
	C594.109131,317.869812 607.527893,321.496796 620.977905,324.996155 
	C620.977905,324.996155 620.995789,325.017181 621.239868,325.272858 
	C624.646362,326.688477 627.808777,327.848419 630.971191,329.008362 
	C630.971191,329.008362 630.990662,329.012238 631.185852,329.279724 
	C632.582520,329.981842 633.783997,330.416504 634.985474,330.851166 
M522.042297,615.044678 
	C522.042297,615.044678 522.056763,614.999146 522.542114,614.890137 
	C522.854675,614.700134 523.012085,614.422180 523.031738,614.031616 
	C523.031738,614.031616 523.056885,614.014771 523.543274,613.902405 
	C523.850769,613.709351 524.011230,613.431396 524.647644,612.806458 
	C527.524231,609.238159 530.400879,605.669861 533.859436,601.782288 
	C549.679138,580.021545 552.214111,555.245544 550.713989,529.299561 
	C537.196594,529.299561 524.116089,529.299561 510.099884,529.140320 
	C493.888702,529.107422 477.677460,529.052979 461.466370,529.081360 
	C460.119720,529.083740 458.774048,529.646179 457.188690,530.796265 
	C457.286621,532.254333 457.384552,533.712463 457.271790,536.052673 
	C457.271790,545.341675 457.271790,554.630676 457.271790,563.841125 
	C474.535614,563.841125 490.773254,563.841125 507.720001,564.049683 
	C508.456909,564.097229 509.193817,564.144775 509.869385,564.892761 
	C508.938568,567.270996 508.007782,569.649170 506.618378,572.462463 
	C506.046783,573.572449 505.475220,574.682495 504.336884,576.119873 
	C503.841827,577.040588 503.346771,577.961365 502.901031,578.966797 
	C502.901031,578.966797 502.918701,578.870361 502.398956,579.006897 
	C502.257477,579.321960 502.115967,579.637085 501.976288,579.981201 
	C501.976288,579.981201 501.943176,579.976501 501.344116,580.273743 
	C496.537170,584.518127 491.730225,588.762512 486.227814,593.097961 
	C481.117340,595.375427 476.006866,597.652893 470.078918,599.984070 
	C466.384155,600.672119 462.689362,601.360168 458.398865,601.872803 
	C457.985107,601.873413 457.571350,601.874084 456.701477,601.150513 
	C452.134460,600.795410 447.567474,600.440369 442.576691,599.989929 
	C442.576691,599.989929 442.142670,599.972900 441.816559,599.318237 
	C439.272736,598.518372 436.728912,597.718506 433.803284,596.314697 
	C429.060181,593.505432 424.317078,590.696167 419.047821,587.175293 
	C416.351837,587.481445 413.655884,587.787598 410.460358,587.755615 
	C409.065491,588.100159 407.670654,588.444641 406.345886,588.641846 
	C406.345886,588.641846 406.196136,588.576965 405.274536,588.598572 
	C394.468475,590.388245 383.662415,592.177917 372.209412,594.074707 
	C379.522827,604.502930 387.046997,613.429749 396.899323,620.090454 
	C396.899323,620.090454 396.780609,620.104492 396.990234,620.624573 
	C397.318085,620.780579 397.645935,620.936523 397.979187,621.017090 
	C397.979187,621.017090 397.910400,621.048523 398.200073,621.617859 
	C399.110901,622.146057 400.021759,622.674194 401.262329,623.781616 
	C403.806183,625.251404 406.350037,626.721191 408.910400,628.096985 
	C408.910400,628.096985 408.818390,628.121948 409.156250,628.650330 
	C409.741089,628.859680 410.325928,629.069031 410.911896,629.129822 
	C410.911896,629.129822 410.771210,629.177429 411.109192,629.708435 
	C411.704620,629.905701 412.300079,630.103027 412.904419,630.125122 
	C412.904419,630.125122 412.733643,630.165344 412.974792,630.660339 
	C413.320190,630.732239 413.665558,630.804138 414.317871,631.122742 
	C414.317871,631.122742 414.710144,631.088257 415.052460,631.659790 
	C415.688232,631.823486 416.324005,631.987244 417.363403,632.733276 
	C420.868103,633.872375 424.372833,635.011475 427.910400,636.096130 
	C427.910400,636.096130 427.847687,636.101135 428.325928,636.688721 
	C431.216187,637.398987 434.106415,638.109192 437.848145,638.983459 
	C443.886169,639.299683 449.924164,639.615845 456.685944,640.076294 
	C462.797760,639.655823 468.909546,639.235413 475.802521,638.898254 
	C479.530396,637.869629 483.258240,636.840942 487.730286,635.872498 
	C493.889587,632.955444 500.048889,630.038391 506.880310,627.039001 
	C508.887970,625.607056 510.895630,624.175110 513.541382,622.591675 
	C516.011719,620.407959 518.482056,618.224243 521.040039,616.037292 
	C521.040039,616.037292 521.053833,615.950684 521.525818,615.851746 
	C521.842712,615.681702 521.999512,615.416870 522.042297,615.044678 
M424.552155,490.997040 
	C424.678955,490.881927 424.805725,490.766815 425.559479,490.461273 
	C427.747833,488.982452 429.936188,487.503662 432.564056,485.989044 
	C432.564056,485.989044 432.979767,485.840851 433.604034,485.983429 
	C434.129608,485.702209 434.655182,485.421021 435.081726,485.107422 
	C435.081726,485.107422 435.091522,485.211151 435.841339,485.115234 
	C437.445831,484.454071 439.050293,483.792908 441.052490,483.464539 
	C441.356781,483.321930 441.661041,483.179291 442.810425,483.100739 
	C446.219940,482.636932 449.629456,482.173126 453.955292,481.829010 
	C458.278564,481.996796 462.601807,482.164581 467.418701,482.883484 
	C468.895691,483.062103 470.372711,483.240692 472.388214,483.892334 
	C476.883026,485.629089 481.377838,487.365845 486.266754,489.652618 
	C491.805450,494.872559 497.344147,500.092499 502.898407,505.735413 
	C502.898407,505.735413 503.184113,506.047729 503.190796,506.518982 
	C503.348480,506.825836 503.598999,506.996399 503.984619,507.029266 
	C503.984619,507.029266 503.963348,507.065857 504.006866,507.810730 
	C505.501801,510.739807 507.632446,510.641998 510.006470,509.102936 
	C510.006470,509.102936 510.070892,509.118835 510.706329,509.125061 
	C511.106018,508.991150 511.505707,508.857208 512.620972,508.815674 
	C514.424927,508.187286 516.228821,507.558899 518.683105,506.999908 
	C519.464905,506.682037 520.246643,506.364136 521.531311,506.085632 
	C521.681213,506.009338 521.831055,505.933075 522.751709,505.896332 
	C530.151489,503.751648 537.551270,501.606934 545.835938,499.205780 
	C541.637573,492.312744 537.940918,486.243408 534.653442,480.127716 
	C534.653442,480.127716 535.006470,479.915649 535.938232,480.020721 
	C565.934448,479.943817 595.930603,479.866913 626.603149,479.995911 
	C627.068787,479.949463 627.534485,479.903015 628.924500,480.015900 
	C634.967163,479.959930 641.009766,479.903931 647.936035,480.002655 
	C657.303894,479.399048 665.539673,481.776520 671.903320,489.014740 
	C671.903320,489.014740 671.877258,489.104492 671.940735,489.840881 
	C672.954468,492.074432 674.316345,494.217651 674.923401,496.556763 
	C678.065002,508.661743 672.410889,518.273315 658.064270,523.939758 
	C650.053955,524.172424 642.043579,524.405090 633.134827,524.369934 
	C629.417725,524.472778 625.700623,524.575623 621.501343,524.419250 
	C621.339844,524.504150 621.178345,524.589111 620.356140,524.383850 
	C619.898193,524.485596 619.440247,524.587280 618.089905,524.409851 
	C599.619568,524.487061 581.149170,524.564331 562.618652,524.435303 
	C562.618652,524.435303 562.503967,524.704895 562.096375,525.601624 
	C562.096375,563.690796 562.096375,601.779968 562.096375,639.942505 
	C577.487488,639.942505 592.293152,639.942505 607.994995,640.150208 
	C618.138733,640.154541 628.283325,640.240112 638.426025,640.140930 
	C649.218689,640.035400 660.009644,639.754822 671.494934,639.703308 
	C672.602844,639.451660 673.710815,639.199951 675.588684,639.059021 
	C677.020203,638.718018 678.451721,638.377014 680.693298,638.135437 
	C684.116699,637.040771 687.540161,635.946045 691.562378,634.905396 
	C692.051514,634.594604 692.540588,634.283752 693.575439,633.959534 
	C693.781555,633.696045 693.987610,633.432495 694.145935,633.252869 
	C694.145935,633.252869 694.236511,633.286316 694.693909,633.308777 
	C694.693909,633.308777 695.019897,632.987122 695.722290,632.948730 
	C701.177856,628.671936 706.633362,624.395203 712.082825,620.084167 
	C712.082825,620.084167 712.052124,620.073547 712.574097,619.961182 
	C712.741333,619.660583 712.908569,619.359985 713.683105,618.767944 
	C720.631836,610.650696 724.151428,601.079468 724.671692,590.573303 
	C725.395081,575.966370 721.639832,562.740173 710.828064,551.464844 
	C710.075806,550.794739 709.323608,550.124573 708.195740,548.902344 
	C703.255005,545.470642 698.314270,542.038879 693.427612,538.644775 
	C710.199524,526.546692 718.421570,508.514740 715.749939,487.634460 
	C713.835449,472.671478 706.301331,460.907745 693.049744,452.405212 
	C692.713745,452.338959 692.377808,452.272675 691.573242,452.032043 
	C691.408936,452.004456 691.244568,451.976868 690.866089,451.337524 
	C690.164124,450.903900 689.462158,450.470306 688.408813,449.529663 
	C687.630554,449.294891 686.852295,449.060120 686.017273,448.932190 
	C686.017273,448.932190 686.119690,448.867767 685.727417,448.284790 
	C680.120361,446.821045 674.513367,445.357300 668.556946,443.288147 
	C667.392517,443.172028 666.228088,443.055939 665.041321,442.966125 
	C665.041321,442.966125 665.017395,442.941132 664.336731,442.484711 
	C643.907654,442.400024 623.478516,442.315338 602.117554,442.074738 
	C580.430725,442.116882 558.743896,442.159027 536.121765,442.061890 
	C514.793762,442.039734 493.465759,441.998138 472.137878,442.032196 
	C470.432922,442.034943 468.728821,442.559906 466.145844,442.958160 
	C464.081451,443.024170 462.017029,443.090149 459.032379,443.014221 
	C453.983887,442.934418 448.926147,442.649384 443.891479,442.876190 
	C441.890289,442.966309 439.940216,444.190216 437.234894,444.801239 
	C436.499084,444.855286 435.763245,444.909332 434.631561,444.399170 
	C430.775330,445.539398 426.919128,446.679596 422.284851,447.901154 
	C416.866730,450.587555 411.448608,453.273956 405.417633,456.029144 
	C405.146393,456.322327 404.875153,456.615540 404.247589,456.857513 
	C404.247589,456.857513 404.020569,457.136902 403.387024,457.228241 
	C402.750275,457.778717 402.113495,458.329163 400.981628,458.935516 
	C400.651672,459.276672 400.321747,459.617828 399.359039,460.097198 
	C398.214172,461.051605 397.069336,462.006012 395.987122,462.993683 
	C395.987122,462.993683 395.975311,462.923767 395.442108,463.052399 
	C395.250854,463.353455 395.059631,463.654510 394.950073,463.962006 
	C394.950073,463.962006 394.931366,463.882263 394.289246,464.097961 
	C385.487854,471.102539 378.141876,479.354950 371.875824,489.378815 
	C383.432404,491.135590 394.184784,492.770081 405.667053,494.931305 
	C409.784058,495.254852 413.901062,495.578430 418.652344,495.816284 
	C419.562836,494.916107 420.473297,494.015930 421.912964,493.060669 
	C422.274353,492.705048 422.635712,492.349426 423.520386,491.887512 
	C423.720245,491.605804 423.920105,491.324097 424.040741,491.033722 
	C424.040741,491.033722 424.051575,491.112671 424.552155,490.997040 
M360.924225,568.535400 
	C363.518311,578.382385 363.518372,578.382690 373.382446,576.627258 
	C374.691986,576.394287 375.999695,576.151001 377.310120,575.923035 
	C386.737122,574.283264 396.164581,572.646301 405.853882,570.962891 
	C396.661285,551.279785 396.834564,531.956604 405.415955,512.258789 
	C391.961639,510.135040 379.174835,508.166534 366.409729,506.066284 
	C363.258514,505.547791 362.832886,507.718719 362.186340,509.761688 
	C356.115417,528.943726 355.565887,548.271851 360.924225,568.535400 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M324.878632,620.988708 
	C328.624115,629.357422 332.369629,637.726135 336.189514,646.451294 
	C334.534821,645.398621 332.805695,643.989502 329.724213,641.478210 
	C330.484711,644.727295 330.997223,646.832336 331.465363,648.947144 
	C331.765808,650.304443 332.107361,651.673584 332.140320,653.044373 
	C332.144806,653.231201 329.858765,653.874573 329.665009,653.593994 
	C328.889252,652.470764 328.423920,651.094788 328.031891,649.757141 
	C327.728546,648.722107 327.705170,647.604980 327.557312,646.524353 
	C327.020294,646.450378 326.483246,646.376404 325.946198,646.302368 
	C325.747162,648.056824 325.063599,649.921204 325.432373,651.546814 
	C327.088074,658.845215 329.068909,666.069458 330.849792,673.340637 
	C331.028503,674.070251 330.822784,675.014709 330.501831,675.727112 
	C327.819153,681.682556 328.703705,683.670227 334.943207,686.070862 
	C335.775024,686.390991 336.284058,687.550049 336.534729,688.666748 
	C325.872925,684.076599 318.634430,688.008362 317.436768,699.063354 
	C317.389526,700.087158 317.655304,701.042419 318.052673,702.257568 
	C318.395264,703.020264 318.606323,703.523010 318.578186,704.293945 
	C317.816864,706.815613 317.302002,709.064941 321.005737,709.265869 
	C321.134003,709.920898 321.114960,710.316345 321.171570,710.990967 
	C321.527649,711.523743 321.808136,711.777466 322.371338,712.286499 
	C323.110291,713.951416 323.962646,715.371033 323.927612,716.768433 
	C323.878418,718.733276 323.785492,721.613098 322.589600,722.402649 
	C321.073120,723.403809 317.607880,723.612549 316.437988,722.554443 
	C313.505890,719.902161 311.393433,716.343628 308.964386,712.799438 
	C308.668976,711.631348 308.361389,710.812439 307.934387,709.893555 
	C307.815002,709.793579 307.891235,709.491638 307.915955,709.088074 
	C307.656067,707.457947 307.371399,706.231384 306.991333,704.826111 
	C306.895905,704.647400 306.915802,704.242798 306.943726,703.935791 
	C306.989624,703.424072 307.007629,703.219360 307.025635,703.014648 
	C307.518433,702.582336 308.352600,702.217285 308.452972,701.707703 
	C310.024506,693.725403 312.551483,685.756165 312.794312,677.721558 
	C313.412537,657.268738 313.058411,636.784363 312.919769,616.313782 
	C312.903961,613.978699 311.705750,611.667786 311.203186,609.314209 
	C310.875641,607.780029 310.834808,606.186340 310.630371,604.623901 
	C310.218445,601.475952 309.783325,598.330933 309.355835,595.184998 
	C309.244934,594.368896 308.857574,593.479736 309.057861,592.750610 
	C310.034515,589.195251 311.167358,585.682800 312.669769,582.475708 
	C314.077118,585.530457 315.059631,588.264648 316.042145,590.998901 
	C316.198700,591.296387 316.355255,591.593811 316.298798,592.553284 
	C317.043091,596.476929 318.000397,599.738647 318.957703,603.000305 
	C318.957703,603.000305 319.203461,603.200073 319.116882,603.741638 
	C319.157990,605.534607 316.671783,607.932068 320.472748,607.572876 
	C320.472748,607.572876 320.450470,607.616882 320.308228,608.027039 
	C320.802643,609.966797 321.439301,611.496338 322.075958,613.025940 
	C322.075958,613.025940 322.204132,613.169373 322.139862,613.681335 
	C322.745026,615.786255 323.414459,617.379211 324.083862,618.972168 
	C324.083862,618.972168 324.009430,618.979858 324.034851,619.244263 
	C324.333069,620.002014 324.605835,620.495361 324.878632,620.988708 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M321.726776,470.957611 
	C321.580658,471.488525 321.434540,472.019440 320.872437,472.973999 
	C320.369019,474.006927 320.281555,474.616272 320.194092,475.225586 
	C320.194092,475.225586 320.149719,475.108429 319.857452,475.211060 
	C318.260498,477.582550 316.885681,479.817963 315.762604,482.173431 
	C315.653564,482.402100 317.100616,483.372742 317.825348,483.998962 
	C317.719818,484.864380 317.614258,485.729797 317.273071,486.829895 
	C313.916382,485.614532 313.614807,483.247070 314.378601,480.150696 
	C315.430145,475.887817 316.034760,471.644806 311.507294,468.518433 
	C310.769135,468.008728 310.921478,465.759613 311.150330,464.396423 
	C311.577789,461.850128 312.883972,459.370178 312.914124,456.851746 
	C313.089447,442.203247 313.099243,427.550415 312.933960,412.901672 
	C312.889648,408.976013 312.022522,405.061279 311.569763,401.137329 
	C311.460693,400.192200 311.079071,398.857330 311.539642,398.340149 
	C313.599976,396.026886 312.283936,394.363434 311.225922,392.140198 
	C309.805420,389.155304 307.762695,385.370819 313.732422,383.877991 
	C314.117737,383.833252 314.118988,383.783417 314.187897,384.045227 
	C314.533661,384.524628 314.810486,384.742188 315.049316,384.973816 
	C315.011322,384.987885 315.052704,384.918213 315.089996,385.283661 
	C316.847992,387.825195 318.680725,389.295624 321.535309,386.592621 
	C322.599823,386.729218 323.302307,386.881989 324.175690,387.275848 
	C324.902222,387.683411 325.457886,387.849915 326.049316,388.363159 
	C326.882599,389.862335 327.680176,391.014801 328.748901,392.559052 
	C327.366516,392.732666 326.211761,392.673279 325.224609,393.051392 
	C323.871918,393.569519 322.646454,394.419708 321.366486,395.127625 
	C322.705109,395.534393 324.022614,396.053497 325.389404,396.310699 
	C326.396820,396.500305 327.468658,396.347931 327.601013,396.347931 
	C327.033020,400.648956 326.205353,404.544525 326.069214,408.464142 
	C325.821747,415.589813 326.844208,422.870728 325.703735,429.818817 
	C324.801880,435.313507 326.447876,438.575134 329.683197,441.356201 
	C331.445923,440.819153 332.735107,440.426361 334.024292,440.033569 
	C331.687012,445.563965 329.349731,451.094360 326.469727,456.877869 
	C325.662872,458.254608 325.398773,459.378265 325.134674,460.501923 
	C325.134674,460.501923 325.208801,460.808441 324.693115,460.916107 
	C320.327148,462.622467 322.033875,465.395508 322.713440,467.951477 
	C322.850891,468.266388 322.816711,468.561432 322.158691,469.034454 
	C321.713196,469.807434 321.720001,470.382538 321.726776,470.957611 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M324.004822,387.034760 
	C323.302307,386.881989 322.599823,386.729218 321.265076,386.355835 
	C318.772766,385.729553 316.912750,385.323883 315.052704,384.918213 
	C315.052704,384.918213 315.011322,384.987885 314.971008,384.711731 
	C314.660095,384.218201 314.389557,384.000824 314.118988,383.783417 
	C314.118988,383.783417 314.117737,383.833252 314.132385,383.809692 
	C312.258331,382.373016 310.369598,380.959869 308.335999,378.945587 
	C307.511261,377.498230 306.831421,376.652008 306.151581,375.805756 
	C306.151581,375.805756 306.095337,375.917847 306.070984,375.519531 
	C302.927185,369.349548 302.920013,363.233032 304.037750,356.991852 
	C307.728851,349.247589 313.285522,343.921967 322.611725,342.884766 
	C323.173004,342.837646 323.338623,342.876160 324.175659,342.934235 
	C326.842621,343.049042 328.838196,343.144257 330.848694,343.179504 
	C330.863556,343.119507 330.761719,343.189545 330.906616,343.464417 
	C331.178528,347.574646 329.969604,349.535675 325.493286,349.168335 
	C318.499573,348.594360 313.165527,352.257111 310.631439,358.525360 
	C308.207642,364.520905 310.030975,371.663208 315.135864,376.169342 
	C320.273468,380.704407 327.611450,381.714752 333.186920,378.654694 
	C341.579651,374.048401 343.716217,364.008423 337.589203,356.564453 
	C334.538910,360.018066 331.522400,363.433411 328.404205,366.963898 
	C326.951813,365.753845 325.696411,364.707886 324.380310,363.611328 
	C328.746582,358.319885 332.956635,353.217804 337.116577,348.098480 
	C337.066498,348.081238 337.111267,348.177246 337.410950,348.141571 
	C338.111969,348.118652 338.513245,348.131409 338.937012,348.098022 
	C338.959503,348.051849 338.864899,348.091766 338.990570,348.389099 
	C340.391754,349.820129 341.667236,350.953857 342.961609,352.051605 
	C342.980499,352.015594 342.902100,352.037262 342.976074,352.303375 
	C343.313660,352.803894 343.577271,353.038300 343.846497,353.208954 
	C343.852081,353.145203 343.735199,353.197327 343.837769,353.543884 
	C345.286072,358.260803 346.631805,362.631226 347.803253,367.337769 
	C345.877136,375.835297 341.999786,382.382263 333.497437,385.775635 
	C330.056183,386.224396 327.030518,386.629578 324.004822,387.034760 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M318.817352,704.025818 
	C318.606323,703.523010 318.395264,703.020264 318.117859,701.883179 
	C317.950958,700.497559 317.850372,699.746216 317.749786,698.994812 
	C318.634430,688.008362 325.872925,684.076599 336.513336,689.059692 
	C336.899750,689.106506 336.927948,689.060852 337.041687,689.374146 
	C339.069977,691.555176 340.984497,693.422913 342.899017,695.290710 
	C344.119232,698.320618 345.541931,701.287292 346.511108,704.395508 
	C347.892517,708.825562 347.794006,712.900024 342.365509,714.617004 
	C341.988373,713.722839 341.555695,712.696960 341.155823,710.990479 
	C340.812134,709.191895 340.435730,708.074097 340.059296,706.956238 
	C340.057465,706.519775 340.055603,706.083374 339.978088,705.328125 
	C337.684814,701.488037 335.820679,697.648254 333.075195,694.602905 
	C331.981567,693.389771 328.127899,692.956604 326.978149,693.911438 
	C325.343262,695.268982 324.058563,698.392334 324.392548,700.478149 
	C324.968781,704.076355 327.043610,707.409912 328.240509,710.939087 
	C330.125671,716.497559 332.568665,722.177368 327.016479,727.609314 
	C323.555969,728.583496 320.250488,729.242859 316.436951,729.676880 
	C315.620209,729.322693 315.311554,729.193848 315.002899,729.065063 
	C315.002899,729.065063 314.754456,728.818970 314.435455,728.581787 
	C313.743561,728.233032 313.370636,728.121460 312.997742,728.009949 
	C312.605408,727.668274 312.213074,727.326599 311.437500,726.683289 
	C310.706757,726.301758 310.359283,726.221924 310.011780,726.142090 
	C309.705200,725.928528 309.398590,725.715027 309.006042,724.980286 
	C308.633514,724.267700 308.346924,724.076294 308.060333,723.884888 
	C308.060333,723.884888 308.034607,723.963867 307.999207,723.693359 
	C307.668854,723.228149 307.373901,723.033447 307.078979,722.838745 
	C307.078979,722.838745 307.064545,722.934509 307.020172,722.674316 
	C306.664032,722.251038 306.352234,722.088074 306.040466,721.925049 
	C306.040466,721.925049 306.007935,721.992188 305.964539,721.708740 
	C305.643341,721.197693 305.365509,720.970154 305.087708,720.742554 
	C304.788361,720.018250 304.489014,719.294006 304.088013,717.908813 
	C303.041016,714.476257 302.095642,711.704529 301.150269,708.932861 
	C301.168213,708.523865 301.186157,708.114807 301.510315,707.271606 
	C302.889099,705.898071 303.961670,704.958679 305.034241,704.019287 
	C305.034241,704.019287 305.005890,703.995239 305.330383,704.017456 
	C306.075165,704.107422 306.495483,704.175110 306.915802,704.242798 
	C306.915802,704.242798 306.895905,704.647400 306.925293,705.218018 
	C307.266876,707.023010 307.579071,708.257324 307.891235,709.491638 
	C307.891235,709.491638 307.815002,709.793579 307.916718,710.248596 
	C308.329681,711.518616 308.640961,712.333618 308.952209,713.148621 
	C311.393433,716.343628 313.505890,719.902161 316.437988,722.554443 
	C317.607880,723.612549 321.073120,723.403809 322.589600,722.402649 
	C323.785492,721.613098 323.878418,718.733276 323.927612,716.768433 
	C323.962646,715.371033 323.110291,713.951416 322.339233,711.986084 
	C321.714966,711.190979 321.405457,710.951416 321.095947,710.711853 
	C321.114960,710.316345 321.134003,709.920898 320.983673,708.921814 
	C320.148651,706.887451 319.483002,705.456604 318.817352,704.025818 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M316.071472,590.645386 
	C315.059631,588.264648 314.077118,585.530457 313.044556,582.399292 
	C312.733368,581.506958 312.472229,581.011414 312.273071,580.166870 
	C312.223358,579.542725 312.111664,579.267700 312.000000,578.992676 
	C311.337921,567.941895 310.675873,556.891052 310.026245,545.364624 
	C310.661255,550.515381 311.165710,556.157898 311.932648,561.764526 
	C313.234955,571.285583 314.702637,580.783936 316.071472,590.645386 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M312.571106,510.299377 
	C312.142639,518.912842 311.464142,527.799622 310.418060,536.900391 
	C310.807312,528.267090 311.564178,519.419922 312.571106,510.299377 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M303.738922,357.212128 
	C302.920013,363.233032 302.927185,369.349548 306.126862,375.491089 
	C301.462738,372.918549 300.071503,369.317688 301.628510,364.471313 
	C302.368286,362.168671 302.844452,359.781342 303.738922,357.212128 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M300.851929,709.164246 
	C302.095642,711.704529 303.041016,714.476257 303.947174,717.623047 
	C301.123596,715.780396 299.115967,713.259705 300.851929,709.164246 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M304.653198,704.020630 
	C303.961670,704.958679 302.889099,705.898071 301.534485,706.964600 
	C300.867798,706.447937 300.483124,705.804138 300.098450,705.160400 
	C301.489685,704.780945 302.880920,704.401489 304.653198,704.020630 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M310.252441,478.837555 
	C310.863312,478.130005 311.552185,477.761627 312.241089,477.393250 
	C312.390289,477.960358 312.539490,478.527435 312.688690,479.094482 
	C311.902588,479.121918 311.116516,479.149323 310.252441,478.837555 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M306.111328,376.180420 
	C306.831421,376.652008 307.511261,377.498230 308.142303,378.674591 
	C307.419373,378.188141 306.745209,377.371613 306.111328,376.180420 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M296.335388,750.969849 
	C296.028046,750.566833 296.015350,750.119202 296.001312,749.335815 
	C296.517609,749.229431 297.035217,749.458862 297.552826,749.688293 
	C297.245239,750.100647 296.937653,750.512939 296.335388,750.969849 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M306.943726,703.935791 
	C306.495483,704.175110 306.075165,704.107422 305.335205,704.035034 
	C305.567719,703.537537 306.119873,703.044739 306.848816,702.783325 
	C307.007629,703.219360 306.989624,703.424072 306.943726,703.935791 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M314.565857,498.272827 
	C314.750458,498.695557 314.708069,499.361755 314.409149,500.253296 
	C314.214722,499.824493 314.276825,499.170380 314.565857,498.272827 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M312.000031,579.243286 
	C312.111664,579.267700 312.223358,579.542725 312.168091,579.906433 
	C312.000793,579.828064 312.000427,579.660950 312.000031,579.243286 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M305.163910,721.008667 
	C305.365509,720.970154 305.643341,721.197693 305.998657,721.693604 
	C305.797485,721.732910 305.518829,721.503845 305.163910,721.008667 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M306.151520,722.164795 
	C306.352234,722.088074 306.664032,722.251038 307.068024,722.666992 
	C306.861023,722.748108 306.561798,722.576294 306.151520,722.164795 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M307.166565,723.092896 
	C307.373901,723.033447 307.668854,723.228149 308.039948,723.685425 
	C307.828796,723.747742 307.541473,723.547424 307.166565,723.092896 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M308.123840,724.111694 
	C308.346924,724.076294 308.633514,724.267700 308.884338,724.722961 
	C308.549164,724.851257 308.328766,724.635193 308.123840,724.111694 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M310.172455,726.346863 
	C310.359283,726.221924 310.706757,726.301758 311.176636,726.639526 
	C310.977081,726.782166 310.655121,726.666931 310.172455,726.346863 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M313.166626,728.245850 
	C313.370636,728.121460 313.743561,728.233032 314.262512,728.607300 
	C314.050873,728.740662 313.693176,728.611206 313.166626,728.245850 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M315.128784,729.277222 
	C315.311554,729.193848 315.620209,729.322693 316.015259,729.709290 
	C315.765717,729.902893 315.483368,729.743652 315.128784,729.277222 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M821.387573,551.994873 
	C818.366882,550.887085 815.738708,549.774170 813.445251,548.440735 
	C818.223328,551.141052 822.579590,550.828796 826.921387,547.920654 
	C826.978149,547.973145 827.095093,548.074341 827.095093,548.074341 
	C826.992004,551.644592 824.440857,551.891602 821.387573,551.994873 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M828.856995,537.148499 
	C829.335876,539.538025 829.814758,541.927490 830.151611,544.657288 
	C829.571960,545.649231 829.134460,546.300903 828.328613,546.963989 
	C827.960388,546.975342 827.842224,546.863159 827.936523,546.473877 
	C828.306213,543.105835 828.581604,540.127197 828.856995,537.148499 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M828.016541,547.034424 
	C827.930542,547.377869 827.735779,547.620056 827.291748,547.947144 
	C827.095093,548.074341 826.978149,547.973145 827.027588,547.703613 
	C827.270874,547.161743 827.525940,546.971436 827.842224,546.863159 
	C827.842224,546.863159 827.960388,546.975342 828.016541,547.034424 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M827.936523,546.473877 
	C827.525940,546.971436 827.270874,547.161743 826.970825,547.651123 
	C822.579590,550.828796 818.223328,551.141052 813.434753,548.072021 
	C813.089478,547.923889 813.073547,547.957886 812.997437,547.578491 
	C812.261780,544.140747 811.602234,541.082275 810.942688,538.023865 
	C810.942688,538.023865 811.090393,537.631836 811.352051,537.239502 
	C811.814697,536.282898 812.015808,535.718689 812.216858,535.154480 
	C812.216858,535.154480 812.181519,535.221680 812.555542,535.124146 
	C819.669067,530.306274 822.271729,530.643738 828.887756,537.096069 
	C828.887756,537.096069 828.886902,537.125916 828.871948,537.137207 
	C828.581604,540.127197 828.306213,543.105835 827.936523,546.473877 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M828.922302,536.717041 
	C822.271729,530.643738 819.669067,530.306274 812.593262,535.119263 
	C812.420288,534.352173 812.583679,533.492493 813.099121,532.213684 
	C813.721313,531.596497 813.909546,531.338684 814.015930,531.021118 
	C814.015930,531.021118 814.506836,531.015259 814.752197,531.008850 
	C818.571411,531.300293 822.240356,531.220032 825.672180,532.082825 
	C827.037415,532.426147 827.877625,534.857422 828.922302,536.717041 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M810.662964,538.303467 
	C811.602234,541.082275 812.261780,544.140747 813.001099,547.566895 
	C807.484985,546.172302 810.677124,541.874817 810.662964,538.303467 
z"/>
<path fill="#1187F9" opacity="1.000000" stroke="none" 
	d="
M805.702271,723.031860 
	C805.826965,723.557495 805.659241,724.113159 805.245728,724.835571 
	C805.136536,724.355530 805.273132,723.708679 805.702271,723.031860 
z"/>
<path fill="#0E93FA" opacity="1.000000" stroke="none" 
	d="
M813.860474,530.796997 
	C813.909546,531.338684 813.721313,531.596497 813.235229,531.904419 
	C812.844177,531.526978 812.669250,531.039612 812.494263,530.552246 
	C812.897888,530.559143 813.301453,530.566040 813.860474,530.796997 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M811.908569,535.269409 
	C812.015808,535.718689 811.814697,536.282898 811.366211,537.030518 
	C811.279297,536.604004 811.439819,535.994202 811.908569,535.269409 
z"/>
<path fill="#128AF8" opacity="1.000000" stroke="none" 
	d="
M252.754227,538.993652 
	C252.888596,538.579041 253.022964,538.164490 253.442841,537.250549 
	C254.210556,535.877136 254.692749,535.003052 255.174942,534.128967 
	C255.174957,534.128967 255.200409,534.127258 255.208740,534.145813 
	C258.671753,533.616089 262.317627,532.109741 265.534698,532.751831 
	C269.973022,533.637573 272.066254,538.584045 271.085938,543.007385 
	C269.946930,548.147034 266.361176,550.629211 260.186951,550.408447 
	C258.549805,550.071716 257.203064,549.677551 255.610809,548.737183 
	C254.596069,547.118896 253.826813,546.046814 253.057556,544.974731 
	C252.951660,543.907288 252.845764,542.839844 252.751404,541.070740 
	C252.760040,539.910583 252.757126,539.452148 252.754227,538.993652 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M252.780075,545.186279 
	C253.826813,546.046814 254.596069,547.118896 255.417938,548.518799 
	C253.066635,548.914368 251.561996,548.208191 252.780075,545.186279 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M254.826950,534.112122 
	C254.692749,535.003052 254.210556,535.877136 253.477997,536.924683 
	C253.644730,536.097168 254.061844,535.096252 254.826950,534.112122 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M252.494263,539.036621 
	C252.757126,539.452148 252.760040,539.910583 252.626953,540.688232 
	C252.405396,540.364746 252.319839,539.722168 252.494263,539.036621 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M745.435791,435.100250 
	C744.976379,434.341339 744.517029,433.582428 743.981567,432.497009 
	C744.059998,430.779541 744.875732,428.696808 744.261719,428.109894 
	C740.259705,424.284760 740.671997,419.704407 740.882324,414.805603 
	C741.101318,409.705444 740.071289,404.556976 740.203247,399.445526 
	C740.271057,396.818542 741.680298,394.226196 742.567505,391.344879 
	C744.811401,391.138580 747.085571,390.929443 750.046326,390.571594 
	C753.791748,389.931244 756.850586,389.439606 759.909424,388.947937 
	C759.390747,389.913483 758.879578,390.883209 758.351257,391.843536 
	C757.733826,392.966156 756.349854,394.854828 756.586365,395.084381 
	C761.457520,399.813049 756.483032,404.693146 756.786621,409.477142 
	C757.467773,420.210052 757.152588,431.019592 756.889954,441.789337 
	C756.826294,444.398804 755.393188,446.974854 753.978882,449.806152 
	C753.154053,450.073334 752.940063,450.100677 752.726074,450.128021 
	C752.452637,449.665466 752.179199,449.202881 751.918335,448.139160 
	C751.599976,447.047638 751.269043,446.557281 750.938110,446.066925 
	C750.306824,444.932220 749.675476,443.797516 749.267090,442.156555 
	C748.785522,440.767059 748.080872,439.883820 747.376282,439.000610 
	C747.280090,438.843933 747.183899,438.687256 747.297852,438.078979 
	C747.021729,437.092499 746.535461,436.557587 746.049133,436.022705 
	C746.049133,436.022705 745.958740,435.986786 745.913513,435.787476 
	C745.766052,435.388428 745.621887,435.225769 745.435791,435.100250 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M759.922791,388.956238 
	C756.850586,389.439606 753.791748,389.931244 750.372681,390.345032 
	C746.212708,386.958496 742.412964,383.649780 738.292542,379.843384 
	C737.694824,379.056366 737.417786,378.767029 737.140808,378.477692 
	C737.140808,378.477692 737.222473,378.196014 737.118408,377.795258 
	C736.701477,376.906799 736.388489,376.419098 736.075500,375.931396 
	C736.075500,375.931396 736.018860,376.010315 736.054688,375.574585 
	C735.585754,372.472626 735.081055,369.806427 734.576294,367.140198 
	C734.687073,366.974670 734.797913,366.809143 735.239136,366.282715 
	C735.730103,365.639496 735.890686,365.357178 736.051270,365.074890 
	C736.051270,365.074890 736.016479,365.017242 736.299255,364.975464 
	C736.757080,364.654297 736.932129,364.374908 737.107178,364.095520 
	C737.107178,364.095520 737.111023,364.121155 737.423645,364.069946 
	C738.474609,363.703094 739.212952,363.387451 739.951294,363.071777 
	C739.951294,363.071777 740.006836,363.022125 739.974426,363.441895 
	C740.921021,367.267273 741.900024,370.672913 742.879028,374.078552 
	C742.879089,374.078522 742.852417,374.085327 742.844482,374.433472 
	C743.524475,375.877441 744.212341,376.973236 744.900208,378.069031 
	C744.900208,378.069031 744.973755,377.998352 744.981323,378.305573 
	C745.276306,378.802979 745.563721,378.993134 745.851196,379.183319 
	C745.851196,379.183319 745.892212,379.048920 745.903748,379.446045 
	C748.398743,383.083099 751.557556,385.441467 755.587036,383.390289 
	C759.249451,381.526031 758.517456,378.046661 757.080933,374.841553 
	C757.080933,374.841553 757.061096,374.390015 756.965088,373.870819 
	C756.262146,372.233185 755.655151,371.114746 755.048218,369.996338 
	C755.048218,369.996307 755.024048,369.981598 755.021973,369.675598 
	C754.697083,368.914948 754.374390,368.460358 754.051636,368.005737 
	C754.051636,368.005737 754.041077,367.973175 754.029785,367.663391 
	C753.702393,366.890564 753.386414,366.427551 753.070435,365.964539 
	C753.070435,365.964539 753.003906,365.989136 753.032959,365.659729 
	C752.729187,364.864899 752.396423,364.399506 752.063599,363.934113 
	C752.034912,363.767426 752.006226,363.600769 751.905273,362.955322 
	C751.653442,362.175995 751.390869,361.998108 751.045227,361.942871 
	C751.045227,361.942871 750.978394,361.450775 751.031006,360.797974 
	C751.395996,357.440918 751.708435,354.736633 752.020874,352.032379 
	C752.020874,352.032379 752.002869,352.007080 752.293091,351.855042 
	C753.737000,350.491425 754.890625,349.279877 756.044250,348.068329 
	C756.044250,348.068329 755.997559,348.008118 756.339111,348.012817 
	C757.209961,347.714905 757.739319,347.412292 758.268677,347.109680 
	C758.268677,347.109680 758.667175,347.119568 758.829773,347.004395 
	C763.075073,348.206146 767.157776,349.523010 771.572449,350.965332 
	C772.087646,351.364380 772.270874,351.638000 772.698975,351.983856 
	C773.113770,352.336914 773.283691,352.617798 773.692505,352.982300 
	C774.087402,353.357117 774.243286,353.648346 774.636658,354.030426 
	C775.364258,355.006836 775.854431,355.892334 776.619080,356.934692 
	C776.998169,357.406128 777.102783,357.720703 777.399048,358.198914 
	C777.798340,358.878815 778.006042,359.395050 778.416870,360.103210 
	C779.363098,362.922638 780.106201,365.550201 780.877075,368.551514 
	C779.354187,370.086761 777.803528,371.248230 776.973206,371.870148 
	C773.682251,366.387054 770.962646,360.913574 767.271545,356.201050 
	C765.921448,354.477448 761.861328,353.365601 759.749817,354.080017 
	C755.955505,355.363739 756.725342,359.053497 758.125610,362.106873 
	C759.572571,365.261719 761.218750,368.325195 762.962097,372.020020 
	C763.395203,372.893250 763.644043,373.175079 763.892883,373.456909 
	C763.892883,373.456909 763.850342,373.770599 763.938965,374.243713 
	C764.294250,375.630859 764.560913,376.544861 764.827637,377.458832 
	C764.827637,377.458832 764.685974,377.711792 764.722290,378.215027 
	C764.431580,380.750458 764.104431,382.782593 763.777283,384.814758 
	C763.777283,384.814758 763.815979,384.891663 763.582642,384.923218 
	C763.228516,385.284943 763.107849,385.615082 762.987183,385.945221 
	C762.987122,385.945221 762.962646,385.968201 762.730652,386.060883 
	C762.213440,386.346680 762.067200,386.615387 762.059875,386.959717 
	C761.762573,387.154388 761.465271,387.349121 760.816284,387.844940 
	C760.150452,388.321777 759.979858,388.589142 759.952698,388.948090 
	C759.952698,388.948090 759.936096,388.964539 759.922791,388.956238 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M716.413513,356.753906 
	C709.135193,351.257507 702.187988,345.720612 694.980713,339.976440 
	C701.148376,331.900726 707.154968,324.035828 713.273560,315.867584 
	C713.385559,315.564240 713.361572,315.361267 713.591675,315.257721 
	C714.058777,314.804718 714.295837,314.455231 714.532837,314.105774 
	C715.307617,313.224243 716.082336,312.342712 717.194458,311.049561 
	C717.811584,310.126465 718.091309,309.614960 718.371094,309.103394 
	C719.289062,307.945770 720.207092,306.788177 721.295837,305.415375 
	C723.224731,306.977600 724.606445,308.096619 726.464233,309.601227 
	C718.913635,319.447479 711.490234,329.127869 703.739197,339.235504 
	C709.628479,343.784332 715.313416,348.175323 721.619629,353.046204 
	C719.752136,354.450989 718.248352,355.582184 716.413513,356.753906 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M734.382690,367.453491 
	C735.081055,369.806427 735.585754,372.472626 736.092163,375.543274 
	C735.458862,373.220703 734.824036,370.493744 734.382690,367.453491 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M739.969604,362.811401 
	C739.212952,363.387451 738.474609,363.703094 737.436584,364.071320 
	C737.853394,363.421570 738.569885,362.719299 739.648071,362.023163 
	C740.002563,362.203186 739.995300,362.377075 739.969604,362.811401 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M736.069214,376.237305 
	C736.388489,376.419098 736.701477,376.906799 737.009216,377.697388 
	C736.690369,377.514618 736.376648,377.028931 736.069214,376.237305 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M718.070740,309.189453 
	C718.091309,309.614960 717.811584,310.126465 717.267334,310.762451 
	C717.258728,310.349792 717.514587,309.812622 718.070740,309.189453 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M737.182617,378.786713 
	C737.417786,378.767029 737.694824,379.056366 738.021240,379.649231 
	C737.788513,379.667114 737.506470,379.381439 737.182617,378.786713 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M736.868286,364.170990 
	C736.932129,364.374908 736.757080,364.654297 736.328430,364.992310 
	C736.259705,364.782806 736.444519,364.514648 736.868286,364.170990 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M735.822083,365.166199 
	C735.890686,365.357178 735.730103,365.639496 735.328369,365.997559 
	C735.133972,365.725891 735.302612,365.453949 735.822083,365.166199 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M714.216858,314.174469 
	C714.295837,314.455231 714.058777,314.804718 713.505859,315.202209 
	C713.427002,314.914551 713.663940,314.578857 714.216858,314.174469 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M682.547363,251.062531 
	C683.072327,251.382919 683.267151,251.625244 683.733032,251.930038 
	C683.956665,252.479233 683.975586,252.912567 683.997314,253.671326 
	C680.089478,255.149490 679.353516,257.560242 680.506592,261.349121 
	C681.199646,263.626526 681.075439,266.225555 680.951416,268.661407 
	C680.770264,272.222961 680.988647,275.315247 686.024475,275.511963 
	C685.931641,276.037384 685.838867,276.562805 685.746033,277.088226 
	C683.779724,277.392029 681.813477,277.695801 679.307434,277.734314 
	C678.179260,276.977356 677.590881,276.485626 676.996094,275.649109 
	C676.629150,274.698944 676.268555,274.093628 675.907959,273.488312 
	C675.605286,274.317322 675.302612,275.146332 674.789978,276.070221 
	C674.312744,276.336823 674.116882,276.568787 673.786255,276.937073 
	C673.580139,277.013092 673.142761,277.056488 672.739502,277.048035 
	C671.576721,278.713989 670.817200,280.388367 670.047180,282.053894 
	C670.036682,282.045044 670.066345,282.069702 670.066345,282.069702 
	C667.044006,286.333740 664.059082,290.625122 660.976501,294.845154 
	C660.047058,296.117462 658.846436,297.191589 657.540894,298.604492 
	C654.144165,293.934448 657.879333,292.436890 660.268066,289.883575 
	C656.941101,287.409180 653.660095,284.810059 655.006165,280.102661 
	C655.266663,279.191833 655.299805,278.055206 655.876343,277.421783 
	C659.488770,273.452789 659.344910,272.255341 654.593262,269.398956 
	C653.912109,268.989532 653.159119,268.699554 652.766724,268.511353 
	C654.870972,264.441345 656.912415,260.492920 659.152893,256.159485 
	C654.858521,254.906250 653.750916,251.904877 655.577820,248.342438 
	C657.921265,243.772842 662.256714,243.099411 666.978027,245.275208 
	C671.879822,247.534164 677.080750,249.144089 682.547363,251.062531 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M679.039917,278.961670 
	C681.210022,284.029266 678.839905,288.032562 676.050537,291.978821 
	C673.450684,295.656982 669.333374,299.212830 675.496826,304.157349 
	C671.776550,306.749481 668.437866,309.075714 665.099121,311.401978 
	C665.332825,311.971100 665.566467,312.540192 665.800110,313.109314 
	C667.492065,312.457062 669.139343,311.580292 670.891602,311.231903 
	C672.051636,311.001282 673.362366,311.528351 674.605591,311.716248 
	C673.389465,317.099731 678.981079,317.995392 680.937012,321.674500 
	C680.952087,322.051270 680.862976,322.140350 680.508667,322.089355 
	C674.311768,319.128113 668.469116,316.217865 662.414551,313.202087 
	C664.762268,308.538605 666.827820,304.592133 668.752502,300.578125 
	C672.170898,293.448761 675.503967,286.278473 678.914429,279.084290 
	C678.956116,279.043304 679.039917,278.961670 679.039917,278.961670 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M680.908813,357.726074 
	C678.317200,356.310150 675.722656,354.536987 672.975769,352.465607 
	C677.147400,351.368591 679.204956,354.091705 680.908813,357.726074 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M680.997803,322.007874 
	C681.356812,323.489838 682.394043,325.464386 681.797668,326.425293 
	C681.073975,327.591583 678.971741,327.902405 677.213867,328.324463 
	C676.961914,328.065552 676.995911,328.008453 677.380249,328.001801 
	C678.797424,326.043549 679.830200,324.091949 680.862976,322.140350 
	C680.862976,322.140350 680.952087,322.051270 680.997803,322.007874 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M673.992432,276.861023 
	C674.116882,276.568787 674.312744,276.336823 675.123657,276.078369 
	C676.112366,275.992401 676.557434,275.993164 677.002441,275.993896 
	C677.590881,276.485626 678.179260,276.977356 678.893921,277.733826 
	C679.020081,277.998596 679.020386,278.480591 679.030151,278.721130 
	C679.039917,278.961670 678.956116,279.043304 678.576843,279.040222 
	C676.795837,278.311768 675.394165,277.586395 673.992432,276.861023 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M684.888062,360.853790 
	C683.975891,360.661530 683.111267,360.140533 682.130676,359.336182 
	C682.988342,359.543579 683.961914,360.034332 684.888062,360.853790 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M689.840576,364.848572 
	C688.977173,364.664673 688.128662,364.118500 687.149780,363.291809 
	C687.964783,363.502991 688.910034,363.994629 689.840576,364.848572 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M698.788940,372.918701 
	C698.190613,372.769104 697.531189,372.299469 696.791077,371.530518 
	C697.382874,371.687042 698.055359,372.142853 698.788940,372.918701 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M696.732788,371.015564 
	C696.293823,371.058807 695.802490,370.804413 695.213501,370.283508 
	C695.637329,370.250610 696.158936,370.484253 696.732788,371.015564 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M681.843994,358.773010 
	C681.643677,358.851044 681.342712,358.706970 680.981323,358.311035 
	C681.258728,358.116699 681.532898,358.280609 681.843994,358.773010 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M690.829834,365.847046 
	C690.608398,365.947418 690.283447,365.802094 689.881226,365.390930 
	C690.111389,365.283783 690.418884,365.442566 690.829834,365.847046 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M685.840698,361.881470 
	C685.623657,361.965027 685.307190,361.796844 684.908203,361.364563 
	C685.130920,361.276886 685.436096,361.453339 685.840698,361.881470 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M699.808105,374.075073 
	C699.560913,374.144958 699.242615,373.922211 698.850464,373.411224 
	C699.096802,373.342834 699.416870,373.562653 699.808105,374.075073 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M694.863037,369.687317 
	C694.628540,369.886353 694.377869,369.756012 694.145142,369.270874 
	C694.371094,369.122040 694.598999,369.269318 694.863037,369.687317 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M692.842041,367.799744 
	C692.647400,367.866760 692.362305,367.709167 692.014038,367.306335 
	C692.286072,367.126007 692.552979,367.297333 692.842041,367.799744 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M691.843018,366.819763 
	C691.632507,366.899139 691.327087,366.745636 690.966125,366.330505 
	C691.189758,366.241547 691.468872,366.414246 691.843018,366.819763 
z"/>
<path fill="#282CEC" opacity="1.000000" stroke="none" 
	d="
M573.958862,860.650696 
	C570.182007,861.464722 566.371338,861.927002 562.265015,862.660522 
	C552.972534,862.937805 543.975647,862.943848 534.450928,862.581787 
	C532.288086,860.403137 529.961060,858.844177 529.189575,856.720154 
	C528.174316,853.925110 528.382263,850.685852 528.045715,847.459900 
	C529.700195,847.045410 531.962402,846.676941 534.040894,845.847839 
	C534.745728,845.566589 534.986450,844.122070 535.439209,843.209106 
	C534.590027,843.043030 533.470093,842.445068 532.935181,842.780396 
	C529.215698,845.111816 528.862000,842.428772 527.850708,839.404358 
	C536.925415,840.338318 533.814697,830.784607 538.580261,828.527771 
	C537.584473,826.456177 536.346191,824.893738 536.222107,823.247253 
	C536.145935,822.236755 537.665222,820.288269 538.657837,820.152893 
	C543.613770,819.477173 548.632507,819.262268 552.927917,818.938171 
	C554.160461,824.109314 555.341919,829.065857 556.645508,834.340454 
	C558.691040,838.431641 560.879150,837.081421 563.399597,834.818054 
	C563.896362,834.392761 564.278015,834.134521 564.276489,833.878418 
	C564.204590,821.452332 564.170654,809.024780 563.907715,796.602234 
	C563.875183,795.063721 562.604919,793.551331 561.945679,792.022949 
	C561.983215,792.019043 561.953369,792.088318 561.898438,791.700745 
	C559.360046,789.794922 556.851746,788.100464 555.136963,792.172180 
	C555.105103,792.089355 555.281189,792.067078 554.916504,792.293091 
	C554.368958,797.109924 554.037292,801.701355 554.058472,806.291260 
	C554.071289,809.080750 553.073425,810.256592 550.290283,810.009399 
	C548.977722,809.892822 547.646057,809.991333 546.323059,809.992065 
	C534.159546,809.998413 534.234558,809.996460 533.913513,797.674011 
	C533.872925,796.114868 532.553589,794.589050 531.622681,793.031921 
	C531.418701,793.016052 531.046204,792.846497 530.987305,792.497437 
	C529.626282,790.248962 527.437561,788.453552 527.200073,786.429321 
	C526.644409,781.691589 527.024353,776.844055 527.350647,772.032227 
	C528.109314,772.031738 528.542786,772.039978 529.052246,772.408508 
	C533.194885,773.092285 537.275513,773.805298 541.325684,773.661194 
	C550.888245,773.320862 560.436279,772.572571 569.990234,771.989258 
	C569.524902,774.150024 568.223999,776.333496 571.227173,777.999512 
	C571.981506,778.417908 572.106018,780.462830 572.047668,781.738647 
	C571.961914,783.613159 571.451477,785.468140 571.069397,787.628235 
	C569.391968,787.705627 567.365051,787.799133 565.338135,787.892639 
	C569.105896,790.624573 569.233826,793.422791 568.613647,797.325439 
	C568.137756,800.320557 569.898499,803.624451 570.380371,806.833679 
	C570.744507,809.258728 571.241272,811.894775 570.674988,814.189087 
	C568.098450,824.627869 569.006287,834.993774 570.638184,845.396912 
	C570.817261,846.538452 570.998230,847.700623 570.975525,848.849243 
	C570.891479,853.096924 571.991272,857.521362 573.958862,860.650696 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M600.066040,784.049927 
	C606.175964,784.114685 611.995605,784.127625 617.648010,787.751587 
	C630.816162,796.193970 630.996765,817.009216 617.724121,824.921204 
	C614.720398,826.711670 610.971436,827.252136 607.249634,828.570068 
	C606.302734,828.724243 605.671753,828.676392 604.432251,828.510986 
	C602.564758,828.295959 601.305847,828.198425 600.046936,828.100952 
	C596.103882,825.565308 592.160828,823.029724 588.063904,820.395142 
	C591.553955,816.537048 593.811707,816.431091 597.182251,819.230896 
	C603.737793,824.676270 613.910278,822.675964 618.659973,815.634399 
	C623.654785,808.229431 621.181458,797.005798 613.539734,792.400574 
	C606.190125,787.971191 596.121033,790.437134 592.504028,798.128113 
	C590.715759,801.930603 588.685730,803.022461 584.720520,801.554810 
	C585.822083,794.704346 589.616882,789.826904 596.142517,786.198975 
	C597.799194,785.300659 598.917542,784.689575 600.035950,784.078552 
	C600.035950,784.078552 600.048706,784.048584 600.066040,784.049927 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M570.367432,771.921631 
	C560.436279,772.572571 550.888245,773.320862 541.325684,773.661194 
	C537.275513,773.805298 533.194885,773.092285 529.066528,772.201172 
	C529.004883,771.633484 529.040283,771.220398 529.040283,771.220398 
	C536.737549,771.162048 544.464722,771.509827 552.124756,770.945679 
	C562.457520,770.184875 572.738647,768.722412 583.484375,767.525635 
	C581.806824,768.326843 579.718811,769.259888 577.558228,769.973328 
	C575.323669,770.711182 573.018738,771.235962 570.367432,771.921631 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M599.662231,784.055664 
	C598.917542,784.689575 597.799194,785.300659 596.347290,785.924072 
	C597.105347,785.301880 598.196960,784.667358 599.662231,784.055664 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M600.317322,828.368164 
	C601.305847,828.198425 602.564758,828.295959 604.135010,828.626221 
	C603.160156,828.784424 601.873962,828.709961 600.317322,828.368164 
z"/>
<path fill="#2832F1" opacity="1.000000" stroke="none" 
	d="
M562.040405,310.842194 
	C560.003540,310.903137 557.966736,310.964081 555.220947,310.750305 
	C551.678711,310.286530 548.845520,310.097473 546.017273,309.549988 
	C547.222046,307.863922 548.421875,306.536316 549.621643,305.208710 
	C549.189636,305.091248 548.757629,304.973785 548.325623,304.856323 
	C548.911621,302.183868 549.388672,299.480713 550.128723,296.851624 
	C550.509827,295.497528 551.358948,294.275116 552.209595,292.707642 
	C553.606140,290.946198 555.769043,289.496613 555.809570,287.989990 
	C556.022766,280.064758 555.672302,272.125458 555.573364,264.190369 
	C555.515808,259.576141 555.562866,254.960632 555.562866,250.345718 
	C556.082092,250.234451 556.601318,250.123184 557.120544,250.011917 
	C555.712158,248.682861 554.303711,247.353790 552.926758,246.037170 
	C552.958130,246.049606 552.908264,246.095383 552.908264,246.095383 
	C552.333679,245.219070 551.481140,243.505402 551.225586,243.590225 
	C546.250549,245.241699 546.728027,241.256378 546.058960,238.631500 
	C545.781799,237.544205 546.035828,236.328873 545.989197,235.174164 
	C545.777100,229.928207 548.259705,224.156830 543.039307,219.511261 
	C545.076904,219.107986 547.106689,219.074295 549.573486,219.037262 
	C550.951599,219.339600 551.878967,219.856277 552.835938,219.918777 
	C564.207581,220.661652 575.583679,221.336823 587.414062,222.055481 
	C586.754089,228.509262 585.638672,234.937332 584.530151,241.325470 
	C585.614136,242.650406 586.764648,244.056641 587.915161,245.462891 
	C586.428894,246.535385 584.942627,247.607864 583.287476,249.412216 
	C580.599792,263.923187 578.080933,277.702301 575.512451,291.752625 
	C582.222839,293.237610 588.616211,294.652435 595.009583,296.067291 
	C595.606445,297.621124 596.203247,299.174988 596.800110,300.728821 
	C595.503723,301.153351 594.196228,301.547241 592.914185,302.011444 
	C591.746643,302.434235 590.606812,302.933411 589.454712,303.398865 
	C590.198853,304.545593 590.821411,305.800842 591.720886,306.808990 
	C592.643677,307.843201 593.837219,308.635834 594.914001,309.535645 
	C592.657349,310.947601 590.839111,312.085266 588.572510,313.616150 
	C580.816895,312.751190 573.519409,311.429901 566.193298,310.293060 
	C564.869263,310.087616 563.427734,310.639801 562.040405,310.842194 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M595.447266,296.022522 
	C588.616211,294.652435 582.222839,293.237610 575.512451,291.752625 
	C578.080933,277.702301 580.599792,263.923187 583.147705,249.753983 
	C589.869385,250.547546 596.618713,251.480591 603.240845,252.976318 
	C612.165161,254.992020 615.218567,261.245880 611.861938,270.314423 
	C611.793823,270.707886 611.804688,270.765198 611.575806,270.867615 
	C611.209106,271.292786 611.071289,271.615540 610.933472,271.938293 
	C610.933472,271.938293 610.997559,272.001221 610.715088,272.034302 
	C610.264099,272.357849 610.095520,272.648315 609.926880,272.938782 
	C609.926880,272.938782 609.921570,272.955811 609.607056,273.019806 
	C608.356567,273.648743 607.420593,274.213684 606.425293,274.814484 
	C613.686462,282.425659 612.157349,290.155762 605.728516,294.898285 
	C602.447327,295.258087 599.166138,295.617920 595.447266,296.022522 
M600.995239,277.932312 
	C600.995239,277.932312 601.074158,277.937317 600.651001,277.306488 
	C595.670715,276.258942 590.690430,275.211395 585.185181,274.053436 
	C584.713989,277.280212 584.292480,280.166443 583.759766,283.255035 
	C583.759766,283.255035 583.924194,283.417175 583.327454,283.991547 
	C583.852844,285.253510 584.155823,287.434723 584.940674,287.624542 
	C589.252563,288.667725 593.662231,289.618805 598.076965,289.843658 
	C603.271606,290.108276 605.612122,286.269653 604.076843,280.991486 
	C604.076843,280.991486 604.130371,280.923950 604.069031,280.247620 
	C603.068542,279.465179 602.068115,278.682739 600.995239,277.932312 
M592.004272,256.794159 
	C590.164307,256.107025 588.444275,255.474518 588.014954,258.449249 
	C587.526001,261.836609 586.828308,265.193848 586.169495,268.852448 
	C590.650879,269.654449 594.705078,270.568665 598.809753,271.065460 
	C603.617065,271.647278 606.446167,269.664948 606.914734,265.814453 
	C607.484985,261.128418 604.675293,259.167480 600.683655,258.311371 
	C598.083679,257.753723 595.439758,257.400970 592.004272,256.794159 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M643.140381,266.449646 
	C645.270996,266.052490 647.388611,265.866760 650.759644,265.571106 
	C645.007812,280.001160 639.831909,292.986450 634.541199,306.325195 
	C632.347534,306.181793 630.268616,305.684998 628.308167,305.216461 
	C631.068054,296.931519 633.550659,289.478882 636.418701,281.591919 
	C637.201294,280.437500 637.598511,279.717407 637.995728,278.997345 
	C637.995728,278.997345 638.230591,278.815033 638.474304,278.436157 
	C639.155151,276.728027 639.592285,275.398773 640.029358,274.069519 
	C640.029358,274.069519 640.057678,274.038757 640.324829,273.810272 
	C641.067749,272.073975 641.543579,270.566162 642.019348,269.058350 
	C642.019348,269.058350 642.043335,269.031708 642.300049,268.864380 
	C642.673279,268.138855 642.789856,267.580688 642.906433,267.022491 
	C642.906433,267.022491 643.127380,266.661041 643.140381,266.449646 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M562.375916,311.030701 
	C563.427734,310.639801 564.869263,310.087616 566.193298,310.293060 
	C573.519409,311.429901 580.816895,312.751190 588.557190,314.008911 
	C598.553650,316.935699 608.122803,319.844238 617.672241,322.816193 
	C618.727539,323.144623 619.670044,323.835449 620.821411,324.677185 
	C607.527893,321.496796 594.109131,317.869812 580.613281,314.557190 
	C574.727539,313.112488 568.683533,312.312134 562.375916,311.030701 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M606.149414,294.926544 
	C612.157349,290.155762 613.686462,282.425659 606.425293,274.814484 
	C607.420593,274.213684 608.356567,273.648743 609.599670,273.006470 
	C610.937134,277.958740 612.113586,282.963989 612.955383,288.024902 
	C613.758362,292.852539 611.416687,295.147766 606.149414,294.926544 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M630.872192,328.745789 
	C627.808777,327.848419 624.646362,326.688477 621.253540,325.271057 
	C623.755493,325.649292 626.487854,326.284973 629.594604,326.962494 
	C630.237061,327.497284 630.505188,327.990265 630.872192,328.745789 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M641.646912,269.108185 
	C641.543579,270.566162 641.067749,272.073975 640.326416,273.789429 
	C640.465454,272.384064 640.869934,270.771057 641.646912,269.108185 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M639.640503,274.124390 
	C639.592285,275.398773 639.155151,276.728027 638.442261,278.290985 
	C638.528259,277.076233 638.890015,275.627747 639.640503,274.124390 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M637.655151,279.048950 
	C637.598511,279.717407 637.201294,280.437500 636.454651,281.209412 
	C636.508301,280.541016 636.911438,279.820801 637.655151,279.048950 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M642.677124,267.195068 
	C642.789856,267.580688 642.673279,268.138855 642.304871,268.847107 
	C642.184570,268.453979 642.316162,267.910797 642.677124,267.195068 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M610.159119,272.845123 
	C610.095520,272.648315 610.264099,272.357849 610.685059,272.001770 
	C610.755432,272.207947 610.573425,272.479706 610.159119,272.845123 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M611.172241,271.827454 
	C611.071289,271.615540 611.209106,271.292786 611.601624,270.881165 
	C611.707825,271.100403 611.559387,271.408508 611.172241,271.827454 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M700.366333,707.957397 
	C706.048889,701.342773 712.117920,694.750549 718.547852,688.395935 
	C720.081238,690.294189 721.253723,691.954895 722.745178,694.067444 
	C722.408569,694.434998 721.505005,695.126465 720.989136,696.037415 
	C718.995239,699.558350 714.695923,701.689758 714.982361,706.609253 
	C715.442627,714.514343 715.624756,722.435303 716.041199,730.343445 
	C716.122925,731.894226 716.225769,734.012451 717.196472,734.842102 
	C720.215088,737.422058 718.426392,739.000488 716.617493,740.926270 
	C715.195740,742.440002 713.981812,744.148926 712.082703,746.507507 
	C715.505554,746.076294 717.474670,745.828247 719.444397,745.580139 
	C717.188538,749.016418 715.513672,751.567749 713.549927,753.951050 
	C708.708862,747.920410 704.156616,742.057800 699.604370,736.195190 
	C700.973511,734.081970 702.342712,731.968811 704.173523,729.143066 
	C702.393188,728.776978 700.983887,728.487244 699.458069,728.173462 
	C699.012085,725.594482 698.525818,722.782715 698.269104,721.298157 
	C700.330139,716.816223 701.988647,713.209717 703.989380,708.859009 
	C702.917664,708.567932 701.835205,708.273865 700.366333,707.957397 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M637.053589,750.939331 
	C649.184448,743.574890 661.681152,736.269348 674.518005,728.902954 
	C671.817566,731.946167 668.777039,735.050354 665.548584,738.346375 
	C667.137329,738.869385 668.977966,739.475403 670.818542,740.081360 
	C670.957764,740.604370 671.096985,741.127441 671.236145,741.650513 
	C668.773560,743.142761 666.310913,744.635010 663.298523,746.460388 
	C664.053223,749.040222 665.021179,752.349121 666.276489,756.640259 
	C667.229675,756.774902 669.239197,757.058716 671.011230,757.308960 
	C671.360535,761.049683 671.690125,764.579834 671.980408,768.575623 
	C671.941101,769.041321 671.839722,769.128845 671.552002,768.932129 
	C669.559875,765.465698 667.855530,762.195984 666.090881,758.810730 
	C663.824158,759.952209 662.092224,760.885620 660.310120,761.710754 
	C654.230408,764.525757 648.132996,767.302673 642.042358,770.094116 
	C642.515625,768.270691 642.988953,766.447327 643.803284,763.310120 
	C641.706543,764.824402 640.445007,765.735474 638.918457,766.837952 
	C634.945679,765.120483 641.173096,757.771057 633.799194,757.048645 
	C634.864868,756.420105 635.877625,755.662048 637.011047,755.199158 
	C638.165039,754.727783 639.437744,754.547119 640.658508,754.239258 
	C639.578796,753.158875 638.499084,752.078491 637.053589,750.939331 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M699.275513,736.183167 
	C704.156616,742.057800 708.708862,747.920410 713.565674,753.945862 
	C713.870239,754.108704 713.901062,754.113403 713.978760,754.430603 
	C715.326721,756.204224 716.596985,757.660583 717.928345,759.108521 
	C717.989380,759.100037 717.867493,759.089233 717.921021,759.358765 
	C718.258423,759.821350 718.542236,760.014526 718.792969,760.204224 
	C718.759827,760.200745 718.756958,760.267273 718.825500,760.581909 
	C719.905701,762.168091 720.917358,763.439636 722.152222,764.991638 
	C723.317871,763.894836 724.236450,763.030457 725.123596,762.093567 
	C725.092224,762.020996 725.111023,762.177917 725.385254,762.123596 
	C725.851257,761.771179 726.043152,761.473083 726.208618,761.211182 
	C726.182190,761.247314 726.267822,761.273621 726.577332,761.202759 
	C727.978333,760.138672 729.069824,759.145447 730.134583,758.095825 
	C730.107788,758.039368 730.102295,758.164246 730.354004,758.077515 
	C730.748657,757.670898 730.891663,757.351074 731.017334,757.015625 
	C731.000000,757.000000 731.033142,757.034729 731.277222,756.994385 
	C731.521301,756.954102 732.000183,756.829712 732.000183,756.829712 
	C733.529358,757.985229 735.058533,759.140747 736.673950,760.606445 
	C728.489197,767.496399 720.263550,774.135071 711.882324,780.571228 
	C710.971436,781.270691 708.429626,781.388000 707.918457,780.751709 
	C706.784729,779.340576 704.752991,777.268127 707.931763,775.523071 
	C708.777527,775.058838 709.366455,774.149780 710.143250,773.533020 
	C715.805176,769.037354 715.830811,768.722412 711.535034,762.980957 
	C710.157471,761.139771 708.948425,759.172424 707.363770,757.099792 
	C702.785522,751.299072 698.505737,745.661377 693.806763,739.471497 
	C695.573853,738.336853 697.260254,737.254028 699.275513,736.183167 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M671.990723,768.996338 
	C677.104126,772.751648 678.704102,778.371033 679.984985,784.585938 
	C679.952759,785.030823 679.859985,785.111511 679.589600,784.895630 
	C676.826050,779.496094 674.332886,774.312439 671.839722,769.128845 
	C671.839722,769.128845 671.941101,769.041321 671.990723,768.996338 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M683.158630,722.926392 
	C684.247681,721.565125 685.719727,720.232727 687.527344,718.869995 
	C686.422485,720.211487 684.982056,721.583435 683.158630,722.926392 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M682.044067,787.657715 
	C682.043945,788.199829 682.015198,788.388916 681.673340,788.680176 
	C680.860107,787.558838 680.360046,786.335144 679.859985,785.111511 
	C679.859985,785.111511 679.952759,785.030823 679.998413,784.989136 
	C680.701233,785.733093 681.358337,786.518799 682.044067,787.657715 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M689.343750,717.934509 
	C689.546997,717.199890 690.139465,716.484009 690.970947,715.648804 
	C690.717651,716.337341 690.225220,717.145325 689.343750,717.934509 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M693.282349,713.915955 
	C693.405884,713.560242 693.849792,713.198853 694.595459,712.772583 
	C694.465698,713.108582 694.034241,713.509460 693.282349,713.915955 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M691.813721,715.432678 
	C691.810547,714.907410 692.112061,714.503906 692.667114,714.030151 
	C692.653320,714.491333 692.385864,715.022949 691.813721,715.432678 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M679.113525,725.846680 
	C679.064331,725.646851 679.255981,725.374451 679.704773,725.052734 
	C679.759460,725.260315 679.556946,725.517212 679.113525,725.846680 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M688.102356,718.834351 
	C688.041443,718.625183 688.226929,718.341553 688.674011,718.001953 
	C688.739990,718.217285 688.544373,718.488586 688.102356,718.834351 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M681.191284,723.876343 
	C681.136902,723.660095 681.299683,723.368958 681.725586,723.092957 
	C681.878601,723.408875 681.685120,723.640015 681.191284,723.876343 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M680.226624,724.863403 
	C680.055115,724.621460 680.221375,724.356445 680.731812,724.075439 
	C680.880737,724.306885 680.710144,724.567139 680.226624,724.863403 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M677.783997,726.834717 
	C677.750732,726.588013 678.032043,726.281189 678.628174,725.921570 
	C678.661560,726.170715 678.380066,726.472717 677.783997,726.834717 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M675.102051,728.831787 
	C675.035461,728.619263 675.217224,728.331726 675.658813,727.986023 
	C675.729187,728.204163 675.539795,728.480408 675.102051,728.831787 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M361.130920,685.013916 
	C365.694000,690.114868 370.257080,695.215820 374.580139,700.643433 
	C373.054382,700.490173 371.768707,700.010315 370.745453,699.628357 
	C363.639832,706.603882 372.012543,709.163147 375.165009,713.212952 
	C374.836670,713.477539 374.508331,713.742126 374.179962,714.006714 
	C372.616577,713.583069 371.053192,713.159424 369.176880,712.651001 
	C370.363617,717.979919 371.504150,723.101257 372.334076,728.106628 
	C369.541626,725.872375 367.059753,723.754028 364.656097,721.702393 
	C359.705170,725.278564 359.870941,725.966919 363.996368,729.095947 
	C368.411316,732.444580 372.393219,736.364197 377.895599,741.206360 
	C374.956848,743.459595 372.597260,745.268799 369.964478,747.184265 
	C369.526367,747.630798 369.361389,747.971130 369.182800,748.233521 
	C369.169220,748.155701 369.320831,748.200500 369.029083,748.265869 
	C368.594116,748.680115 368.450867,749.028931 368.306335,749.260986 
	C368.305054,749.144226 368.371948,749.368042 367.991150,749.281860 
	C366.767303,750.497192 365.924286,751.798645 365.046173,753.051147 
	C365.011108,753.002319 365.101593,753.081604 364.706268,753.063599 
	C363.641418,754.135498 362.971893,755.225403 362.262543,756.258667 
	C362.222717,756.202026 362.356812,756.236816 362.058472,756.291382 
	C361.627899,756.700928 361.495636,757.055969 361.405823,757.367554 
	C361.448273,757.324158 361.411133,757.439758 361.039856,757.328979 
	C357.570892,758.043701 355.712738,759.777283 356.229248,763.268311 
	C356.216156,763.276428 356.243835,763.296265 356.243835,763.296265 
	C349.227814,761.601746 348.691833,760.679626 350.495911,753.944519 
	C351.553680,749.995483 352.476715,746.010437 353.654938,741.256409 
	C348.953186,738.464844 350.970215,732.793762 351.355438,727.733276 
	C351.656342,723.780273 352.784271,720.286133 351.403229,715.919983 
	C349.950256,711.326294 351.280334,705.845703 351.597900,700.469666 
	C351.602600,700.389832 354.149017,700.459717 356.045868,700.459717 
	C355.556488,699.465454 355.398743,698.462646 354.915771,698.268372 
	C349.710510,696.174744 348.854919,693.237732 351.854218,688.471313 
	C352.506653,687.434509 352.095032,685.760498 352.292267,684.397156 
	C352.584320,682.378723 352.982483,680.375549 353.883301,678.292603 
	C354.700104,678.420654 354.881683,678.682495 354.837128,679.214722 
	C355.507233,679.587341 356.316254,679.750061 357.125275,679.912720 
	C357.125275,679.912720 357.024414,679.976440 357.028687,680.351807 
	C356.510773,683.961182 357.790314,685.472717 361.130920,685.013916 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M370.237610,747.078003 
	C372.597260,745.268799 374.956848,743.459595 377.895599,741.206360 
	C372.393219,736.364197 368.411316,732.444580 363.996368,729.095947 
	C359.870941,725.966919 359.705170,725.278564 364.656097,721.702393 
	C367.059753,723.754028 369.541626,725.872375 372.349060,728.094482 
	C372.674591,728.198181 372.708588,728.212708 372.813446,728.469788 
	C373.244385,728.888977 373.570465,729.051086 373.905945,729.152466 
	C373.915344,729.091675 373.794128,729.112244 373.888611,729.373047 
	C374.302185,729.803162 374.621246,729.972534 374.947510,730.097168 
	C374.954681,730.052490 374.873108,730.091675 375.014404,730.395325 
	C377.044159,732.208618 378.932617,733.718323 380.846069,735.182129 
	C380.871063,735.136292 380.766724,735.141968 380.855774,735.405273 
	C381.264282,735.837830 381.583679,736.007202 381.911285,736.130249 
	C381.919464,736.083923 381.827698,736.104309 381.915833,736.363281 
	C382.319550,736.783752 382.635101,736.945129 382.961731,737.067688 
	C382.972748,737.028809 382.894653,737.049683 382.984009,737.301025 
	C383.370544,737.736145 383.667786,737.919800 384.020386,738.340088 
	C384.266083,738.878845 384.539764,739.028687 384.950623,739.355774 
	C384.314148,740.781067 383.623840,741.876648 382.692017,743.016113 
	C382.136963,743.237000 381.970764,743.505310 381.700073,743.962402 
	C381.326874,744.374573 381.205444,744.689148 380.967346,745.130798 
	C380.850708,745.257874 380.883209,745.601318 380.614014,745.734802 
	C380.162292,746.214355 379.979767,746.560425 379.854218,746.909851 
	C379.911224,746.913208 379.879242,746.803589 379.620728,746.896240 
	C379.202606,747.307922 379.042938,747.627014 378.923828,747.955444 
	C378.964417,747.964722 378.940857,747.884949 378.691711,747.973145 
	C378.282837,748.364929 378.123077,748.668579 377.981659,748.986145 
	C378.000000,749.000000 377.965454,748.968689 377.651154,749.095703 
	C374.444580,752.702576 371.552338,756.182373 368.690186,759.776245 
	C368.720337,759.890320 368.718445,759.654358 368.462585,759.755432 
	C368.037994,760.172913 367.869263,760.489258 367.685364,760.820740 
	C367.670258,760.835815 367.641479,760.802673 367.641479,760.802673 
	C363.994324,764.573669 360.347198,768.344666 356.343201,772.105103 
	C354.777740,770.985596 353.569183,769.876526 352.203552,768.623352 
	C353.565216,766.927185 354.782593,765.410767 356.121887,763.595337 
	C356.243835,763.296265 356.216156,763.276428 356.525269,763.122314 
	C358.359955,761.125305 359.885529,759.282532 361.411133,757.439758 
	C361.411133,757.439758 361.448273,757.324158 361.649170,757.248657 
	C362.018982,756.860962 362.187866,756.548889 362.356812,756.236816 
	C362.356812,756.236816 362.222717,756.202026 362.538025,756.115723 
	C363.602722,755.046753 364.352142,754.064148 365.101562,753.081604 
	C365.101593,753.081604 365.011108,753.002319 365.346008,752.937988 
	C366.577942,751.705139 367.474945,750.536560 368.371948,749.368042 
	C368.371948,749.368042 368.305054,749.144226 368.557251,749.148071 
	C368.979919,748.834778 369.150391,748.517639 369.320862,748.200500 
	C369.320831,748.200500 369.169220,748.155701 369.445068,748.118652 
	C369.893158,747.747009 370.065369,747.412476 370.237610,747.078003 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M340.939789,656.082397 
	C342.798462,658.356323 344.377777,660.822632 346.025757,663.629883 
	C342.084778,662.957581 340.371643,660.322937 340.939789,656.082397 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M342.927979,694.882080 
	C340.984497,693.422913 339.069977,691.555176 337.017517,689.384644 
	C340.451813,689.135864 343.286316,690.021484 342.927979,694.882080 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M347.026611,666.026001 
	C348.575348,667.620972 349.838440,669.389160 351.179810,671.481079 
	C347.867004,671.455566 346.695068,669.318115 347.026611,666.026001 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M354.976013,679.004883 
	C354.881683,678.682495 354.700104,678.420654 354.214386,678.110840 
	C353.340271,676.550537 352.682983,675.098633 352.032593,673.290771 
	C353.402374,674.699524 354.765289,676.464294 355.892975,678.590698 
	C355.430511,678.969788 355.203278,678.987305 354.976013,679.004883 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M339.766785,707.101196 
	C340.435730,708.074097 340.812134,709.191895 341.037964,710.660278 
	C338.523499,710.466370 336.941162,709.628601 339.766785,707.101196 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M338.107666,650.877747 
	C338.762390,651.410767 339.385498,652.347290 340.065796,653.622314 
	C339.440704,653.067688 338.758362,652.174500 338.107666,650.877747 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M336.209259,647.000732 
	C336.447296,646.974243 336.715485,647.209900 336.966827,647.725952 
	C336.713104,647.758606 336.476257,647.510681 336.209259,647.000732 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M337.169678,648.896973 
	C337.399109,648.880554 337.676575,649.145386 337.960114,649.707886 
	C337.716705,649.729858 337.467194,649.454041 337.169678,648.896973 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M351.216980,672.004272 
	C351.438141,671.965515 351.705383,672.196838 352.019775,672.700928 
	C351.798981,672.740540 351.531006,672.507507 351.216980,672.004272 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M369.010010,388.013489 
	C369.008026,388.366852 368.871918,388.657379 368.115936,389.072144 
	C367.449371,389.568176 367.268555,389.877106 367.087769,390.186035 
	C367.087769,390.186035 367.066986,390.071686 366.861267,390.174927 
	C366.482056,390.597900 366.308563,390.917633 366.135071,391.237335 
	C366.135071,391.237335 366.108551,391.107544 365.903656,391.186584 
	C365.480835,391.553253 365.262909,391.840851 365.044983,392.128479 
	C364.565063,393.007599 364.085114,393.886749 363.088898,395.009918 
	C362.404449,395.551666 362.236298,395.849426 362.068115,396.147156 
	C362.068115,396.147156 362.049805,396.060699 361.699127,396.083160 
	C357.645447,396.627838 356.172180,398.615753 357.277496,402.298676 
	C357.277496,402.298676 357.154205,402.054657 356.830139,402.077667 
	C352.154053,401.706909 351.010406,399.269562 350.853821,395.040985 
	C350.629639,388.987244 352.881958,382.605042 349.101196,376.862366 
	C348.936279,376.611908 349.360413,375.973694 349.208099,376.439117 
	C351.170898,375.433136 353.555511,374.265472 353.532166,374.215149 
	C351.381714,369.580719 356.866791,363.888245 351.558441,359.657959 
	C350.986481,359.202209 351.339386,357.505310 351.372192,356.387299 
	C351.452454,353.652588 351.596527,350.919739 351.775330,346.791046 
	C351.775330,346.327026 351.783417,344.757050 351.771851,343.187195 
	C351.766235,342.424072 351.526398,341.603210 351.730743,340.907867 
	C352.907806,336.902496 354.186035,332.926849 355.481964,328.774628 
	C349.818695,326.774872 352.744141,321.695923 351.941223,317.913696 
	C351.977264,317.965149 351.870087,317.899597 352.249207,317.925995 
	C353.354492,317.216064 354.080688,316.479706 354.881683,315.797943 
	C354.956512,315.852509 354.780121,315.795990 355.207794,315.861115 
	C356.784454,315.932587 357.933472,315.938965 359.045898,315.965820 
	C359.009338,315.986298 359.044891,315.910400 359.110535,316.130554 
	C359.314545,316.638824 359.527893,316.851898 359.908630,317.198975 
	C360.001068,317.408051 360.046204,317.863037 360.034790,318.202972 
	C360.692444,319.015717 361.361511,319.488495 362.320526,320.134064 
	C367.772919,327.838593 372.935394,335.370361 378.097900,342.902100 
	C377.136932,343.347168 376.175964,343.792206 375.214966,344.237244 
	C376.236572,345.515930 377.258148,346.794647 378.315582,348.118164 
	C377.049591,349.493896 376.639221,349.422180 369.009766,345.675079 
	C376.082092,353.941895 374.548553,361.924835 372.522186,369.921906 
	C375.921844,374.763336 380.351410,371.428009 384.688293,371.512604 
	C383.868317,372.758698 382.785004,373.784241 381.105042,375.032654 
	C380.059448,375.930542 379.610504,376.605560 379.161591,377.280579 
	C379.161591,377.280579 379.129913,377.128357 378.881714,377.200775 
	C378.140411,377.940704 377.647308,378.608246 377.154175,379.275787 
	C377.154205,379.275787 377.124390,379.118195 376.850616,379.197205 
	C374.867950,381.320282 373.159058,383.364349 371.450134,385.408386 
	C371.312988,385.312897 371.175842,385.217377 370.864380,385.181396 
	C370.130005,386.165131 369.570007,387.089325 369.010010,388.013489 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M457.721497,326.069489 
	C450.647644,329.635376 443.156860,333.172577 435.100708,336.535828 
	C433.687622,336.875031 432.839966,337.388153 431.992340,337.901306 
	C431.992340,337.901306 431.584656,338.027039 431.112976,338.144623 
	C430.467163,338.582581 430.293060,338.902954 430.118927,339.223328 
	C430.118927,339.223328 430.096680,339.118500 429.749939,339.089172 
	C428.287994,339.737030 427.172821,340.414246 426.057648,341.091461 
	C426.057648,341.091461 426.016266,341.021057 425.812256,341.130554 
	C425.421661,341.528900 425.235046,341.817780 425.048462,342.106659 
	C425.048462,342.106659 425.024933,342.018372 424.738037,342.046722 
	C424.000000,342.462097 423.548889,342.849091 423.097748,343.236084 
	C423.097748,343.236084 423.080872,343.099762 422.888916,343.162231 
	C422.467560,343.478577 422.238190,343.732483 422.008789,343.986389 
	C422.008789,343.986389 421.998444,344.005951 421.986450,344.002991 
	C418.359283,345.349915 414.744110,346.699768 411.055908,348.076904 
	C408.948517,347.459564 406.400604,346.713196 403.665924,345.912109 
	C404.518707,345.411438 405.592316,344.781128 407.165039,343.857788 
	C406.511932,342.802155 405.636658,341.842438 405.291351,340.719757 
	C404.807556,339.146729 404.542053,337.456879 404.499847,335.808899 
	C404.429169,333.048462 405.157013,330.155518 404.556946,327.546448 
	C403.603607,323.401428 404.471222,321.883423 408.841400,322.719849 
	C409.703369,322.884857 410.781647,321.919769 412.289581,321.243286 
	C418.797363,318.601562 424.775757,316.192413 430.754150,313.783264 
	C431.656403,317.239746 432.676636,320.672058 433.392029,324.166748 
	C433.619080,325.276031 432.872498,326.549835 432.859650,327.751465 
	C432.842102,329.391571 432.510071,331.839966 433.383911,332.480438 
	C434.580627,333.357483 436.954193,333.381866 438.524170,332.864197 
	C444.846863,330.779541 451.054443,328.345703 457.721497,326.069489 
M408.358582,339.366852 
	C409.330658,339.851807 410.302734,340.336761 411.274811,340.821716 
	C411.422638,340.246216 411.570465,339.670746 411.718292,339.095245 
	C410.621399,338.965759 409.524536,338.836273 408.358582,339.366852 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M430.762878,313.794128 
	C424.775757,316.192413 418.797363,318.601562 412.446716,320.917847 
	C408.970642,313.673676 405.866821,306.522400 402.447937,298.818420 
	C401.790344,297.707245 401.447815,297.148743 401.105316,296.590271 
	C401.105316,296.590271 401.172913,296.262512 401.074554,295.863800 
	C400.748718,295.196991 400.521271,294.928894 400.293823,294.660767 
	C400.293823,294.660767 400.317688,294.790802 400.252686,294.399048 
	C399.176056,291.943665 398.164429,289.880066 397.152802,287.816437 
	C397.152802,287.816437 397.119110,287.934631 397.147400,287.591492 
	C396.823517,286.693268 396.471344,286.138153 396.119202,285.583038 
	C396.119202,285.583038 396.188934,285.253326 396.067505,284.805389 
	C395.666351,284.040527 395.386627,283.723633 395.106903,283.406738 
	C395.106903,283.406738 395.197754,283.179535 395.121216,282.839783 
	C394.992523,282.335449 394.940369,282.170898 394.888245,282.006348 
	C395.092224,281.707581 395.296234,281.408783 396.024170,281.036621 
	C396.723846,280.652588 396.899567,280.341949 397.075317,280.031342 
	C397.075317,280.031342 397.007050,280.008789 397.393860,280.012817 
	C399.881897,279.018158 401.983093,278.019470 404.084320,277.020782 
	C404.084320,277.020782 404.552673,276.978302 405.039581,276.937439 
	C405.723907,276.607788 405.921326,276.318970 406.118744,276.030151 
	C406.118744,276.030151 406.558319,275.996155 407.074036,275.999084 
	C408.100372,275.691223 408.610962,275.380432 409.121582,275.069672 
	C409.121582,275.069672 409.043701,275.046082 409.365234,275.063171 
	C410.203491,274.744690 410.720276,274.409180 411.438904,274.087341 
	C411.640747,274.101044 411.983307,273.885620 412.287659,273.904114 
	C412.873901,273.648529 413.155853,273.374451 413.601318,273.128265 
	C413.764862,273.156189 413.992767,272.915009 414.285522,272.925293 
	C415.045837,272.621674 415.513397,272.307770 416.283203,271.985535 
	C417.064026,271.677551 417.542633,271.377899 418.266785,271.060303 
	C418.869049,271.015472 419.133698,270.845398 419.306213,270.532104 
	C419.306213,270.532104 419.365692,270.494659 419.689392,270.480286 
	C420.708557,270.068390 421.404022,269.670929 422.099487,269.273438 
	C423.181854,270.851227 424.264191,272.429016 425.627228,274.415955 
	C417.976227,277.926422 410.821503,281.209167 403.269043,284.674438 
	C404.999573,288.543121 406.663269,292.262451 408.429230,296.210419 
	C415.231903,293.339539 421.735291,290.594971 428.617493,287.925598 
	C428.996277,288.000824 429.000000,288.000000 429.020447,288.342041 
	C429.519440,289.976654 429.998016,291.269165 430.525391,292.693512 
	C424.823151,295.537842 419.420685,298.232666 413.657166,300.869263 
	C409.110077,303.250793 413.178162,305.122284 413.741669,307.174133 
	C413.744934,307.113068 413.640137,307.176178 413.682495,307.497620 
	C414.142090,308.553436 414.559326,309.287842 414.988281,310.011108 
	C415.000000,310.000000 414.975586,310.022217 415.002991,310.380157 
	C416.822357,315.250031 418.954926,311.807495 420.985504,310.999329 
	C420.996033,311.006042 421.000458,310.981812 421.327637,310.953125 
	C421.982300,310.654419 422.309753,310.384338 422.732544,310.153931 
	C422.827850,310.193634 422.973663,310.047333 423.290497,310.037964 
	C424.124542,309.702698 424.641754,309.376862 425.488770,308.907928 
	C428.900635,307.196228 431.982788,305.627655 435.064911,304.059052 
	C435.064911,304.059052 434.993927,304.011444 435.405273,304.030518 
	C439.371857,303.060394 439.194794,306.714722 440.847137,308.093018 
	C440.589691,308.511871 440.332245,308.930756 439.661713,309.654419 
	C437.807129,310.620117 436.365662,311.280945 434.924194,311.941772 
	C434.924194,311.941803 434.986572,311.991241 434.665222,311.964111 
	C433.854034,312.257233 433.364258,312.577545 432.874451,312.897827 
	C432.874451,312.897827 432.955170,312.939911 432.636871,312.922852 
	C431.811859,313.205170 431.305145,313.504517 430.798431,313.803833 
	C430.798431,313.803833 430.771576,313.805023 430.762878,313.794128 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M378.069214,342.920074 
	C372.935394,335.370361 367.772919,327.838593 362.260071,319.834290 
	C361.288544,318.862183 360.667389,318.362610 360.046204,317.863037 
	C360.046204,317.863037 360.001068,317.408051 359.935791,316.941467 
	C359.595306,316.286743 359.320099,316.098572 359.044891,315.910400 
	C359.044891,315.910400 359.009338,315.986298 358.955688,315.633850 
	C357.173096,312.609467 356.008301,314.456665 354.780121,315.795990 
	C354.780121,315.795990 354.956512,315.852509 354.565460,315.845642 
	C353.406311,316.525696 352.638184,317.212646 351.870087,317.899597 
	C351.870087,317.899597 351.977264,317.965149 351.580566,317.956848 
	C347.725494,320.996735 344.320618,322.931488 341.556824,317.594025 
	C344.095062,315.229218 346.484802,312.462524 349.381989,310.413239 
	C355.916382,305.791260 362.701813,301.524200 369.385101,297.112732 
	C370.985992,298.287170 372.586884,299.461639 374.668915,300.989044 
	C370.471497,304.088257 366.946960,306.690674 362.900970,309.678101 
	C369.666748,319.057220 376.429047,328.431519 383.474976,337.987122 
	C383.758636,338.168365 383.778290,338.120636 383.835815,338.374817 
	C383.893372,338.628998 384.251617,338.960052 384.251617,338.960052 
	C384.220123,339.407410 384.188629,339.854797 383.800201,340.673279 
	C383.244507,341.336548 383.045746,341.628693 382.846985,341.920837 
	C382.846985,341.920837 382.946198,341.944763 382.631134,341.975159 
	C381.272644,342.525940 380.229218,343.046295 379.185791,343.566650 
	C379.185791,343.566681 379.107452,343.765839 379.085693,343.525726 
	C378.723816,343.147217 378.383636,343.008820 378.043488,342.870422 
	C378.043488,342.870422 378.040497,342.938049 378.069214,342.920074 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M379.270782,343.917236 
	C380.229218,343.046295 381.272644,342.525940 382.603882,341.932007 
	C383.512177,343.584198 384.132660,345.309937 384.753143,347.035675 
	C384.472504,347.398315 384.191864,347.760925 383.911224,348.123566 
	C382.392761,346.838318 380.874298,345.553040 379.270782,343.917236 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M403.682800,277.014954 
	C401.983093,278.019470 399.881897,279.018158 397.409485,280.045166 
	C399.119263,279.052063 401.200256,278.030579 403.682800,277.014954 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M397.105255,288.236633 
	C398.164429,289.880066 399.176056,291.943665 400.287292,294.342743 
	C396.339355,294.295410 397.157074,291.222626 397.105255,288.236633 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M387.200500,294.190704 
	C387.763428,293.944794 388.264954,293.998627 388.766449,294.052429 
	C388.652405,294.502625 388.538361,294.952789 388.424286,295.402985 
	C387.995880,295.098785 387.567474,294.794586 387.200500,294.190704 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M396.108185,285.942627 
	C396.471344,286.138153 396.823517,286.693268 397.208710,287.587402 
	C396.860199,287.385040 396.478699,286.843628 396.108185,285.942627 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M401.097870,296.949097 
	C401.447815,297.148743 401.790344,297.707245 402.176514,298.604584 
	C401.843597,298.398285 401.467010,297.853088 401.097870,296.949097 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M378.168121,343.125977 
	C378.383636,343.008820 378.723816,343.147217 379.192566,343.531555 
	C378.978333,343.645508 378.635559,343.513519 378.168121,343.125977 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M383.097382,341.841248 
	C383.045746,341.628693 383.244507,341.336548 383.712219,340.998383 
	C383.770050,341.222137 383.558929,341.491913 383.097382,341.841248 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M395.149994,283.721802 
	C395.386627,283.723633 395.666351,284.040527 395.949982,284.684631 
	C395.700287,284.686829 395.446686,284.361847 395.149994,283.721802 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M394.732300,282.111084 
	C394.940369,282.170898 394.992523,282.335449 395.029602,282.758667 
	C394.868469,282.750183 394.722412,282.483002 394.732300,282.111084 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M396.850739,280.109680 
	C396.899567,280.341949 396.723846,280.652588 396.276886,280.925415 
	C396.126526,280.578186 396.333344,280.344971 396.850739,280.109680 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M400.278503,294.934174 
	C400.521271,294.928894 400.748718,295.196991 400.959015,295.742798 
	C400.715607,295.749512 400.489380,295.478546 400.278503,294.934174 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M405.841278,276.013428 
	C405.921326,276.318970 405.723907,276.607788 405.261353,276.859314 
	C404.996246,276.822052 404.997253,276.398987 405.002777,276.186523 
	C405.193481,275.981628 405.378662,275.989197 405.841278,276.013428 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M384.370270,338.821045 
	C384.251617,338.960052 383.893372,338.628998 383.830017,338.393555 
	C384.007416,338.332703 384.248169,338.507355 384.370270,338.821045 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M546.012268,309.908417 
	C548.845520,310.097473 551.678711,310.286530 554.785278,310.781677 
	C552.580383,311.097656 550.101196,311.159668 547.624207,311.109161 
	C528.899231,310.727448 510.377228,312.560211 491.222229,316.093414 
	C489.276581,316.378143 488.122437,316.721802 486.968292,317.065430 
	C486.968292,317.065430 486.701385,317.261017 486.183289,317.157562 
	C484.778839,317.335693 483.892487,317.617279 483.006165,317.898865 
	C483.006165,317.898865 482.557159,317.996643 481.894592,318.000122 
	C479.166870,318.693787 477.101715,319.383972 475.036530,320.074158 
	C475.036530,320.074158 475.005341,320.009338 474.726776,320.029724 
	C473.953979,320.374451 473.459747,320.698822 472.965485,321.023193 
	C472.965485,321.023163 472.779266,321.201874 472.214722,321.123840 
	C469.100555,321.366455 466.550934,321.687134 464.001282,322.007812 
	C458.717712,321.452332 457.585388,318.371460 457.829865,313.433136 
	C458.005707,309.880981 456.592865,306.250153 455.833099,302.370636 
	C457.217041,302.779968 458.803955,303.249298 460.390900,303.718658 
	C460.639801,303.421783 460.888733,303.124908 461.137665,302.828064 
	C459.175354,300.694153 457.213013,298.560272 454.776794,295.911041 
	C456.450806,294.838348 457.867157,293.930725 459.879974,293.063080 
	C460.803314,293.243134 460.990997,293.489319 461.149780,294.103210 
	C461.587463,294.534546 461.914948,294.704254 462.178467,294.863586 
	C462.114502,294.853149 462.183746,294.743591 462.150665,295.115387 
	C463.301544,297.172821 464.485535,298.858429 465.086914,299.714630 
	C467.722595,299.714630 469.713928,300.012878 471.575348,299.648163 
	C474.764526,299.023376 477.872406,297.983917 481.156006,296.999146 
	C481.297577,296.879730 481.666718,296.910339 482.008545,296.920593 
	C482.894684,296.597168 483.439026,296.263458 484.073059,295.714905 
	C484.162781,295.500000 484.534790,295.219940 484.534790,295.219940 
	C484.534790,295.219940 485.000366,295.226501 485.002533,295.424255 
	C485.004700,295.622040 485.020020,296.018768 485.027283,296.399841 
	C486.521759,297.913116 488.008911,299.045380 489.695892,300.329742 
	C488.587463,301.717529 487.384460,303.223663 486.191864,304.716797 
	C486.865936,306.175842 487.531586,307.478760 488.080139,308.829254 
	C489.927734,313.377686 492.763153,315.012115 497.809723,313.342041 
	C501.166229,312.231293 504.966736,312.529236 508.528839,311.951843 
	C511.383209,311.489166 514.177917,310.658051 517.467896,309.991547 
	C527.295227,309.964539 536.653748,309.936462 546.012268,309.908417 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M481.666718,296.910339 
	C481.666718,296.910339 481.297577,296.879730 480.733917,296.979156 
	C473.820984,299.025330 467.921051,297.510864 462.183746,294.743591 
	C462.183746,294.743591 462.114502,294.853149 462.078247,294.595703 
	C461.707855,294.172699 461.373688,294.007172 461.039551,293.841644 
	C460.990997,293.489319 460.803314,293.243134 460.239716,293.057861 
	C449.403961,280.815002 449.150146,272.403046 459.027588,258.428589 
	C459.085419,258.086456 459.046448,258.060242 459.422119,258.029419 
	C466.191498,253.046448 473.425751,253.032974 480.896271,254.407684 
	C482.592407,255.307526 484.288544,256.207397 486.330261,257.159424 
	C486.675812,257.211639 486.720856,257.183533 486.803772,257.459869 
	C487.225708,257.895111 487.564728,258.054047 487.900665,258.161011 
	C487.897583,258.109039 487.806274,258.159027 487.931458,258.453400 
	C489.007782,259.602325 489.958954,260.456879 490.897858,261.499298 
	C490.885620,261.687164 491.103241,261.994415 490.977081,262.334381 
	C488.608734,264.101318 486.882019,266.489502 483.669312,263.504730 
	C478.756012,258.940033 471.658112,258.503601 466.152100,261.614288 
	C460.973267,264.540131 457.713715,271.015259 458.548828,276.718384 
	C459.676147,284.416901 463.807953,289.480072 470.297455,291.115295 
	C478.633209,293.215729 484.281647,289.947693 489.446564,279.805023 
	C491.172699,279.888062 493.045349,279.978119 495.206482,280.082062 
	C494.634613,282.454285 494.215698,284.191864 493.860291,285.934387 
	C493.923798,285.939301 493.903137,285.813629 493.660583,285.922119 
	C493.324738,286.362061 493.231445,286.693481 493.012756,287.140320 
	C492.887360,287.255707 492.899872,287.596375 492.608978,287.779175 
	C490.877472,289.652588 489.436859,291.343170 487.781372,293.143555 
	C487.290833,293.443878 487.102325,293.698853 486.733276,294.055542 
	C486.257385,294.370636 486.049133,294.648407 485.874878,294.922302 
	C485.908875,294.918457 485.857300,294.872742 485.623718,294.914307 
	C485.390106,294.955841 485.000366,295.226501 485.000366,295.226501 
	C485.000366,295.226501 484.534790,295.219940 484.534790,295.219940 
	C484.534790,295.219940 484.162781,295.500000 483.741943,295.742065 
	C482.769653,296.292847 482.218201,296.601593 481.666718,296.910339 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M414.018250,300.927460 
	C419.420685,298.232666 424.823151,295.537842 430.525391,292.693512 
	C429.998016,291.269165 429.519440,289.976654 429.019562,288.344116 
	C430.815430,291.326508 432.632629,294.648926 434.399841,297.879974 
	C430.400970,298.196350 427.403564,298.433502 424.406158,298.670654 
	C425.406555,300.981750 426.406921,303.292877 427.399994,305.587158 
	C429.865417,305.024902 432.069580,304.522217 434.669342,304.039307 
	C431.982788,305.627655 428.900635,307.196228 425.166016,308.913696 
	C424.000244,309.390778 423.486938,309.719055 422.973663,310.047333 
	C422.973663,310.047333 422.827850,310.193634 422.408569,310.194702 
	C421.659668,310.457794 421.330048,310.719788 421.000458,310.981812 
	C421.000458,310.981812 420.996033,311.006042 420.598816,311.000916 
	C418.459625,310.671265 416.717590,310.346741 414.975586,310.022217 
	C414.975586,310.022217 415.000000,310.000000 414.992615,309.653992 
	C414.536865,308.597382 414.088501,307.886780 413.640137,307.176178 
	C413.640137,307.176178 413.744934,307.113068 413.829651,306.725616 
	C413.949005,304.534607 413.983612,302.731018 414.018250,300.927460 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M441.120911,307.893524 
	C439.194794,306.714722 439.371857,303.060394 435.435577,304.061401 
	C436.247223,303.424957 437.439850,302.776733 438.632507,302.128510 
	C439.553253,303.983673 440.473969,305.838837 441.120911,307.893524 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M463.827209,322.264038 
	C466.550934,321.687134 469.100555,321.366455 472.102661,321.059845 
	C468.967102,322.393829 465.379059,323.713745 461.404144,325.035767 
	C461.895874,324.198639 462.774506,323.359467 463.827209,322.264038 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M435.332153,311.960480 
	C436.365662,311.280945 437.807129,310.620117 439.596985,309.900330 
	C438.543610,310.553986 437.141876,311.266602 435.332153,311.960480 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M460.689331,325.022766 
	C460.266174,325.399933 459.493073,325.772400 458.383118,326.152039 
	C458.810638,325.778839 459.575043,325.398468 460.689331,325.022766 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M431.125427,313.846558 
	C431.305145,313.504517 431.811859,313.205170 432.631897,312.877625 
	C432.447662,313.196014 431.950043,313.542633 431.125427,313.846558 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M433.191528,312.910400 
	C433.364258,312.577545 433.854034,312.257233 434.656189,311.925354 
	C434.481934,312.250153 433.995270,312.586578 433.191528,312.910400 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M432.847626,744.922607 
	C433.265991,744.898193 433.674530,744.875732 433.976318,745.203735 
	C434.528687,745.768738 434.826294,745.894592 435.170898,745.909424 
	C435.170898,745.909424 435.052582,745.933777 435.203308,746.168579 
	C435.966888,746.581665 436.579712,746.760010 437.192535,746.938354 
	C437.192535,746.938354 437.640320,746.960815 437.970886,747.268433 
	C438.547455,747.788513 438.832825,747.902649 439.157593,747.918457 
	C439.157593,747.918457 439.076782,747.957581 439.046783,748.199585 
	C439.012390,748.633484 439.007996,748.825317 438.954651,749.340820 
	C437.556824,750.387634 435.405457,750.808960 435.000183,751.886780 
	C433.382629,756.188843 431.944183,760.631042 431.150146,765.146362 
	C430.534210,768.648560 431.036774,772.339172 430.887421,775.939636 
	C430.860596,776.585938 430.145203,777.260559 429.633240,777.816772 
	C428.848602,778.669312 427.964569,779.430481 427.122681,780.230469 
	C428.291473,780.849731 429.453003,781.483337 430.630981,782.084656 
	C432.046326,782.807129 433.475708,783.502075 434.902344,784.210571 
	C434.162933,786.518738 433.581329,788.334229 432.999756,790.149719 
	C433.409271,790.547119 433.818787,790.944519 434.228271,791.341919 
	C435.363434,790.377441 437.633362,789.017578 437.456604,788.510254 
	C435.845520,783.886841 439.477142,783.417603 442.088745,782.488770 
	C438.015228,793.400269 433.964081,803.934998 429.825104,814.698120 
	C432.001831,815.567017 433.757904,816.267944 435.551483,816.983887 
	C440.186340,805.772339 444.689697,794.878967 449.394470,783.993164 
	C449.595917,784.000671 449.999084,783.999268 449.999084,783.999268 
	C447.945984,790.374878 445.847687,796.736572 443.869995,803.135498 
	C443.342468,804.842407 443.184021,806.663330 443.873291,808.723328 
	C442.504730,810.245178 441.136139,811.767029 439.318298,813.788452 
	C441.543427,814.628845 443.053772,815.199280 444.564117,815.769653 
	C444.530029,816.045532 444.495972,816.321350 444.461884,816.597229 
	C442.960907,816.663208 441.459900,816.729126 439.031036,816.835876 
	C439.031036,823.174500 439.033661,829.567017 439.029907,835.959473 
	C439.028076,839.026978 439.014252,842.094482 439.008911,845.574890 
	C435.963593,845.315552 432.915253,844.643311 429.466675,843.947998 
	C426.443970,842.460144 423.851593,840.937805 421.191956,839.543823 
	C417.048492,837.372314 412.859375,835.287964 408.659210,833.151184 
	C408.659210,826.955688 408.844971,820.575806 408.503448,814.224304 
	C408.434631,812.944397 406.366302,811.772034 404.466675,809.747314 
	C406.508148,809.433350 407.984558,809.362549 409.356476,808.946960 
	C410.502808,808.599670 411.553680,807.888550 412.559235,807.208923 
	C412.649139,807.148132 412.141357,805.677307 411.830719,805.645508 
	C410.786926,805.538513 409.693878,805.716797 408.643066,805.912048 
	C406.117218,806.381348 403.604675,806.922180 401.062195,806.660767 
	C404.816284,804.144043 408.570374,801.627380 411.335388,799.773743 
	C413.024231,794.067383 414.434052,789.303894 415.757904,784.169678 
	C415.671936,783.798950 415.682648,783.763733 416.071899,783.789795 
	C416.950012,782.512634 417.438904,781.209290 417.951324,779.951965 
	C417.974854,779.997986 417.895386,779.931824 418.247131,779.911133 
	C421.591949,777.093506 424.464935,774.235046 423.873749,769.420776 
	C423.873657,769.240784 424.142242,769.001221 424.341064,768.863037 
	C424.773315,768.480103 424.901062,768.189087 424.929840,767.895996 
	C424.936615,767.940369 424.852203,767.909668 425.203430,767.896179 
	C425.961945,767.308411 426.369293,766.734192 426.776642,766.159912 
	C426.135620,765.801575 425.494598,765.443237 424.898285,765.073730 
	C424.942993,765.062439 424.938965,765.154541 424.822632,764.892578 
	C424.360260,764.507507 424.014191,764.384338 423.724060,764.285522 
	C423.779999,764.309875 423.675018,764.372009 423.746033,764.038330 
	C423.155823,763.567932 422.494598,763.431152 421.551758,763.229858 
	C420.830780,762.828796 420.391418,762.492249 419.916779,761.778931 
	C418.828827,761.024109 417.776184,760.646057 416.707764,760.266357 
	C416.691956,760.264648 416.675995,760.292725 416.675995,760.292725 
	C415.603424,758.538879 414.566803,755.311768 413.452118,755.284607 
	C408.453979,755.162659 408.183929,749.867371 404.790100,748.136780 
	C404.109039,747.789490 404.299011,745.734009 404.087402,744.466064 
	C404.532013,744.250061 404.976593,744.034119 405.421173,743.818115 
	C406.457275,745.062134 407.354492,746.473633 408.579865,747.489319 
	C409.355286,748.132141 410.619965,748.184753 411.665222,748.502075 
	C411.942383,747.348816 412.655273,746.090454 412.407745,745.063721 
	C411.862854,742.803955 409.704865,740.898804 412.691895,738.500916 
	C415.831451,735.980591 417.787018,737.984131 420.170837,739.455444 
	C424.005219,741.822205 427.438568,745.143250 432.847626,744.922607 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M384.896851,739.026123 
	C384.539764,739.028687 384.266083,738.878845 383.918030,738.097107 
	C383.471710,737.428284 383.183197,737.238953 382.894653,737.049683 
	C382.894653,737.049683 382.972748,737.028809 382.857727,736.820129 
	C382.437714,736.442383 382.132721,736.273315 381.827698,736.104309 
	C381.827698,736.104309 381.919464,736.083923 381.820282,735.866272 
	C381.402985,735.479736 381.084839,735.310852 380.766724,735.141968 
	C380.766724,735.141968 380.871063,735.136292 380.874939,734.813599 
	C378.876892,733.024475 376.875000,731.558105 374.873108,730.091675 
	C374.873108,730.091675 374.954681,730.052490 374.844543,729.841187 
	C374.421021,729.457336 374.107574,729.284790 373.794128,729.112244 
	C373.794128,729.112244 373.915344,729.091675 373.812317,728.881348 
	C373.375702,728.518250 373.042145,728.365479 372.708588,728.212708 
	C372.708588,728.212708 372.674591,728.198181 372.659607,728.210327 
	C371.504150,723.101257 370.363617,717.979919 369.176880,712.651001 
	C371.053192,713.159424 372.616577,713.583069 374.179962,714.006714 
	C374.508331,713.742126 374.836670,713.477539 375.165009,713.212952 
	C372.012543,709.163147 363.639832,706.603882 370.745453,699.628357 
	C371.768707,700.010315 373.054382,700.490173 374.698547,700.957397 
	C375.056976,700.944702 375.019897,700.974915 375.024170,701.325562 
	C377.530609,705.713989 379.068420,710.820557 385.190918,710.846375 
	C385.190918,710.846375 385.098206,710.881104 385.190643,711.102966 
	C385.602356,711.504211 385.921631,711.683594 386.240875,711.863037 
	C386.240875,711.863037 386.130829,711.879089 386.117310,712.166809 
	C386.402527,713.302185 386.701263,714.149902 387.000000,714.997559 
	C387.816864,717.160278 388.633698,719.322998 389.450531,721.485718 
	C390.065552,721.671753 390.680603,721.857788 391.295624,722.043823 
	C389.524750,722.959778 387.753876,723.875793 385.409973,725.088196 
	C381.527008,730.596130 387.104736,735.017334 388.615448,740.164734 
	C388.937897,741.263489 389.706604,742.378540 389.611877,743.426575 
	C389.236084,747.582458 388.599335,751.714844 388.053711,755.898743 
	C387.054474,756.500427 385.592285,757.380920 384.130127,758.261414 
	C385.310822,759.616943 386.415100,761.050598 387.695679,762.304138 
	C388.773193,763.358826 390.055847,764.203857 392.215790,765.903503 
	C386.172089,766.868896 386.586823,769.896118 387.846832,773.369995 
	C388.170654,774.262817 387.993927,775.348267 387.994812,776.345215 
	C387.999847,782.045837 387.892303,787.660156 388.554810,793.438538 
	C389.301880,799.954651 389.411194,807.241394 385.535614,813.632080 
	C384.818481,814.814636 386.231262,817.455200 386.967102,819.300842 
	C387.575775,820.827637 388.636078,822.174316 389.248749,823.800415 
	C386.288818,822.987793 383.576416,821.975586 380.472717,820.937805 
	C377.430389,818.355774 375.173920,814.999390 372.027435,813.447388 
	C368.181091,811.550171 369.048431,809.914612 371.201935,807.257080 
	C369.788574,806.499939 368.581146,805.853149 367.267212,805.149231 
	C367.267212,804.719910 367.118347,804.173462 367.289581,803.759460 
	C369.844116,797.582275 370.331635,791.483582 367.019775,785.361816 
	C366.561005,784.513855 366.826172,783.248169 366.836975,782.176697 
	C366.877991,778.126953 366.864990,774.073608 367.043945,770.029236 
	C367.168793,767.208252 367.573730,764.399780 367.747498,761.194214 
	C367.641479,760.802673 367.670258,760.835815 367.974121,760.758118 
	C368.424805,760.338379 368.571625,759.996338 368.718445,759.654358 
	C368.718445,759.654358 368.720337,759.890320 369.065430,759.857605 
	C374.940582,758.316772 376.534027,753.706543 377.965454,748.968689 
	C377.965454,748.968689 378.000000,749.000000 378.213593,748.876099 
	C378.598389,748.463135 378.769623,748.174072 378.940857,747.884949 
	C378.940857,747.884949 378.964417,747.964722 379.173889,747.850098 
	C379.548676,747.424866 379.713959,747.114258 379.879242,746.803589 
	C379.879242,746.803589 379.911224,746.913208 380.136658,746.792297 
	C380.535767,746.314697 380.709473,745.958008 380.883179,745.601318 
	C380.883209,745.601318 380.850708,745.257874 381.178345,744.997925 
	C381.757690,744.499634 381.906311,744.208557 381.951874,743.864929 
	C381.970764,743.505310 382.136963,743.237000 383.067505,743.018555 
	C386.788940,742.488708 386.536926,740.970337 384.896851,739.026123 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M424.142242,769.001221 
	C424.142242,769.001221 423.873657,769.240784 423.576935,769.625244 
	C421.485291,773.317078 419.690338,776.624451 417.895386,779.931824 
	C417.895386,779.931824 417.974854,779.997986 417.652222,780.097839 
	C416.780609,781.386353 416.231628,782.575073 415.682648,783.763733 
	C415.682648,783.763733 415.671936,783.798950 415.662537,783.779663 
	C411.742981,790.179443 407.832825,796.598450 403.765045,803.276245 
	C402.028809,802.319946 400.483307,801.468750 398.378632,800.309570 
	C401.532379,794.830444 404.565277,789.561707 407.597717,784.292725 
	C410.658081,778.975403 413.717926,773.657776 417.042236,767.880981 
	C410.919128,764.268127 404.862640,760.694580 398.606171,757.002991 
	C399.569702,755.223083 400.338776,753.802307 401.371857,751.893921 
	C406.350586,754.701965 411.173279,757.422058 416.335999,760.217468 
	C416.675995,760.292725 416.691956,760.264648 416.882141,760.534912 
	C418.032257,761.255371 418.992188,761.705566 419.952087,762.155701 
	C420.391418,762.492249 420.830780,762.828796 421.698853,763.468872 
	C422.643402,763.972229 423.159210,764.172119 423.675018,764.372009 
	C423.675018,764.372009 423.779999,764.309875 423.845825,764.534546 
	C424.254089,764.891052 424.596527,765.022766 424.938965,765.154541 
	C424.938965,765.154541 424.942993,765.062439 424.947998,765.425415 
	C424.919373,766.495422 424.885773,767.202576 424.852203,767.909668 
	C424.852203,767.909668 424.936615,767.940369 424.690369,767.996094 
	C424.343506,768.368225 424.242889,768.684753 424.142242,769.001221 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M387.286469,714.984741 
	C386.701263,714.149902 386.402527,713.302185 386.137085,712.114746 
	C387.014404,712.371094 387.858429,712.967163 388.841095,713.846252 
	C388.510803,714.410156 388.041870,714.691040 387.286469,714.984741 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M486.008026,832.041138 
	C485.587463,832.013977 485.166901,831.986755 484.425629,831.962524 
	C481.360382,831.149658 478.596985,830.391846 475.875366,829.505676 
	C470.857758,827.871948 466.109039,825.674255 465.116852,819.801758 
	C464.066132,813.582825 466.866364,809.179321 472.677338,806.704773 
	C467.648590,801.236511 466.531616,796.853210 469.222595,791.907349 
	C472.445770,785.983337 477.905853,785.299866 484.332336,786.197937 
	C484.779785,786.227234 484.793671,786.183899 485.058014,786.467285 
	C488.142822,787.277649 490.963287,787.804626 493.829407,788.301392 
	C493.875092,788.271240 493.788116,788.204773 494.049988,788.480530 
	C496.831299,789.305359 499.350739,789.854431 502.061249,790.619263 
	C502.821350,790.914917 503.390442,790.994873 503.968628,791.052917 
	C503.977722,791.031006 503.933716,791.050903 503.933685,791.050903 
	C500.861023,804.441284 497.796692,817.833618 494.688477,831.215698 
	C494.548553,831.818176 494.010925,832.328186 492.919739,832.949951 
	C490.123352,832.692810 488.065704,832.366943 486.008026,832.041138 
M493.066406,794.447815 
	C489.368683,793.624878 485.701752,792.597839 481.964935,792.034058 
	C477.880493,791.417847 475.266083,793.575317 474.289001,797.439514 
	C473.343781,801.177795 475.481689,803.713074 478.676819,804.739624 
	C483.476379,806.281616 488.485687,807.170837 493.558411,808.373779 
	C494.466400,804.544983 495.448212,801.366394 495.856689,798.115723 
	C495.984802,797.096497 494.570129,795.883362 493.066406,794.447815 
M471.155518,816.628784 
	C472.358307,818.647949 473.112366,821.758789 474.851379,822.473694 
	C479.359894,824.327209 484.302002,825.125854 489.434448,826.438538 
	C490.454895,821.776978 491.318542,817.831604 492.252869,813.563477 
	C487.781403,812.584473 483.912659,811.751587 480.050781,810.888000 
	C475.599335,809.892700 472.595306,811.420227 471.155518,816.628784 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M442.111145,782.112061 
	C439.477142,783.417603 435.845520,783.886841 437.456604,788.510254 
	C437.633362,789.017578 435.363434,790.377441 434.228271,791.341919 
	C433.818787,790.944519 433.409271,790.547119 432.999756,790.149719 
	C433.581329,788.334229 434.162933,786.518738 434.902344,784.210571 
	C433.475708,783.502075 432.046326,782.807129 430.630981,782.084656 
	C429.453003,781.483337 428.291473,780.849731 427.122681,780.230469 
	C427.964569,779.430481 428.848602,778.669312 429.633240,777.816772 
	C430.145203,777.260559 430.860596,776.585938 430.887421,775.939636 
	C431.036774,772.339172 430.534210,768.648560 431.150146,765.146362 
	C431.944183,760.631042 433.382629,756.188843 435.000183,751.886780 
	C435.405457,750.808960 437.556824,750.387634 439.309631,749.333618 
	C440.187195,748.969299 440.660828,748.935913 441.134491,748.902527 
	C441.134491,748.902527 441.050385,748.879333 441.102722,749.034424 
	C441.432312,749.181091 441.709503,749.172607 441.986725,749.164185 
	C446.883850,751.225220 451.780975,753.286255 456.908020,755.618286 
	C446.622955,757.365601 446.236267,757.790466 450.221680,767.591797 
	C451.295258,770.232117 450.262390,770.753113 448.003845,772.001465 
	C446.781433,773.401672 445.981293,774.271423 445.155182,775.114929 
	C445.129242,775.088684 445.202606,775.084473 444.832520,775.188477 
	C440.881805,776.595459 442.219177,779.609802 442.086914,782.096741 
	C442.085022,782.080017 442.111145,782.112061 442.111145,782.112061 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M484.765411,786.244995 
	C482.658905,785.507690 480.598480,784.226135 478.468597,784.097717 
	C472.444275,783.734863 471.763489,783.489014 472.082153,777.457031 
	C472.323456,772.889343 473.322845,768.361633 474.337646,763.444824 
	C475.788696,763.388184 476.887054,763.702820 478.021881,764.308228 
	C478.705566,764.731934 479.352783,764.864746 480.297180,765.020569 
	C481.065063,765.360779 481.535736,765.677917 482.092712,766.163574 
	C482.450470,766.220032 482.722015,766.107971 483.415527,765.996094 
	C485.244659,765.987122 486.651825,765.977905 488.059021,765.968628 
	C488.059021,765.968689 488.001434,765.996643 488.089142,766.365479 
	C491.514130,767.106262 494.851379,767.478149 498.188660,767.850098 
	C498.188660,767.850098 498.079407,767.846558 498.068726,768.120972 
	C498.040619,768.605591 498.023163,768.815918 497.998810,769.405396 
	C495.185760,774.691345 493.863281,779.666138 496.911377,785.034363 
	C497.340759,785.790588 497.005096,786.981140 496.618652,787.981934 
	C495.405212,788.063782 494.596680,788.134277 493.788116,788.204773 
	C493.788116,788.204773 493.875092,788.271240 493.836670,787.879883 
	C490.796692,787.053650 487.795197,786.618774 484.793671,786.183899 
	C484.793671,786.183899 484.779785,786.227234 484.765411,786.244995 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M482.006439,765.995117 
	C481.535736,765.677917 481.065063,765.360779 480.128906,764.774719 
	C479.104126,764.343018 478.544769,764.180237 477.985413,764.017456 
	C476.887054,763.702820 475.788696,763.388184 474.346741,763.040405 
	C473.111633,762.487305 472.220123,761.967285 471.149963,761.181763 
	C476.543793,762.385376 482.116272,763.854431 487.873901,765.646118 
	C486.651825,765.977905 485.244659,765.987122 483.168762,765.996094 
	C482.500061,765.995789 482.006439,765.995117 482.006439,765.995117 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M457.114929,756.208984 
	C461.384338,757.315552 465.670868,758.791199 470.188416,760.568970 
	C465.584961,760.694580 460.799713,760.365845 457.114929,756.208984 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M445.181152,775.141174 
	C445.981293,774.271423 446.781433,773.401672 447.790405,772.269653 
	C449.243103,772.810425 450.486969,773.613525 451.810455,775.066406 
	C451.840424,775.956421 451.790802,776.196655 451.377655,776.337585 
	C449.069824,775.872620 447.125488,775.506897 445.181152,775.141174 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M451.741150,776.436829 
	C451.790802,776.196655 451.840424,775.956421 451.954163,775.353882 
	C454.744873,775.735291 457.575012,776.393250 456.660522,780.598022 
	C455.175751,779.624329 453.961517,778.828003 452.317291,777.973022 
	C451.887329,777.914429 451.747986,777.796570 451.708496,777.569336 
	C451.577240,777.031067 451.601288,776.729309 451.741150,776.436829 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M451.955383,777.975159 
	C451.595367,779.645508 451.167297,781.255127 450.306641,782.878052 
	C449.874084,782.891418 449.746735,782.781555 449.778870,782.434326 
	C450.456635,780.656921 451.102295,779.226746 451.747986,777.796570 
	C451.747986,777.796570 451.887329,777.914429 451.955383,777.975159 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M463.551514,853.465942 
	C463.585175,853.521667 463.517853,853.410156 463.551514,853.465942 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M449.999664,783.750305 
	C449.999084,783.999268 449.595917,784.000671 449.375549,783.751038 
	C449.207520,783.142456 449.404694,782.902527 449.746735,782.781555 
	C449.746735,782.781555 449.874084,782.891418 449.938141,782.947388 
	C450.002197,783.003357 450.000244,783.501343 449.999664,783.750305 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M679.589600,784.895630 
	C680.360046,786.335144 680.860107,787.558838 681.637451,788.964783 
	C685.229370,797.927185 685.230469,797.929382 676.412659,802.356628 
	C671.077515,805.035278 665.738342,807.706116 660.182434,810.913879 
	C659.963867,811.447327 659.841614,811.912964 659.841614,811.912964 
	C659.841614,811.912964 659.902405,811.923767 659.883179,811.898926 
	C658.407654,810.213623 656.951294,808.553223 655.118652,806.463806 
	C662.958923,802.469360 669.852600,798.957092 677.284668,795.170593 
	C675.355896,791.347290 673.524963,787.717896 671.543640,783.790405 
	C665.133606,786.963623 658.935364,790.032043 652.218689,793.357117 
	C651.333618,791.764709 650.508545,790.280273 649.450562,788.376709 
	C656.006287,785.025024 662.163757,781.876953 668.847168,778.459961 
	C666.982300,774.725037 665.187866,771.131226 663.190063,767.130066 
	C655.784302,770.737183 648.830078,774.124329 641.585571,777.662170 
	C640.329529,776.113220 639.363770,774.413574 638.317505,772.572266 
	C639.522827,771.910522 640.636047,771.299377 641.895752,770.391174 
	C648.132996,767.302673 654.230408,764.525757 660.310120,761.710754 
	C662.092224,760.885620 663.824158,759.952209 666.090881,758.810730 
	C667.855530,762.195984 669.559875,765.465698 671.552002,768.932129 
	C674.332886,774.312439 676.826050,779.496094 679.589600,784.895630 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M634.003113,770.109070 
	C634.839478,769.859070 635.527588,769.882690 636.215698,769.906250 
	C635.602234,770.949402 634.988770,771.992554 634.375244,773.035706 
	C634.028198,772.849854 633.681091,772.664062 633.333984,772.478271 
	C633.507629,771.779724 633.681274,771.081177 634.003113,770.109070 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M632.191162,798.798096 
	C631.945801,798.236084 631.999573,797.735474 632.053345,797.234863 
	C632.502686,797.348694 632.952148,797.462585 633.401550,797.576477 
	C633.097778,798.004150 632.794067,798.431824 632.191162,798.798096 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M673.786255,276.937073 
	C675.394165,277.586395 676.795837,278.311768 678.535156,279.081238 
	C675.503967,286.278473 672.170898,293.448761 668.752502,300.578125 
	C666.827820,304.592133 664.762268,308.538605 662.414551,313.202087 
	C668.469116,316.217865 674.311768,319.128113 680.508667,322.089355 
	C679.830200,324.091949 678.797424,326.043549 677.399658,327.974823 
	C669.537048,323.889038 662.039368,319.823578 654.158936,315.550537 
	C659.430603,304.643555 664.709961,293.720642 670.027832,282.433716 
	C670.066345,282.069702 670.036682,282.045044 670.345947,281.901062 
	C671.484375,280.190186 672.313538,278.623322 673.142700,277.056458 
	C673.142761,277.056488 673.580139,277.013092 673.786255,276.937073 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M634.808899,330.566711 
	C633.783997,330.416504 632.582520,329.981842 631.192871,329.286743 
	C632.213928,329.444946 633.423157,329.863586 634.808899,330.566711 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M713.854492,754.113892 
	C715.513672,751.567749 717.188538,749.016418 719.444397,745.580139 
	C717.474670,745.828247 715.505554,746.076294 712.082703,746.507507 
	C713.981812,744.148926 715.195740,742.440002 716.617493,740.926270 
	C718.426392,739.000488 720.215088,737.422058 717.196472,734.842102 
	C716.225769,734.012451 716.122925,731.894226 716.041199,730.343445 
	C715.624756,722.435303 715.442627,714.514343 714.982361,706.609253 
	C714.695923,701.689758 718.995239,699.558350 720.989136,696.037415 
	C721.505005,695.126465 722.408569,694.434998 722.745178,694.067444 
	C721.253723,691.954895 720.081238,690.294189 718.836792,688.231140 
	C718.764832,687.828796 718.789795,687.854431 719.171387,687.868774 
	C722.845215,686.721069 724.401611,684.464905 723.933777,680.932922 
	C724.117371,680.661316 724.300964,680.389709 724.951660,679.928589 
	C725.582153,679.449707 725.745544,679.160217 725.908997,678.870728 
	C725.908997,678.870728 725.871521,678.955872 726.221802,678.908203 
	C727.022583,677.891724 727.473145,676.922913 727.923706,675.954163 
	C727.923706,675.954163 727.975159,676.006653 728.208374,675.840454 
	C728.588562,675.331970 728.735596,674.989807 728.882690,674.647583 
	C728.882690,674.647583 728.867126,674.269592 729.185913,674.010620 
	C729.746765,673.545593 729.889648,673.284973 729.933228,672.969971 
	C730.704712,671.721863 731.476196,670.473755 732.896301,669.122925 
	C739.493225,671.598206 738.982239,677.590698 738.475830,681.870239 
	C737.899597,686.740295 739.618652,688.898499 742.851196,691.307861 
	C736.587830,694.844604 731.999939,699.386414 730.008240,706.200562 
	C727.399719,715.124878 731.342346,721.940918 737.156982,728.383179 
	C737.480835,728.780518 737.734619,728.901611 738.047241,728.938843 
	C738.060547,728.950500 738.097412,728.952087 738.097412,728.952087 
	C738.064941,733.975525 738.029419,738.998901 738.001648,744.022339 
	C737.991577,745.839661 737.788452,747.687195 738.041748,749.468567 
	C738.672363,753.903259 737.409973,756.355042 732.002197,755.841553 
	C731.286499,756.429199 731.110962,756.700317 731.033142,757.034729 
	C731.033142,757.034729 731.000000,757.000000 730.784424,757.130371 
	C730.413269,757.561890 730.257812,757.863037 730.102295,758.164246 
	C730.102295,758.164246 730.107788,758.039368 729.748108,758.049561 
	C728.348267,759.131042 727.308044,760.202332 726.267822,761.273621 
	C726.267822,761.273621 726.182190,761.247314 725.935425,761.263123 
	C725.496094,761.578613 725.303589,761.878296 725.111084,762.177917 
	C725.111023,762.177917 725.092224,762.020996 724.740479,762.035461 
	C722.511414,761.455688 720.634216,760.861450 718.756958,760.267273 
	C718.756958,760.267273 718.759827,760.200745 718.742676,759.939331 
	C718.439453,759.481689 718.153503,759.285461 717.867493,759.089233 
	C717.867493,759.089233 717.989380,759.100037 717.966797,758.720459 
	C717.734680,755.843262 717.160400,753.694336 713.901062,754.113403 
	C713.901062,754.113403 713.870239,754.108704 713.854492,754.113892 
z"/>
<path fill="#EAF8FE" opacity="1.000000" stroke="none" 
	d="
M737.041199,728.202332 
	C731.342346,721.940918 727.399719,715.124878 730.008240,706.200562 
	C731.999939,699.386414 736.587830,694.844604 743.272217,691.320129 
	C747.534485,690.754211 751.210022,690.474121 754.921997,690.169373 
	C754.958374,690.144714 754.876343,690.113159 755.117249,690.378174 
	C757.205017,691.141785 759.051819,691.640320 760.898682,692.138916 
	C760.898682,692.138916 760.986145,692.121216 761.123657,692.385742 
	C762.769226,693.514954 764.277405,694.379639 765.785522,695.244263 
	C765.785522,695.244263 765.866516,695.191650 765.895996,695.437866 
	C766.250244,695.846252 766.575073,696.008484 766.899902,696.170776 
	C766.899902,696.170776 766.921387,696.080994 766.946960,696.360291 
	C767.285217,696.809204 767.597961,696.978821 767.910645,697.148438 
	C767.910645,697.148438 767.874756,697.063660 767.893799,697.446716 
	C769.565918,700.359314 771.218994,702.888794 772.872131,705.418335 
	C772.872131,705.418335 772.772583,705.727783 772.842834,706.274475 
	C772.890381,711.188599 772.867676,715.556091 772.844910,719.923523 
	C772.844910,719.923523 772.988647,719.981689 772.626465,720.105957 
	C771.139282,722.450073 770.014343,724.669983 768.889404,726.889893 
	C768.889404,726.889893 768.890015,726.953796 768.649475,726.991211 
	C768.244751,727.330994 768.080566,727.633423 767.916382,727.935852 
	C767.916321,727.935852 767.884827,727.907043 767.862671,727.906372 
	C761.894348,733.336548 754.974976,735.220215 746.766602,733.537292 
	C746.943848,729.512634 748.372009,727.614441 752.990173,727.772766 
	C759.937012,728.010986 765.786743,722.680969 767.033936,715.930115 
	C768.412842,708.466309 764.539612,701.296997 757.366089,698.035828 
	C750.903564,695.097778 743.370178,696.930908 738.906006,702.527527 
	C734.517761,708.029175 734.679260,714.934631 739.674927,721.182312 
	C742.496643,717.635559 745.248962,714.175964 748.253235,710.399719 
	C749.608032,711.248596 750.927124,712.075195 752.850403,713.280334 
	C748.839661,718.473877 745.030273,723.406738 740.836792,728.684875 
	C740.001587,729.217896 739.550354,729.405640 739.099182,729.593323 
	C739.013916,729.291016 738.821045,729.094543 738.308960,728.978027 
	C738.097412,728.952087 738.060547,728.950500 737.977051,728.708252 
	C737.681641,728.144775 737.397522,728.056885 737.041199,728.202332 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M739.189575,729.883362 
	C739.550354,729.405640 740.001587,729.217896 740.696289,728.989502 
	C740.386536,729.356995 739.833252,729.765198 739.189575,729.883362 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M731.277222,756.994385 
	C731.110962,756.700317 731.286499,756.429199 731.778687,756.108276 
	C731.997559,755.995117 732.000549,756.412415 732.000366,756.621094 
	C732.000183,756.829712 731.521301,756.954102 731.277222,756.994385 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M757.047241,375.242737 
	C758.517456,378.046661 759.249451,381.526031 755.587036,383.390289 
	C751.557556,385.441467 748.398743,383.083099 745.854492,379.496155 
	C746.398621,379.372711 747.102112,379.466461 747.593506,379.839569 
	C753.927368,384.648895 755.644958,383.682800 757.047241,375.242737 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M742.919983,373.672546 
	C741.900024,370.672913 740.921021,367.267273 739.938599,363.452026 
	C740.626099,364.426758 741.316956,365.811066 742.026611,367.892761 
	C742.364380,369.061310 742.683411,369.532501 743.002441,370.003662 
	C742.988586,371.091309 742.974792,372.178925 742.919983,373.672546 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M751.635559,352.100708 
	C751.708435,354.736633 751.395996,357.440918 750.972168,360.556702 
	C750.990601,358.035187 751.120361,355.102081 751.635559,352.100708 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M755.664429,348.049744 
	C754.890625,349.279877 753.737000,350.491425 752.307861,351.858765 
	C753.116394,350.686737 754.200439,349.358948 755.664429,348.049744 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M755.030396,370.361786 
	C755.655151,371.114746 756.262146,372.233185 756.845581,373.679016 
	C756.218994,372.913330 755.615845,371.820282 755.030396,370.361786 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M744.931396,377.710449 
	C744.212341,376.973236 743.524475,375.877441 742.837524,374.445435 
	C743.546509,375.190094 744.254517,376.270966 744.931396,377.710449 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M743.230225,369.831726 
	C742.683411,369.532501 742.364380,369.061310 742.030029,368.290466 
	C742.495728,368.547180 742.976868,369.103516 743.230225,369.831726 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M752.061646,364.240479 
	C752.396423,364.399506 752.729187,364.864899 753.061584,365.638367 
	C752.727356,365.479889 752.393555,365.013367 752.061646,364.240479 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M754.053345,368.302032 
	C754.374390,368.460358 754.697083,368.914948 755.023743,369.661560 
	C754.703430,369.501770 754.379272,369.050049 754.053345,368.302032 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M753.065674,366.264313 
	C753.386414,366.427551 753.702393,366.890564 754.018555,367.650421 
	C753.699463,367.486206 753.380127,367.025146 753.065674,366.264313 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M757.938354,347.091919 
	C757.739319,347.412292 757.209961,347.714905 756.364014,348.039429 
	C756.567505,347.732300 757.087708,347.403229 757.938354,347.091919 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M745.795898,378.925598 
	C745.563721,378.993134 745.276306,378.802979 744.949280,378.341583 
	C745.186646,378.269562 745.463623,378.468750 745.795898,378.925598 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M751.105042,362.154266 
	C751.390869,361.998108 751.653442,362.175995 751.811157,362.719727 
	C751.508179,362.840240 751.299988,362.641144 751.105042,362.154266 
z"/>
<path fill="#2528D7" opacity="1.000000" stroke="none" 
	d="
M492.899872,287.596375 
	C492.899872,287.596375 492.887360,287.255707 493.215698,286.992676 
	C493.663727,286.424286 493.783447,286.118958 493.903137,285.813629 
	C493.903137,285.813629 493.923798,285.939301 494.212036,285.824280 
	C495.245667,283.816559 495.991089,281.923920 496.736511,280.031311 
	C494.807007,279.352631 492.908417,278.245697 490.940155,278.103455 
	C488.465393,277.924591 485.933594,278.535217 482.393463,278.916382 
	C483.934509,274.639893 494.065735,272.351349 484.726318,266.954285 
	C487.315704,266.954285 489.905090,266.954285 493.297882,266.954285 
	C492.580261,265.332489 491.841766,263.663452 491.103241,261.994415 
	C491.103241,261.994415 490.885620,261.687164 490.925354,261.094971 
	C489.912140,259.721527 488.859222,258.940277 487.806274,258.159027 
	C487.806274,258.159027 487.897583,258.109039 487.815125,257.882019 
	C487.395386,257.497864 487.058105,257.340698 486.720856,257.183533 
	C486.720856,257.183533 486.675812,257.211639 486.651123,257.222290 
	C487.312958,253.195160 488.223297,249.052460 481.053986,248.275543 
	C486.262238,244.161026 485.560364,239.514420 485.138397,234.504547 
	C484.275055,224.254044 489.516510,223.990280 499.810913,222.061569 
	C503.524933,221.365707 507.363281,221.333572 511.572449,220.998657 
	C512.196350,227.583130 512.216064,234.180588 512.702454,240.743439 
	C512.838928,242.584976 514.347961,244.324783 515.227417,246.111237 
	C515.664917,245.924591 516.102478,245.737930 516.539978,245.551285 
	C516.539978,244.199677 516.539978,242.848068 516.539978,240.786057 
	C518.466736,242.664429 521.910522,242.854416 518.896057,246.650452 
	C517.823975,247.424408 516.835571,247.921234 516.846558,248.394775 
	C517.170288,262.288635 517.593506,276.180206 518.011047,290.419861 
	C520.462158,290.252899 522.356201,290.123901 524.237061,289.995789 
	C524.237061,283.863098 524.237061,278.255280 524.237061,271.747681 
	C531.666382,271.747681 539.109375,271.747681 547.135132,271.747681 
	C547.135132,277.169220 547.135132,283.076233 546.805908,288.719818 
	C546.314514,284.506226 545.846619,280.538177 546.076477,276.610962 
	C546.304138,272.721161 544.755249,271.530853 541.173706,272.003510 
	C536.180542,272.662506 531.176392,273.237915 526.344727,273.828522 
	C526.344727,279.017273 526.520813,283.812164 526.221863,288.577271 
	C526.146484,289.778992 524.699951,291.678253 523.626770,291.878113 
	C521.024719,292.362579 518.271545,292.035553 515.579285,292.035553 
	C519.725891,294.913971 523.894348,297.047180 518.328064,302.163513 
	C519.638062,302.836517 520.695801,303.379944 522.997009,304.562195 
	C519.719727,306.023529 517.535950,306.997253 515.279785,308.003265 
	C515.935425,308.761047 516.467224,309.375763 516.999084,309.990479 
	C514.177917,310.658051 511.383209,311.489166 508.528839,311.951843 
	C504.966736,312.529236 501.166229,312.231293 497.809723,313.342041 
	C492.763153,315.012115 489.927734,313.377686 488.080139,308.829254 
	C487.531586,307.478760 486.865936,306.175842 486.191864,304.716797 
	C487.384460,303.223663 488.587463,301.717529 489.695892,300.329742 
	C488.008911,299.045380 486.521759,297.913116 485.278046,296.396362 
	C485.689117,296.011627 485.856720,296.011475 486.422485,296.016846 
	C492.959045,297.468170 492.959045,297.468170 492.998627,290.573364 
	C492.968048,289.297577 492.933960,288.446991 492.899872,287.596375 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M547.135132,288.983246 
	C547.135132,283.076233 547.135132,277.169220 547.135132,271.747681 
	C539.109375,271.747681 531.666382,271.747681 524.237061,271.747681 
	C524.237061,278.255280 524.237061,283.863098 524.237061,289.995789 
	C522.356201,290.123901 520.462158,290.252899 518.011047,290.419861 
	C517.593506,276.180206 517.170288,262.288635 516.846558,248.394775 
	C516.835571,247.921234 517.823975,247.424408 518.690247,246.846710 
	C520.187683,246.855133 521.341370,246.955261 522.885193,247.089264 
	C523.177856,253.129837 523.471558,259.191956 523.755310,265.047852 
	C531.649902,265.047852 539.034790,265.047852 546.451477,265.047852 
	C546.451477,258.498535 546.451477,252.519516 546.451477,245.987839 
	C548.493530,245.987839 550.268250,245.987839 552.475586,246.041611 
	C552.908264,246.095383 552.958130,246.049606 552.937134,246.505341 
	C552.916199,260.874390 552.916199,274.787689 552.916199,288.875458 
	C551.807007,289.107300 549.918823,289.501953 547.973755,289.668518 
	C547.740906,289.143250 547.480347,288.990875 547.135132,288.983246 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M527.181335,219.319870 
	C527.551331,219.227829 528.094727,219.358307 528.799866,219.725754 
	C528.425964,219.822617 527.890381,219.682510 527.181335,219.319870 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M368.979431,297.081055 
	C362.701813,301.524200 355.916382,305.791260 349.381989,310.413239 
	C346.484802,312.462524 344.095062,315.229218 341.556824,317.594025 
	C344.320618,322.931488 347.725494,320.996735 351.544495,317.905396 
	C352.744141,321.695923 349.818695,326.774872 355.481964,328.774628 
	C354.186035,332.926849 352.907806,336.902496 351.730743,340.907867 
	C351.526398,341.603210 351.766235,342.424072 351.771851,343.187195 
	C351.783417,344.757050 351.775330,346.327026 351.775330,346.791046 
	C351.596527,350.919739 351.452454,353.652588 351.372192,356.387299 
	C351.339386,357.505310 350.986481,359.202209 351.558441,359.657959 
	C356.866791,363.888245 351.381714,369.580719 353.532166,374.215149 
	C353.555511,374.265472 351.170898,375.433136 349.208099,376.439117 
	C349.360413,375.973694 348.936279,376.611908 349.101196,376.862366 
	C352.881958,382.605042 350.629639,388.987244 350.853821,395.040985 
	C351.010406,399.269562 352.154053,401.706909 356.913818,402.185730 
	C349.743622,414.723602 342.165649,427.176422 334.306000,439.831360 
	C332.735107,440.426361 331.445923,440.819153 329.683197,441.356201 
	C326.447876,438.575134 324.801880,435.313507 325.703735,429.818817 
	C326.844208,422.870728 325.821747,415.589813 326.069214,408.464142 
	C326.205353,404.544525 327.033020,400.648956 327.601013,396.347931 
	C327.468658,396.347931 326.396820,396.500305 325.389404,396.310699 
	C324.022614,396.053497 322.705109,395.534393 321.366486,395.127625 
	C322.646454,394.419708 323.871918,393.569519 325.224609,393.051392 
	C326.211761,392.673279 327.366516,392.732666 328.748901,392.559052 
	C327.680176,391.014801 326.882599,389.862335 326.463318,388.360107 
	C329.198700,387.250854 331.555847,386.491394 333.912994,385.731995 
	C341.999786,382.382263 345.877136,375.835297 348.092194,367.084412 
	C347.991058,362.658752 347.581543,358.787872 346.736511,355.014526 
	C346.559601,354.224396 344.777588,353.793671 343.735199,353.197327 
	C343.735199,353.197327 343.852081,353.145203 343.794373,352.934875 
	C343.458435,352.495483 343.180267,352.266388 342.902100,352.037262 
	C342.902100,352.037262 342.980499,352.015594 342.975372,351.664062 
	C341.601807,350.238922 340.233337,349.165344 338.864899,348.091766 
	C338.864899,348.091766 338.959503,348.051849 338.928589,347.782043 
	C338.489777,347.172974 338.081879,346.833649 337.673981,346.494324 
	C337.486389,347.055328 337.298828,347.616272 337.111267,348.177246 
	C337.111267,348.177246 337.066498,348.081238 336.718018,348.062317 
	C330.481842,347.888611 334.103821,345.545807 336.016174,343.189545 
	C333.847198,343.189545 332.304474,343.189545 330.761719,343.189545 
	C330.761719,343.189545 330.863556,343.119507 330.901733,342.869019 
	C330.961945,342.409912 330.984039,342.201294 331.006104,341.992676 
	C331.553741,340.856323 332.101379,339.719971 332.802368,338.265350 
	C328.374481,336.786652 327.692078,333.411469 327.951660,329.293610 
	C328.438293,321.573639 327.685547,313.625793 329.325104,306.186310 
	C330.257812,301.954102 331.532562,296.215027 337.863617,294.524139 
	C339.715881,294.029449 340.669098,290.168549 342.060791,287.468323 
	C346.975800,283.966125 351.893555,280.890198 356.732483,277.694855 
	C357.926880,276.906128 358.864929,275.729187 359.992371,274.399506 
	C360.063904,274.068268 360.541840,274.023102 360.781738,274.009796 
	C367.460266,278.026062 361.679962,279.497498 358.865143,281.806305 
	C360.790131,283.126556 362.395355,284.227478 365.334930,286.243561 
	C362.426208,286.630432 360.685211,286.408905 359.575012,287.142303 
	C358.548523,287.820435 357.538239,290.554230 357.644928,290.622375 
	C361.204407,292.895386 364.898956,294.956879 368.979431,297.081055 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M311.440063,767.012146 
	C311.025970,766.846191 311.018219,766.674438 311.005005,766.244751 
	C312.697388,765.090210 314.395233,764.193665 316.093079,763.297119 
	C316.322266,763.854065 316.775116,764.887451 316.748688,764.899841 
	C315.139648,765.655273 313.491180,766.326904 311.440063,767.012146 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M497.023560,787.970581 
	C497.005096,786.981140 497.340759,785.790588 496.911377,785.034363 
	C493.863281,779.666138 495.185760,774.691345 498.419312,769.411011 
	C503.048645,769.314148 507.250580,769.590881 511.452545,769.867676 
	C511.452545,769.867676 511.730438,769.700012 512.107544,770.110779 
	C517.331604,771.028015 522.178528,771.534485 527.025513,772.041016 
	C527.024353,776.844055 526.644409,781.691589 527.200073,786.429321 
	C527.437561,788.453552 529.626282,790.248962 530.538940,792.567688 
	C528.752319,793.107483 527.355103,793.227905 526.754761,793.279663 
	C526.754761,808.061096 526.754761,822.262512 526.754761,836.966248 
	C529.450562,836.547546 531.161316,836.281860 533.253296,835.956909 
	C533.253296,829.664185 533.253296,823.760132 533.253296,818.225159 
	C541.056030,817.804565 548.373291,817.410156 556.523376,816.970825 
	C556.523376,823.116089 556.523376,828.569275 556.523315,834.022400 
	C555.341919,829.065857 554.160461,824.109314 552.927917,818.938171 
	C548.632507,819.262268 543.613770,819.477173 538.657837,820.152893 
	C537.665222,820.288269 536.145935,822.236755 536.222107,823.247253 
	C536.346191,824.893738 537.584473,826.456177 538.580261,828.527771 
	C533.814697,830.784607 536.925415,840.338318 527.850708,839.404358 
	C528.862000,842.428772 529.215698,845.111816 532.935181,842.780396 
	C533.470093,842.445068 534.590027,843.043030 535.439209,843.209106 
	C534.986450,844.122070 534.745728,845.566589 534.040894,845.847839 
	C531.962402,846.676941 529.700195,847.045410 528.045715,847.459900 
	C528.382263,850.685852 528.174316,853.925110 529.189575,856.720154 
	C529.961060,858.844177 532.288086,860.403137 533.985413,862.569641 
	C522.864441,861.942383 511.563873,861.582642 500.583252,859.519897 
	C497.968231,859.028687 496.268219,852.919556 494.476196,849.234497 
	C493.914032,848.078430 494.944031,846.118408 494.344635,845.016052 
	C492.976624,842.500122 493.317780,840.798340 495.416321,838.940002 
	C496.284851,838.170776 496.929688,836.643250 496.915894,835.469543 
	C496.851227,829.960815 497.137665,824.749573 500.571106,819.911133 
	C501.872345,818.077393 501.252838,814.943970 501.719269,812.442261 
	C502.955536,805.811157 504.328278,799.205505 505.559631,792.573547 
	C505.636139,792.161316 504.960938,791.609497 504.284607,791.086792 
	C503.933716,791.050903 503.977722,791.031006 503.942963,790.724731 
	C503.274780,789.943909 502.641357,789.469421 501.760895,788.984253 
	C501.513824,788.973572 501.019531,788.956238 500.901489,788.746826 
	C500.577789,788.276001 500.314941,788.099731 499.623230,788.003784 
	C498.508881,787.989441 497.766205,787.980042 497.023560,787.970581 
M500.431305,827.703674 
	C500.704193,827.989563 500.977081,828.275391 501.249969,828.561279 
	C501.368286,828.448120 501.595734,828.242737 501.589172,828.235107 
	C501.334503,827.933533 501.058502,827.650024 500.431305,827.703674 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M486.182129,832.358765 
	C488.065704,832.366943 490.123352,832.692810 492.574219,833.113159 
	C490.763733,833.030579 488.559967,832.853516 486.182129,832.358765 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M480.784180,254.006897 
	C473.425751,253.032974 466.191498,253.046448 459.432312,258.049622 
	C459.879211,256.750153 460.418610,255.079971 461.555298,254.109390 
	C464.915527,251.240250 476.705963,251.077988 480.784180,254.006897 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M411.983337,273.885620 
	C411.983307,273.885620 411.640747,274.101044 411.123627,274.060883 
	C410.396729,273.999451 410.186951,273.978210 409.977203,273.956940 
	C409.944763,268.286896 413.518707,268.810608 417.652954,270.114441 
	C418.010254,270.716980 418.015747,270.897614 418.021271,271.078278 
	C417.542633,271.377899 417.064026,271.677551 415.995239,272.014130 
	C414.934326,272.339020 414.463562,272.627014 413.992767,272.915009 
	C413.992767,272.915009 413.764862,273.156189 413.314880,273.169434 
	C412.571045,273.417023 412.277191,273.651337 411.983337,273.885620 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M423.205536,279.798584 
	C423.569305,279.196594 423.995819,278.893860 424.422333,278.591125 
	C424.537109,279.038361 424.651917,279.485565 424.766724,279.932800 
	C424.267242,279.987823 423.767761,280.042847 423.205536,279.798584 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M421.934570,268.988770 
	C421.404022,269.670929 420.708557,270.068390 419.713501,270.505859 
	C420.199158,269.931915 420.984406,269.317993 421.934570,268.988770 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M408.817749,275.067108 
	C408.610962,275.380432 408.100372,275.691223 407.284607,275.933990 
	C407.490997,275.598816 408.002472,275.331665 408.817749,275.067108 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M409.754456,274.091919 
	C410.186951,273.978210 410.396729,273.999451 410.921753,274.047180 
	C410.720276,274.409180 410.203491,274.744690 409.363892,275.103821 
	C409.204620,274.827271 409.368164,274.527100 409.754456,274.091919 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M418.266785,271.060303 
	C418.015747,270.897614 418.010254,270.716980 417.996277,270.265350 
	C418.219727,270.051849 418.451691,270.109406 418.994934,270.349518 
	C419.133698,270.845398 418.869049,271.015472 418.266785,271.060303 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M660.024658,811.884094 
	C659.841614,811.912964 659.963867,811.447327 660.085693,811.239746 
	C660.306335,811.236206 660.420898,811.435364 660.488647,811.649292 
	C660.499817,811.684692 660.306519,811.784973 660.024658,811.884094 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M330.616943,341.994171 
	C330.984039,342.201294 330.961945,342.409912 330.886841,342.929016 
	C328.838196,343.144257 326.842621,343.049042 324.423462,342.873932 
	C326.075867,342.527924 328.151825,342.261810 330.616943,341.994171 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M754.000000,633.999512 
	C755.093750,633.721985 756.175476,633.371521 757.284851,633.188538 
	C758.270142,633.026001 759.286194,633.049194 760.288635,632.990356 
	C760.167419,634.169312 760.399231,636.171143 759.869690,636.398376 
	C755.187866,638.406982 756.899414,642.103882 756.940918,645.576111 
	C757.089233,657.991394 756.958313,670.409729 757.040100,682.826294 
	C757.054016,684.934448 757.595215,687.039246 757.557800,689.568604 
	C756.440613,690.032166 755.658508,690.072632 754.876343,690.113159 
	C754.876343,690.113159 754.958374,690.144714 754.887573,689.756226 
	C752.691895,688.910217 750.523376,688.591553 748.458740,687.942383 
	C747.241516,687.559570 745.231201,686.672363 745.216919,685.978333 
	C745.119995,681.277588 745.460571,676.567810 745.682068,671.330078 
	C743.007019,669.883179 742.919739,666.631836 745.638550,664.699951 
	C749.663513,661.839783 747.282043,659.972107 745.812256,657.407104 
	C744.922913,655.855042 743.774292,653.469849 744.361511,652.219421 
	C747.278687,646.007324 750.734131,640.047913 754.000000,633.999512 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M754.007141,633.610352 
	C750.734131,640.047913 747.278687,646.007324 744.361511,652.219421 
	C743.774292,653.469849 744.922913,655.855042 745.812256,657.407104 
	C747.282043,659.972107 749.663513,661.839783 745.638550,664.699951 
	C742.919739,666.631836 743.007019,669.883179 745.682068,671.330078 
	C745.460571,676.567810 745.119995,681.277588 745.216919,685.978333 
	C745.231201,686.672363 747.241516,687.559570 748.458740,687.942383 
	C750.523376,688.591553 752.691895,688.910217 754.851196,689.780884 
	C751.210022,690.474121 747.534485,690.754211 743.437805,691.022095 
	C739.618652,688.898499 737.899597,686.740295 738.475830,681.870239 
	C738.982239,677.590698 739.493225,671.598206 733.172119,668.962097 
	C739.242737,656.806335 745.714661,644.723877 752.104492,632.598145 
	C753.341492,630.250793 754.257629,627.734497 755.606445,625.090942 
	C755.265503,627.664246 754.639893,630.442749 754.007141,633.610352 
M740.466309,666.176331 
	C740.052795,667.218628 739.639221,668.260925 739.225647,669.303284 
	C739.865295,669.397095 740.505005,669.490845 741.144653,669.584656 
	C741.144653,668.492004 741.144653,667.399353 740.466309,666.176331 
M741.019958,660.505188 
	C741.337524,661.189819 741.655090,661.874512 741.972656,662.559204 
	C742.326050,662.445068 742.679443,662.330994 743.032776,662.216858 
	C742.742615,661.000122 742.452393,659.783386 742.162231,658.566650 
	C741.790833,658.975159 741.419434,659.383667 741.019958,660.505188 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M771.145447,541.001038 
	C770.940430,510.841217 765.283569,481.753052 754.005981,453.140015 
	C754.041870,452.336578 754.070435,452.164337 754.099060,451.992096 
	C755.673828,453.979553 757.909485,455.735199 758.702881,457.996765 
	C761.368042,465.594086 764.000549,473.265076 765.738770,481.108795 
	C767.683716,489.885651 768.899841,498.850800 769.959167,507.790192 
	C771.233032,518.539490 772.007263,529.347961 772.688232,540.565430 
	C771.967590,541.000366 771.556519,541.000671 771.145447,541.001038 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M771.068481,541.462646 
	C771.556519,541.000671 771.967590,541.000366 772.686890,541.000000 
	C772.664246,546.440857 772.555481,551.906494 771.952393,557.317017 
	C770.953369,566.279724 769.699341,575.215942 768.427063,584.145447 
	C767.757507,588.845093 766.821777,593.506897 766.003784,598.842041 
	C766.001282,599.498291 765.997559,599.995117 765.997559,599.995117 
	C763.998413,606.720581 761.999207,613.446045 759.701294,620.624268 
	C759.082886,621.484497 758.763306,621.891968 758.443726,622.299438 
	C758.961670,622.533752 759.479614,622.768127 759.997559,623.002441 
	C759.735229,623.495728 759.472900,623.988953 759.105286,624.741089 
	C759.000000,625.000000 759.000000,624.997559 759.000000,624.997559 
	C758.208801,624.985901 757.417542,624.974304 756.231445,624.920715 
	C758.693909,615.260498 761.848022,605.718628 764.344177,596.007507 
	C768.897644,578.292053 771.187378,560.248413 771.068481,541.462646 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M773.181458,719.732849 
	C772.867676,715.556091 772.890381,711.188599 772.947021,706.399231 
	C776.244263,710.376831 775.624207,714.930054 773.181458,719.732849 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M772.908447,704.995850 
	C771.218994,702.888794 769.565918,700.359314 767.870117,697.486206 
	C772.260132,697.741699 772.857361,700.982056 772.908447,704.995850 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M769.279785,726.919312 
	C770.014343,724.669983 771.139282,722.450073 772.561890,720.062988 
	C772.805359,722.703064 773.443115,725.823120 769.279785,726.919312 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M764.173218,384.815796 
	C764.104431,382.782593 764.431580,380.750458 764.815430,378.326172 
	C764.771179,380.228271 764.670166,382.522552 764.173218,384.815796 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M765.832764,694.890747 
	C764.277405,694.379639 762.769226,693.514954 761.080383,692.375000 
	C763.040466,691.929993 765.664978,690.771973 765.832764,694.890747 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M755.117249,690.378174 
	C755.658508,690.072632 756.440613,690.032166 757.613831,689.982056 
	C758.962402,690.455383 759.919983,690.938354 760.888062,691.780151 
	C759.051819,691.640320 757.205017,691.141785 755.117249,690.378174 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M764.943054,377.132141 
	C764.560913,376.544861 764.294250,375.630859 764.056396,374.349854 
	C764.409668,374.923706 764.734070,375.864563 764.943054,377.132141 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M758.998779,625.000000 
	C759.508850,625.302368 760.020081,625.604675 760.531372,625.907043 
	C760.349365,626.117065 760.167297,626.327087 759.985229,626.537109 
	C759.679932,626.213867 759.374573,625.890686 759.034668,625.282471 
	C759.000000,624.997559 759.000000,625.000000 758.998779,625.000000 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M759.998535,622.668701 
	C759.479614,622.768127 758.961670,622.533752 758.443726,622.299438 
	C758.763306,621.891968 759.082886,621.484497 759.701172,621.038513 
	C759.999817,621.445007 759.999695,621.889954 759.998535,622.668701 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M754.065308,451.663147 
	C754.070435,452.164337 754.041870,452.336578 753.987549,452.769165 
	C753.580566,452.279816 753.199219,451.530182 752.771973,450.454285 
	C752.940063,450.100677 753.154053,450.073334 753.691284,450.029114 
	C754.020203,450.452850 754.025940,450.893524 754.065308,451.663147 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M763.863831,373.176819 
	C763.644043,373.175079 763.395203,372.893250 763.156860,372.320709 
	C763.389832,372.318939 763.612305,372.607849 763.863831,373.176819 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M766.814575,695.907410 
	C766.575073,696.008484 766.250244,695.846252 765.854736,695.412659 
	C766.099121,695.308899 766.414124,695.476501 766.814575,695.907410 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M767.824829,696.891602 
	C767.597961,696.978821 767.285217,696.809204 766.902039,696.370850 
	C767.134033,696.279602 767.436584,696.457153 767.824829,696.891602 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M766.165405,599.908936 
	C765.997559,599.995117 766.001282,599.498291 766.004272,599.249939 
	C766.179871,599.168396 766.367798,599.323486 766.511841,599.512085 
	C766.539917,599.548828 766.397217,599.715820 766.165405,599.908936 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M760.175049,388.866577 
	C759.979858,388.589142 760.150452,388.321777 760.703369,388.092773 
	C760.865967,388.364960 760.684387,388.613464 760.175049,388.866577 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M762.265869,386.860657 
	C762.067200,386.615387 762.213440,386.346680 762.722656,386.075653 
	C762.883423,386.311462 762.725159,386.566071 762.265869,386.860657 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M763.217896,385.836609 
	C763.107849,385.615082 763.228516,385.284943 763.611267,384.891113 
	C763.731689,385.127655 763.590149,385.427826 763.217896,385.836609 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M768.156067,727.844360 
	C768.080566,727.633423 768.244751,727.330994 768.670959,726.967529 
	C768.753906,727.188660 768.574829,727.470764 768.156067,727.844360 
z"/>
<path fill="#128AF8" opacity="1.000000" stroke="none" 
	d="
M691.080139,451.949249 
	C691.244568,451.976868 691.408936,452.004456 691.964966,452.315125 
	C692.672180,452.695251 692.987671,452.792328 693.303223,452.889374 
	C706.301331,460.907745 713.835449,472.671478 715.749939,487.634460 
	C718.421570,508.514740 710.199524,526.546692 693.427612,538.644775 
	C698.314270,542.038879 703.255005,545.470642 708.221252,549.319702 
	C708.246765,549.737061 707.952209,550.047119 707.576538,549.985229 
	C704.026794,548.299805 700.852783,546.676208 698.089844,545.263000 
	C698.089844,571.380676 698.089844,598.433838 698.089844,625.320618 
	C703.389038,627.266052 705.725769,624.200439 708.327637,620.863647 
	C709.799133,620.519470 710.944031,620.318909 712.088928,620.118408 
	C706.633362,624.395203 701.177856,628.671936 695.258789,632.802734 
	C694.609009,632.866638 694.422791,633.076477 694.236572,633.286377 
	C694.236511,633.286316 694.145935,633.252869 693.916260,633.243958 
	C693.467651,633.481018 693.248718,633.727051 693.029663,633.973022 
	C692.540588,634.283752 692.051514,634.594604 691.053650,634.627502 
	C688.696655,632.346680 686.848389,630.343750 685.271790,628.635254 
	C686.768921,626.961548 688.979187,625.388184 688.728210,624.633667 
	C685.904785,616.147156 690.620728,607.228638 687.096008,598.819580 
	C686.633789,597.716919 687.102234,596.198730 687.224548,594.880859 
	C687.685852,589.907532 688.187744,584.937866 688.629883,579.962891 
	C688.639648,579.853760 688.116516,579.697266 687.755920,579.520752 
	C687.195984,580.542542 686.644043,581.549683 686.092102,582.556763 
	C685.617371,582.400879 685.142578,582.244934 684.667786,582.088989 
	C685.260071,579.921753 685.592224,577.636841 686.506653,575.615356 
	C687.827209,572.696289 689.692322,570.016968 685.816101,567.534973 
	C685.360046,567.242859 685.248962,565.568237 685.634705,564.933838 
	C690.387756,557.117065 688.057007,548.263489 688.574646,540.065369 
	C689.394287,527.084961 687.621826,513.944824 687.036499,500.868195 
	C686.925842,498.395874 687.302429,495.905762 687.339600,493.422119 
	C687.502502,482.538849 688.075378,471.631989 687.525452,460.783051 
	C687.320068,456.731384 687.790283,454.068176 691.080139,451.949249 
z"/>
<path fill="#1292F7" opacity="1.000000" stroke="none" 
	d="
M360.817261,568.138794 
	C355.565887,548.271851 356.115417,528.943726 362.186340,509.761688 
	C362.832886,507.718719 363.258514,505.547791 366.409729,506.066284 
	C379.174835,508.166534 391.961639,510.135040 405.415955,512.258789 
	C396.834564,531.956604 396.661285,551.279785 405.853882,570.962891 
	C396.164581,572.646301 386.737122,574.283264 377.310120,575.923035 
	C375.999695,576.151001 374.691986,576.394287 373.382446,576.627258 
	C363.518372,578.382690 363.518311,578.382385 360.817261,568.138794 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M690.973145,451.643372 
	C687.790283,454.068176 687.320068,456.731384 687.525452,460.783051 
	C688.075378,471.631989 687.502502,482.538849 687.339600,493.422119 
	C687.302429,495.905762 686.925842,498.395874 687.036499,500.868195 
	C687.621826,513.944824 689.394287,527.084961 688.574646,540.065369 
	C688.057007,548.263489 690.387756,557.117065 685.634705,564.933838 
	C685.248962,565.568237 685.360046,567.242859 685.816101,567.534973 
	C689.692322,570.016968 687.827209,572.696289 686.506653,575.615356 
	C685.592224,577.636841 685.260071,579.921753 684.667786,582.088989 
	C685.142578,582.244934 685.617371,582.400879 686.092102,582.556763 
	C686.644043,581.549683 687.195984,580.542542 687.755920,579.520752 
	C688.116516,579.697266 688.639648,579.853760 688.629883,579.962891 
	C688.187744,584.937866 687.685852,589.907532 687.224548,594.880859 
	C687.102234,596.198730 686.633789,597.716919 687.096008,598.819580 
	C690.620728,607.228638 685.904785,616.147156 688.728210,624.633667 
	C688.979187,625.388184 686.768921,626.961548 685.271790,628.635254 
	C686.848389,630.343750 688.696655,632.346680 690.754272,634.600464 
	C687.540161,635.946045 684.116699,637.040771 679.866333,638.093628 
	C677.038269,638.046143 675.037170,638.040466 672.735718,637.800659 
	C671.956970,636.021729 671.101379,634.485657 671.065369,632.930603 
	C670.910400,626.235474 671.307068,619.515991 670.903076,612.841614 
	C670.528198,606.647949 670.907532,601.090271 676.383057,596.960754 
	C677.363953,596.220947 677.521240,594.389160 678.410767,592.980469 
	C682.894165,587.969238 683.499756,573.766479 679.731750,568.814026 
	C679.383362,568.356140 678.619263,568.214539 677.934937,567.720459 
	C677.632324,567.257690 677.396667,567.054321 677.074341,566.544189 
	C676.558289,565.163635 676.362854,563.686035 675.562561,563.195801 
	C669.199036,559.297668 670.962524,553.199036 670.853027,547.443970 
	C670.714050,540.134644 672.302917,532.685791 669.561401,525.305725 
	C670.169434,524.451172 671.797485,522.750305 671.553467,522.416016 
	C668.815491,518.665222 672.588074,516.917358 674.034363,514.941467 
	C679.510620,507.460144 677.737488,499.737366 675.323914,491.993713 
	C674.948608,490.789520 673.063965,490.055786 671.877258,489.104492 
	C671.877258,489.104492 671.903320,489.014740 671.934509,488.655823 
	C671.172119,485.463806 669.875671,482.660675 669.704346,479.790436 
	C669.455933,475.626648 670.015869,471.414093 670.239014,467.222809 
	C670.544983,461.476624 670.681335,455.713715 671.235962,449.991455 
	C671.563843,446.608856 673.465698,445.151001 677.110657,446.485413 
	C680.015137,447.548737 683.109558,448.093048 686.119690,448.867767 
	C686.119690,448.867767 686.017273,448.932190 686.186584,449.166809 
	C687.157349,449.613190 687.958740,449.824951 688.760193,450.036682 
	C689.462158,450.470306 690.164124,450.903900 690.973145,451.643372 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M607.098755,639.942505 
	C592.293152,639.942505 577.487488,639.942505 562.096375,639.942505 
	C562.096375,601.779968 562.096375,563.690796 562.338745,525.195435 
	C562.581055,524.789185 562.678833,524.641541 562.678833,524.641602 
	C581.149170,524.564331 599.619568,524.487061 618.523438,524.762878 
	C618.972412,525.401062 618.975891,525.686401 618.504395,525.972351 
	C613.025085,525.981934 608.008850,525.990967 602.525574,525.995239 
	C596.708191,525.999390 591.357849,526.008240 585.549683,526.010498 
	C584.758545,526.002991 584.425293,526.001221 584.091980,526.001282 
	C574.983215,526.002869 574.992249,526.002869 574.993530,534.696350 
	C574.997803,563.859619 574.983459,593.022827 575.014893,622.186096 
	C575.017700,624.809875 575.668091,627.474243 575.382568,630.049377 
	C574.499329,638.016113 574.380066,638.003052 582.500305,638.004578 
	C583.333557,638.004761 584.166748,638.001038 585.449463,638.000854 
	C591.599487,638.303772 597.300049,638.604919 603.411987,638.931335 
	C604.930481,638.971924 606.037598,638.987183 607.132812,639.237427 
	C607.120911,639.472412 607.098755,639.942505 607.098755,639.942505 
z"/>
<path fill="#1292F7" opacity="1.000000" stroke="none" 
	d="
M511.035583,529.299561 
	C524.116089,529.299561 537.196594,529.299561 550.713989,529.299561 
	C552.214111,555.245544 549.679138,580.021545 533.184937,601.883057 
	C531.363525,600.564575 529.284119,599.183594 529.223938,597.719604 
	C528.884033,589.449341 528.961365,581.158630 529.023010,572.875854 
	C529.128113,558.761902 529.326904,544.648682 529.491821,530.051697 
	C526.250366,530.051697 522.142273,530.051697 517.593994,530.026978 
	C515.104004,529.977966 513.054260,529.953674 511.005615,529.771973 
	C511.006653,529.614685 511.035583,529.299561 511.035583,529.299561 
z"/>
<path fill="#157EF8" opacity="1.000000" stroke="none" 
	d="
M518.034241,530.051697 
	C522.142273,530.051697 526.250366,530.051697 529.491821,530.051697 
	C529.326904,544.648682 529.128113,558.761902 529.023010,572.875854 
	C528.961365,581.158630 528.884033,589.449341 529.223938,597.719604 
	C529.284119,599.183594 531.363525,600.564575 532.893982,602.042725 
	C530.400879,605.669861 527.524231,609.238159 524.113403,613.021729 
	C523.299255,613.425110 523.125122,613.684387 523.056885,614.014771 
	C523.056885,614.014771 523.031738,614.031616 522.804077,614.132202 
	C522.300232,614.418518 522.127075,614.673950 522.056763,614.999146 
	C522.056763,614.999146 522.042297,615.044678 521.806396,615.132812 
	C521.299988,615.394592 521.127747,615.637817 521.053833,615.950684 
	C521.053833,615.950684 521.040039,616.037292 520.606323,616.023071 
	C516.096375,616.359924 515.406555,613.416626 515.420593,610.537598 
	C515.469604,600.483948 515.886780,590.432495 515.974182,580.378540 
	C516.082031,567.972900 515.826721,555.561768 516.093323,543.160889 
	C516.187500,538.779236 517.356140,534.420776 518.034241,530.051697 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M607.546875,640.046387 
	C607.098755,639.942505 607.120911,639.472412 607.581543,639.242798 
	C611.531006,639.005615 615.031494,639.175049 618.505554,638.944641 
	C622.642029,638.670288 624.979370,637.221680 624.350159,632.062622 
	C623.578064,625.731445 624.052551,619.247437 624.003540,612.829407 
	C623.982422,610.064941 624.000000,607.300293 624.000000,604.027832 
	C618.244873,603.650635 613.167053,603.317810 608.070801,602.781494 
	C608.052368,602.577942 608.025513,602.170105 608.483154,602.078247 
	C626.620117,602.035217 644.299500,602.084167 661.864868,602.370850 
	C661.490051,603.065125 661.229187,603.521606 660.508301,603.982239 
	C657.058838,603.991882 654.066833,603.924316 651.080261,604.021606 
	C647.541931,604.136902 646.327454,606.352173 646.308655,609.580688 
	C646.262695,617.476868 646.284302,625.375488 646.074158,633.267578 
	C646.000427,636.037476 645.843567,638.139648 649.669312,638.097595 
	C653.441589,638.056091 657.220764,638.630554 661.435791,638.955139 
	C664.850220,639.167236 667.825806,639.358948 670.801331,639.550659 
	C660.009644,639.754822 649.218689,640.035400 638.426025,640.140930 
	C628.283325,640.240112 618.138733,640.154541 607.546875,640.046387 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M517.593994,530.026978 
	C517.356140,534.420776 516.187500,538.779236 516.093323,543.160889 
	C515.826721,555.561768 516.082031,567.972900 515.974182,580.378540 
	C515.886780,590.432495 515.469604,600.483948 515.420593,610.537598 
	C515.406555,613.416626 516.096375,616.359924 520.562500,616.024658 
	C518.482056,618.224243 516.011719,620.407959 512.949951,622.438354 
	C510.448395,622.406311 508.538300,622.527649 506.313751,622.669006 
	C506.220581,621.206848 505.787140,619.822998 506.112976,618.650024 
	C508.461029,610.197205 506.607635,601.697510 506.588257,593.222717 
	C506.586487,592.443420 506.390381,591.282715 506.807098,590.946045 
	C509.579620,588.706055 508.112030,586.337280 507.500092,583.767334 
	C506.952850,581.469299 507.131500,578.991821 507.051849,576.591431 
	C507.001404,575.072021 507.064697,573.548889 507.076965,572.027405 
	C508.007782,569.649170 508.938568,567.270996 509.740723,564.243408 
	C508.747528,563.421753 507.882996,563.249329 506.616760,563.052673 
	C505.142822,562.989807 504.070587,562.951233 502.999634,562.453552 
	C503.000793,556.678101 502.502441,551.301208 503.139252,546.062195 
	C503.789337,540.714050 501.151367,534.693909 506.353699,530.040527 
	C508.185486,529.987549 509.595032,529.958496 511.004547,529.929321 
	C513.054260,529.953674 515.104004,529.977966 517.593994,530.026978 
z"/>
<path fill="#0D9BFA" opacity="1.000000" stroke="none" 
	d="
M712.085876,620.101318 
	C710.944031,620.318909 709.799133,620.519470 708.046265,620.604126 
	C707.625549,615.323242 708.037781,610.154541 707.953125,604.993896 
	C707.778931,594.373413 707.027832,583.754517 707.121521,573.140320 
	C707.180786,566.421875 708.336487,559.713135 709.296509,552.982544 
	C710.068726,552.660522 710.544922,552.356018 711.021057,552.051514 
	C721.639832,562.740173 725.395081,575.966370 724.671692,590.573303 
	C724.151428,601.079468 720.631836,610.650696 713.140503,619.001770 
	C712.416016,619.514893 712.234070,619.794250 712.052124,620.073547 
	C712.052124,620.073547 712.082825,620.084167 712.085876,620.101318 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M685.923584,448.576294 
	C683.109558,448.093048 680.015137,447.548737 677.110657,446.485413 
	C673.465698,445.151001 671.563843,446.608856 671.235962,449.991455 
	C670.681335,455.713715 670.544983,461.476624 670.239014,467.222809 
	C670.015869,471.414093 669.455933,475.626648 669.704346,479.790436 
	C669.875671,482.660675 671.172119,485.463806 671.931030,488.699463 
	C665.539673,481.776520 657.303894,479.399048 647.761230,479.676086 
	C648.981934,479.022339 650.377441,478.695160 651.002441,478.548645 
	C651.002441,472.348907 650.878845,466.626251 651.036499,460.911377 
	C651.200684,454.960693 651.636292,449.017517 652.420532,443.029602 
	C656.931091,442.972473 660.974243,442.956787 665.017395,442.941132 
	C665.017395,442.941132 665.041321,442.966125 665.272339,443.216797 
	C666.637695,443.609467 667.772034,443.751495 668.906311,443.893555 
	C674.513367,445.357300 680.120361,446.821045 685.923584,448.576294 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M628.000122,479.856567 
	C627.534485,479.903015 627.068787,479.949463 625.940796,479.649170 
	C620.178223,479.166504 615.078003,479.030579 609.986694,478.471375 
	C609.992981,466.422668 609.990295,454.797211 610.455200,443.131348 
	C618.271057,443.098724 625.619385,443.106506 632.977173,443.515686 
	C630.580688,450.984833 631.379517,458.119385 631.639343,465.419861 
	C631.804260,470.051941 634.453979,476.456909 628.000122,479.856567 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M518.032776,506.930481 
	C516.228821,507.558899 514.424927,508.187286 512.089600,508.589478 
	C511.062469,508.615143 510.566681,508.867004 510.070892,509.118835 
	C510.070892,509.118835 510.006470,509.102936 509.667053,509.068054 
	C507.539551,508.377380 505.751434,507.721619 503.963348,507.065857 
	C503.963348,507.065857 503.984619,507.029266 503.894531,506.826691 
	C503.664124,506.360474 503.457367,506.168335 503.184113,506.047729 
	C503.184113,506.047729 502.898407,505.735413 502.922852,505.115051 
	C501.626465,501.021851 501.932800,497.713654 502.820984,494.052155 
	C503.850037,489.809479 503.893951,485.239471 503.777924,480.826996 
	C503.574982,473.109802 502.833649,465.407349 502.581299,457.690094 
	C502.552124,456.797485 504.195129,455.850189 505.046234,454.942017 
	C505.029633,454.955780 505.034424,454.931885 505.167175,455.187927 
	C505.649292,455.583008 505.998688,455.722107 506.626892,456.039124 
	C508.903534,457.160828 510.901398,458.104584 513.011353,459.276184 
	C513.414185,459.659882 513.704956,459.815826 514.154907,460.220337 
	C514.847046,460.705414 515.380066,460.941864 516.071289,461.465393 
	C518.109436,463.516174 519.989502,465.279877 521.569397,467.188965 
	C516.083557,469.111389 516.796326,472.949005 517.106873,477.325958 
	C517.805115,487.166504 517.769592,497.059113 518.032776,506.930481 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M628.462280,479.936218 
	C634.453979,476.456909 631.804260,470.051941 631.639343,465.419861 
	C631.379517,458.119385 630.580688,450.984833 633.447754,443.473572 
	C639.923584,443.043732 645.938354,443.057404 651.953186,443.071045 
	C651.636292,449.017517 651.200684,454.960693 651.036499,460.911377 
	C650.878845,466.626251 651.002441,472.348907 651.002441,478.548645 
	C650.377441,478.695160 648.981934,479.022339 647.319397,479.598724 
	C641.009766,479.903931 634.967163,479.959930 628.462280,479.936218 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M505.062866,454.928223 
	C504.195129,455.850189 502.552124,456.797485 502.581299,457.690094 
	C502.833649,465.407349 503.574982,473.109802 503.777924,480.826996 
	C503.893951,485.239471 503.850037,489.809479 502.820984,494.052155 
	C501.932800,497.713654 501.626465,501.021851 502.915070,504.903564 
	C497.344147,500.092499 491.805450,494.872559 486.372559,489.114075 
	C486.984375,484.189484 487.782959,479.813171 487.930267,475.415070 
	C488.173767,468.145966 488.080475,460.861389 487.957855,453.586212 
	C487.906555,450.543304 488.876801,448.798950 492.504608,448.918335 
	C496.934052,450.932098 500.998474,452.930176 505.062866,454.928223 
z"/>
<path fill="#128AF8" opacity="1.000000" stroke="none" 
	d="
M518.357910,506.965210 
	C517.769592,497.059113 517.805115,487.166504 517.106873,477.325958 
	C516.796326,472.949005 516.083557,469.111389 521.881348,467.323792 
	C525.053101,469.863129 527.612793,472.412994 530.271729,475.272858 
	C531.661987,477.113281 532.953064,478.643707 534.244202,480.174133 
	C537.940918,486.243408 541.637573,492.312744 545.835938,499.205780 
	C537.551270,501.606934 530.151489,503.751648 522.258789,505.727905 
	C521.520081,505.721741 521.274231,505.884003 521.028442,506.046265 
	C520.246643,506.364136 519.464905,506.682037 518.357910,506.965210 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M427.877533,636.150574 
	C424.372833,635.011475 420.868103,633.872375 416.991150,632.150635 
	C415.982635,631.408081 415.346375,631.248169 414.710144,631.088257 
	C414.710144,631.088257 414.317871,631.122742 414.009583,630.792847 
	C413.378784,630.363708 413.056213,630.264526 412.733643,630.165344 
	C412.733643,630.165344 412.904419,630.125122 412.778992,629.892700 
	C412.026123,629.499329 411.398651,629.338379 410.771210,629.177429 
	C410.771210,629.177429 410.911896,629.129822 410.786530,628.892517 
	C410.046906,628.477478 409.432648,628.299683 408.818390,628.121948 
	C408.818390,628.121948 408.910400,628.096985 408.904755,627.732544 
	C409.063751,624.552246 409.198151,621.734131 409.405457,618.921387 
	C409.512909,617.463623 409.891876,616.016541 409.904144,614.562561 
	C409.976044,606.041992 409.975861,597.520935 410.245056,588.996582 
	C410.490082,588.993164 410.979889,588.974426 411.406250,588.981323 
	C414.413086,588.621094 416.993530,588.253967 419.573975,587.886841 
	C424.317078,590.696167 429.060181,593.505432 433.564575,596.641113 
	C430.884857,596.682922 428.443817,596.398315 426.054291,596.119629 
	C424.725464,598.138794 423.477844,600.034607 422.148895,602.053955 
	C423.072693,603.242981 424.646484,604.333984 424.705048,605.501160 
	C425.001343,611.405518 425.939362,617.568054 424.648102,623.178772 
	C423.397888,628.611267 422.842682,632.771667 427.877533,636.150574 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M634.033264,524.637817 
	C642.043579,524.405090 650.053955,524.172424 658.724060,524.166321 
	C655.816162,528.844360 654.309937,533.852905 654.985352,539.574097 
	C655.364380,542.784485 655.984680,546.141174 655.431274,549.241882 
	C654.763977,552.981140 655.473389,555.862244 657.538513,558.581665 
	C649.052673,558.614502 641.025696,558.548889 633.130859,558.153320 
	C634.748901,557.299927 636.234802,556.776489 636.997559,556.507812 
	C636.997559,547.765869 637.200012,539.443665 636.861877,531.143433 
	C636.772034,528.940125 635.021362,526.804504 634.033264,524.637817 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M657.997375,558.483215 
	C655.473389,555.862244 654.763977,552.981140 655.431274,549.241882 
	C655.984680,546.141174 655.364380,542.784485 654.985352,539.574097 
	C654.309937,533.852905 655.816162,528.844360 659.192993,524.188110 
	C672.410889,518.273315 678.065002,508.661743 674.923401,496.556763 
	C674.316345,494.217651 672.954468,492.074432 671.908997,489.472717 
	C673.063965,490.055786 674.948608,490.789520 675.323914,491.993713 
	C677.737488,499.737366 679.510620,507.460144 674.034363,514.941467 
	C672.588074,516.917358 668.815491,518.665222 671.553467,522.416016 
	C671.797485,522.750305 670.169434,524.451172 669.561401,525.305725 
	C672.302917,532.685791 670.714050,540.134644 670.853027,547.443970 
	C670.962524,553.199036 669.199036,559.297668 675.562561,563.195801 
	C676.362854,563.686035 676.558289,565.163635 677.048279,566.564331 
	C677.062561,566.944885 677.014404,566.989807 676.733276,566.805664 
	C671.597717,560.967163 665.131653,558.967529 657.997375,558.483215 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M633.584045,524.503906 
	C635.021362,526.804504 636.772034,528.940125 636.861877,531.143433 
	C637.200012,539.443665 636.997559,547.765869 636.997559,556.507812 
	C636.234802,556.776489 634.748901,557.299927 632.675110,558.243408 
	C628.055359,558.602600 624.023376,558.541809 619.944458,558.245728 
	C619.594299,557.670349 619.291016,557.330322 618.991699,556.557007 
	C618.986267,546.073120 618.976929,536.022461 618.967651,525.971863 
	C618.975891,525.686401 618.972412,525.401062 618.969666,524.902466 
	C619.440247,524.587280 619.898193,524.485596 620.704468,524.706665 
	C621.363037,524.912415 621.673279,524.795410 621.983521,524.678467 
	C625.700623,524.575623 629.417725,524.472778 633.584045,524.503906 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M506.208221,627.121399 
	C500.048889,630.038391 493.889587,632.955444 487.295288,635.512939 
	C486.381927,633.888916 485.118866,632.159302 485.549133,631.433533 
	C489.038635,625.547974 486.458984,619.403809 486.586365,613.418701 
	C486.731140,606.617188 483.248871,599.763184 486.923248,593.006897 
	C491.730225,588.762512 496.537170,584.518127 501.658508,580.497803 
	C501.368256,582.042480 500.567841,583.311584 500.203796,584.695557 
	C499.621704,586.908325 499.156219,589.182617 498.962830,591.458191 
	C498.767029,593.761536 499.058289,596.101929 498.966125,598.419312 
	C498.738068,604.154968 498.401611,609.886414 498.147491,615.621216 
	C498.126801,616.088318 498.479523,616.561035 498.527008,617.044006 
	C498.757721,619.391846 499.115662,621.745300 499.099762,624.094543 
	C499.081665,626.766968 499.699799,628.357788 502.812836,627.596863 
	C503.917389,627.326904 505.074890,627.273560 506.208221,627.121399 
z"/>
<path fill="#2832F1" opacity="1.000000" stroke="none" 
	d="
M471.849701,483.419281 
	C470.372711,483.240692 468.895691,483.062103 467.057983,482.225708 
	C464.131531,481.044464 461.565765,480.520996 458.999512,479.547852 
	C458.664764,467.397949 458.330536,455.697754 458.278137,443.982849 
	C459.024170,443.697449 459.488403,443.426788 459.952637,443.156128 
	C462.017029,443.090149 464.081451,443.024170 466.982971,442.967987 
	C469.941040,443.195587 472.061981,443.413361 474.139069,443.922729 
	C474.089447,444.408966 474.083740,444.603546 473.926880,445.164825 
	C473.566681,446.153503 473.162720,446.780243 473.177765,447.396759 
	C473.419891,457.318329 473.845734,467.238007 473.893127,477.159698 
	C473.903076,479.242920 472.566071,481.332581 471.849701,483.419281 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M472.118958,483.655823 
	C472.566071,481.332581 473.903076,479.242920 473.893127,477.159698 
	C473.845734,467.238007 473.419891,457.318329 473.177765,447.396759 
	C473.162720,446.780243 473.566681,446.153503 474.366028,445.224609 
	C480.684113,446.246033 486.411835,447.574341 492.139557,448.902679 
	C488.876801,448.798950 487.906555,450.543304 487.957855,453.586212 
	C488.080475,460.861389 488.173767,468.145966 487.930267,475.415070 
	C487.782959,479.813171 486.984375,484.189484 486.175476,488.839081 
	C481.377838,487.365845 476.883026,485.629089 472.118958,483.655823 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M486.575531,593.052429 
	C483.248871,599.763184 486.731140,606.617188 486.586365,613.418701 
	C486.458984,619.403809 489.038635,625.547974 485.549133,631.433533 
	C485.118866,632.159302 486.381927,633.888916 486.923218,635.482788 
	C483.258240,636.840942 479.530396,637.869629 475.285126,638.666321 
	C474.554626,638.205505 474.298157,638.046692 473.944641,637.545166 
	C473.611176,636.183411 473.103912,635.238525 473.086487,634.284790 
	C472.894012,623.748535 472.820251,613.209961 472.566345,602.675537 
	C472.543976,601.747620 471.477753,600.844849 470.896362,599.930420 
	C476.006866,597.652893 481.117340,595.375427 486.575531,593.052429 
z"/>
<path fill="#282CEC" opacity="1.000000" stroke="none" 
	d="
M470.487640,599.957214 
	C471.477753,600.844849 472.543976,601.747620 472.566345,602.675537 
	C472.820251,613.209961 472.894012,623.748535 473.086487,634.284790 
	C473.103912,635.238525 473.611176,636.183411 473.478027,637.560181 
	C468.203491,637.988037 463.341858,637.988037 458.994568,637.988037 
	C458.994568,625.386414 458.994568,613.717346 458.994568,602.048218 
	C462.689362,601.360168 466.384155,600.672119 470.487640,599.957214 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M410.000000,589.000000 
	C409.975861,597.520935 409.976044,606.041992 409.904144,614.562561 
	C409.891876,616.016541 409.512909,617.463623 409.405457,618.921387 
	C409.198151,621.734131 409.063751,624.552246 408.896484,627.779541 
	C406.350037,626.721191 403.806183,625.251404 401.073975,623.124756 
	C399.893890,621.994812 398.902130,621.521667 397.910400,621.048523 
	C397.910400,621.048523 397.979187,621.017090 397.853271,620.810913 
	C397.411804,620.437988 397.096191,620.271240 396.780609,620.104492 
	C396.780609,620.104492 396.899323,620.090454 396.898743,619.784912 
	C395.840057,618.058777 394.694794,616.693176 393.753662,615.198975 
	C393.097595,614.157349 392.223419,612.903137 392.303589,611.806458 
	C392.719635,606.115417 393.177368,600.403259 394.167175,594.794922 
	C394.421844,593.351807 396.453339,591.819214 398.022491,591.102234 
	C400.594635,589.927002 403.456482,589.385681 406.196136,588.576965 
	C406.196136,588.576965 406.345886,588.641846 406.778442,588.775269 
	C408.140686,588.939148 409.070343,588.969604 410.000000,589.000000 
z"/>
<path fill="#282CEC" opacity="1.000000" stroke="none" 
	d="
M457.482483,535.170532 
	C457.384552,533.712463 457.286621,532.254333 457.745178,530.394165 
	C463.851868,530.018982 469.402069,530.045837 474.719666,530.374390 
	C473.991943,532.157043 473.131317,533.620911 473.061371,535.121704 
	C472.628937,544.393555 472.374542,553.673767 471.623047,562.974915 
	C469.552032,563.138306 467.892639,563.138977 466.290436,563.441956 
	C459.778351,564.673218 459.160156,564.074646 458.910339,557.702454 
	C458.615662,550.184692 457.973816,542.680542 457.482483,535.170532 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M534.448853,480.150940 
	C532.953064,478.643707 531.661987,477.113281 530.601929,475.284790 
	C532.056580,474.627655 533.280273,474.268585 534.503906,473.909485 
	C533.693604,471.841949 532.883301,469.774384 532.073059,467.706787 
	C532.388367,467.796051 532.703674,467.885345 533.018982,467.974609 
	C533.018982,459.673676 533.018982,451.372772 533.421143,443.052612 
	C534.896851,443.022400 535.970398,443.011383 537.491699,443.007355 
	C540.646484,443.049194 543.353516,443.084045 546.041016,443.546722 
	C546.027954,444.969879 545.987976,445.967957 546.047729,446.960083 
	C546.663391,457.186554 549.936218,467.753448 542.119263,476.898895 
	C541.791382,477.282532 542.074585,478.188507 541.712524,478.854218 
	C539.236755,479.210083 537.121643,479.562836 535.006470,479.915649 
	C535.006470,479.915649 534.653442,480.127716 534.448853,480.150940 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M442.142670,599.972900 
	C442.142670,599.972900 442.576691,599.989929 442.965210,600.346558 
	C444.905487,601.138672 446.457214,601.574219 448.006470,602.412354 
	C448.336395,606.968506 449.094055,611.141235 448.908691,615.271545 
	C448.568176,622.857849 447.672180,630.419250 446.570282,637.998779 
	C443.088531,637.671021 440.044281,637.335510 436.681030,636.970642 
	C435.570831,636.630798 434.779663,636.320251 434.026306,635.665283 
	C434.876892,632.215942 437.024811,628.724731 436.258575,626.076721 
	C433.688721,617.196045 435.329956,608.877747 437.649536,600.237122 
	C439.218353,600.144897 440.680511,600.058899 442.142670,599.972900 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M441.979614,599.645569 
	C440.680511,600.058899 439.218353,600.144897 437.649536,600.237122 
	C435.329956,608.877747 433.688721,617.196045 436.258575,626.076721 
	C437.024811,628.724731 434.876892,632.215942 433.590027,635.666443 
	C431.359802,636.041748 429.603729,636.071472 427.847687,636.101196 
	C427.847687,636.101135 427.910400,636.096130 427.893982,636.123352 
	C422.842682,632.771667 423.397888,628.611267 424.648102,623.178772 
	C425.939362,617.568054 425.001343,611.405518 424.705048,605.501160 
	C424.646484,604.333984 423.072693,603.242981 422.148895,602.053955 
	C423.477844,600.034607 424.725464,598.138794 426.054291,596.119629 
	C428.443817,596.398315 430.884857,596.682922 433.755493,596.943115 
	C436.728912,597.718506 439.272736,598.518372 441.979614,599.645569 
z"/>
<path fill="#2528E0" opacity="1.000000" stroke="none" 
	d="
M447.007751,637.990967 
	C447.672180,630.419250 448.568176,622.857849 448.908691,615.271545 
	C449.094055,611.141235 448.336395,606.968506 448.461853,602.406982 
	C451.665649,601.957581 454.411621,601.916138 457.157593,601.874695 
	C457.571350,601.874084 457.985107,601.873413 458.696716,601.960510 
	C458.994568,613.717346 458.994568,625.386414 458.994568,637.988037 
	C463.341858,637.988037 468.203491,637.988037 473.531708,637.972961 
	C474.298157,638.046692 474.554626,638.205505 474.894531,638.624695 
	C468.909546,639.235413 462.797760,639.655823 456.061920,639.730225 
	C452.627869,638.919800 449.817841,638.455383 447.007751,637.990967 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M432.124542,486.024872 
	C429.936188,487.503662 427.747833,488.982452 425.104919,490.457031 
	C424.450806,490.672791 424.251190,490.892731 424.051575,491.112671 
	C424.051575,491.112671 424.040741,491.033722 423.840027,491.127167 
	C423.425293,491.478363 423.211212,491.736115 422.997131,491.993866 
	C422.635712,492.349426 422.274353,492.705048 421.277405,493.057861 
	C418.998291,492.734100 417.354706,492.413086 415.711151,492.092102 
	C416.611908,490.013031 417.512665,487.933990 418.013306,486.778442 
	C417.187012,484.276062 416.117767,482.487396 416.080109,480.677277 
	C415.899963,472.014252 415.999268,463.345367 415.999268,454.678589 
	C416.368774,455.201172 416.738281,455.723755 417.107819,456.246338 
	C419.092834,453.437500 421.077881,450.628662 423.062897,447.819824 
	C426.919128,446.679596 430.775330,445.539398 434.589050,444.960388 
	C433.916687,445.696167 433.289368,445.880707 432.656555,446.043793 
	C424.824615,448.061829 424.752686,448.218170 426.829620,456.148041 
	C427.074646,457.083557 426.983551,458.126404 426.940979,459.114899 
	C426.642853,466.035248 426.495819,472.968872 425.937927,479.868958 
	C425.548706,484.682800 427.327332,486.718475 432.124542,486.024872 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M459.492493,443.085175 
	C459.488403,443.426788 459.024170,443.697449 457.819641,443.980713 
	C453.848358,443.993256 450.617401,443.993256 448.059052,443.993256 
	C448.059052,456.893036 448.059052,468.904663 447.676758,480.939026 
	C445.518066,481.653351 443.741669,482.345001 441.965302,483.036621 
	C441.661041,483.179291 441.356781,483.321930 440.396057,483.254822 
	C438.827942,483.027740 437.916290,483.010315 437.004150,482.525940 
	C436.955109,477.436859 437.352966,472.756409 436.759003,468.205414 
	C435.728638,460.310455 435.094513,452.543671 437.966858,444.897949 
	C439.940216,444.190216 441.890289,442.966309 443.891479,442.876190 
	C448.926147,442.649384 453.983887,442.934418 459.492493,443.085175 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M418.018066,495.901978 
	C413.901062,495.578430 409.784058,495.254852 405.179626,494.359497 
	C402.531830,493.192230 400.401855,492.283234 398.204437,492.070343 
	C394.311005,491.693146 393.675110,490.044281 393.142609,486.084106 
	C392.412048,480.651062 398.503204,474.336914 391.647217,470.487061 
	C392.903076,467.961365 393.917236,465.921814 394.931366,463.882263 
	C394.931366,463.882263 394.950073,463.962006 395.155579,463.861084 
	C395.565826,463.481384 395.770569,463.202576 395.975311,462.923767 
	C395.975311,462.923767 395.987122,462.993683 396.327179,462.981018 
	C397.775421,461.965210 398.883575,460.962097 399.991791,459.958984 
	C400.321747,459.617828 400.651672,459.276672 401.587585,458.914856 
	C402.799042,458.594421 403.404572,458.294647 404.300537,458.215515 
	C408.448212,466.146729 407.440704,474.023254 405.545288,482.325836 
	C410.066345,483.908264 407.547302,486.055176 405.492126,487.750732 
	C407.364105,492.236481 410.204803,494.668976 414.927734,494.561279 
	C415.947144,494.538055 416.987396,495.431030 418.018066,495.901978 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M418.335205,495.859131 
	C416.987396,495.431030 415.947144,494.538055 414.927734,494.561279 
	C410.204803,494.668976 407.364105,492.236481 405.492126,487.750732 
	C407.547302,486.055176 410.066345,483.908264 405.545288,482.325836 
	C407.440704,474.023254 408.448212,466.146729 404.295746,458.000977 
	C404.000519,457.565796 404.020569,457.136902 404.020569,457.136902 
	C404.020569,457.136902 404.247589,456.857513 404.717804,456.813751 
	C405.468842,456.500122 405.749664,456.230255 406.030487,455.960388 
	C411.448608,453.273956 416.866730,450.587555 422.673889,447.860474 
	C421.077881,450.628662 419.092834,453.437500 417.107819,456.246338 
	C416.738281,455.723755 416.368774,455.201172 415.999268,454.678589 
	C415.999268,463.345367 415.899963,472.014252 416.080109,480.677277 
	C416.117767,482.487396 417.187012,484.276062 418.013306,486.778442 
	C417.512665,487.933990 416.611908,490.013031 415.711151,492.092102 
	C417.354706,492.413086 418.998291,492.734100 421.012817,493.085419 
	C420.473297,494.015930 419.562836,494.916107 418.335205,495.859131 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M437.600891,444.849609 
	C435.094513,452.543671 435.728638,460.310455 436.759003,468.205414 
	C437.352966,472.756409 436.955109,477.436859 436.727020,482.659119 
	C435.997406,483.909851 435.544464,484.560516 435.091553,485.211182 
	C435.091522,485.211151 435.081726,485.107422 434.822754,485.088989 
	C434.035767,485.327332 433.507782,485.584106 432.979767,485.840881 
	C432.979767,485.840851 432.564056,485.989044 432.344299,486.006958 
	C427.327332,486.718475 425.548706,484.682800 425.937927,479.868958 
	C426.495819,472.968872 426.642853,466.035248 426.940979,459.114899 
	C426.983551,458.126404 427.074646,457.083557 426.829620,456.148041 
	C424.752686,448.218170 424.824615,448.061829 432.656555,446.043793 
	C433.289368,445.880707 433.916687,445.696167 434.786987,445.242493 
	C435.763245,444.909332 436.499084,444.855286 437.600891,444.849609 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M506.544250,627.080200 
	C505.074890,627.273560 503.917389,627.326904 502.812836,627.596863 
	C499.699799,628.357788 499.081665,626.766968 499.099762,624.094543 
	C499.115662,621.745300 498.757721,619.391846 498.527008,617.044006 
	C498.479523,616.561035 498.126801,616.088318 498.147491,615.621216 
	C498.401611,609.886414 498.738068,604.154968 498.966125,598.419312 
	C499.058289,596.101929 498.767029,593.761536 498.962830,591.458191 
	C499.156219,589.182617 499.621704,586.908325 500.203796,584.695557 
	C500.567841,583.311584 501.368256,582.042480 501.958038,580.349121 
	C501.943176,579.976501 501.976288,579.981201 502.206726,579.861816 
	C502.597687,579.451721 502.758179,579.161011 502.918701,578.870361 
	C502.918701,578.870361 502.901031,578.966797 503.249237,578.910767 
	C504.032837,577.833923 504.468231,576.813232 504.903625,575.792480 
	C505.475220,574.682495 506.046783,573.572449 506.847656,572.244934 
	C507.064697,573.548889 507.001404,575.072021 507.051849,576.591431 
	C507.131500,578.991821 506.952850,581.469299 507.500092,583.767334 
	C508.112030,586.337280 509.579620,588.706055 506.807098,590.946045 
	C506.390381,591.282715 506.586487,592.443420 506.588257,593.222717 
	C506.607635,601.697510 508.461029,610.197205 506.112976,618.650024 
	C505.787140,619.822998 506.220581,621.206848 506.313751,622.669006 
	C508.538300,622.527649 510.448395,622.406311 512.630859,622.514038 
	C510.895630,624.175110 508.887970,625.607056 506.544250,627.080200 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M660.968262,603.978027 
	C661.229187,603.521606 661.490051,603.065125 662.210938,602.221619 
	C668.648315,600.399963 673.869873,597.677307 677.931091,592.943481 
	C677.973938,592.982544 678.058777,593.061584 678.058777,593.061584 
	C677.521240,594.389160 677.363953,596.220947 676.383057,596.960754 
	C670.907532,601.090271 670.528198,606.647949 670.903076,612.841614 
	C671.307068,619.515991 670.910400,626.235474 671.065369,632.930603 
	C671.101379,634.485657 671.956970,636.021729 672.880859,638.023499 
	C673.823669,638.636414 674.321228,638.792358 674.818726,638.948242 
	C673.710815,639.199951 672.602844,639.451660 671.148132,639.627014 
	C667.825806,639.358948 664.850220,639.167236 661.488953,638.561890 
	C661.372375,634.289734 661.913025,630.427063 661.852905,626.573669 
	C661.735291,619.039062 661.282349,611.509583 660.968262,603.978027 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M394.610291,463.990112 
	C393.917236,465.921814 392.903076,467.961365 391.647217,470.487061 
	C398.503204,474.336914 392.412048,480.651062 393.142609,486.084106 
	C393.675110,490.044281 394.311005,491.693146 398.204437,492.070343 
	C400.401855,492.283234 402.531830,493.192230 404.814667,494.096130 
	C394.184784,492.770081 383.432404,491.135590 371.875824,489.378815 
	C378.141876,479.354950 385.487854,471.102539 394.610291,463.990112 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M405.735352,588.587769 
	C403.456482,589.385681 400.594635,589.927002 398.022491,591.102234 
	C396.453339,591.819214 394.421844,593.351807 394.167175,594.794922 
	C393.177368,600.403259 392.719635,606.115417 392.303589,611.806458 
	C392.223419,612.903137 393.097595,614.157349 393.753662,615.198975 
	C394.694794,616.693176 395.840057,618.058777 396.884521,619.842896 
	C387.046997,613.429749 379.522827,604.502930 372.209412,594.074707 
	C383.662415,592.177917 394.468475,590.388245 405.735352,588.587769 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M457.377136,535.611572 
	C457.973816,542.680542 458.615662,550.184692 458.910339,557.702454 
	C459.160156,564.074646 459.778351,564.673218 466.290436,563.441956 
	C467.892639,563.138977 469.552032,563.138306 472.081909,562.996765 
	C477.662018,562.969604 482.345306,562.944336 487.493164,562.940674 
	C492.971252,562.945801 497.984772,562.929199 502.998322,562.912598 
	C504.070587,562.951233 505.142822,562.989807 506.613220,563.243286 
	C507.011383,563.458130 507.010895,563.841125 507.010895,563.841125 
	C490.773254,563.841125 474.535614,563.841125 457.271790,563.841125 
	C457.271790,554.630676 457.271790,545.341675 457.377136,535.611572 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M537.043945,443.000366 
	C535.970398,443.011383 534.896851,443.022400 532.956848,443.021118 
	C528.067932,443.019714 524.045410,443.030609 519.554077,443.024109 
	C508.005646,443.343018 496.927521,443.741913 485.845947,443.981842 
	C481.962646,444.065918 478.070862,443.759308 474.182953,443.631104 
	C472.061981,443.413361 469.941040,443.195587 467.422180,442.909973 
	C468.728821,442.559906 470.432922,442.034943 472.137878,442.032196 
	C493.465759,441.998138 514.793762,442.039734 536.572144,442.331146 
	C537.022522,442.600403 537.043945,443.000366 537.043945,443.000366 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M535.472351,479.968170 
	C537.121643,479.562836 539.236755,479.210083 542.171082,478.891785 
	C548.335754,478.921204 553.681274,478.916199 559.484131,478.936768 
	C563.634399,478.956177 567.327332,478.950043 571.484985,478.958557 
	C576.650269,478.938354 581.350830,478.903534 586.515869,478.898804 
	C594.646057,478.917480 602.311890,478.906067 609.977722,478.894653 
	C615.078003,479.030579 620.178223,479.166504 625.602661,479.546204 
	C595.930603,479.866913 565.934448,479.943817 535.472351,479.968170 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M537.491699,443.007355 
	C537.043945,443.000366 537.022522,442.600403 537.039795,442.400787 
	C558.743896,442.159027 580.430725,442.116882 602.562256,442.335754 
	C603.006958,442.596741 603.004333,442.965088 602.536072,442.978607 
	C596.397766,443.045715 590.727783,443.099274 584.618652,443.099365 
	C580.124634,443.380737 576.069702,443.715546 571.558899,444.029541 
	C566.746887,443.714783 562.390808,443.420837 557.573364,443.095093 
	C553.428101,443.081787 549.744324,443.100342 546.060486,443.118896 
	C543.353516,443.084045 540.646484,443.049194 537.491699,443.007355 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M603.004333,442.965088 
	C603.004333,442.965088 603.006958,442.596741 603.028198,442.413696 
	C623.478516,442.315338 643.907654,442.400024 664.677063,442.712921 
	C660.974243,442.956787 656.931091,442.972473 652.420532,443.029602 
	C645.938354,443.057404 639.923584,443.043732 633.438232,443.072174 
	C625.619385,443.106506 618.271057,443.098724 610.020874,443.083801 
	C607.080750,443.039459 605.042542,443.002258 603.004333,442.965088 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M474.952240,530.072632 
	C469.402069,530.045837 463.851868,530.018982 457.864807,529.970215 
	C458.774048,529.646179 460.119720,529.083740 461.466370,529.081360 
	C477.677460,529.052979 493.888702,529.107422 510.567749,529.219971 
	C511.035583,529.299561 511.006653,529.614685 511.005615,529.772034 
	C509.595032,529.958496 508.185486,529.987549 505.894531,530.017212 
	C499.670471,530.040222 494.327820,530.062683 488.522644,530.058716 
	C483.690796,530.045715 479.321533,530.059143 474.952240,530.072632 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M446.570312,637.998718 
	C449.817841,638.455383 452.627869,638.919800 455.700043,639.658081 
	C449.924164,639.615845 443.886169,639.299683 437.421997,638.598267 
	C436.997253,637.808655 436.998627,637.404358 437.000000,637.000000 
	C440.044281,637.335510 443.088531,637.671021 446.570312,637.998718 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M385.059143,710.537476 
	C379.068420,710.820557 377.530609,705.713989 375.038696,701.308960 
	C378.341736,704.037415 381.634552,707.133057 385.059143,710.537476 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M459.000000,479.997559 
	C461.565765,480.520996 464.131531,481.044464 466.811188,481.950134 
	C462.601807,482.164581 458.278564,481.996796 453.472748,481.576782 
	C452.990204,481.324585 452.960358,480.937988 453.237518,480.670410 
	C455.343109,480.267761 457.171570,480.132629 459.000000,479.997559 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M527.350647,772.032227 
	C522.178528,771.534485 517.331604,771.028015 512.238403,770.206665 
	C517.373169,770.276184 522.754150,770.660522 528.587708,771.132629 
	C529.040283,771.220398 529.004883,771.633484 528.990601,771.840820 
	C528.542786,772.039978 528.109314,772.031738 527.350647,772.032227 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M456.929535,601.512573 
	C454.411621,601.916138 451.665649,601.957581 448.464294,602.004395 
	C446.457214,601.574219 444.905487,601.138672 443.177124,600.394287 
	C447.567474,600.440369 452.134460,600.795410 456.929535,601.512573 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M317.986938,483.650330 
	C317.100616,483.372742 315.653564,482.402100 315.762604,482.173431 
	C316.885681,479.817963 318.260498,477.582550 319.919739,475.217773 
	C319.565704,477.848450 318.857147,480.575073 317.986938,483.650330 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M357.559540,402.125916 
	C356.172180,398.615753 357.645447,396.627838 361.741547,396.095520 
	C360.703613,398.041321 359.272583,399.997253 357.559540,402.125916 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M511.140778,769.570557 
	C507.250580,769.590881 503.048645,769.314148 498.426208,769.031738 
	C498.023163,768.815918 498.040619,768.605591 498.107422,768.082336 
	C502.380829,768.270691 506.604919,768.772095 511.140778,769.570557 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M371.726837,385.242798 
	C373.159058,383.364349 374.867950,381.320282 376.929901,379.209290 
	C375.523163,381.120605 373.763336,383.098877 371.726837,385.242798 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M497.927551,767.551025 
	C494.851379,767.478149 491.514130,767.106262 488.104431,766.337341 
	C491.243469,766.377625 494.454956,766.814819 497.927551,767.551025 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M723.622925,681.061646 
	C724.401611,684.464905 722.845215,686.721069 719.182495,687.857605 
	C720.312012,685.618225 721.812073,683.404236 723.622925,681.061646 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M322.869202,467.615387 
	C322.033875,465.395508 320.327148,462.622467 324.566162,461.009583 
	C324.311554,463.090027 323.668243,465.184662 322.869202,467.615387 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M318.980286,602.596924 
	C318.000397,599.738647 317.043091,596.476929 316.136383,592.757751 
	C317.125610,595.597961 318.064240,598.895752 318.980286,602.596924 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M452.960358,480.937988 
	C452.960358,480.937988 452.990204,481.324585 453.014587,481.516968 
	C449.629456,482.173126 446.219940,482.636932 442.387878,483.068665 
	C443.741669,482.345001 445.518066,481.653351 448.083313,480.952423 
	C450.234924,480.941406 451.597626,480.939697 452.960358,480.937988 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M436.681030,636.970642 
	C436.998627,637.404358 436.997253,637.808655 436.996277,638.516235 
	C434.106415,638.109192 431.216187,637.398987 428.086792,636.394958 
	C429.603729,636.071472 431.359802,636.041748 433.552155,636.010864 
	C434.779663,636.320251 435.570831,636.630798 436.681030,636.970642 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M361.042297,684.690430 
	C357.790314,685.472717 356.510773,683.961182 357.061737,680.302185 
	C358.378265,681.373718 359.665955,682.870361 361.042297,684.690430 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M419.310913,587.531067 
	C416.993530,588.253967 414.413086,588.621094 411.403381,588.761230 
	C410.974152,588.534302 410.959900,588.093811 410.959900,588.093750 
	C413.655884,587.787598 416.351837,587.481445 419.310913,587.531067 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M475.427429,320.059875 
	C477.101715,319.383972 479.166870,318.693787 481.665192,318.013092 
	C480.005005,318.696899 477.911682,319.371246 475.427429,320.059875 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M503.985107,507.438293 
	C505.751434,507.721619 507.539551,508.377380 509.698730,509.058624 
	C507.632446,510.641998 505.501801,510.739807 503.985107,507.438293 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M399.675415,460.028076 
	C398.883575,460.962097 397.775421,461.965210 396.295868,462.964386 
	C397.069336,462.006012 398.214172,461.051605 399.675415,460.028076 
z"/>
<path fill="#128AF8" opacity="1.000000" stroke="none" 
	d="
M710.924561,551.758179 
	C710.544922,552.356018 710.068726,552.660522 709.172058,552.690979 
	C708.485168,551.627014 708.218689,550.837036 707.952209,550.047119 
	C707.952209,550.047119 708.246765,549.737061 708.409058,549.595764 
	C709.323608,550.124573 710.075806,550.794739 710.924561,551.758179 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M322.074524,612.664246 
	C321.439301,611.496338 320.802643,609.966797 320.294922,608.047852 
	C320.973572,609.206482 321.523346,610.754517 322.074524,612.664246 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M324.055176,618.595703 
	C323.414459,617.379211 322.745026,615.786255 322.100037,613.768921 
	C322.758484,614.969421 323.392517,616.594299 324.055176,618.595703 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M675.203735,639.003662 
	C674.321228,638.792358 673.823669,638.636414 673.181091,638.257629 
	C675.037170,638.040466 677.038269,638.046143 679.461304,638.043945 
	C678.451721,638.377014 677.020203,638.718018 675.203735,639.003662 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M435.466431,485.163208 
	C435.544464,484.560516 435.997406,483.909851 436.727478,483.126038 
	C437.916290,483.010315 438.827942,483.027740 440.197205,483.088440 
	C439.050293,483.792908 437.445831,484.454071 435.466431,485.163208 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M426.397461,341.046509 
	C427.172821,340.414246 428.287994,339.737030 429.797729,339.113403 
	C429.040588,339.778473 427.888916,340.390015 426.397461,341.046509 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M320.395844,607.215637 
	C316.671783,607.932068 319.157990,605.534607 319.074768,603.894287 
	C319.519135,604.623047 319.919037,605.740723 320.395844,607.215637 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M747.410645,439.292725 
	C748.080872,439.883820 748.785522,440.767059 749.259888,441.824799 
	C748.501465,441.194489 747.973206,440.389679 747.410645,439.292725 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M727.633789,676.085449 
	C727.473145,676.922913 727.022583,677.891724 726.209595,678.863281 
	C726.346069,677.982971 726.844971,677.099854 727.633789,676.085449 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M504.620239,575.956177 
	C504.468231,576.813232 504.032837,577.833923 503.224579,578.868408 
	C503.346771,577.961365 503.841827,577.040588 504.620239,575.956177 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M410.710144,587.924683 
	C410.959900,588.093811 410.974152,588.534302 410.977020,588.754395 
	C410.979889,588.974426 410.490082,588.993164 410.245056,588.996582 
	C409.070343,588.969604 408.140686,588.939148 406.743408,588.848938 
	C407.670654,588.444641 409.065491,588.100159 410.710144,587.924683 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M398.055237,621.333191 
	C398.902130,621.521667 399.893890,621.994812 400.909119,622.835205 
	C400.021759,622.674194 399.110901,622.146057 398.055237,621.333191 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M432.317383,337.990997 
	C432.839966,337.388153 433.687622,336.875031 434.754578,336.581787 
	C434.196747,337.227997 433.419556,337.654358 432.317383,337.990997 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M369.293213,387.901703 
	C369.570007,387.089325 370.130005,386.165131 371.060486,385.337524 
	C370.812805,386.219421 370.194611,387.004669 369.293213,387.901703 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M668.731628,443.590820 
	C667.772034,443.751495 666.637695,443.609467 665.283569,443.203674 
	C666.228088,443.055939 667.392517,443.172028 668.731628,443.590820 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M746.049683,436.315186 
	C746.535461,436.557587 747.021729,437.092499 747.291260,437.803284 
	C746.733032,437.522034 746.391663,437.064880 746.049683,436.315186 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M487.347839,317.128784 
	C488.122437,316.721802 489.276581,316.378143 490.819092,316.050842 
	C490.047485,316.442169 488.887451,316.817169 487.347839,317.128784 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M325.430634,460.268860 
	C325.398773,459.378265 325.662872,458.254608 326.276215,457.153564 
	C326.325836,458.129395 326.026215,459.082581 325.430634,460.268860 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M688.584473,449.783203 
	C687.958740,449.824951 687.157349,449.613190 686.214966,449.113403 
	C686.852295,449.060120 687.630554,449.294891 688.584473,449.783203 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M507.365448,563.945435 
	C507.010895,563.841125 507.011383,563.458130 507.014893,563.267517 
	C507.882996,563.249329 508.747528,563.421753 509.771393,563.893188 
	C509.193817,564.144775 508.456909,564.097229 507.365448,563.945435 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M403.703796,457.182556 
	C404.020569,457.136902 404.000519,457.565796 404.005310,457.780334 
	C403.404572,458.294647 402.799042,458.594421 401.835114,458.886902 
	C402.113495,458.329163 402.750275,457.778717 403.703796,457.182556 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M354.837128,679.214722 
	C355.203278,678.987305 355.430511,678.969788 355.997955,678.917908 
	C356.694031,678.919495 356.932098,679.105957 357.088806,679.677856 
	C356.316254,679.750061 355.507233,679.587341 354.837128,679.214722 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M483.363190,318.007385 
	C483.892487,317.617279 484.778839,317.335693 486.037354,317.079651 
	C485.513092,317.442047 484.616669,317.778961 483.363190,318.007385 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M379.455505,377.172546 
	C379.610504,376.605560 380.059448,375.930542 380.804993,375.141968 
	C380.650879,375.707153 380.200165,376.385864 379.455505,377.172546 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M377.444824,379.164185 
	C377.647308,378.608246 378.140411,377.940704 378.958374,377.213684 
	C378.767273,377.786957 378.251373,378.419800 377.444824,379.164185 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M750.939026,446.375671 
	C751.269043,446.557281 751.599976,447.047638 751.925842,447.856445 
	C751.593872,447.678040 751.266907,447.181213 750.939026,446.375671 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M437.035706,746.656738 
	C436.579712,746.760010 435.966888,746.581665 435.204376,746.108154 
	C435.662750,746.000427 436.270813,746.187866 437.035706,746.656738 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M440.988617,748.631470 
	C440.660828,748.935913 440.187195,748.969299 439.358582,749.009888 
	C439.007996,748.825317 439.012390,748.633484 439.050720,748.154907 
	C439.670654,748.032288 440.256683,748.196350 440.988617,748.631470 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M473.289398,321.048035 
	C473.459747,320.698822 473.953979,320.374451 474.760498,320.042175 
	C474.586304,320.380463 474.099823,320.726654 473.289398,321.048035 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M423.405182,343.185120 
	C423.548889,342.849091 424.000000,342.462097 424.774353,342.074402 
	C424.635956,342.427216 424.174286,342.780670 423.405182,343.185120 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M320.445618,475.035278 
	C320.281555,474.616272 320.369019,474.006927 320.704346,473.201050 
	C320.867218,473.617950 320.782196,474.231476 320.445618,475.035278 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M433.291901,485.912170 
	C433.507782,485.584106 434.035767,485.327332 434.872253,485.105225 
	C434.655182,485.421021 434.129608,485.702209 433.291901,485.912170 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M408.987305,628.386108 
	C409.432648,628.299683 410.046906,628.477478 410.785950,628.966797 
	C410.325928,629.069031 409.741089,628.859680 408.987305,628.386108 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M410.940186,629.442932 
	C411.398651,629.338379 412.026123,629.499329 412.774536,629.980347 
	C412.300079,630.103027 411.704620,629.905701 410.940186,629.442932 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M321.878021,470.661560 
	C321.720001,470.382538 321.713196,469.807434 321.953857,469.143372 
	C322.143951,469.491425 322.086609,469.928467 321.878021,470.661560 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M414.881287,631.374023 
	C415.346375,631.248169 415.982635,631.408081 416.789337,631.859436 
	C416.324005,631.987244 415.688232,631.823486 414.881287,631.374023 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M324.917114,620.683655 
	C324.605835,620.495361 324.333069,620.002014 324.054199,619.212280 
	C324.350586,619.403442 324.653076,619.890991 324.917114,620.683655 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M510.388611,509.121948 
	C510.566681,508.867004 511.062469,508.615143 511.731812,508.543274 
	C511.505707,508.857208 511.106018,508.991150 510.388611,509.121948 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M621.742432,524.548828 
	C621.673279,524.795410 621.363037,524.912415 621.034790,524.851685 
	C621.178345,524.589111 621.339844,524.504150 621.742432,524.548828 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M430.392670,339.141846 
	C430.293060,338.902954 430.467163,338.582581 430.899780,338.152405 
	C430.994293,338.381805 430.830353,338.721069 430.392670,339.141846 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M425.312134,342.037567 
	C425.235046,341.817780 425.421661,341.528900 425.852051,341.139618 
	C425.922546,341.348969 425.749207,341.658722 425.312134,342.037567 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M422.284882,343.973663 
	C422.238190,343.732483 422.467560,343.478577 422.956604,343.173950 
	C422.997803,343.402466 422.779388,343.681702 422.284882,343.973663 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M694.465210,633.297607 
	C694.422791,633.076477 694.609009,632.866638 694.907593,632.821960 
	C695.019897,632.987122 694.693909,633.308777 694.465210,633.297607 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M521.279907,506.065948 
	C521.274231,505.884003 521.520081,505.721741 521.873352,505.708160 
	C521.831055,505.933075 521.681213,506.009338 521.279907,506.065948 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M693.302612,633.966309 
	C693.248718,633.727051 693.467651,633.481018 693.940186,633.202026 
	C693.987610,633.432495 693.781555,633.696045 693.302612,633.966309 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M423.258789,491.940704 
	C423.211212,491.736115 423.425293,491.478363 423.879639,491.131500 
	C423.920105,491.324097 423.720245,491.605804 423.258789,491.940704 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M424.301880,491.054871 
	C424.251190,490.892731 424.450806,490.672791 424.791443,490.552246 
	C424.805725,490.766815 424.678955,490.881927 424.301880,491.054871 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M729.687805,673.032227 
	C729.889648,673.284973 729.746765,673.545593 729.308044,673.866211 
	C729.221741,673.685303 729.332031,673.389954 729.687805,673.032227 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M728.626709,674.779236 
	C728.735596,674.989807 728.588562,675.331970 728.190308,675.809692 
	C728.083008,675.600464 728.226807,675.255676 728.626709,674.779236 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M412.854218,630.412842 
	C413.056213,630.264526 413.378784,630.363708 413.856140,630.669495 
	C413.665558,630.804138 413.320190,630.732239 412.854218,630.412842 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M725.651672,678.937500 
	C725.745544,679.160217 725.582153,679.449707 725.185242,679.838745 
	C725.099243,679.626953 725.246765,679.315674 725.651672,678.937500 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M395.708710,462.988098 
	C395.770569,463.202576 395.565826,463.481384 395.114746,463.857880 
	C395.059631,463.654510 395.250854,463.353455 395.708710,462.988098 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M405.724060,455.994751 
	C405.749664,456.230255 405.468842,456.500122 404.895996,456.839355 
	C404.875153,456.615540 405.146393,456.322327 405.724060,455.994751 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M693.176514,452.647278 
	C692.987671,452.792328 692.672180,452.695251 692.199219,452.402283 
	C692.377808,452.272675 692.713745,452.338959 693.176514,452.647278 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M502.658813,578.938599 
	C502.758179,579.161011 502.597687,579.451721 502.205841,579.847290 
	C502.115967,579.637085 502.257477,579.321960 502.658813,578.938599 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M523.300049,613.958618 
	C523.125122,613.684387 523.299255,613.425110 523.801941,613.152710 
	C524.011230,613.431396 523.850769,613.709351 523.300049,613.958618 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M522.299438,614.944641 
	C522.127075,614.673950 522.300232,614.418518 522.795349,614.144653 
	C523.012085,614.422180 522.854675,614.700134 522.299438,614.944641 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M521.289795,615.901245 
	C521.127747,615.637817 521.299988,615.394592 521.783447,615.139038 
	C521.999512,615.416870 521.842712,615.681702 521.289795,615.901245 
z"/>
<path fill="#128AF8" opacity="1.000000" stroke="none" 
	d="
M712.313110,620.017334 
	C712.234070,619.794250 712.416016,619.514893 712.836914,619.147461 
	C712.908569,619.359985 712.741333,619.660583 712.313110,620.017334 
z"/>
<path fill="#0B1562" opacity="1.000000" stroke="none" 
	d="
M396.885437,620.364502 
	C397.096191,620.271240 397.411804,620.437988 397.850586,620.848633 
	C397.645935,620.936523 397.318085,620.780579 396.885437,620.364502 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M562.648743,524.538452 
	C562.678833,524.641541 562.581055,524.789185 562.542480,524.747070 
	C562.503967,524.704895 562.618652,524.435303 562.648743,524.538452 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M503.187439,506.283356 
	C503.457367,506.168335 503.664124,506.360474 503.873352,506.827393 
	C503.598999,506.996399 503.348480,506.825836 503.187439,506.283356 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M362.319641,396.050385 
	C362.236298,395.849426 362.404449,395.551666 362.804688,395.134766 
	C362.881500,395.328247 362.726318,395.640930 362.319641,396.050385 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M435.070160,745.661133 
	C434.826294,745.894592 434.528687,745.768738 434.138489,745.328491 
	C434.322357,745.221130 434.645905,745.316956 435.070160,745.661133 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M365.309814,392.045837 
	C365.262909,391.840851 365.480835,391.553253 365.968994,391.197357 
	C366.017731,391.407135 365.796173,391.685211 365.309814,392.045837 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M745.440674,435.279541 
	C745.621887,435.225769 745.766052,435.388428 745.926819,435.740723 
	C745.728882,435.843506 745.549011,435.698700 745.440674,435.279541 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M439.052307,747.687378 
	C438.832825,747.902649 438.547455,747.788513 438.158783,747.390686 
	C438.326447,747.289001 438.636749,747.372620 439.052307,747.687378 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M366.398224,391.139954 
	C366.308563,390.917633 366.482056,390.597900 366.919159,390.179932 
	C367.008972,390.401978 366.835175,390.722260 366.398224,391.139954 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M367.344666,390.083344 
	C367.268555,389.877106 367.449371,389.568176 367.881226,389.148987 
	C367.955383,389.352661 367.778473,389.666656 367.344666,390.083344 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M441.793457,749.008545 
	C441.709503,749.172607 441.432312,749.181091 441.130371,749.000732 
	C441.105652,748.811890 441.600220,748.852905 441.793457,749.008545 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M386.148926,711.598145 
	C385.921631,711.683594 385.602356,711.504211 385.197327,711.053894 
	C385.426666,710.966431 385.741791,711.149841 386.148926,711.598145 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M318.578186,704.293945 
	C319.483002,705.456604 320.148651,706.887451 320.836365,708.662231 
	C317.302002,709.064941 317.816864,706.815613 318.578186,704.293945 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M317.436768,699.063354 
	C317.850372,699.746216 317.950958,700.497559 317.986328,701.623291 
	C317.655304,701.042419 317.389526,700.087158 317.436768,699.063354 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M307.915955,709.088074 
	C307.579071,708.257324 307.266876,707.023010 307.020721,705.396729 
	C307.371399,706.231384 307.656067,707.457947 307.915955,709.088074 
z"/>
<path fill="#0E156A" opacity="1.000000" stroke="none" 
	d="
M308.964386,712.799438 
	C308.640961,712.333618 308.329681,711.518616 308.036102,710.348572 
	C308.361389,710.812439 308.668976,711.631348 308.964386,712.799438 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M321.171570,710.990967 
	C321.405457,710.951416 321.714966,711.190979 322.056549,711.730896 
	C321.808136,711.777466 321.527649,711.523743 321.171570,710.990967 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M333.497437,385.775604 
	C331.555847,386.491394 329.198700,387.250854 326.427551,388.013336 
	C325.457886,387.849915 324.902222,387.683411 324.175690,387.275848 
	C327.030518,386.629578 330.056183,386.224396 333.497437,385.775604 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M315.089996,385.283661 
	C316.912750,385.323883 318.772766,385.729553 320.903076,386.372009 
	C318.680725,389.295624 316.847992,387.825195 315.089996,385.283661 
z"/>
<path fill="#0E1770" opacity="1.000000" stroke="none" 
	d="
M314.187897,384.045227 
	C314.389557,384.000824 314.660095,384.218201 315.009003,384.697693 
	C314.810486,384.742188 314.533661,384.524628 314.187897,384.045227 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M330.906616,343.464417 
	C332.304474,343.189545 333.847198,343.189545 336.016174,343.189545 
	C334.103821,345.545807 330.481842,347.888611 336.768127,348.079529 
	C332.956635,353.217804 328.746582,358.319885 324.380310,363.611328 
	C325.696411,364.707886 326.951813,365.753845 328.404205,366.963898 
	C331.522400,363.433411 334.538910,360.018066 337.589203,356.564453 
	C343.716217,364.008423 341.579651,374.048401 333.186920,378.654694 
	C327.611450,381.714752 320.273468,380.704407 315.135864,376.169342 
	C310.030975,371.663208 308.207642,364.520905 310.631439,358.525360 
	C313.165527,352.257111 318.499573,348.594360 325.493286,349.168335 
	C329.969604,349.535675 331.178528,347.574646 330.906616,343.464417 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M343.837769,353.543884 
	C344.777588,353.793671 346.559601,354.224396 346.736511,355.014526 
	C347.581543,358.787872 347.991058,362.658752 348.266449,366.748291 
	C346.631805,362.631226 345.286072,358.260803 343.837769,353.543884 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M338.990570,348.389099 
	C340.233337,349.165344 341.601807,350.238922 342.956482,351.700073 
	C341.667236,350.953857 340.391754,349.820129 338.990570,348.389099 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M337.410950,348.141571 
	C337.298828,347.616272 337.486389,347.055328 337.673981,346.494324 
	C338.081879,346.833649 338.489777,347.172974 338.906128,347.828217 
	C338.513245,348.131409 338.111969,348.118652 337.410950,348.141571 
z"/>
<path fill="#121A79" opacity="1.000000" stroke="none" 
	d="
M342.976074,352.303375 
	C343.180267,352.266388 343.458435,352.495483 343.788757,352.998657 
	C343.577271,353.038300 343.313660,352.803894 342.976074,352.303375 
z"/>
<path fill="#2253F0" opacity="1.000000" stroke="none" 
	d="
M672.739502,277.048035 
	C672.313538,278.623322 671.484375,280.190186 670.356445,281.909912 
	C670.817200,280.388367 671.576721,278.713989 672.739502,277.048035 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M676.996094,275.649109 
	C676.557434,275.993164 676.112366,275.992401 675.333679,275.983521 
	C675.302612,275.146332 675.605286,274.317322 675.907959,273.488312 
	C676.268555,274.093628 676.629150,274.698944 676.996094,275.649109 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M556.645508,834.340454 
	C556.523376,828.569275 556.523376,823.116089 556.523376,816.970825 
	C548.373291,817.410156 541.056030,817.804565 533.253296,818.225159 
	C533.253296,823.760132 533.253296,829.664185 533.253296,835.956909 
	C531.161316,836.281860 529.450562,836.547546 526.754761,836.966248 
	C526.754761,822.262512 526.754761,808.061096 526.754761,793.279663 
	C527.355103,793.227905 528.752319,793.107483 530.597900,792.916748 
	C531.046204,792.846497 531.418701,793.016052 531.680420,793.487793 
	C532.213196,799.872925 532.484314,805.786316 532.727112,811.081909 
	C540.949402,811.081909 548.346985,811.081909 555.281189,811.081909 
	C555.281189,804.254150 555.281189,798.160583 555.281189,792.067078 
	C555.281189,792.067078 555.105103,792.089355 555.554077,792.098999 
	C557.986450,792.101929 559.969910,792.095093 561.953369,792.088257 
	C561.953369,792.088318 561.983215,792.019043 561.979248,792.489441 
	C562.352112,806.968567 562.728943,820.977356 563.105774,834.986084 
	C560.879150,837.081421 558.691040,838.431641 556.645508,834.340454 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M554.916504,792.293091 
	C555.281189,798.160583 555.281189,804.254150 555.281189,811.081909 
	C548.346985,811.081909 540.949402,811.081909 532.727112,811.081909 
	C532.484314,805.786316 532.213196,799.872925 531.884399,793.503662 
	C532.553589,794.589050 533.872925,796.114868 533.913513,797.674011 
	C534.234558,809.996460 534.159546,809.998413 546.323059,809.992065 
	C547.646057,809.991333 548.977722,809.892822 550.290283,810.009399 
	C553.073425,810.256592 554.071289,809.080750 554.058472,806.291260 
	C554.037292,801.701355 554.368958,797.109924 554.916504,792.293091 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M563.399597,834.818054 
	C562.728943,820.977356 562.352112,806.968567 561.941711,792.493347 
	C562.604919,793.551331 563.875183,795.063721 563.907715,796.602234 
	C564.170654,809.024780 564.204590,821.452332 564.276489,833.878418 
	C564.278015,834.134521 563.896362,834.392761 563.399597,834.818054 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M561.898438,791.700684 
	C559.969910,792.095093 557.986450,792.101929 555.585938,792.181885 
	C556.851746,788.100464 559.360046,789.794922 561.898438,791.700684 
z"/>
<path fill="#282CEC" opacity="1.000000" stroke="none" 
	d="
M546.805908,288.719818 
	C547.480347,288.990875 547.740906,289.143250 548.000000,290.032501 
	C549.387634,291.414154 550.692139,292.203644 551.996582,292.993164 
	C551.358948,294.275116 550.509827,295.497528 550.128723,296.851624 
	C549.388672,299.480713 548.911621,302.183868 548.325623,304.856323 
	C548.757629,304.973785 549.189636,305.091248 549.621643,305.208710 
	C548.421875,306.536316 547.222046,307.863922 546.017273,309.549988 
	C536.653748,309.936462 527.295227,309.964539 517.467896,309.991547 
	C516.467224,309.375763 515.935425,308.761047 515.279785,308.003265 
	C517.535950,306.997253 519.719727,306.023529 522.997009,304.562195 
	C520.695801,303.379944 519.638062,302.836517 518.328064,302.163513 
	C523.894348,297.047180 519.725891,294.913971 515.579285,292.035553 
	C518.271545,292.035553 521.024719,292.362579 523.626770,291.878113 
	C524.699951,291.678253 526.146484,289.778992 526.221863,288.577271 
	C526.520813,283.812164 526.344727,279.017273 526.344727,273.828522 
	C531.176392,273.237915 536.180542,272.662506 541.173706,272.003510 
	C544.755249,271.530853 546.304138,272.721161 546.076477,276.610962 
	C545.846619,280.538177 546.314514,284.506226 546.805908,288.719818 
z"/>
<path fill="#2528E0" opacity="1.000000" stroke="none" 
	d="
M552.209534,292.707642 
	C550.692139,292.203644 549.387634,291.414154 548.056885,290.260620 
	C549.918823,289.501953 551.807007,289.107300 552.916199,288.875458 
	C552.916199,274.787689 552.916199,260.874390 552.905762,246.492889 
	C554.303711,247.353790 555.712158,248.682861 557.120544,250.011917 
	C556.601318,250.123184 556.082092,250.234451 555.562866,250.345718 
	C555.562866,254.960632 555.515808,259.576141 555.573364,264.190369 
	C555.672302,272.125458 556.022766,280.064758 555.809570,287.989990 
	C555.769043,289.496613 553.606140,290.946198 552.209534,292.707642 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M592.409912,256.875275 
	C595.439758,257.400970 598.083679,257.753723 600.683655,258.311371 
	C604.675293,259.167480 607.484985,261.128418 606.914734,265.814453 
	C606.446167,269.664948 603.617065,271.647278 598.809753,271.065460 
	C594.705078,270.568665 590.650879,269.654449 586.169495,268.852448 
	C586.828308,265.193848 587.526001,261.836609 588.014954,258.449249 
	C588.444275,255.474518 590.164307,256.107025 592.409912,256.875275 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M583.924194,283.417175 
	C583.924194,283.417175 583.759766,283.255035 584.211426,283.070709 
	C585.369019,280.827606 586.075012,278.768829 586.950134,276.216888 
	C591.447510,276.764709 596.260864,277.351013 601.074158,277.937317 
	C601.074158,277.937317 600.995239,277.932312 601.037842,278.272034 
	C602.097046,279.382507 603.113708,280.153229 604.130371,280.923950 
	C604.130371,280.923950 604.076843,280.991486 604.047119,281.343292 
	C603.439331,286.437683 601.030945,288.796051 596.025513,288.028748 
	C592.933411,287.554688 589.737061,287.565094 586.737366,286.783203 
	C585.585449,286.482971 584.849609,284.586365 583.924194,283.417175 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M583.625854,283.704346 
	C584.849609,284.586365 585.585449,286.482971 586.737366,286.783203 
	C589.737061,287.565094 592.933411,287.554688 596.025513,288.028748 
	C601.030945,288.796051 603.439331,286.437683 604.043091,281.300385 
	C605.612122,286.269653 603.271606,290.108276 598.076965,289.843658 
	C593.662231,289.618805 589.252563,288.667725 584.940674,287.624542 
	C584.155823,287.434723 583.852844,285.253510 583.625854,283.704346 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M600.862549,277.621887 
	C596.260864,277.351013 591.447510,276.764709 586.950134,276.216888 
	C586.075012,278.768829 585.369019,280.827606 584.266968,282.969513 
	C584.292480,280.166443 584.713989,277.280212 585.185181,274.053436 
	C590.690430,275.211395 595.670715,276.258942 600.862549,277.621887 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M604.099731,280.585785 
	C603.113708,280.153229 602.097046,279.382507 601.073975,278.256042 
	C602.068115,278.682739 603.068542,279.465179 604.099731,280.585785 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M718.825500,760.581909 
	C720.634216,760.861450 722.511414,761.455688 724.771851,762.108032 
	C724.236450,763.030457 723.317871,763.894836 722.152222,764.991638 
	C720.917358,763.439636 719.905701,762.168091 718.825500,760.581909 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M713.978760,754.430603 
	C717.160400,753.694336 717.734680,755.843262 717.905762,758.729004 
	C716.596985,757.660583 715.326721,756.204224 713.978760,754.430603 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M726.577332,761.202759 
	C727.308044,760.202332 728.348267,759.131042 729.774902,758.106018 
	C729.069824,759.145447 727.978333,760.138672 726.577332,761.202759 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M730.354004,758.077515 
	C730.257812,757.863037 730.413269,757.561890 730.801758,757.145996 
	C730.891663,757.351074 730.748657,757.670898 730.354004,758.077515 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M725.385254,762.123596 
	C725.303589,761.878296 725.496094,761.578613 725.961792,761.226929 
	C726.043152,761.473083 725.851257,761.771179 725.385254,762.123596 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M717.921021,759.358765 
	C718.153503,759.285461 718.439453,759.481689 718.775757,759.942749 
	C718.542236,760.014526 718.258423,759.821350 717.921021,759.358765 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M361.039856,757.328979 
	C359.885529,759.282532 358.359955,761.125305 356.538361,763.114136 
	C355.712738,759.777283 357.570892,758.043701 361.039856,757.328979 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M367.991150,749.281860 
	C367.474945,750.536560 366.577942,751.705139 365.381104,752.986816 
	C365.924286,751.798645 366.767303,750.497192 367.991150,749.281860 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M364.706238,753.063599 
	C364.352142,754.064148 363.602722,755.046753 362.577850,756.172363 
	C362.971893,755.225403 363.641418,754.135498 364.706238,753.063599 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M362.058472,756.291382 
	C362.187866,756.548889 362.018982,756.860962 361.606750,757.292053 
	C361.495636,757.055969 361.627899,756.700928 362.058472,756.291382 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M369.964478,747.184204 
	C370.065369,747.412476 369.893158,747.747009 369.458679,748.196472 
	C369.361389,747.971130 369.526367,747.630798 369.964478,747.184204 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M369.029114,748.265869 
	C369.150391,748.517639 368.979919,748.834778 368.558533,749.264893 
	C368.450867,749.028931 368.594116,748.680115 369.029114,748.265869 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M377.651154,749.095703 
	C376.534027,753.706543 374.940582,758.316772 369.035309,759.743530 
	C371.552338,756.182373 374.444580,752.702576 377.651154,749.095703 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M375.014404,730.395325 
	C376.875000,731.558105 378.876892,733.024475 380.849915,734.859436 
	C378.932617,733.718323 377.044159,732.208618 375.014404,730.395325 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M384.950623,739.355774 
	C386.536926,740.970337 386.788940,742.488708 383.309021,742.974731 
	C383.623840,741.876648 384.314148,740.781067 384.950623,739.355774 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M368.462585,759.755432 
	C368.571625,759.996338 368.424805,760.338379 367.989258,760.743042 
	C367.869263,760.489258 368.037994,760.172913 368.462585,759.755432 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M379.620728,746.896240 
	C379.713959,747.114258 379.548676,747.424866 379.133331,747.840820 
	C379.042938,747.627014 379.202606,747.307922 379.620728,746.896240 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M380.855774,735.405273 
	C381.084839,735.310852 381.402985,735.479736 381.812103,735.912598 
	C381.583679,736.007202 381.264282,735.837830 380.855774,735.405273 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M381.915833,736.363281 
	C382.132721,736.273315 382.437714,736.442383 382.846680,736.859009 
	C382.635101,736.945129 382.319550,736.783752 381.915833,736.363281 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M382.984009,737.301025 
	C383.183197,737.238953 383.471710,737.428284 383.862610,737.860596 
	C383.667786,737.919800 383.370544,737.736145 382.984009,737.301025 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M381.700073,743.962402 
	C381.906311,744.208557 381.757690,744.499634 381.295013,744.870850 
	C381.205444,744.689148 381.326874,744.374573 381.700073,743.962402 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M380.614014,745.734802 
	C380.709473,745.958008 380.535767,746.314697 380.079651,746.788940 
	C379.979767,746.560425 380.162292,746.214355 380.614014,745.734802 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M378.691711,747.973145 
	C378.769623,748.174072 378.598389,748.463135 378.195251,748.862305 
	C378.123077,748.668579 378.282837,748.364929 378.691711,747.973145 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M372.813446,728.469788 
	C373.042145,728.365479 373.375702,728.518250 373.802917,728.942139 
	C373.570465,729.051086 373.244385,728.888977 372.813446,728.469788 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M373.888611,729.373047 
	C374.107574,729.284790 374.421021,729.457336 374.837402,729.885864 
	C374.621246,729.972534 374.302185,729.803162 373.888611,729.373047 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M355.207794,315.861115 
	C356.008301,314.456665 357.173096,312.609467 358.992249,315.613373 
	C357.933472,315.938965 356.784454,315.932587 355.207794,315.861115 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M360.034790,318.202972 
	C360.667389,318.362610 361.288544,318.862183 361.970154,319.661530 
	C361.361511,319.488495 360.692444,319.015717 360.034790,318.202972 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M352.249207,317.925995 
	C352.638184,317.212646 353.406311,316.525696 354.490631,315.791077 
	C354.080688,316.479706 353.354492,317.216064 352.249207,317.925995 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M359.110535,316.130554 
	C359.320099,316.098572 359.595306,316.286743 359.843353,316.732391 
	C359.527893,316.851898 359.314545,316.638824 359.110535,316.130554 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M408.393127,339.036804 
	C409.524536,338.836273 410.621399,338.965759 411.718292,339.095245 
	C411.570465,339.670746 411.422638,340.246216 411.274811,340.821716 
	C410.302734,340.336761 409.330658,339.851807 408.393127,339.036804 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M413.657196,300.869263 
	C413.983612,302.731018 413.949005,304.534607 413.826385,306.786682 
	C413.178162,305.122284 409.110077,303.250793 413.657196,300.869263 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M415.002991,310.380157 
	C416.717590,310.346741 418.459625,310.671265 420.588318,310.994202 
	C418.954926,311.807495 416.822357,315.250031 415.002991,310.380157 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M413.682495,307.497620 
	C414.088501,307.886780 414.536865,308.597382 414.980896,309.665100 
	C414.559326,309.287842 414.142090,308.553436 413.682495,307.497620 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M414.285522,272.925293 
	C414.463562,272.627014 414.934326,272.339020 415.693054,272.022461 
	C415.513397,272.307770 415.045837,272.621674 414.285522,272.925293 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M423.290497,310.037964 
	C423.486938,309.719055 424.000244,309.390778 424.836273,309.056763 
	C424.641754,309.376862 424.124542,309.702698 423.290497,310.037964 
z"/>
<path fill="#161C8F" opacity="1.000000" stroke="none" 
	d="
M412.287659,273.904144 
	C412.277191,273.651337 412.571045,273.417023 413.151367,273.141541 
	C413.155853,273.374451 412.873901,273.648529 412.287659,273.904144 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M421.327637,310.953125 
	C421.330048,310.719788 421.659668,310.457794 422.313232,310.155029 
	C422.309753,310.384338 421.982300,310.654419 421.327637,310.953125 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M462.150665,295.115387 
	C467.921051,297.510864 473.820984,299.025330 480.592346,297.098572 
	C477.872406,297.983917 474.764526,299.023376 471.575348,299.648163 
	C469.713928,300.012878 467.722595,299.714630 465.086914,299.714630 
	C464.485535,298.858429 463.301544,297.172821 462.150665,295.115387 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M482.008545,296.920593 
	C482.218201,296.601593 482.769653,296.292847 483.652222,295.956940 
	C483.439026,296.263458 482.894684,296.597168 482.008545,296.920593 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M461.149780,294.103210 
	C461.373688,294.007172 461.707855,294.172699 462.142212,294.606110 
	C461.914948,294.704254 461.587463,294.534546 461.149780,294.103210 
z"/>
<path fill="#1F24B7" opacity="1.000000" stroke="none" 
	d="
M486.024292,296.011353 
	C485.856720,296.011475 485.689117,296.011627 485.270752,296.015289 
	C485.020020,296.018768 485.004700,295.622040 485.002533,295.424255 
	C485.000366,295.226501 485.390106,294.955841 485.672607,295.197937 
	C485.978180,295.630493 486.001251,295.820923 486.024292,296.011353 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M490.977081,262.334381 
	C491.841766,263.663452 492.580261,265.332489 493.297882,266.954285 
	C489.905090,266.954285 487.315704,266.954285 484.726318,266.954285 
	C494.065735,272.351349 483.934509,274.639893 482.393463,278.916382 
	C485.933594,278.535217 488.465393,277.924591 490.940155,278.103455 
	C492.908417,278.245697 494.807007,279.352631 496.736511,280.031311 
	C495.991089,281.923920 495.245667,283.816559 494.148529,285.819336 
	C494.215698,284.191864 494.634613,282.454285 495.206482,280.082062 
	C493.045349,279.978119 491.172699,279.888062 489.446564,279.805023 
	C484.281647,289.947693 478.633209,293.215729 470.297455,291.115295 
	C463.807953,289.480072 459.676147,284.416901 458.548828,276.718384 
	C457.713715,271.015259 460.973267,264.540131 466.152100,261.614288 
	C471.658112,258.503601 478.756012,258.940033 483.669312,263.504730 
	C486.882019,266.489502 488.608734,264.101318 490.977081,262.334381 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M486.422485,296.016846 
	C486.001251,295.820923 485.978180,295.630493 485.906189,295.156403 
	C485.857300,294.872742 485.908875,294.918457 486.124084,294.851196 
	C486.559875,294.528748 486.780396,294.273499 487.000916,294.018250 
	C487.102325,293.698853 487.290833,293.443878 487.968445,293.377777 
	C489.911987,292.667664 491.453552,291.833069 492.995117,290.998505 
	C492.959045,297.468170 492.959045,297.468170 486.422485,296.016846 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M492.998627,290.573364 
	C491.453552,291.833069 489.911987,292.667664 488.183350,293.268005 
	C489.436859,291.343170 490.877472,289.652588 492.608978,287.779175 
	C492.933960,288.446991 492.968048,289.297577 492.998627,290.573364 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M487.931458,258.453400 
	C488.859222,258.940277 489.912140,259.721527 490.937592,260.907104 
	C489.958954,260.456879 489.007782,259.602325 487.931458,258.453400 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M486.803772,257.459869 
	C487.058105,257.340698 487.395386,257.497864 487.818176,257.933990 
	C487.564728,258.054047 487.225708,257.895111 486.803772,257.459869 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M493.660583,285.922119 
	C493.783447,286.118958 493.663727,286.424286 493.341125,286.877258 
	C493.231445,286.693481 493.324738,286.362061 493.660583,285.922119 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M486.733276,294.055542 
	C486.780396,294.273499 486.559875,294.528748 486.090088,294.855072 
	C486.049133,294.648407 486.257385,294.370636 486.733276,294.055542 
z"/>
<path fill="#F1F4FC" opacity="1.000000" stroke="none" 
	d="
M449.778870,782.434326 
	C449.404694,782.902527 449.207520,783.142456 449.174133,783.743530 
	C444.689697,794.878967 440.186340,805.772339 435.551483,816.983887 
	C433.757904,816.267944 432.001831,815.567017 429.825104,814.698120 
	C433.964081,803.934998 438.015228,793.400269 442.088745,782.488770 
	C442.111145,782.112061 442.085022,782.080017 442.403229,781.923889 
	C443.548492,779.539978 444.375549,777.312256 445.202606,775.084473 
	C445.202606,775.084473 445.129242,775.088684 445.155212,775.114929 
	C447.125488,775.506897 449.069824,775.872620 451.377655,776.337585 
	C451.601288,776.729309 451.577240,777.031067 451.708496,777.569336 
	C451.102295,779.226746 450.456635,780.656921 449.778870,782.434326 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M418.247131,779.911133 
	C419.690338,776.624451 421.485291,773.317078 423.577026,769.805176 
	C424.464935,774.235046 421.591949,777.093506 418.247131,779.911133 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M416.071899,783.789795 
	C416.231628,782.575073 416.780609,781.386353 417.628693,780.051819 
	C417.438904,781.209290 416.950012,782.512634 416.071899,783.789795 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M425.203430,767.896179 
	C424.885773,767.202576 424.919373,766.495422 424.903259,765.436646 
	C425.494598,765.443237 426.135620,765.801575 426.776642,766.159912 
	C426.369293,766.734192 425.961945,767.308411 425.203430,767.896179 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M419.916779,761.778931 
	C418.992188,761.705566 418.032257,761.255371 416.897949,760.536621 
	C417.776184,760.646057 418.828827,761.024109 419.916779,761.778931 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M423.746033,764.038330 
	C423.159210,764.172119 422.643402,763.972229 421.980469,763.533325 
	C422.494598,763.431152 423.155823,763.567932 423.746033,764.038330 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M424.822632,764.892578 
	C424.596527,765.022766 424.254089,764.891052 423.789917,764.510254 
	C424.014191,764.384338 424.360260,764.507507 424.822632,764.892578 
z"/>
<path fill="#1B21A0" opacity="1.000000" stroke="none" 
	d="
M424.341064,768.863037 
	C424.242889,768.684753 424.343506,768.368225 424.683594,767.951660 
	C424.901062,768.189087 424.773315,768.480103 424.341064,768.863037 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M493.464020,794.603394 
	C494.570129,795.883362 495.984802,797.096497 495.856689,798.115723 
	C495.448212,801.366394 494.466400,804.544983 493.558411,808.373779 
	C488.485687,807.170837 483.476379,806.281616 478.676819,804.739624 
	C475.481689,803.713074 473.343781,801.177795 474.289001,797.439514 
	C475.266083,793.575317 477.880493,791.417847 481.964935,792.034058 
	C485.701752,792.597839 489.368683,793.624878 493.464020,794.603394 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M471.195862,816.251343 
	C472.595306,811.420227 475.599335,809.892700 480.050781,810.888000 
	C483.912659,811.751587 487.781403,812.584473 492.252869,813.563477 
	C491.318542,817.831604 490.454895,821.776978 489.434448,826.438538 
	C484.302002,825.125854 479.359894,824.327209 474.851379,822.473694 
	C473.112366,821.758789 472.358307,818.647949 471.195862,816.251343 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M485.058014,786.467285 
	C487.795197,786.618774 490.796692,787.053650 493.790985,787.910034 
	C490.963287,787.804626 488.142822,787.277649 485.058014,786.467285 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M496.618652,787.981934 
	C497.766205,787.980042 498.508881,787.989441 499.733459,788.220154 
	C500.420074,788.712036 500.688110,788.883606 501.019531,788.956238 
	C501.019531,788.956238 501.513824,788.973572 501.730682,789.336853 
	C501.921753,789.934570 501.895996,790.169067 501.870209,790.403503 
	C499.350739,789.854431 496.831299,789.305359 494.049988,788.480530 
	C494.596680,788.134277 495.405212,788.063782 496.618652,787.981934 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M502.061249,790.619263 
	C501.895996,790.169067 501.921753,789.934570 501.977753,789.347534 
	C502.641357,789.469421 503.274780,789.943909 503.933838,790.746582 
	C503.390442,790.994873 502.821350,790.914917 502.061249,790.619263 
z"/>
<path fill="#1D22AE" opacity="1.000000" stroke="none" 
	d="
M444.832520,775.188477 
	C444.375549,777.312256 443.548492,779.539978 442.405151,781.940613 
	C442.219177,779.609802 440.881805,776.595459 444.832520,775.188477 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M478.021881,764.308228 
	C478.544769,764.180237 479.104126,764.343018 479.831726,764.751709 
	C479.352783,764.864746 478.705566,764.731934 478.021881,764.308228 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M482.092712,766.163574 
	C482.006439,765.995117 482.500061,765.995789 482.746826,765.995850 
	C482.722015,766.107971 482.450470,766.220032 482.092712,766.163574 
z"/>
<path fill="#1A77F3" opacity="1.000000" stroke="none" 
	d="
M737.156982,728.383179 
	C737.397522,728.056885 737.681641,728.144775 737.963806,728.696594 
	C737.734619,728.901611 737.480835,728.780518 737.156982,728.383179 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M500.608307,827.533936 
	C501.058502,827.650024 501.334503,827.933533 501.589172,828.235107 
	C501.595734,828.242737 501.368286,828.448120 501.249969,828.561279 
	C500.977081,828.275391 500.704193,827.989563 500.608307,827.533936 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M500.901489,788.746826 
	C500.688110,788.883606 500.420074,788.712036 500.105164,788.225098 
	C500.314941,788.099731 500.577789,788.276001 500.901489,788.746826 
z"/>
<path fill="#1A6FF5" opacity="1.000000" stroke="none" 
	d="
M740.805481,666.241516 
	C741.144653,667.399353 741.144653,668.492004 741.144653,669.584656 
	C740.505005,669.490845 739.865295,669.397095 739.225647,669.303284 
	C739.639221,668.260925 740.052795,667.218628 740.805481,666.241516 
z"/>
<path fill="#1779F6" opacity="1.000000" stroke="none" 
	d="
M741.034058,660.148682 
	C741.419434,659.383667 741.790833,658.975159 742.162231,658.566650 
	C742.452393,659.783386 742.742615,661.000122 743.032776,662.216858 
	C742.679443,662.330994 742.326050,662.445068 741.972656,662.559204 
	C741.655090,661.874512 741.337524,661.189819 741.034058,660.148682 
z"/>
<path fill="#0F8FFB" opacity="1.000000" stroke="none" 
	d="
M707.576538,549.985229 
	C708.218689,550.837036 708.485168,551.627014 708.875977,552.708496 
	C708.336487,559.713135 707.180786,566.421875 707.121521,573.140320 
	C707.027832,583.754517 707.778931,594.373413 707.953125,604.993896 
	C708.037781,610.154541 707.625549,615.323242 707.719604,620.747803 
	C705.725769,624.200439 703.389038,627.266052 698.089844,625.320618 
	C698.089844,598.433838 698.089844,571.380676 698.089844,545.263000 
	C700.852783,546.676208 704.026794,548.299805 707.576538,549.985229 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M678.048218,567.926147 
	C678.619263,568.214539 679.383362,568.356140 679.731750,568.814026 
	C683.499756,573.766479 682.894165,587.969238 678.410767,592.980469 
	C678.058777,593.061584 677.973938,592.982544 678.012817,592.581055 
	C682.755859,584.115540 682.152283,576.075928 677.968994,568.022522 
	C677.997437,567.992126 678.048218,567.926147 678.048218,567.926147 
z"/>
<path fill="#1B6AF4" opacity="1.000000" stroke="none" 
	d="
M677.088623,566.924805 
	C677.396667,567.054321 677.632324,567.257690 677.934937,567.720459 
	C678.048218,567.926147 677.997437,567.992126 677.724243,567.940369 
	C677.305542,567.588989 677.159973,567.289429 677.014404,566.989807 
	C677.014404,566.989807 677.062561,566.944885 677.088623,566.924805 
z"/>
<path fill="#282CEC" opacity="1.000000" stroke="none" 
	d="
M618.987793,556.990234 
	C619.291016,557.330322 619.594299,557.670349 619.483948,558.329468 
	C613.853027,558.648499 608.635681,558.648499 603.369995,558.648499 
	C603.369995,573.568970 603.369995,587.600586 603.369995,601.724182 
	C605.089905,601.888916 606.557678,602.029541 608.025513,602.170166 
	C608.025513,602.170105 608.052368,602.577942 607.844910,603.057373 
	C606.091675,607.014221 603.499084,610.407471 603.211182,613.986145 
	C602.546692,622.244995 603.000427,630.593811 603.000610,638.906128 
	C597.300049,638.604919 591.599487,638.303772 585.450073,637.600891 
	C585.334167,631.334045 585.936523,625.469788 585.954956,619.603638 
	C586.053162,588.408386 586.005066,557.212585 586.007568,526.017029 
	C591.357849,526.008240 596.708191,525.999390 602.475403,526.410645 
	C602.561035,532.094971 602.562744,537.406128 601.829956,542.613892 
	C600.249817,553.843323 602.774231,557.003540 614.008484,557.004272 
	C615.668274,557.004333 617.328003,556.995117 618.987793,556.990234 
z"/>
<path fill="#2528E0" opacity="1.000000" stroke="none" 
	d="
M585.549683,526.010498 
	C586.005066,557.212585 586.053162,588.408386 585.954956,619.603638 
	C585.936523,625.469788 585.334167,631.334045 585.000610,637.599121 
	C584.166748,638.001038 583.333557,638.004761 582.500305,638.004578 
	C574.380066,638.003052 574.499329,638.016113 575.382568,630.049377 
	C575.668091,627.474243 575.017700,624.809875 575.014893,622.186096 
	C574.983459,593.022827 574.997803,563.859619 574.993530,534.696350 
	C574.992249,526.002869 574.983215,526.002869 584.091980,526.001282 
	C584.425293,526.001221 584.758545,526.002991 585.549683,526.010498 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M603.411987,638.931396 
	C603.000427,630.593811 602.546692,622.244995 603.211182,613.986145 
	C603.499084,610.407471 606.091675,607.014221 607.863281,603.260864 
	C613.167053,603.317810 618.244873,603.650635 624.000000,604.027832 
	C624.000000,607.300293 623.982422,610.064941 624.003540,612.829407 
	C624.052551,619.247437 623.578064,625.731445 624.350159,632.062622 
	C624.979370,637.221680 622.642029,638.670288 618.505554,638.944641 
	C615.031494,639.175049 611.531006,639.005615 607.593506,639.007812 
	C606.037598,638.987183 604.930481,638.971924 603.411987,638.931396 
z"/>
<path fill="#2739F0" opacity="1.000000" stroke="none" 
	d="
M618.991699,556.557007 
	C617.328003,556.995117 615.668274,557.004333 614.008484,557.004272 
	C602.774231,557.003540 600.249817,553.843323 601.829956,542.613892 
	C602.562744,537.406128 602.561035,532.094971 602.942505,526.415344 
	C608.008850,525.990967 613.025085,525.981934 618.504456,525.972351 
	C618.976929,536.022461 618.986267,546.073120 618.991699,556.557007 
z"/>
<path fill="#FEFEFF" opacity="1.000000" stroke="none" 
	d="
M608.483154,602.078247 
	C606.557678,602.029541 605.089905,601.888916 603.369995,601.724182 
	C603.369995,587.600586 603.369995,573.568970 603.369995,558.648499 
	C608.635681,558.648499 613.853027,558.648499 619.530884,558.564819 
	C624.023376,558.541809 628.055359,558.602600 632.542969,558.573364 
	C641.025696,558.548889 649.052673,558.614502 657.538513,558.581665 
	C665.131653,558.967529 671.597717,560.967163 676.733276,566.805664 
	C677.159973,567.289429 677.305542,567.588989 677.695862,567.970764 
	C682.152283,576.075928 682.755859,584.115540 677.969971,592.541992 
	C673.869873,597.677307 668.648315,600.399963 662.324890,601.983887 
	C644.299500,602.084167 626.620117,602.035217 608.483154,602.078247 
z"/>
<path fill="#1D61F3" opacity="1.000000" stroke="none" 
	d="
M660.508301,603.982239 
	C661.282349,611.509583 661.735291,619.039062 661.852905,626.573669 
	C661.913025,630.427063 661.372375,634.289734 661.050049,638.541504 
	C657.220764,638.630554 653.441589,638.056091 649.669312,638.097595 
	C645.843567,638.139648 646.000427,636.037476 646.074158,633.267578 
	C646.284302,625.375488 646.262695,617.476868 646.308655,609.580688 
	C646.327454,606.352173 647.541931,604.136902 651.080261,604.021606 
	C654.066833,603.924316 657.058838,603.991882 660.508301,603.982239 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M488.985199,530.085144 
	C494.327820,530.062683 499.670471,530.040222 505.472290,530.041077 
	C501.151367,534.693909 503.789337,540.714050 503.139252,546.062195 
	C502.502441,551.301208 503.000793,556.678101 502.999634,562.453552 
	C497.984772,562.929199 492.971252,562.945801 487.495148,562.526733 
	C487.342010,557.035095 488.530365,551.835876 487.735260,546.960022 
	C486.772278,541.054932 487.041260,535.600342 488.985199,530.085144 
z"/>
<path fill="#2832F1" opacity="1.000000" stroke="none" 
	d="
M602.536072,442.978607 
	C605.042542,443.002258 607.080750,443.039459 609.553284,443.124207 
	C609.990295,454.797211 609.992981,466.422668 609.986694,478.471375 
	C602.311890,478.906067 594.646057,478.917480 586.500854,478.488281 
	C585.700256,466.416046 585.379028,454.784424 585.057739,443.152832 
	C590.727783,443.099274 596.397766,443.045715 602.536072,442.978607 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M520.022949,443.041504 
	C524.045410,443.030609 528.067932,443.019714 532.554688,443.040344 
	C533.018982,451.372772 533.018982,459.673676 533.018982,467.974609 
	C532.703674,467.885345 532.388367,467.796051 532.073059,467.706787 
	C532.883301,469.774384 533.693604,471.841949 534.503906,473.909485 
	C533.280273,474.268585 532.056580,474.627655 530.502686,474.974792 
	C527.612793,472.412994 525.053101,469.863129 522.181458,467.178406 
	C519.989502,465.279877 518.109436,463.516174 516.229248,461.133301 
	C517.093323,459.562500 518.577393,458.692810 518.716003,457.645081 
	C519.356506,452.804352 519.622681,447.914062 520.022949,443.041504 
z"/>
<path fill="#141B85" opacity="1.000000" stroke="none" 
	d="
M519.554077,443.024109 
	C519.622681,447.914062 519.356506,452.804352 518.716003,457.645081 
	C518.577393,458.692810 517.093323,459.562500 516.071045,460.846252 
	C515.380066,460.941864 514.847046,460.705414 514.037903,459.998260 
	C513.474243,459.367859 513.186768,459.208099 512.899231,459.048370 
	C510.901398,458.104584 508.903534,457.160828 506.457794,455.817566 
	C505.684723,455.256012 505.359589,455.093964 505.034424,454.931885 
	C505.034424,454.931885 505.029633,454.955780 505.046265,454.942017 
	C500.998474,452.930176 496.934052,450.932098 492.504608,448.918335 
	C486.411835,447.574341 480.684113,446.246033 474.517212,444.857910 
	C474.083740,444.603546 474.089447,444.408966 474.139069,443.922729 
	C478.070862,443.759308 481.962646,444.065918 485.845947,443.981842 
	C496.927521,443.741913 508.005646,443.343018 519.554077,443.024109 
z"/>
<path fill="#161D99" opacity="1.000000" stroke="none" 
	d="
M513.011353,459.276184 
	C513.186768,459.208099 513.474243,459.367859 513.878784,459.749695 
	C513.704956,459.815826 513.414185,459.659882 513.011353,459.276184 
z"/>
<path fill="#1E58F4" opacity="1.000000" stroke="none" 
	d="
M505.167175,455.187927 
	C505.359589,455.093964 505.684723,455.256012 506.178986,455.639648 
	C505.998688,455.722107 505.649292,455.583008 505.167175,455.187927 
z"/>
<path fill="#2528E0" opacity="1.000000" stroke="none" 
	d="
M453.237518,480.670410 
	C451.597626,480.939697 450.234924,480.941406 448.465637,480.929718 
	C448.059052,468.904663 448.059052,456.893036 448.059052,443.993256 
	C450.617401,443.993256 453.848358,443.993256 457.537842,443.995422 
	C458.330536,455.697754 458.664764,467.397949 458.999512,479.547852 
	C457.171570,480.132629 455.343109,480.267761 453.237518,480.670410 
z"/>
<path fill="#2444F2" opacity="1.000000" stroke="none" 
	d="
M488.522644,530.058716 
	C487.041260,535.600342 486.772278,541.054932 487.735260,546.960022 
	C488.530365,551.835876 487.342010,557.035095 487.030579,562.505066 
	C482.345306,562.944336 477.662018,562.969604 472.519867,562.973022 
	C472.374542,553.673767 472.628937,544.393555 473.061371,535.121704 
	C473.131317,533.620911 473.991943,532.157043 474.719666,530.374329 
	C479.321533,530.059143 483.690796,530.045715 488.522644,530.058716 
z"/>
<path fill="#2125BE" opacity="1.000000" stroke="none" 
	d="
M546.041016,443.546722 
	C549.744324,443.100342 553.428101,443.081787 557.561646,443.491211 
	C557.757324,445.700653 557.209961,447.496704 557.297668,449.261139 
	C557.789307,459.148682 558.434204,469.028625 559.026794,478.911194 
	C553.681274,478.916199 548.335754,478.921204 542.531616,478.888672 
	C542.074585,478.188507 541.791382,477.282532 542.119263,476.898895 
	C549.936218,467.753448 546.663391,457.186554 546.047729,446.960083 
	C545.987976,445.967957 546.027954,444.969879 546.041016,443.546722 
z"/>
<path fill="#282ADA" opacity="1.000000" stroke="none" 
	d="
M584.618652,443.099365 
	C585.379028,454.784424 585.700256,466.416046 586.036499,478.458160 
	C581.350830,478.903534 576.650269,478.938354 571.476562,478.564240 
	C571.002258,469.380096 570.925476,460.603882 571.046692,451.830353 
	C571.082581,449.233063 571.676575,446.643463 572.014832,444.050354 
	C576.069702,443.715546 580.124634,443.380737 584.618652,443.099365 
z"/>
<path fill="#2528CA" opacity="1.000000" stroke="none" 
	d="
M571.558899,444.029541 
	C571.676575,446.643463 571.082581,449.233063 571.046692,451.830353 
	C570.925476,460.603882 571.002258,469.380096 571.011841,478.549622 
	C567.327332,478.950043 563.634399,478.956177 559.484131,478.936768 
	C558.434204,469.028625 557.789307,459.148682 557.297668,449.261139 
	C557.209961,447.496704 557.757324,445.700653 558.023071,443.523071 
	C562.390808,443.420837 566.746887,443.714783 571.558899,444.029541 
z"/>


    
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
